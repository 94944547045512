import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/cn';

export const badgeVariants = cva(
  'rounded leading-none inline-flex items-center justify-center py-1 px-2 text-xs gap-1.5',
  {
    variants: {
      variant: {
        default: 'badge-default bg-gray-100 text-gray-800',
        yellow: 'badge-yellow bg-yellow-100 text-yellow-800',
        red: 'badge-red bg-red-100 text-red-800',
        green: 'badge-green bg-green-100 text-green-800',
        blue: 'badge-blue bg-blue-100 text-blue-800',
        primary: 'badge-primary bg-primary-100 text-primary-800',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
export interface BadgeProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof badgeVariants> {}

export const Badge: React.FC<BadgeProps> = ({
  className,
  variant,
  children,
  ...props
}: BadgeProps) => {
  return (
    <span
      className={cn(badgeVariants({ variant }), className)}
      data-cy={`${variant}-badge`}
      {...props}
    >
      {children}
    </span>
  );
};
