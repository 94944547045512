import React from 'react';
import { useHits } from 'react-instantsearch';

import ContentHit from './ContentHit';

interface Hit {
  objectID: string;
  genres: string[];
  // Add other fields from your hit object as needed
}

interface HitsByCategoryProps {
  query: string;
}
// Define the Hit type with necessary fields
interface CustomHit extends Hit {
  objectID: string;
  genres: string[];
  title: string;
  poster_path: string;
  overview: string;
  vote_average: number;
  type?: string;
  __position: number;
  __queryID?: string;
  // Add other fields from your hit object as needed
}

// Define the Props type for the component
interface HitsByCategoryProps {
  query: string;
}

const HitsByCategory: React.FC<HitsByCategoryProps> = ({ query }) => {
  const { items } = useHits<CustomHit>();

  // Group hits by genre
  const genres = items.reduce<Record<string, CustomHit[]>>((acc, hit) => {
    hit.genres.forEach((genre) => {
      if (!Object.prototype.hasOwnProperty.call(acc, genre)) {
        acc[genre] = [];
      }
      acc[genre].push(hit);
    });
    return acc;
  }, {});

  return (
    <div
      className='relative'
      data-cy='search-results-container'
    >
      <div className='space-y-4'>
        {Object.keys(genres).map((genre) => (
          <div
            key={genre}
            className='space-y-4'
            data-cy={`search-results-${genre}`}
          >
            <div className='mt-2 flex items-center'>
              <span className='ml-4 font-semibold'>{genre}</span>
              <span
                className='ml-1 text-sm text-gray-600'
                data-cy={`search-results-count: ${genres[genre].length}`}
              >
                ({genres[genre].length})
              </span>
            </div>
            <div className='flex flex-wrap border-gray-300'>
              {genres[genre].map((hit) => (
                <ContentHit
                  key={hit.objectID}
                  hit={hit}
                />
              ))}
            </div>
            <hr className='border-gray-300' />
          </div>
        ))}
        {Object.keys(genres).length === 0 && (
          <div
            className='flex h-[60vh] items-center justify-center'
            data-cy='No results found'
          >
            <p className='text-center text-gray-600'>
              No Results Found for <strong>&quot;{query}&quot;</strong>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HitsByCategory;
