/**
 * @description Generate country flag base on the country code
 */

export function countryCodeToFlag(countryCode: string | null) {
  if (
    !countryCode ||
    countryCode.length !== 2 ||
    !/^[a-zA-Z]+$/.test(countryCode)
  )
    return;

  const flag = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...flag);
}
