import { useState } from 'react';
import { VariantProps } from 'class-variance-authority';
import { FieldName, FieldValues, Path, useFormContext } from 'react-hook-form';
import {
  ErrorMessage,
  FieldValuesFromFieldErrors,
} from '@hookform/error-message';

import {
  Button,
  PasswordCriteria,
  inputVariants,
  InputError,
} from '@/components';
import { cn } from '@/utils/cn';
import { useShowPasswordCriteriaTooltip } from '@/components/hooks';

export interface PasswordInputProps<T extends FieldValues>
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  name: Path<T>;
  showPasswordTooltip?: boolean;
  errorFieldName: FieldName<FieldValuesFromFieldErrors<T>>;
}

export function PasswordInput<T extends FieldValues>({
  showPasswordTooltip = true,
  name,
  className,
  errorFieldName,
  ...props
}: PasswordInputProps<T>) {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<T>();

  const [showPassword, setShowPassword] = useState(false);

  const { validPasswordTooltip, handleFocus, handleBlur } =
    useShowPasswordCriteriaTooltip();

  const handleTogglePassword = () => {
    setShowPassword((previousState) => !previousState);
  };
  return (
    <>
      <div
        className='relative'
        data-cy='password input'
      >
        <input
          {...register(name)}
          type={showPassword ? 'text' : 'password'}
          className={cn(
            inputVariants({}),
            Object.prototype.hasOwnProperty.call(errors, name) &&
              'border-red-600 bg-red-50',
            'pr-16',
            className,
          )}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
        />
        <Button
          variant='blue'
          intent='tertiary'
          type='button'
          className='absolute right-4 top-1/2 -translate-y-1/2 p-0'
          onClick={handleTogglePassword}
        >
          {showPassword ? 'Hide' : 'Show'}
        </Button>
        {showPasswordTooltip && validPasswordTooltip && (
          <PasswordCriteria password={watch(name)} />
        )}
      </div>
      <ErrorMessage
        errors={errors}
        name={errorFieldName}
        render={({ message }) => <InputError message={message} />}
      />
    </>
  );
}
