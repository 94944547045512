import { z } from 'zod';
import { emailSchema, passwordSchema } from '@/schemas/common.schema';

export const CreateAccountNewPasswordFormSchema = z
  .object({
    email: emailSchema,
    password: passwordSchema,
    confirmPassword: passwordSchema,
  })
  .superRefine((arg, ctx) => {
    if (arg.confirmPassword !== arg.password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Password did not match',
        path: ['confirmPassword'],
      });
    }
  });

export type TCreateNewPasswordForm = z.infer<
  typeof CreateAccountNewPasswordFormSchema
>;
