import React from 'react';
import { IconProps } from '../iconProps';

export const IconTrendDown: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.5001 12V18C22.5001 18.1989 22.421 18.3897 22.2804 18.5303C22.1397 18.671 21.949 18.75 21.7501 18.75H15.7501C15.5511 18.75 15.3604 18.671 15.2197 18.5303C15.0791 18.3897 15.0001 18.1989 15.0001 18C15.0001 17.8011 15.0791 17.6103 15.2197 17.4697C15.3604 17.329 15.5511 17.25 15.7501 17.25H19.9397L12.7501 10.0603L9.53068 13.2806C9.46102 13.3504 9.3783 13.4057 9.28726 13.4434C9.19621 13.4812 9.09861 13.5006 9.00005 13.5006C8.90149 13.5006 8.80389 13.4812 8.71285 13.4434C8.6218 13.4057 8.53908 13.3504 8.46943 13.2806L1.71943 6.53063C1.5787 6.3899 1.49963 6.19903 1.49963 6.00001C1.49963 5.80098 1.5787 5.61011 1.71943 5.46938C1.86016 5.32865 2.05103 5.24959 2.25005 5.24959C2.44907 5.24959 2.63995 5.32865 2.78068 5.46938L9.00005 11.6897L12.2194 8.46938C12.2891 8.39965 12.3718 8.34433 12.4628 8.30659C12.5539 8.26884 12.6515 8.24941 12.7501 8.24941C12.8486 8.24941 12.9462 8.26884 13.0373 8.30659C13.1283 8.34433 13.211 8.39965 13.2807 8.46938L21.0001 16.1897V12C21.0001 11.8011 21.0791 11.6103 21.2197 11.4697C21.3604 11.329 21.5511 11.25 21.7501 11.25C21.949 11.25 22.1397 11.329 22.2804 11.4697C22.421 11.6103 22.5001 11.8011 22.5001 12Z'
        fill={fill}
      />
    </svg>
  );
};
