/**
 * @description Limits the rate at which a function can fire.
 * @param {{ leading?: boolean }} options - Configuration options.
 * @param {boolean} [options.leading=false] - If set to false, the function will not be called immediately on the leading edge of the delay period.
 *                                            Instead, it will only be called after the specified delay has passed
 *                                            since the last time the debounced function was invoked.
 */
export function debounce<T extends unknown[] = [HTMLFormElement]>(
  debounceFunction: (...args: T) => void,
  delay: number,
  { leading }: { leading?: boolean } = {},
): (...args: T) => void {
  let timerId: unknown;

  return (...args: T) => {
    if (!timerId && leading) {
      debounceFunction(...args);
    }
    if (timerId) {
      clearTimeout(timerId as unknown as number);
    }

    timerId = setTimeout(() => debounceFunction(...args), delay);
  };
}
