import { create } from 'zustand';

import { TSingleProductVariantSchema } from '@/schemas';

interface SingleProductSizeQuantity {
  shoeSizeId: string;
  size: number | null;
  stock: number | null;
}

interface SingleProductCartInterface {
  productQuantity: number;
  totalCost: number;
  productPrice: number;
  sizeQuantityValues: SingleProductSizeQuantity[];
  isProductQuantityDirty: boolean;
  singleProductDetailVariants: TSingleProductVariantSchema[];
}

interface SingleProductCartActions {
  setProductPrice: (productPrice: number) => void;
  setSizeQuantityValues: (
    sizeQuantityValues: SingleProductSizeQuantity[],
  ) => void;
  cleanSingleProductCartStore: () => void;
  setIsProductQuantityDirty: (isProductQuantityDirty: boolean) => void;
  setSingleProductDetailVariants: (
    singleProductDetailVariants: TSingleProductVariantSchema[],
  ) => void;
}

const initialState: SingleProductCartInterface = {
  productQuantity: 0,
  totalCost: 0,
  productPrice: 0,
  sizeQuantityValues: [],
  isProductQuantityDirty: false,
  singleProductDetailVariants: [],
};

type TSingleProductCart = SingleProductCartInterface & SingleProductCartActions;

const calculateTotalQuantity = (
  sizeQuantityValues: SingleProductSizeQuantity[],
) => {
  return sizeQuantityValues.reduce((acc, { stock }) => acc + (stock ?? 0), 0);
};

const useSingleProductCartStore = create<TSingleProductCart>((set, get) => ({
  ...initialState,
  setProductPrice: (productPrice: number) => {
    set({ productPrice });
  },
  setIsProductQuantityDirty: (isProductQuantityDirty: boolean) => {
    set({ isProductQuantityDirty });
  },
  setSizeQuantityValues: (sizeQuantityValues: SingleProductSizeQuantity[]) => {
    const { productPrice } = get();
    const productQuantity = calculateTotalQuantity(sizeQuantityValues);
    const totalCost = productQuantity * productPrice;
    set({
      sizeQuantityValues,
      productQuantity,
      totalCost,
    });
  },
  setSingleProductDetailVariants: (
    singleProductDetailVariants: TSingleProductVariantSchema[],
  ) => {
    set({ singleProductDetailVariants });
  },
  cleanSingleProductCartStore: () => {
    set({
      ...initialState,
    });
  },
}));

export default useSingleProductCartStore;
