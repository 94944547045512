import { useSearchParams } from 'react-router-dom';

/**
 * @description Retrieves the multiple search parameters from the current URL and returns them as an object.
 */
export function getSearchParams<T extends string>(
  keys: T[],
): Record<T, string[]> {
  const [searchParams] = useSearchParams();
  const params: Record<T, string[]> = {} as Record<T, string[]>;

  keys.forEach((key) => {
    params[key] = searchParams.getAll(key);
  });

  return params;
}
