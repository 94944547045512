import { useState, useCallback, useRef } from 'react';

export const useClipboard = () => {
  const [copied, setCopied] = useState(false);
  const lastCopiedTextRef = useRef('');

  const copyToClipboard = useCallback(async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      lastCopiedTextRef.current = text;
    } catch (err) {
      console.error('Failed to copy text: ', err);
      setCopied(false);
    }
  }, []);

  return {
    copyToClipboard,
    copied,
  };
};
