import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { TANSTACK_QUERY_KEY } from '@/utils/constants';

export const useSyncLoginAcrossTabs = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === TANSTACK_QUERY_KEY.USER && event.newValue) {
        window.location.reload();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [navigate]);
};
