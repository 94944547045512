import { z } from 'zod';

import { api } from '@/utils/helper';
import { API_ENDPOINT, HttpMethods } from '@/utils/constants';
import {
  PaymentConfirmRequestSchema,
  PaymentConfirmResponseSchema,
  PaymentRequestSchema,
  PaymentResponseSchema,
} from '@/schemas/api/payment';
import {
  cartAddressUpdateRequestSchema,
  cartAddressUpdateResponseSchema,
} from '@/schemas/createShippingAddress.schema';

const payment = (path: string) =>
  api<
    z.infer<typeof PaymentRequestSchema>,
    z.infer<typeof PaymentResponseSchema>
  >({
    path,
    method: HttpMethods.POST,
    requestSchema: PaymentRequestSchema,
    responseSchema: PaymentResponseSchema,
  });

const paymentConfirm = (path: string) =>
  api<
    z.infer<typeof PaymentConfirmRequestSchema>,
    z.infer<typeof PaymentConfirmResponseSchema>
  >({
    path,
    method: HttpMethods.POST,
    requestSchema: PaymentConfirmRequestSchema,
    responseSchema: PaymentConfirmResponseSchema,
  });

const cartAddressPrimary = ({ cartId }: { cartId: string }) =>
  api<
    z.infer<typeof cartAddressUpdateRequestSchema>,
    z.infer<typeof cartAddressUpdateResponseSchema>
  >({
    method: 'PATCH',
    path: `${API_ENDPOINT.ORDERS}/${cartId}/update-address`,
    requestSchema: cartAddressUpdateRequestSchema,
    responseSchema: cartAddressUpdateResponseSchema,
  });

export const PaymentAPI = {
  payment,
  paymentConfirm,
  cartAddressPrimary,
};
