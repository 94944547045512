interface EmptyMessageProps {
  message: string;
  variant?: '400' | '500';
  className?: string;
}

export const EmptyMessage = ({
  message,
  variant = '500',
  className = '',
}: EmptyMessageProps): JSX.Element => {
  const textColor = `text-gray-${variant}`;
  return (
    <span className={`text-sm font-normal ${textColor} ${className}`}>
      {message}
    </span>
  );
};
