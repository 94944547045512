import { z } from 'zod';
import { AxiosRequestConfig, Method } from 'axios';

import {
  instanceWithInterceptor,
  instanceWithoutInterceptor,
} from '@/components/lib';

interface APICallPayload<Request, Response> {
  path: string;
  method: Method;
  type?: 'protected' | 'public';
  requestSchema: z.ZodType<Request>;
  responseSchema: z.ZodType<Response>;
}

/**
 * Makes an API call with the given parameters.
 * @param {APICallPayload<Request, Response>} payload - API call configuration.
 * @returns {Promise<Response>} - A promise that resolves to the response data.
 * @throws {Error} - If the safe-parsing of the response data fails.
 */
export function api<Request, Response>({
  type = 'protected',
  path,
  method,
  requestSchema,
  responseSchema,
}: APICallPayload<Request, Response>) {
  return async (requestPayload: Request, id?: string) => {
    requestSchema.parse(requestPayload);

    const url = (() => {
      if (id) {
        // Check if the path includes either ':companyId' or ':userId' and replace it with the id
        return path.includes(':companyId')
          ? path.replace(':companyId', id)
          : path.includes(':userId')
            ? path.replace(':userId', id)
            : path.includes(':orderId')
              ? path.replace(':orderId', id)
              : `${path}/${id}`;
      }

      if (requestPayload && method === 'GET')
        return `${path}/${requestPayload}`;

      return path;
    })();

    const data =
      requestPayload &&
      (method === 'POST' ||
        method === 'PUT' ||
        method === 'PATCH' ||
        method === 'DELETE')
        ? requestPayload
        : null;

    const config: AxiosRequestConfig = {
      method,
      url,
      data,
    };

    const response =
      type === 'protected'
        ? await instanceWithInterceptor(config)
        : await instanceWithoutInterceptor(config);

    const result = responseSchema.safeParse(response.data);

    if (!result.success) {
      console.error('🚨 Safe-Parsing Failed ', result.error);
      return Promise.reject(new Error(result.error.message));
    }

    return result.data;
  };
}
