import { useState } from 'react';

import {
  scrollToTop,
  useAlert,
  useBulkSend2faResetLink,
} from '@/components/hooks';
import { Button, Modal, FormGroup, Badge } from '@/components';
import { TPersonalDetails } from '@/schemas';
import { Password2faReset } from '@/utils/constants';
import { handleApiError } from '@/utils/helper';

export function Reset2FA({
  id,
  firstName,
  lastName,
  twoFactorSetAt,
  isInvited,
}: TPersonalDetails & {
  id: string;
  twoFactorSetAt: string | undefined | null;
  isInvited?: boolean;
}) {
  const [showAuthenticationModal, setShowAuthenticationModal] = useState(false);

  const userName = `${firstName} ${lastName}`;

  const { showAlert } = useAlert();
  const { mutate, isPending } = useBulkSend2faResetLink();

  const handleReset2FA = () => {
    const bulkSendPasswordResetLink = {
      users: [id],
    };
    mutate(bulkSendPasswordResetLink, {
      onSuccess: () => {
        showAlert(Password2faReset, 'success');
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {
        scrollToTop();
        setShowAuthenticationModal(false);
      },
    });
  };
  return (
    <FormGroup
      title='Two-factor authentication'
      description='Two-factor authentication (2FA) will give you an additional security by requiring a verification code whenever your sign.'
      className='items-center'
    >
      <div className='flex flex-col space-y-4'>
        {twoFactorSetAt !== null && (
          <p
            className='text-base font-bold text-gray-500'
            data-cy='form-group-Two-factor authentication'
          >
            <Badge
              variant='green'
              className='mr-2 font-normal'
            >
              Enabled
            </Badge>
            Via Authentication App
          </p>
        )}

        <Modal
          showModal={showAuthenticationModal}
          setShowModal={setShowAuthenticationModal}
        >
          <Modal.Toggle
            intent='outline'
            variant='blue'
            className='w-full sm:w-auto'
            disabled={!twoFactorSetAt || !isInvited}
          >
            Reset 2FA
          </Modal.Toggle>
          <Modal.Content>
            <Modal.Title>
              <h6>Reset 2FA</h6>
            </Modal.Title>
            <Modal.Body className='space-y-4'>
              <div className='space-y-4'>
                <p className='text-base text-gray-800'>
                  You are about to reset 2FA for the user{' '}
                  <span className='font-bold'>{userName}</span>.
                </p>
                <div>
                  <p className='text-base font-semibold text-gray-800'>
                    This will
                  </p>
                  <ul className='ml-3 list-inside list-disc text-base font-normal text-gray-800'>
                    <li className='font-normal'>
                      Logout all users associated with seleted email.
                    </li>
                    <li className='font-normal'>
                      Users wont be granted access until they have logged in
                      again.
                    </li>
                  </ul>
                </div>
                <p className='text-base text-gray-800'>
                  This action will disable any existing 2FA codes associated
                  with <span className='font-bold'>{userName}</span> account.
                </p>
              </div>
              <p className='text-base font-bold text-gray-800'>
                Are you sure you want to reset 2FA?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Modal.CloseButton>Cancel</Modal.CloseButton>
              <Button
                disabled={isPending}
                onClick={handleReset2FA}
              >
                Send 2FA Instruction
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </div>
    </FormGroup>
  );
}
