import { useEffect, useState } from 'react';

import {
  useGetAllProducts,
  useRangeFilter,
  useURLSearchParams,
} from '@/components/hooks';
import { FilterGroup } from '../index';
import { isEmpty } from '@/utils/helper';
import { QUERY_CONSTANTS } from '@/utils/constants';
import { Button, InputSkeletons, Range } from '@/components';
import { ProductFilterKeys, useProductFilterStore } from '@/store';

export const PriceRangeFilter = () => {
  const {
    toggleFilter,
    showPriceRangeSlider,
    setShoePriceRange,
    setInitialShoePriceRange,
    initialShoePriceRange,
    setIsPriceRangeFilterApplied,
  } = useProductFilterStore();

  const roundRangeValues = (value: number | string) => {
    return Math.round(Number(value));
  };

  const { data: allProductsData, isLoading: isAllProductDataLoading } =
    useGetAllProducts();

  const { searchValue: urlShoePriceRangeFrom } = useURLSearchParams(
    QUERY_CONSTANTS.PRICE_RANGE.FROM,
  );
  const { searchValue: urlShoePriceRangeTo } = useURLSearchParams(
    QUERY_CONSTANTS.PRICE_RANGE.TO,
  );

  const priceRangeData = allProductsData?.filterable?.price;

  const [priceRange, setPriceRange] = useState({
    min: 0,
    max: Infinity,
  });

  const {
    handleSubmit,
    isButtonDisabled: isButtonHidden,
    range,
    setRange,
  } = useRangeFilter({
    initialRange: initialShoePriceRange,
    queryKeyFrom: QUERY_CONSTANTS.PRICE_RANGE.FROM,
    queryKeyTo: QUERY_CONSTANTS.PRICE_RANGE.TO,
    ...priceRange,
  });

  const handleSubmitNewPriceRange = () => {
    handleSubmit();
    setIsPriceRangeFilterApplied(true);
  };

  useEffect(() => {
    setShoePriceRange(
      roundRangeValues(range.inputFrom),
      roundRangeValues(range.inputTo),
    );
  }, [range]);

  useEffect(() => {
    if (!isEmpty(urlShoePriceRangeFrom) && !showPriceRangeSlider) {
      toggleFilter(ProductFilterKeys.PriceRangeSlider);
    }
  }, [urlShoePriceRangeFrom]);

  useEffect(() => {
    const min = roundRangeValues(priceRangeData?.min ?? 0);
    const max = roundRangeValues(priceRangeData?.max ?? 100);

    const prevMin = roundRangeValues(initialShoePriceRange.inputFrom);
    const prevMax = roundRangeValues(initialShoePriceRange.inputTo);

    const urlMin = roundRangeValues(urlShoePriceRangeFrom);
    const urlMax = roundRangeValues(urlShoePriceRangeTo);

    if (isEmpty(priceRangeData?.min) && isEmpty(priceRangeData?.max)) {
      if (priceRange.min === 0 && priceRange.max === Infinity) {
        setPriceRange({
          min: urlMin,
          max: urlMax,
        });
      } else return;
    } else {
      setPriceRange({
        min: Math.min(urlMin, min, prevMin),
        max: Math.max(urlMax, max, prevMax),
      });

      if (urlMax > 0 && urlMin > 0) {
        setInitialShoePriceRange(urlMin, urlMax);
      } else {
        setInitialShoePriceRange(min, max);
      }
    }
  }, [priceRangeData]);

  return (
    <FilterGroup
      title='Price Range'
      isActive={showPriceRangeSlider}
      toggleFilter={() => toggleFilter(ProductFilterKeys.PriceRangeSlider)}
    >
      {isAllProductDataLoading ? (
        <InputSkeletons height={36} />
      ) : (
        !isEmpty(range) && (
          <>
            <div className='flex flex-wrap items-center gap-2'>
              <Range
                range={range}
                setRange={setRange}
                {...priceRange}
              >
                <Range.Slider prefixSymbol='£' />
                <Range.Input />
              </Range>
            </div>
            {!isButtonHidden && (
              <Button
                intent={'tertiary'}
                variant={'blue'}
                className='pb-4 pl-3 pt-6'
                onClick={() => handleSubmitNewPriceRange()}
              >
                Apply
              </Button>
            )}
          </>
        )
      )}
    </FilterGroup>
  );
};
