import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import {
  Button,
  Input,
  Label,
  FormGroup,
  ImageUpload,
  PhoneNumberInput,
} from '@/components';
import { TPersonalDetails, PersonalDetailsSchema } from '@/schemas';
import {
  useAlert,
  useScrollToTop,
  useUpdateUserDetails,
} from '@/components/hooks';
import { handleApiError, handleEnterKeyPress } from '@/utils/helper';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';
import { useUploadUserProfileImage } from '@/components/hooks/api/settings/personal/usePersonalSetting';
import { useEffect } from 'react';

export function UserDetailsForm({
  initialValues,
  isInvited,
  id,
}: {
  initialValues: TPersonalDetails;
  isInvited?: boolean;
  id: string | undefined;
}) {
  const queryClient = useQueryClient();
  const scrollToTop = useScrollToTop();
  const { showAlert } = useAlert();

  const { mutate, isPending: isEditUserPending } = useUpdateUserDetails();
  const { mutate: uploadUserProfileImage } = useUploadUserProfileImage();

  const userDetailsMethods = useForm<TPersonalDetails>({
    mode: 'all',
    resolver: zodResolver(PersonalDetailsSchema),
    defaultValues: { ...initialValues },
  });
  const {
    handleSubmit,
    formState: { defaultValues, isValid, isDirty },
    trigger,
    reset,
  } = userDetailsMethods;

  useEffect(() => {
    // Trigger validation for the phone field when the component mounts
    trigger('phone');
  }, [trigger]);

  const onSubmit: SubmitHandler<TPersonalDetails> = (data) => {
    const { profileImage, firstName, lastName, designation, phone } = data;
    const phoneNumberWithCode = phone?.split('-') ?? [];

    const updatePersonalInfoPayload = {
      firstName,
      lastName,
      designation,
      phone: phoneNumberWithCode[1],
      countryCode: phoneNumberWithCode[0],
    };

    const file = profileImage?.[0] as File;

    /**
     * @description profile picture upload logic
     */
    if (file instanceof File) {
      uploadUserProfileImage(
        { file, userId: id ?? '' },
        {
          onSuccess: () => {},
          onError: (error) => {
            showAlert(handleApiError(error), 'error');
          },
        },
      );
    }

    mutate(
      { ...updatePersonalInfoPayload, userId: id },
      {
        onSuccess: () => {
          showAlert('User updated successfully', 'success');
          queryClient.invalidateQueries({
            queryKey: [TANSTACK_QUERY_KEY.USER],
          });
          queryClient.invalidateQueries({
            queryKey: [TANSTACK_QUERY_KEY.USER_DETAIL],
          });
          reset(data);
        },
        onError: (error) => {
          showAlert(handleApiError(error), 'error');
        },
        onSettled: () => {
          scrollToTop();
        },
      },
    );
  };

  return (
    <FormProvider {...userDetailsMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        encType='multipart/form-data'
      >
        <FormGroup
          title='Profile Picture'
          description='Upload a profile picture.'
          className={!isInvited ? 'pointer-events-none opacity-75' : ''}
        >
          <ImageUpload
            name='profileImage'
            profileImage={defaultValues?.profileImage as string}
            firstName={initialValues.firstName}
          />
        </FormGroup>
        <hr className='my-8' />
        <FormGroup
          title='Personal Details'
          description='Add your basic information.'
          isMandatory
        >
          <div className='space-y-4'>
            <div className='grid gap-6 sm:grid-cols-2'>
              <div className='space-y-1'>
                <Label htmlFor='firstName'>First Name</Label>
                <Input
                  type='text'
                  name='firstName'
                  placeholder='Enter first name'
                  disabled={!isInvited}
                  onKeyDown={handleEnterKeyPress}
                />
              </div>
              <div className='space-y-1'>
                <Label htmlFor='lastName'>Last Name</Label>
                <Input
                  type='text'
                  name='lastName'
                  placeholder='Enter last name'
                  disabled={!isInvited}
                  onKeyDown={handleEnterKeyPress}
                />
              </div>
            </div>
            <div className='space-y-1'>
              <Label htmlFor='email'>Email</Label>
              <Input
                type='email'
                name='email'
                disabled
                placeholder='Enter email address'
              />
            </div>
            <div className='space-y-1'>
              <Label htmlFor='phone'>Phone Number</Label>
              <PhoneNumberInput
                name='phone'
                disabled={!isInvited}
              />
            </div>
            <div className='space-y-1'>
              <Label htmlFor='designation'>Designation</Label>
              <Input
                type='text'
                name='designation'
                placeholder='Enter designation'
                disabled={!isInvited}
                onKeyDown={handleEnterKeyPress}
              />
            </div>
            <div className='space-y-4'>
              {isDirty && (
                <Button
                  disabled={!isValid || isEditUserPending || !isInvited}
                  type='submit'
                >
                  Save Changes
                </Button>
              )}
            </div>
          </div>
        </FormGroup>
      </form>
    </FormProvider>
  );
}
