import React from 'react';
import { IconProps } from '../iconProps';

export const IconArrowUp: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.2807 11.0306C19.211 11.1004 19.1283 11.1557 19.0373 11.1934C18.9462 11.2312 18.8486 11.2506 18.7501 11.2506C18.6515 11.2506 18.5539 11.2312 18.4628 11.1934C18.3718 11.1557 18.2891 11.1004 18.2194 11.0306L12.7501 5.56031V20.25C12.7501 20.4489 12.671 20.6397 12.5304 20.7803C12.3897 20.921 12.199 21 12.0001 21C11.8011 21 11.6104 20.921 11.4697 20.7803C11.3291 20.6397 11.2501 20.4489 11.2501 20.25V5.56031L5.78068 11.0306C5.63995 11.1714 5.44907 11.2504 5.25005 11.2504C5.05103 11.2504 4.86016 11.1714 4.71943 11.0306C4.5787 10.8899 4.49963 10.699 4.49963 10.5C4.49963 10.301 4.5787 10.1101 4.71943 9.96937L11.4694 3.21937C11.5391 3.14964 11.6218 3.09432 11.7128 3.05658C11.8039 3.01883 11.9015 2.9994 12.0001 2.9994C12.0986 2.9994 12.1962 3.01883 12.2873 3.05658C12.3783 3.09432 12.461 3.14964 12.5307 3.21937L19.2807 9.96937C19.3504 10.039 19.4057 10.1217 19.4435 10.2128C19.4812 10.3038 19.5006 10.4014 19.5006 10.5C19.5006 10.5986 19.4812 10.6962 19.4435 10.7872C19.4057 10.8782 19.3504 10.961 19.2807 11.0306Z'
        fill={fill ? fill : '#000000'}
      />
    </svg>
  );
};
