import React from 'react';

import { cn } from '@/utils/cn';
import { Button, IconsComponent } from '@/components';

export const FilterGroup = ({
  title,
  isActive,
  toggleFilter,
  children,
  isLastFilter = false,
  classes,
}: {
  title: string;
  isActive: boolean;
  toggleFilter: () => void;
  children?: React.ReactNode;
  isLastFilter?: boolean;
  classes?: string;
}) => (
  <div
    className={cn(
      'border-gray-200 py-4',
      isLastFilter ? 'border-t-[1px]' : 'border-y-[1px]',
      classes,
    )}
    data-cy={`${title} filter section`}
  >
    <Button
      intent={'tertiary'}
      variant={'blue'}
      className='flex w-full items-center justify-between p-0'
      onClick={toggleFilter}
    >
      <div
        className={cn(
          'flex w-full items-center justify-between',
          isActive ? 'pb-6' : '',
        )}
      >
        <span className='text-lg font-semibold text-gray-800'>{title}</span>
        <div className='px-3 text-sm'>
          <IconsComponent
            icon={isActive ? 'reg-chevron-up' : 'reg-chevron-down'}
            size='md'
          />
        </div>
      </div>
    </Button>
    {isActive && children}
  </div>
);
