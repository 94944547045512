import { useState } from 'react';

import { Modal } from '@/components';
import { ContactSupportForm } from '../contact-support/ContactSupportForm';

/**
 * @description ContactSupportModal component
 */
export function ViewContactSupportModal({ customId }: { customId: string }) {
  const [showContactSupportModal, setShowContactSupportModal] = useState(false);

  return (
    <Modal
      showModal={showContactSupportModal}
      setShowModal={setShowContactSupportModal}
    >
      <Modal.Toggle
        intent='solid'
        size='xs'
        className='px-4'
      >
        Contact Support
      </Modal.Toggle>
      <Modal.Content className='w-full sm:w-[860px]'>
        <Modal.Title>
          <h6>Contact Support</h6>
        </Modal.Title>
        <Modal.Body className='space-y-4'>
          <ContactSupportForm
            setShowContactSupportModal={setShowContactSupportModal}
            companyName='ABC Enterprise Pvt. Ltd.'
            customId={customId}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
