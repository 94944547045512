import { useEffect, useState } from 'react';
import {
  type ColumnDef,
  RowData,
  RowSelectionState,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

/**
 * Custom hook for created on top of tanstack table.
 * @template T - Generics types.
 * @param {ColumnDef<T>[]} columns - Array of column definitions.
 * @param {T[]} apiResponseData - Array of data items.
 * @returns {{ table: Table<T>, sorting: SortingState, setSorting: React.Dispatch<React.SetStateAction<SortingState>> }}
 */

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateRowData: (rowIndex: number, columnId: string, value: unknown) => void;
    deleteRowData: (rowIndex: number) => void;
    addRowData: (row: TData) => void;
  }
}

export function useTable<T>(
  columns: ColumnDef<T>[],
  apiResponseData: T[],
  rowId?: keyof T,
) {
  const [data, setData] = useState(apiResponseData);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    const handler = setTimeout(() => {
      setData(apiResponseData);
    }, 50);

    return () => clearTimeout(handler);
  }, [apiResponseData]);

  const table = useReactTable<T>({
    data,
    columns,
    state: {
      rowSelection,
    },
    manualSorting: true,
    enableRowSelection: true,
    getRowId: (originalRow, index) =>
      rowId ? String(originalRow[rowId]) : String(index),
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel<T>(),

    meta: {
      addRowData: (row) => {
        setData((previousData) => [...previousData, row]);
      },
      updateRowData: (rowIndex, columnId, value) => {
        setData((previousData) =>
          previousData.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...previousData[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          }),
        );
      },
      deleteRowData(rowIndex) {
        setData((previousData) =>
          previousData.filter((_, index) => index !== rowIndex),
        );
      },
    },
  });

  return { table };
}
