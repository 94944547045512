import { z } from 'zod';

import {
  BaseResponseSchema,
  Role,
  emailSchema,
  phoneNumberSchema,
} from '@/schemas';
import { FILE } from '@/utils/constants';

export const PersonalDetailsSchema = z
  .object({
    profileImage: z
      .custom<File[] | string>()
      .optional()
      .refine((files) => {
        if (files === undefined) return true;

        const file = files[0];
        if (file instanceof File) {
          return file.size <= FILE.MAX_SIZE_MB * 1024 * 1024;
        }
        return true;
      }, 'Max file size is 4MB.')
      .refine((files) => {
        if (files === undefined) return true;

        const file = files[0];
        if (file instanceof File) {
          return FILE.ACCEPTED_IMAGE_TYPES.includes(file.type);
        }
        return true;
      }, 'Please choose .jpg , .jpeg or .png files.'),

    email: emailSchema,
    firstName: z
      .string()
      .min(1, { message: 'First Name is required' })
      .max(50, { message: 'First Name is too long' })
      .trim(),
    lastName: z
      .string()
      .min(1, { message: 'Last Name is required' })
      .max(50, { message: 'Last Name is too long' })
      .trim(),
    phone: phoneNumberSchema,
    designation: z
      .string()
      .min(1, { message: 'Designation is required' })
      .max(50, { message: 'Designation Name is too long' })
      .trim(),
  })
  .partial();

export type TPersonalDetails = z.infer<typeof PersonalDetailsSchema>;

export const UpdatePersonalDetailsRequestSchema = PersonalDetailsSchema.omit({
  phone: true,
});

export const UpdateUserDetailsRequestSchema = PersonalDetailsSchema.omit({
  profileImage: true,
  phone: true,
});

export const UpdateUserDetailsResponseSchema = BaseResponseSchema;

export const UpdatePersonalDetailsResponseSchema = BaseResponseSchema;

export const UpdateUserRolesRequestSchema = z.object({
  users: z.array(z.string()),
  role: z.nativeEnum(Role),
});

export const UpdateUserRolesResponseSchema = BaseResponseSchema;

export type TUpdateUserRoles = z.infer<typeof UpdateUserRolesRequestSchema>;

export const UpdateAssignTeamRequestSchema = z.object({
  teams: z.array(z.string()).optional(),
});

export const UpdateAssignTeamResponseSchema = BaseResponseSchema;

export type TUpdateAssignTeam = z.infer<typeof UpdateAssignTeamRequestSchema>;

export const UpdateUserStatusRequestSchema = z.object({
  userId: z.string(),
  status: z.string(),
});

export const UpdateUserStatusResponseSchema = BaseResponseSchema;

export type TUpdateUserStatus = z.infer<typeof UpdateUserStatusRequestSchema>;

export const Reset2FARequestSchema = z.object({
  userId: z.string(),
});

export const Reset2FAResponseSchema = BaseResponseSchema;

export type TReset2FA = z.infer<typeof Reset2FARequestSchema>;

export const SendPasswordResetLinkRequestSchema = z.object({
  email: z.string().email().optional(),
});

export const SendPasswordResetLinkResponseSchema = z.any();

export type TSendPasswordResetLink = z.infer<
  typeof SendPasswordResetLinkRequestSchema
>;

export const ResendInvitationLinkRequestSchema = z.void();

export const ResendInvitationLinkResponseSchema = z.object({
  message: z.string(),
  data: z.object({
    message: z.string(),
  }),
});
