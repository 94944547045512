import { useState } from 'react';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';

import { useAlert } from '@/components/hooks';
import RecoveryLinkSent from './RecoveryLinkSent';
import { Alert, IconsComponent } from '@/components';
import ForgotPasswordForm from './ForgotPasswordForm';
import { ForgotPasswordStep, ROUTES } from '@/utils/constants';
import { ForgotPasswordFormSchema, TForgotPasswordFormSchema } from '@/schemas';

const ForgotPassword = () => {
  const { isAlertVisible, isAlertDismissed, alertMessage, isType } = useAlert(); // Use the custom hook

  const [forgotPasswordStep, setForgotPasswordStep] =
    useState<ForgotPasswordStep>(ForgotPasswordStep.CREATE_NEW_PASSWORD);

  const forgotPasswordMethod = useForm<TForgotPasswordFormSchema>({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(ForgotPasswordFormSchema),
  });

  return (
    <>
      {isAlertVisible && !isAlertDismissed && (
        <Alert
          type={isType}
          classes='mt-4 mb-1.5'
          title={alertMessage}
        />
      )}
      <div className='flex min-h-[calc(100vh_-_76px)] justify-center py-24'>
        {forgotPasswordStep === ForgotPasswordStep.CREATE_NEW_PASSWORD && (
          <FormProvider {...forgotPasswordMethod}>
            <ForgotPasswordForm setForgotPasswordStep={setForgotPasswordStep} />
          </FormProvider>
        )}
        {forgotPasswordStep === ForgotPasswordStep.RECOVERY_LINK_SENT && (
          <FormProvider {...forgotPasswordMethod}>
            <RecoveryLinkSent />
          </FormProvider>
        )}
      </div>
    </>
  );
};

export default ForgotPassword;

export const BackToLoginPage = () => {
  return (
    <Link
      to={`/${ROUTES.LOGIN}`}
      className='flex items-center gap-2 p-0 text-sm font-semibold uppercase tracking-wider text-primary-600'
    >
      <IconsComponent
        icon='reg-chevron-left'
        size='xs'
        fill='#6e2534'
      />
      Back to login
    </Link>
  );
};
