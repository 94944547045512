import React from 'react';

import { cn } from '@/utils/cn';
import { Label } from '../label';

export interface ToggleSwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked = false,
  disabled,
  className,
  ...props
}: ToggleSwitchProps) => {
  return (
    <div
      className={`flex items-center ${className}`}
      data-cy={`switch, switch is ${checked ? 'checked' : 'not checked'}`}
    >
      <Label
        className={`relative inline-flex cursor-pointer items-center ${disabled ? 'cursor-not-allowed' : ''}`}
      >
        <input
          type='checkbox'
          checked={checked}
          disabled={disabled}
          className={cn('sr-only', className)}
          {...props}
        />
        <div
          className={`h-5 w-10 rounded-full transition-colors duration-300 ${
            checked ? 'bg-green-600' : 'bg-gray-300'
          } ${disabled ? 'bg-gray-200' : ''} flex items-center`}
        >
          <div
            className={`h-4 w-4 transform rounded-full bg-white shadow-md transition-transform duration-300 ${
              checked ? 'translate-x-[22px]' : 'translate-x-0.5'
            }`}
          ></div>
        </div>
      </Label>
    </div>
  );
};
