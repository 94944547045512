import React from 'react';
import { IconProps } from '../iconProps';

export const IconOrderProcessing: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.2279 4.66667V9.25C19.2279 11.2722 17.5834 12.9167 15.5613 12.9167H8.22792C6.20575 12.9167 4.56125 11.2722 4.56125 9.25V4.66667C4.56125 2.6445 6.20575 1 8.22792 1H9.25V5.58333C9.25 6.59442 10.0723 7.41667 11.0833 7.41667H12.9167C13.9278 7.41667 14.75 6.59442 14.75 5.58333V1H15.5613C17.5834 1 19.2279 2.6445 19.2279 4.66667ZM12.9167 1H11.0833V5.58333H12.9167V1ZM23 18.875C23 21.1493 21.1493 23 18.875 23H5.125C2.85075 23 1 21.1493 1 18.875C1 16.6007 2.85075 14.75 5.125 14.75H18.875C21.1493 14.75 23 16.6007 23 18.875ZM6.5 18.875C6.5 18.116 5.884 17.5 5.125 17.5C4.366 17.5 3.75 18.116 3.75 18.875C3.75 19.634 4.366 20.25 5.125 20.25C5.884 20.25 6.5 19.634 6.5 18.875ZM13.375 18.875C13.375 18.116 12.759 17.5 12 17.5C11.241 17.5 10.625 18.116 10.625 18.875C10.625 19.634 11.241 20.25 12 20.25C12.759 20.25 13.375 19.634 13.375 18.875ZM20.25 18.875C20.25 18.116 19.634 17.5 18.875 17.5C18.116 17.5 17.5 18.116 17.5 18.875C17.5 19.634 18.116 20.25 18.875 20.25C19.634 20.25 20.25 19.634 20.25 18.875Z'
        fill={fill}
      />
    </svg>
  );
};
