import React from 'react';

interface LoadingContentProps {
  size?: string;
  borderWidth?: string;
  message?: string;
  color?: string;
  height?: string;
}

export const LoadingContent: React.FC<LoadingContentProps> = ({
  size = '32px',
  borderWidth = '2.5px',
  message = '',
  color = '#6b7280',
  height = '70vh',
}) => {
  return (
    <div
      className='flex w-full items-center justify-center'
      style={{ height }}
    >
      <div className='flex flex-col items-center'>
        <div
          className='relative mb-6 aspect-square'
          style={{ width: size }}
        >
          <div
            className='absolute h-full w-full animate-spin rounded-full'
            style={{
              border: `${borderWidth} solid #f3f3f3`,
              borderTopColor: color,
            }}
          />
        </div>
        {message && (
          <p className='text-center text-base text-gray-800'>{message}</p>
        )}
      </div>
    </div>
  );
};
