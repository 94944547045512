import React from 'react';
import { IconProps } from '../iconProps';

export const IconChevronDown: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.0306 9.53063L12.5306 17.0306C12.461 17.1004 12.3782 17.1557 12.2872 17.1934C12.1961 17.2312 12.0986 17.2506 12 17.2506C11.9014 17.2506 11.8038 17.2312 11.7128 17.1934C11.6217 17.1557 11.539 17.1004 11.4694 17.0306L3.96936 9.53063C3.82863 9.3899 3.74957 9.19902 3.74957 9C3.74957 8.80098 3.82863 8.61011 3.96936 8.46938C4.1101 8.32865 4.30097 8.24958 4.49999 8.24958C4.69901 8.24958 4.88988 8.32865 5.03061 8.46938L12 15.4397L18.9694 8.46938C19.039 8.39969 19.1218 8.34442 19.2128 8.30671C19.3039 8.26899 19.4014 8.24958 19.5 8.24958C19.5985 8.24958 19.6961 8.26899 19.7872 8.30671C19.8782 8.34442 19.9609 8.39969 20.0306 8.46938C20.1003 8.53906 20.1556 8.62178 20.1933 8.71283C20.231 8.80387 20.2504 8.90146 20.2504 9C20.2504 9.09855 20.231 9.19613 20.1933 9.28717C20.1556 9.37822 20.1003 9.46094 20.0306 9.53063Z'
        fill={fill}
      />
    </svg>
  );
};
