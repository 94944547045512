import React from 'react';
import { IconProps } from '../iconProps';

export const IconOrderPicked: React.FC<IconProps> = ({ size, fill }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.91666 3.29166C1.91666 2.02575 2.9424 1 4.20831 1C5.47422 1 6.49996 2.02575 6.49996 3.29166C6.49996 4.55757 5.47422 5.58332 4.20831 5.58332C2.9424 5.58332 1.91666 4.55757 1.91666 3.29166ZM22.2904 18.6403L15.607 20.1867C15.6464 20.3544 15.6675 20.5286 15.6675 20.7083C15.6675 22.1731 14.2934 23.3162 12.7644 22.9211C11.9862 22.7194 11.3518 22.0787 11.1575 21.2986C10.8257 19.9676 11.6562 18.7613 12.846 18.4789L11.5911 12.9166H8.9007C8.20587 12.9166 7.57154 12.5243 7.26079 11.9028L6.00588 9.39206C5.89863 9.17664 5.75472 8.98872 5.58514 8.83289V15.8793L6.08563 16.1809C6.91246 16.6786 7.41846 17.5724 7.41846 18.5376V22.0833C7.41846 22.5902 7.00779 22.9999 6.5018 22.9999C5.9958 22.9999 5.58514 22.5902 5.58514 22.0833V18.5367C5.58514 18.2149 5.41647 17.9179 5.14147 17.752L2.60782 16.2267C1.65541 15.6547 1.06783 14.6317 1.05225 13.5225L1 9.80272C1 7.4414 3.46582 5.66674 5.93988 6.90606C6.7328 7.30298 7.35062 7.98406 7.74754 8.77789L8.89978 11.0833H11.1768L10.1886 6.70165C10.0768 6.20848 10.3875 5.71807 10.8807 5.60624C11.3757 5.4944 11.8652 5.80515 11.977 6.29923L14.7307 18.5074L21.876 16.8537C22.371 16.7382 22.8614 17.0471 22.976 17.5403C23.0906 18.0334 22.7844 18.5257 22.2904 18.6403ZM2.83332 18.4166C2.32732 18.4166 1.91666 18.8264 1.91666 19.3333V22.0833C1.91666 22.5902 2.32732 22.9999 2.83332 22.9999C3.33932 22.9999 3.74998 22.5902 3.74998 22.0833V19.3333C3.74998 18.8264 3.33932 18.4166 2.83332 18.4166ZM14.8113 11.7699C14.5217 10.5315 15.2907 9.28664 16.5273 8.99422L19.071 8.38923C19.6742 8.24623 20.2948 8.34614 20.821 8.67248C21.3471 8.99697 21.7147 9.50847 21.8559 10.1098L22.4682 12.7241C22.7579 13.9625 21.9888 15.2074 20.7522 15.4998L18.2085 16.1048C18.0297 16.1479 17.8491 16.168 17.6704 16.168C17.246 16.168 16.8289 16.0507 16.4595 15.8215C15.9333 15.497 15.5657 14.9855 15.4246 14.3833L14.8113 11.7699Z'
        fill={fill}
      />
    </svg>
  );
};
