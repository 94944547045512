import { z } from 'zod';

import { ProductFilterSchema } from '../product-filter.schema';
import { PaginationResponseSchema } from '@/schemas/common.schema';
import { SingleProductDetailSchema } from '../detail/singleProductDetail.schema';
import { PromotionSchema } from '../../marketing-resources/promotions/promotion.schema';

export const AllProductsRequestSchema = z.void();

export type TAllProductsRequestSchema = z.infer<
  typeof AllProductsRequestSchema
>;

export const AllProductsResponseSchema = z.object({
  data: z.array(SingleProductDetailSchema),
  filterable: ProductFilterSchema.nullable(),
  message: z.string(),
  meta: z.object({
    pagination: PaginationResponseSchema,
  }),
  promo: PromotionSchema.optional(),
});

export type TAllProductsResponseSchema = z.infer<
  typeof AllProductsResponseSchema
>;
