import { z } from 'zod';

import {
  AllCategoriesRequestSchema,
  AllCategoriesResponseSchema,
  CategoriesByIdRequestSchema,
  CategoriesByIdResponseSchema,
} from '@/schemas';
import { api } from '@/utils/helper';
import { API_ENDPOINT, HttpMethods } from '@/utils/constants';

const getAllCategories = api<
  z.infer<typeof AllCategoriesRequestSchema>,
  z.infer<typeof AllCategoriesResponseSchema>
>({
  path: API_ENDPOINT.PRODUCTS.ALL_CATEGORIES,
  method: HttpMethods.GET,
  requestSchema: AllCategoriesRequestSchema,
  responseSchema: AllCategoriesResponseSchema,
});

const getCategoriesById = api<
  z.infer<typeof CategoriesByIdRequestSchema>,
  z.infer<typeof CategoriesByIdResponseSchema>
>({
  path: API_ENDPOINT.PRODUCTS.ALL_CATEGORIES,
  method: HttpMethods.GET,
  requestSchema: CategoriesByIdRequestSchema,
  responseSchema: CategoriesByIdResponseSchema,
});

export const AllProductsCategoriesApi = {
  getAllCategories,
  getCategoriesById,
};
