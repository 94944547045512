import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  IconsComponent,
} from '@/components';
import { useAlert } from '@/components/hooks';
import { useExportOrderList } from '@/components/hooks/api/orders/useOrderExport';
import { downloadCSV } from '@/components/hooks/utils/useDownloadCsv';
import { ROUTES } from '@/utils/constants';

export function ActionBar() {
  const { showAlert } = useAlert();
  const { refetch, isLoading } = useExportOrderList();

  const handleExportClick = async () => {
    try {
      const response = await refetch();
      downloadCSV(response.data ?? '', 'exported_orders.csv');
    } catch (error) {
      showAlert('Error exporting file', 'error');
    }
  };

  return (
    <>
      <div className='pb-8'>
        <Breadcrumb>
          <BreadcrumbItem href={`/${ROUTES.OVERVIEW}`}>
            Dashboard
          </BreadcrumbItem>
          <BreadcrumbItem>Orders</BreadcrumbItem>
        </Breadcrumb>
        <div className='items-center justify-between space-y-3 sm:flex sm:flex-row'>
          <h4>Orders</h4>
          <div className='flex items-center space-x-4'>
            <Button
              variant='gray'
              intent='outline'
              className='w-full'
              disabled={isLoading}
              onClick={handleExportClick}
            >
              <IconsComponent
                size='md'
                icon='reg-export'
              />{' '}
              Export
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
