import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch';

import 'instantsearch.css/themes/satellite.css';

import {
  V12_ALGOLIA_SEARCH_ID,
  V12_ALGOLIA_SEARCH_KEY,
} from '@/utils/constants';
import HitsByCategory from './HitsByCategory';
import { Button, IconsComponent } from '@/components';
import { useMediaQuery } from '@/components/hooks';

const searchClient = algoliasearch(
  V12_ALGOLIA_SEARCH_ID,
  V12_ALGOLIA_SEARCH_KEY,
);
interface AlgoliaSearchProps {
  placeholder?: string;
}

export const AlgoliaSearch: React.FC<AlgoliaSearchProps> = ({
  placeholder,
}) => {
  const [query, setQuery] = useState('');
  const isNotSmallDevice: boolean = useMediaQuery('(min-width: 640px)');
  const [openSearchMbl, setOpenSearchMbl] = useState(false);
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName='movie'
      data-cy='algolia-instant-search'
      future={{ preserveSharedStateOnUnmount: true }}
    >
      <Configure query={query} />
      <div className='ais-InstantSearch'>
        {!isNotSmallDevice && (
          <>
            <Button
              intent='tertiary'
              size='xs'
              className='px-0'
              onClick={() => setOpenSearchMbl(!openSearchMbl)}
            >
              <IconsComponent
                icon='reg-search'
                fill='#4B5563'
              />
            </Button>
            {openSearchMbl && (
              <SearchBox
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setQuery(e.target.value)
                }
                onResetCapture={() => setQuery('')}
                placeholder={placeholder}
                data-cy='algolia-instant-searchbox'
                className='absolute left-0 top-full block w-full rounded-none [&>form>input]:h-12 [&>form>input]:border-gray-300 [&>form>input]:shadow-none [&>form]:min-h-12'
              />
            )}
          </>
        )}

        {isNotSmallDevice && (
          <SearchBox
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setQuery(e.target.value)
            }
            onResetCapture={() => setQuery('')}
            placeholder={placeholder}
            data-cy='algolia-instant-searchbox'
            className='block [&>form>input]:border-transparent [&>form>input]:shadow-none 2lg:[&>form>input]:border-gray-300 [&>form]:min-h-12'
          />
        )}

        {query && (
          <>
            <div className='h-[60vh] overflow-y-auto bg-white shadow-lg'>
              {/* Set a fixed height and enable vertical scrolling */}
              <HitsByCategory query={query} />
            </div>
            <div className='sticky bottom-0 left-0 right-0 z-10 border-t border-gray-300 bg-white p-4 shadow-lg'>
              <Button
                size='xs'
                variant='primary'
                intent='tertiary'
                className='px-0'
              >
                View All Results
                <IconsComponent
                  icon='reg-arrow-right'
                  fill='#6E2534'
                  size='sm'
                />
              </Button>
            </div>
          </>
        )}
      </div>
    </InstantSearch>
  );
};
