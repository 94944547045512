export function PromotionCardSkeletion({ length = 4 }: { length?: number }) {
  return (
    <div className='space-y-2'>
      {Array.from({ length }).map((_, index) => (
        <div
          key={index}
          className='animate-pulse items-center justify-between space-y-2 lg:flex lg:border-b lg:pb-8'
        >
          <figure className='flex flex-col gap-2 lg:flex-row lg:items-center'>
            <div className='h-48 w-full rounded bg-gray-300 lg:w-[236px]' />
            <figcaption className='space-y-3'>
              <div className='h-4 w-1/2 rounded bg-gray-300' />
              <div className='h-6 w-3/4 rounded bg-gray-300' />
              <div className='mb-1 h-4 w-24 rounded bg-gray-300' />
            </figcaption>
          </figure>
        </div>
      ))}
    </div>
  );
}
