import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  API_ENDPOINT,
  QUERY_CONSTANTS,
  TANSTACK_QUERY_KEY,
} from '@/utils/constants';
import { PromotionAPI } from '@/services/api/marketing-resource/promotion.service';
import { getSearchParams } from '@/components/hooks/router';
import { STATUS } from '@/utils/constants/promotion.constant';
import { AllProductsApi } from '@/services';

async function fetchPromotionList({
  pageParam,
  status,
}: {
  pageParam: number;
  status: string;
}) {
  const isExpired = status === STATUS.INACTIVE.toString();

  const path = `${API_ENDPOINT.PROMOTIONS.LIST}?${isExpired ? 'expired=true&' : ''}page=${pageParam}&pageSize=10`;

  return PromotionAPI.getPromotionList(path)();
}

export function useGetPromotionInfiniteList() {
  const { status } = getSearchParams([QUERY_CONSTANTS.STATUS]);
  return useInfiniteQuery({
    queryKey: [TANSTACK_QUERY_KEY.PROMOTION, status],
    queryFn: ({ pageParam }) =>
      fetchPromotionList({ pageParam, status: status[0] ?? STATUS.ACTIVE }),
    initialPageParam: 1,
    getNextPageParam: ({ meta: { pagination } }) => {
      return pagination.page < pagination.pageCount
        ? pagination.page + 1
        : null;
    },
  });
}

export function useGetPromotionDetail(id: string) {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.PROMOTION_DETAIL, id],
    queryFn: () => PromotionAPI.getPromotionDetail(id),
    enabled: !!id,
  });
}

async function fetchPromotionProductList({
  pageParam,
  promoId,
}: {
  pageParam: number;
  promoId: string;
}) {
  const path = `?promoId=${promoId}&page=${pageParam}`;
  return AllProductsApi.getAllProducts(path)();
}

/**
 * @description Hook to fetch promotion products with infinite scrolling.
 * @param {string} promotionId - The promotion ID.
 */
export function useGetPromotionInfiniteProductList(promotionId: string) {
  return useInfiniteQuery({
    queryKey: [TANSTACK_QUERY_KEY.PRODUCTS.ALL_PRODUCTS, promotionId],
    queryFn: ({ pageParam }) =>
      fetchPromotionProductList({ pageParam, promoId: promotionId }),
    initialPageParam: 1,
    getNextPageParam: ({ meta: { pagination } }) => {
      return pagination.page < pagination.pageCount
        ? pagination.page + 1
        : null;
    },
  });
}
