import { OrdersAPI } from '@/services/api/orders';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';
import { useQuery } from '@tanstack/react-query';

export function useGetOrderDetail(orderId: string) {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.ORDER_DETAILS, orderId],
    queryFn: () => OrdersAPI.getOrderDetail(orderId),
  });
}
