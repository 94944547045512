import React from 'react';
import { IconProps } from '../iconProps';

export const IconFilter: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.75 12.75C18.75 12.9489 18.671 13.1397 18.5303 13.2803C18.3897 13.421 18.1989 13.5 18 13.5H6C5.80109 13.5 5.61032 13.421 5.46967 13.2803C5.32902 13.1397 5.25 12.9489 5.25 12.75C5.25 12.5511 5.32902 12.3603 5.46967 12.2197C5.61032 12.079 5.80109 12 6 12H18C18.1989 12 18.3897 12.079 18.5303 12.2197C18.671 12.3603 18.75 12.5511 18.75 12.75ZM21.75 7.5H2.25C2.05109 7.5 1.86032 7.57902 1.71967 7.71967C1.57902 7.86032 1.5 8.05109 1.5 8.25C1.5 8.44891 1.57902 8.63968 1.71967 8.78033C1.86032 8.92098 2.05109 9 2.25 9H21.75C21.9489 9 22.1397 8.92098 22.2803 8.78033C22.421 8.63968 22.5 8.44891 22.5 8.25C22.5 8.05109 22.421 7.86032 22.2803 7.71967C22.1397 7.57902 21.9489 7.5 21.75 7.5ZM14.25 16.5H9.75C9.55109 16.5 9.36032 16.579 9.21967 16.7197C9.07902 16.8603 9 17.0511 9 17.25C9 17.4489 9.07902 17.6397 9.21967 17.7803C9.36032 17.921 9.55109 18 9.75 18H14.25C14.4489 18 14.6397 17.921 14.7803 17.7803C14.921 17.6397 15 17.4489 15 17.25C15 17.0511 14.921 16.8603 14.7803 16.7197C14.6397 16.579 14.4489 16.5 14.25 16.5Z'
        fill={fill}
      />
    </svg>
  );
};
