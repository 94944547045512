import { z } from 'zod';

import {
  ForgotPasswordRequestSchema,
  ForgotPasswordResponseSchema,
  ForgotPasswordFormVerifyCodeRequestSchema,
  ForgotPasswordFormVerifyCodeResponseSchema,
  CreateNewPasswordResponseSchema,
  CreateNewPasswordRequestSchema,
  ResetNewPasswordRequestSchema,
  VerifyTokenRequestSchema,
  VerifyTokenResponseSchema,
} from '@/schemas';
import { api } from '@/utils/helper';
import { API_ENDPOINT, AUTHENTICATION, HttpMethods } from '@/utils/constants';

const forgotPassword = api<
  z.infer<typeof ForgotPasswordRequestSchema>,
  z.infer<typeof ForgotPasswordResponseSchema>
>({
  type: 'public',
  path: AUTHENTICATION.FORGOT_PASSWORD.RESET,
  method: 'POST',
  requestSchema: ForgotPasswordRequestSchema,
  responseSchema: ForgotPasswordResponseSchema,
});

const verifyCodeForgotPassword = api<
  z.infer<typeof ForgotPasswordFormVerifyCodeRequestSchema>,
  z.infer<typeof ForgotPasswordFormVerifyCodeResponseSchema>
>({
  type: 'public',
  path: `${import.meta.env.VITE_API_AUTH_URL}/forgot-password/verify-code`,
  method: 'POST',
  requestSchema: ForgotPasswordFormVerifyCodeRequestSchema,
  responseSchema: ForgotPasswordFormVerifyCodeResponseSchema,
});

const newPassword = (codeId: string, userId: string) =>
  api<
    z.infer<typeof CreateNewPasswordRequestSchema>,
    z.infer<typeof CreateNewPasswordResponseSchema>
  >({
    type: 'public',
    // Inject the codeId in the URL path
    path: `${API_ENDPOINT.PERSONAL_SETTING.UPDATE}/${userId}?code=${codeId}`,
    method: 'PUT',
    requestSchema: CreateNewPasswordRequestSchema,
    responseSchema: CreateNewPasswordResponseSchema,
  });

const resetPassword = api<
  z.infer<typeof ResetNewPasswordRequestSchema>,
  z.infer<typeof CreateNewPasswordResponseSchema>
>({
  type: 'public',
  path: AUTHENTICATION.CREATE_PASSWORD,
  method: HttpMethods.PATCH,
  requestSchema: ResetNewPasswordRequestSchema,
  responseSchema: CreateNewPasswordResponseSchema,
});

const verifyResetPasswordToken = api<
  z.infer<typeof VerifyTokenRequestSchema>,
  z.infer<typeof VerifyTokenResponseSchema>
>({
  type: 'public',
  path: AUTHENTICATION.FORGOT_PASSWORD.VERIFY_TOKEN,
  method: 'POST',
  requestSchema: VerifyTokenRequestSchema,
  responseSchema: VerifyTokenResponseSchema,
});

export const ForgotPasswordAPI = {
  forgotPassword,
  verifyCodeForgotPassword,
  verifyResetPasswordToken,
  newPassword,
  resetPassword,
};
