import { z } from 'zod';
import { phoneNumberSchema } from './common.schema';
import { Status } from './user';
import {
  dateEnum,
  shoeSizeType,
  timeFormatEnum,
} from './systemSetting/systemSetting.schema';

export const CompanyDetailsSchema = z.object({
  id: z.string(),
  name: z.string().min(1, { message: 'Company Name is required' }).trim(),
  email: z.string().min(1, { message: 'Email is required' }).trim(),
  website: z.string().min(1, { message: 'Url is required' }).trim(),
  registrationNumber: z
    .string()
    .min(1, { message: 'Business Registration Number is required' })
    .min(6, { message: 'Minimum Business Registration Number length is 6' })
    .max(10, { message: 'Maximum Business Registration Number length is 10' })
    .regex(/^[a-zA-Z0-9]*$/, {
      message: 'Business Registration Number must be alphanumeric',
    }),
  phone: phoneNumberSchema,
  country: z.string().min(1, { message: 'Country is required' }),
  state: z
    .union([z.string(), z.number()])
    .transform((val) => String(val))
    .optional(),
  city: z.string().min(1, { message: 'Suburb is required' }),
  countryCode: z.string().optional(),
});

export type TCompanyDetails = z.infer<typeof CompanyDetailsSchema>;

export const CompanyDetailsSchemaResponse = z.object({
  message: z.string(),
  data: z.object({
    id: z.string().uuid(),
    name: z.string(),
    email: z.string().email(),
    website: z.string().url(),
    registrationNumber: z.string(),
    country: z.string(),
    countryCode: z.string(),
    phone: z.string(),
    state: z.string().optional(),
    city: z.string(),
    accountStatus: z.nativeEnum(Status),
    registrationStatus: z.string(),
    createdAt: z.string().datetime(),
    timezone: z.string(),
    timeFormat: timeFormatEnum,
    dateFormat: z.nativeEnum(dateEnum.enum),
    currency: z.string(),
    shoeSizeType: shoeSizeType,
    strapiId: z.any().optional(),
  }),
  timestamp: z.string().datetime(),
});

export const CompanyDetailsRequestSchema = z.void();

export const ownerSchema = z.object({
  userId: z.string(),
  email: z.string().email(),
});

const CompanySchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  email: z.string().email(),
  website: z.string().url(),
  registrationNumber: z.string(),
  country: z.string(),
  state: z.string().optional(),
  city: z.string(),
  phone: z.string(),
  countryCode: z.string().optional(),
});
const SystemSchema = z.object({
  timezone: z.string().optional(),
  timeFormat: timeFormatEnum.optional(),
  dateFormat: z.nativeEnum(dateEnum.enum).optional(),
  currency: z.string().optional(),
  shoeSizeType: shoeSizeType.optional(),
});
export const CompanyDetailsData = z.object({
  company: CompanySchema.optional(),
  system: SystemSchema.optional(),
});
export type TCompanyDetailsData = z.infer<typeof CompanyDetailsData>;

export const CompanyDetailsResponse = z.object({
  id: z.string().uuid(),
  name: z.string(),
  email: z.string().email(),
  website: z.string().url(),
  registrationNumber: z.string(),
  country: z.string(),
  countryCode: z.string(),
  phone: z.string(),
  state: z.string().optional(),
  city: z.string(),
  accountStatus: z.nativeEnum(Status),
  registrationStatus: z.string(),
  createdAt: z.string().datetime(),
  timezone: z.string(),
  timeFormat: timeFormatEnum,
  dateFormat: z.nativeEnum(dateEnum.enum),
  currency: z.string(),
  shoeSizeType: shoeSizeType,
});

export type TCompanyDetailsResponse = z.infer<typeof CompanyDetailsResponse>;

export const companyIdResponseSchema = z.string();
export const CompanyDetailsResponseSchema = CompanyDetailsResponse;
export type TCompanyDetailsResponseSchema = z.infer<
  typeof CompanyDetailsResponseSchema
>;
