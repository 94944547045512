import { useQuery } from '@tanstack/react-query';

import { getCompanyOrderDetails } from '@/services/api/orders/get-orders';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';

export const useGetOrderByCompany = () => {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.PENDING_ORDERS],
    queryFn: () => getCompanyOrderDetails(),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    select: (data) => data.data,
    retry: 3,
  });
};
