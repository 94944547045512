import { AlertType } from '@/components/ui/alert';
import { create } from 'zustand';

interface AlertInterface {
  isAlertVisible: boolean;
  isAlertDismissed: boolean;
  alertMessage: string;
  isType?: AlertType;
}

interface AlertInterfaceActions {
  setIsAlertVisible: (isAlertVisible: boolean) => void;
  setIsAlertDismissed: (isAlertDismissed: boolean) => void;
  setAlertMessage: (alertMessage: string) => void;
  setType: (type: AlertType) => void;
}

const initialState: AlertInterface = {
  isAlertVisible: false,
  isAlertDismissed: false,
  alertMessage: 'Address Added Successfully',
  isType: 'success',
};

type TAlertStoreType = AlertInterface & AlertInterfaceActions;

const useAlertStore = create<TAlertStoreType>()((set) => ({
  ...initialState,
  setIsAlertVisible: (isAlertVisible: boolean) => {
    set({ isAlertVisible });
  },
  setIsAlertDismissed: (isAlertDismissed: boolean) => {
    set({ isAlertDismissed });
  },
  setAlertMessage: (alertMessage: string) => {
    set({ alertMessage });
  },
  setType: (isType: AlertType) => {
    set({ isType });
  },
}));

export default useAlertStore;
