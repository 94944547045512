import { z } from 'zod';

import {
  EnrollAuthenticatorResponseSchema,
  PasswordSchema,
  VerifiyCodeFormSchema,
  VerifiyCodePersonalSettingsSchema,
} from '@/schemas';
import { api } from '@/utils/helper';
import { API_ENDPOINT, AUTHENTICATION } from '@/utils/constants';

//TODO update personalSetting
const getPersonalDetails = api({
  path: API_ENDPOINT.PERSONAL_SETTING.LIST,
  method: 'GET',
  requestSchema: z.object({
    userId: z.string(), // Example schema expecting an object with personID
  }),
  responseSchema: z.any(),
});

const addPassword2FA = api<
  z.infer<typeof PasswordSchema>,
  z.infer<typeof EnrollAuthenticatorResponseSchema>
>({
  path: AUTHENTICATION.PASSWORD_2FA,
  method: 'POST',
  requestSchema: PasswordSchema,
  responseSchema: EnrollAuthenticatorResponseSchema,
});

const addPasswordSMS = api<z.infer<typeof PasswordSchema>, typeof z.any>({
  path: API_ENDPOINT.PASSWORD_SMS,
  method: 'POST',
  requestSchema: PasswordSchema,
  responseSchema: z.any(),
});

const addVerificationCode = api<
  z.infer<typeof VerifiyCodeFormSchema>,
  typeof z.any
>({
  path: API_ENDPOINT.VERIFY_CODE,
  method: 'POST',
  requestSchema: VerifiyCodeFormSchema,
  responseSchema: z.any(),
});

const addVerificationCodeSMS = api<
  z.infer<typeof VerifiyCodePersonalSettingsSchema>,
  typeof z.any
>({
  path: API_ENDPOINT.VERIFY_CODE_SMS,
  method: 'POST',
  requestSchema: VerifiyCodePersonalSettingsSchema,
  responseSchema: z.any(),
});

export const personalSettingAPI = {
  getPersonalDetails,
  addPassword2FA,
  addPasswordSMS,
  addVerificationCode,
  addVerificationCodeSMS,
};
