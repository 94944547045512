import React from 'react';
import { IconProps } from '../iconProps';

export const IconDispatched: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.5 11H1C0.447 11 0 10.552 0 10C0 9.448 0.447 9 1 9H4.5C5.053 9 5.5 9.448 5.5 10C5.5 10.552 5.053 11 4.5 11ZM10.154 2C10.154 1.448 9.707 1 9.154 1H1C0.447 1 0 1.448 0 2C0 2.552 0.447 3 1 3H9.154C9.707 3 10.154 2.552 10.154 2ZM8.154 6C8.154 5.448 7.707 5 7.154 5H1C0.447 5 0 5.448 0 6C0 6.552 0.447 7 1 7H7.154C7.707 7 8.154 6.552 8.154 6ZM15.057 20C15.023 20.162 14.999 20.328 14.999 20.5C14.999 21.881 16.118 23 17.499 23C18.88 23 19.999 21.881 19.999 20.5C19.999 20.328 19.975 20.162 19.941 20H15.056H15.057ZM14.999 18V6C14.999 3.991 13.814 2.259 12.104 1.464C12.136 1.638 12.153 1.817 12.153 2C12.153 3.382 11.214 4.547 9.941 4.894C10.077 5.236 10.153 5.609 10.153 6C10.153 7.597 8.899 8.905 7.325 8.995C7.437 9.309 7.499 9.648 7.499 10C7.499 11.654 6.153 13 4.499 13H1C0.649 13 0.313 12.939 0 12.828V14C0 16.209 1.791 18 4 18H15H14.999ZM4.057 20C4.023 20.162 3.999 20.328 3.999 20.5C3.999 21.881 5.118 23 6.499 23C7.88 23 8.999 21.881 8.999 20.5C8.999 20.328 8.975 20.162 8.941 20H4.057ZM16.999 18H19.999C22.208 18 23.999 16.209 23.999 14V13H16.999V18ZM18.999 5H16.999V11H23.999V10C23.999 7.239 21.76 5 18.999 5Z'
        fill={fill}
      />
    </svg>
  );
};
