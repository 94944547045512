import React from 'react';
import { IconProps } from '../iconProps';

export const IconSidebar: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.25 3.75H3.75C3.35218 3.75 2.97064 3.90804 2.68934 4.18934C2.40804 4.47064 2.25 4.85218 2.25 5.25V18.75C2.25 19.1478 2.40804 19.5294 2.68934 19.8107C2.97064 20.092 3.35218 20.25 3.75 20.25H20.25C20.6478 20.25 21.0294 20.092 21.3107 19.8107C21.592 19.5294 21.75 19.1478 21.75 18.75V5.25C21.75 4.85218 21.592 4.47064 21.3107 4.18934C21.0294 3.90804 20.6478 3.75 20.25 3.75ZM6 14.25H4.5C4.30109 14.25 4.11032 14.171 3.96967 14.0303C3.82902 13.8897 3.75 13.6989 3.75 13.5C3.75 13.3011 3.82902 13.1103 3.96967 12.9697C4.11032 12.829 4.30109 12.75 4.5 12.75H6C6.19891 12.75 6.38968 12.829 6.53033 12.9697C6.67098 13.1103 6.75 13.3011 6.75 13.5C6.75 13.6989 6.67098 13.8897 6.53033 14.0303C6.38968 14.171 6.19891 14.25 6 14.25ZM6 11.25H4.5C4.30109 11.25 4.11032 11.171 3.96967 11.0303C3.82902 10.8897 3.75 10.6989 3.75 10.5C3.75 10.3011 3.82902 10.1103 3.96967 9.96967C4.11032 9.82902 4.30109 9.75 4.5 9.75H6C6.19891 9.75 6.38968 9.82902 6.53033 9.96967C6.67098 10.1103 6.75 10.3011 6.75 10.5C6.75 10.6989 6.67098 10.8897 6.53033 11.0303C6.38968 11.171 6.19891 11.25 6 11.25ZM6 8.25H4.5C4.30109 8.25 4.11032 8.17098 3.96967 8.03033C3.82902 7.88968 3.75 7.69891 3.75 7.5C3.75 7.30109 3.82902 7.11032 3.96967 6.96967C4.11032 6.82902 4.30109 6.75 4.5 6.75H6C6.19891 6.75 6.38968 6.82902 6.53033 6.96967C6.67098 7.11032 6.75 7.30109 6.75 7.5C6.75 7.69891 6.67098 7.88968 6.53033 8.03033C6.38968 8.17098 6.19891 8.25 6 8.25ZM20.25 18.75H8.25V5.25H20.25V18.75Z'
        fill={fill}
      />
    </svg>
  );
};
