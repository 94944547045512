import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface SidebarInterface {
  isSidebarIconOnlyView: boolean;
  isSidebarVisible: boolean;
}

interface SidebarInterfaceActions {
  setSidebarIconOnlyView: (isSidebarIconOnlyView: boolean) => void;
  sidebarIconOnlyViewToggler: () => void;
  setIsSidebarVisible: (isSidebarVisible: boolean) => void;
  sidebarVisibilityToggler: () => void;
}

const initialState: SidebarInterface = {
  isSidebarIconOnlyView: false,
  isSidebarVisible: true,
};

type TSidebarType = SidebarInterface & SidebarInterfaceActions;

const useSidebarStore = create<TSidebarType>()(
  persist(
    (set) => ({
      ...initialState,
      setSidebarIconOnlyView: (isSidebarIconOnlyView: boolean) => {
        set({ isSidebarIconOnlyView });
      },
      setIsSidebarVisible: (isSidebarVisible: boolean) => {
        set({ isSidebarVisible });
      },
      sidebarIconOnlyViewToggler: () => {
        set((state) => ({
          isSidebarIconOnlyView: !state.isSidebarIconOnlyView,
        }));
      },
      sidebarVisibilityToggler: () => {
        set((state) => ({
          isSidebarVisible: !state.isSidebarVisible,
        }));
      },
    }),
    {
      name: 'sidebar',
      // persist only sidebarIconView
      partialize: (state) => ({
        isSidebarIconOnlyView: state.isSidebarIconOnlyView,
      }),
    },
  ),
);

export default useSidebarStore;
