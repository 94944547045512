import { z } from 'zod';

export const DeleteMemberOnTeamRequestSchema = z.object({
  userIds: z.array(z.string()).optional(),
});
export const DeleteMemberOnTeamResponseSchema = z.object({
  message: z.string(),
  timestamp: z.string().optional(),
  data: z.object({
    status: z.number(),
    message: z.string(),
    payload: z.object({
      id: z.string(),
      companyId: z.string(),
      name: z.string(),
      description: z.string().optional(),
      createdAt: z.string().optional(),
      updatedAt: z.string().optional(),
      users: z.array(z.any()).optional(),
    }),
  }),
});

export type TDeleteMemberOnTeam = z.infer<
  typeof DeleteMemberOnTeamRequestSchema
>;
