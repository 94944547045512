import { z } from 'zod';

import { PASSWORD_REGEX } from '@/utils/constants';
import libphonenumber from 'google-libphonenumber';

export const passwordSchema = z
  .string()
  .min(1, { message: 'Password is required' })
  .max(15, { message: 'Password over 15 characters' })
  .regex(PASSWORD_REGEX, {
    message: 'This password is not valid',
  });

export const emailSchema = z
  .string()
  .min(1, { message: 'Email is required' })
  .email({ message: 'Invalid email address' })
  .trim();

export const BaseResponseSchema = z.object({
  statusCode: z.number().optional().nullable(),
  message: z.string(),
  id: z.string().optional(),
});

export const PaginationResponseSchema = z.object({
  page: z.number(),
  pageCount: z.number(),
  pageSize: z.number(),
  total: z.number(),
});

export const phoneNumberSchema = z
  .string({ required_error: 'Phone number is required' })
  .refine(
    (value) => {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

      const rawPhoneNumber = value.split('-');

      const countryISO = rawPhoneNumber[0];
      const phoneNumber = rawPhoneNumber[1];

      try {
        return phoneUtil.isValidNumberForRegion(
          phoneUtil.parse(phoneNumber, countryISO || 'gb'),
          countryISO,
        );
      } catch (error) {
        return false;
      }
    },
    { message: 'Valid Phone number is required' },
  );

export const VoidSchema = z.object({});
