import React from 'react';

import { cn } from '@/utils/cn';

export function Radio({
  className,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <div className='inline-flex items-center'>
      <div className='relative flex cursor-pointer items-center'>
        <input
          type='radio'
          className={cn(
            "before:content[''] before:bg-blue-gray-500 peer relative size-5 cursor-pointer appearance-none rounded-full border border-gray-300 transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-blue-600 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:checked:border-blue-200",
            className,
          )}
          data-cy='radio button'
          {...props}
        />
        <div className='pointer-events-none absolute left-2/4 top-2/4 h-3 w-3 -translate-x-2/4 -translate-y-2/4 rounded-full bg-blue-600 opacity-0 transition-opacity peer-checked:opacity-100 peer-disabled:peer-checked:opacity-50'></div>
      </div>
    </div>
  );
}
