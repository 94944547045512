export const IconVisa = () => {
  return (
    <svg
      width='42'
      height='28'
      viewBox='0 0 42 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1.16602'
        y='0.5'
        width='39.8333'
        height='27'
        rx='3.5'
        fill='white'
        stroke='#D9D9D9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.0622 18.9683H10.5884L8.73346 11.8914C8.64542 11.5659 8.45847 11.2781 8.18348 11.1425C7.49721 10.8016 6.74099 10.5303 5.91602 10.3935V10.121H9.90097C10.4509 10.121 10.8634 10.5303 10.9322 11.0056L11.8946 16.1104L14.3671 10.121H16.7721L13.0622 18.9683ZM18.1471 18.9683H15.8109L17.7346 10.121H20.0708L18.1471 18.9683ZM23.0933 12.572C23.162 12.0955 23.5745 11.823 24.0557 11.823C24.812 11.7546 25.6357 11.8914 26.3232 12.2311L26.7357 10.3263C26.0482 10.0538 25.292 9.91699 24.6057 9.91699C22.3383 9.91699 20.6883 11.1425 20.6883 12.8433C20.6883 14.1372 21.857 14.8165 22.682 15.2258C23.5745 15.6339 23.9183 15.9064 23.8495 16.3145C23.8495 16.9266 23.162 17.1991 22.4758 17.1991C21.6508 17.1991 20.8258 16.995 20.0708 16.6542L19.6583 18.5602C20.4833 18.8999 21.3758 19.0367 22.2008 19.0367C24.7432 19.1039 26.3232 17.8796 26.3232 16.042C26.3232 13.7279 23.0933 13.5922 23.0933 12.572ZM34.4994 18.9683L32.6444 10.121H30.6519C30.2394 10.121 29.8269 10.3935 29.6894 10.8016L26.2545 18.9683H28.6594L29.1394 17.6756H32.0944L32.3694 18.9683H34.4994ZM30.9956 12.5036L31.6819 15.838H29.7582L30.9956 12.5036Z'
        fill='#172B85'
      />
    </svg>
  );
};
