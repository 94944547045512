import { z } from 'zod';

import { Badge } from '@/components';
import { MediaType } from '@/utils/constants';
import { formatDate } from '@/utils/helper/datetime.helper';
import { formatCompanyPrice } from '@/utils/helper/country-locale';
import { AsyncLoaderImage } from '@/components/ui/image/AsyncImage';
import { DiscountLabel } from '@/utils/constants/promotion.constant';
import { PromotionSchema } from '@/schemas/api/marketing-resources/promotions/promotion.schema';

type BannerProps = z.infer<typeof PromotionSchema>;

export function Banner({ promo }: { promo?: BannerProps }) {
  const bannerImage = promo?.medias.find(
    (item) => item.type === MediaType.IMAGE,
  );

  return (
    <div className='relative w-full overflow-hidden'>
      <figure className='h-[300px] brightness-75'>
        <AsyncLoaderImage
          className='h-full w-full rounded-none object-cover'
          src={bannerImage?.url}
          alt={bannerImage?.altText ?? ''}
        />
      </figure>
      <div className='absolute inset-0 bg-gradient-to-t from-black/60 to-transparent' />
      <div className='absolute bottom-0 left-0 right-0 p-4 md:p-6 lg:p-8'>
        <Badge
          variant='green'
          className='mb-1'
        >
          {promo?.discountLabel === DiscountLabel.FIXED
            ? `Sale ${formatCompanyPrice(Math.round(Number(promo.discount)))} off`
            : `Sale up to ${promo?.discount}% off`}
        </Badge>
        <p className='py-1 text-xl font-semibold text-white'>{promo?.name}</p>
        <p className='text-base font-normal text-white'>
          <span className='font-bold'>Expires in: </span>
          {formatDate(promo?.endDate ?? '')}
        </p>
      </div>
    </div>
  );
}
