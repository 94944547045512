import { Link } from 'react-router-dom';
import React, { Children } from 'react';

interface ItemsProps {
  href?: string;
  className?: string;
  children: React.ReactNode;
}

const Breadcrumb = ({ children }: ItemsProps) => {
  const childrenArray = Children.toArray(children);

  const childrenWithSeperator = childrenArray.map((child, index) => {
    if (index !== childrenArray.length - 1) {
      return (
        <React.Fragment key={`breadcrumb-${index}`}>
          {child}
          <li>
            <span className='px-1 text-gray-500'>/</span>
          </li>
        </React.Fragment>
      );
    }
    return child;
  });

  return (
    <ul className='flex flex-wrap gap-x-1.5 text-sm font-medium text-gray-800'>
      {childrenWithSeperator}
    </ul>
  );
};

const BreadcrumbItem = ({
  children,
  className,
  href,
  ...props
}: ItemsProps) => {
  return (
    <li
      {...props}
      className={className}
    >
      {href ? (
        <Link
          className='font-normal text-gray-500'
          to={href}
        >
          {children}
        </Link>
      ) : (
        <>{children}</>
      )}
    </li>
  );
};

export { Breadcrumb, BreadcrumbItem };
