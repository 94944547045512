import { OrdersAPI } from '@/services/api/orders';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';
import { useQuery } from '@tanstack/react-query';

export function useExportOrderList() {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.ORDER_EXPORT],
    queryFn: () => OrdersAPI.exportOrderList(),
    enabled: false,
  });
}
