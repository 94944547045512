import React from 'react';
import { Highlight } from 'react-instantsearch';

interface ContentHitProps {
  hit: {
    title: string;
    poster_path: string;
    overview: string;
    vote_average: number;
    genres: string[];
    type?: string; // Optional, adjust based on actual hit properties
    __position: number; // Required by Highlight component
    __queryID?: string; // Optional, adjust based on actual hit properties
    objectID: string;
  };
}

const ContentHit: React.FC<ContentHitProps> = ({ hit }) => (
  <div className='mb-3 flex w-full items-start px-4'>
    <img
      src={hit.poster_path}
      alt={hit.title}
      className='mr-4 h-auto w-32 rounded-lg'
    />
    <div className='flex-1'>
      <h3 className='mb-2 text-xl font-semibold'>
        <Highlight
          attribute='title'
          hit={hit}
          data-cy={`search-${hit.title}`}
        />
      </h3>
      <p className='mb-2 text-gray-700'>
        <Highlight
          attribute='overview'
          hit={hit}
        />
      </p>
      <p className='text-gray-500'>Rating: {hit.vote_average}</p>
    </div>
  </div>
);

export default ContentHit;
