import { Skeleton } from '@/components/lib/Skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

interface UserTableSkeletonProps {
  rowCount?: number;
  cellCount?: number;
  headers?: string[];
}

export function UserTableSkeleton({
  rowCount = 10,
  cellCount = 5,
  headers = [
    'Name/Emai',
    'Phone No',
    'Team',
    'Designation',
    'Status',
    'Role',
    'Action',
  ],
}: UserTableSkeletonProps) {
  return (
    <Table className='userTableSkeleton w-full overflow-auto'>
      <TableHeader>
        <TableRow>
          <TableHead className='w-full md:w-[68px]'>
            <div className='h-5 w-5 rounded-md bg-gray-200' />
          </TableHead>
          {headers.map((header, index) => (
            <TableHead key={index}>{header}</TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array.from({ length: rowCount }).map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            <TableCell className='text-start'>
              <div className='h-5 w-5 rounded-md bg-gray-200' />
            </TableCell>
            <TableCell>
              <div className='flex items-center space-x-3'>
                <div className='h-8 w-8 rounded-full bg-gray-200' />
                <div className='flex-1'>
                  <Skeleton className='mb-1 h-5 w-full' />
                  <Skeleton className='h-5 w-full' />
                </div>
              </div>
            </TableCell>
            {Array.from({ length: cellCount }).map((__, cellIndex) => (
              <TableCell key={cellIndex}>
                <Skeleton className='h-5 w-full' />
              </TableCell>
            ))}
            <TableCell className='py-2 text-start'>
              <Skeleton className='h-5 w-8' />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
