import React from 'react';
import { IconProps } from '../iconProps';

export const IconCompare: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.0306 17.0306L17.0306 20.0306C16.8899 20.1713 16.699 20.2504 16.5 20.2504C16.301 20.2504 16.1101 20.1713 15.9694 20.0306C15.8286 19.8899 15.7496 19.699 15.7496 19.5C15.7496 19.301 15.8286 19.1101 15.9694 18.9694L17.6897 17.25H4.49998C4.30107 17.25 4.1103 17.171 3.96965 17.0303C3.829 16.8897 3.74998 16.6989 3.74998 16.5C3.74998 16.3011 3.829 16.1103 3.96965 15.9697C4.1103 15.829 4.30107 15.75 4.49998 15.75H17.6897L15.9694 14.0306C15.8286 13.8899 15.7496 13.699 15.7496 13.5C15.7496 13.301 15.8286 13.1101 15.9694 12.9694C16.1101 12.8286 16.301 12.7496 16.5 12.7496C16.699 12.7496 16.8899 12.8286 17.0306 12.9694L20.0306 15.9694C20.1003 16.039 20.1557 16.1217 20.1934 16.2128C20.2311 16.3038 20.2506 16.4014 20.2506 16.5C20.2506 16.5986 20.2311 16.6961 20.1934 16.7872C20.1557 16.8782 20.1003 16.961 20.0306 17.0306ZM6.96935 11.0306C7.11009 11.1713 7.30096 11.2504 7.49998 11.2504C7.699 11.2504 7.88987 11.1713 8.03061 11.0306C8.17134 10.8899 8.2504 10.699 8.2504 10.5C8.2504 10.301 8.17134 10.1101 8.03061 9.96936L6.31029 8.24999H19.5C19.6989 8.24999 19.8897 8.17097 20.0303 8.03032C20.171 7.88967 20.25 7.6989 20.25 7.49999C20.25 7.30108 20.171 7.11031 20.0303 6.96966C19.8897 6.82901 19.6989 6.74999 19.5 6.74999H6.31029L8.03061 5.03061C8.17134 4.88988 8.2504 4.69901 8.2504 4.49999C8.2504 4.30097 8.17133 4.1101 8.0306 3.96936C7.88987 3.82863 7.699 3.74957 7.49998 3.74957C7.30096 3.74957 7.11009 3.82863 6.96935 3.96936L3.96935 6.96936C3.89962 7.03902 3.8443 7.12174 3.80656 7.21278C3.76882 7.30383 3.74939 7.40143 3.74939 7.49999C3.74939 7.59855 3.76882 7.69615 3.80656 7.7872C3.8443 7.87824 3.89962 7.96096 3.96935 8.03061L6.96935 11.0306Z'
        fill={fill ? fill : '#000000'}
      />
    </svg>
  );
};
