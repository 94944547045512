import React from 'react';
import { IconProps } from '../iconProps';

export const IconExport: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.25 10.5V19.5C20.25 19.8978 20.092 20.2794 19.8107 20.5607C19.5294 20.842 19.1478 21 18.75 21H5.25C4.85218 21 4.47064 20.842 4.18934 20.5607C3.90804 20.2794 3.75 19.8978 3.75 19.5V10.5C3.75 10.1022 3.90804 9.72065 4.18934 9.43935C4.47064 9.15805 4.85218 9.00001 5.25 9.00001H7.5C7.69891 9.00001 7.88968 9.07903 8.03033 9.21968C8.17098 9.36033 8.25 9.5511 8.25 9.75001C8.25 9.94892 8.17098 10.1397 8.03033 10.2803C7.88968 10.421 7.69891 10.5 7.5 10.5H5.25V19.5H18.75V10.5H16.5C16.3011 10.5 16.1103 10.421 15.9697 10.2803C15.829 10.1397 15.75 9.94892 15.75 9.75001C15.75 9.5511 15.829 9.36033 15.9697 9.21968C16.1103 9.07903 16.3011 9.00001 16.5 9.00001H18.75C19.1478 9.00001 19.5294 9.15805 19.8107 9.43935C20.092 9.72065 20.25 10.1022 20.25 10.5ZM8.78063 6.53064L11.25 4.06032V12.75C11.25 12.9489 11.329 13.1397 11.4697 13.2803C11.6103 13.421 11.8011 13.5 12 13.5C12.1989 13.5 12.3897 13.421 12.5303 13.2803C12.671 13.1397 12.75 12.9489 12.75 12.75V4.06032L15.2194 6.53064C15.3601 6.67137 15.551 6.75043 15.75 6.75043C15.949 6.75043 16.1399 6.67137 16.2806 6.53064C16.4214 6.3899 16.5004 6.19903 16.5004 6.00001C16.5004 5.80099 16.4214 5.61012 16.2806 5.46939L12.5306 1.71939C12.461 1.64965 12.3783 1.59433 12.2872 1.55659C12.1962 1.51885 12.0986 1.49942 12 1.49942C11.9014 1.49942 11.8038 1.51885 11.7128 1.55659C11.6217 1.59433 11.539 1.64965 11.4694 1.71939L7.71937 5.46939C7.57864 5.61012 7.49958 5.80099 7.49958 6.00001C7.49958 6.19903 7.57864 6.3899 7.71938 6.53063C7.86011 6.67137 8.05098 6.75043 8.25 6.75043C8.44902 6.75043 8.63989 6.67137 8.78063 6.53064Z'
        fill={fill}
      />
    </svg>
  );
};
