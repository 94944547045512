import React from 'react';
import { IconProps } from '../iconProps';

export const IconProduct: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 10.5H10.5253C10.3318 10.5026 10.1444 10.4318 10.0009 10.3019C9.85741 10.172 9.76844 9.99255 9.75187 9.79969C9.74507 9.69726 9.75937 9.59452 9.79389 9.49784C9.8284 9.40116 9.8824 9.31259 9.95253 9.23762C10.0227 9.16266 10.1074 9.10289 10.2016 9.06201C10.2958 9.02114 10.3973 9.00003 10.5 9H14.625C14.7245 9 14.8198 8.96049 14.8902 8.89017C14.9605 8.81984 15 8.72446 15 8.625V7.875C15 7.77555 14.9605 7.68016 14.8902 7.60984C14.8198 7.53951 14.7245 7.5 14.625 7.5H10.5253C10.3314 7.50309 10.1436 7.43253 9.99971 7.30256C9.85582 7.17259 9.76658 6.99288 9.75 6.79969C9.74318 6.6971 9.75754 6.59421 9.79217 6.4974C9.8268 6.40059 9.88096 6.31193 9.95129 6.23694C10.0216 6.16194 10.1066 6.10221 10.201 6.06145C10.2954 6.02069 10.3972 5.99978 10.5 6H14.625C14.7245 6 14.8198 5.96049 14.8902 5.89017C14.9605 5.81984 15 5.72446 15 5.625V5.25C15 4.85218 14.842 4.47065 14.5607 4.18934C14.2794 3.90804 13.8978 3.75 13.5 3.75H3.02062C2.8492 3.74692 2.68163 3.80101 2.54437 3.90375C2.41574 4.00211 2.3224 4.13945 2.2783 4.29525C2.23419 4.45105 2.24169 4.61694 2.29968 4.76813C3.33656 7.47188 3.12093 12.6159 2.27906 15.5428C2.25974 15.6102 2.24996 15.6799 2.25 15.75V18.75C2.25 19.1478 2.40803 19.5294 2.68934 19.8107C2.97064 20.092 3.35217 20.25 3.75 20.25H6.19781C6.43083 20.25 6.66065 20.1958 6.86906 20.0916L8.05218 19.5H9.57281L10.7559 20.0916C10.9643 20.1958 11.1942 20.25 11.4272 20.25H14.0728C14.3058 20.25 14.5357 20.1958 14.7441 20.0916L15.9272 19.5H17.4478L18.6309 20.0916C18.8393 20.1958 19.0692 20.25 19.3022 20.25H21.75C22.1478 20.25 22.5294 20.092 22.8107 19.8107C23.092 19.5294 23.25 19.1478 23.25 18.75V15.75C23.25 14.3576 22.6969 13.0223 21.7123 12.0377C20.7277 11.0531 19.3924 10.5 18 10.5ZM21.75 18.75H19.3022L18.1191 18.1584C17.9107 18.0542 17.6808 18 17.4478 18H15.9272C15.6942 18 15.4643 18.0542 15.2559 18.1584L14.0728 18.75H11.4272L10.2441 18.1584C10.0357 18.0542 9.80583 18 9.57281 18H8.05218C7.81916 18 7.58934 18.0542 7.38093 18.1584L6.19781 18.75H3.75V16.5H21.75V18.75Z'
        fill={fill}
      />
    </svg>
  );
};
