import { useQueryParamSearch } from '@/components/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Section {
  name: string;
  id: string;
}

interface TabSectionProps {
  sections: Section[];
  queryParam: string;
}

/**
 * @description Component for rendering a tab section with clickable tabs.
 **/
export function TabSection({ sections, queryParam }: TabSectionProps) {
  const navigate = useNavigate();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const searchParams = useQueryParamSearch(queryParam);

  const handleTabClick = (index: number, id: string) => {
    setActiveTabIndex(index);
    navigate(`?${queryParam}=${id}`);
  };

  useEffect(() => {
    if (queryParam) {
      const tabIndex = sections.findIndex(
        (section) => section.id === searchParams,
      );
      if (tabIndex !== -1) {
        setActiveTabIndex(tabIndex);
      }
    }
  }, [queryParam, sections, searchParams]);

  return (
    <ul className='mb-12 flex gap-6 border-b pt-4'>
      {sections.map((section, index) => (
        <li
          key={index}
          className={`flex gap-3 border-b-2 py-2 transition-colors duration-300 ${
            index === activeTabIndex
              ? 'border-gray-800 font-semibold'
              : 'border-transparent font-semibold text-gray-500'
          }`}
        >
          <button
            className='cursor-pointer'
            onClick={(e) => {
              e.preventDefault();
              handleTabClick(index, section.id);
            }}
          >
            {section.name}
          </button>
        </li>
      ))}
    </ul>
  );
}
