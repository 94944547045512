import { useEffect, useState } from 'react';

const useShowPasswordCriteriaTooltip = () => {
  const [validPasswordTooltip, setValidPasswordTooltip] =
    useState<boolean>(false);
  const [isPasswordFieldFocused, setIsPasswordFieldFocused] =
    useState<boolean>(false);

  useEffect(() => {
    setValidPasswordTooltip(isPasswordFieldFocused);
    return () => {
      setValidPasswordTooltip(false);
    };
  }, [isPasswordFieldFocused]);

  const handleFocus = () => {
    setIsPasswordFieldFocused(true);
  };
  const handleBlur = () => {
    setIsPasswordFieldFocused(false);
  };

  return { validPasswordTooltip, handleFocus, handleBlur };
};

export default useShowPasswordCriteriaTooltip;
