import { useQuery } from '@tanstack/react-query';

import { OrdersAPI } from '@/services/api/orders';
import { TOrder } from '@/schemas/orders/my-orders';
import { useURLSearchParams } from '@/components/hooks/router/useURLSearchParams';
import {
  API_ENDPOINT,
  QUERY_CONSTANTS,
  TANSTACK_QUERY_KEY,
} from '@/utils/constants';

export function useGetOrderList() {
  /**
   * @todo This query build needs to refactor once the backend response is consistent across all the endpoints.
   */

  const searchParams = new URLSearchParams();

  const { searchValue: search } = useURLSearchParams(QUERY_CONSTANTS.SEARCH);

  const { searchValue: sortField } = useURLSearchParams(
    QUERY_CONSTANTS.SORT.FIELD,
  );

  const { searchValue: sortOrder } = useURLSearchParams(
    QUERY_CONSTANTS.SORT.ORDER,
  );

  const { searchValue: page } = useURLSearchParams(QUERY_CONSTANTS.PAGE);

  const { searchValue: limit } = useURLSearchParams(QUERY_CONSTANTS.LIMIT);

  const { searchValue: startDate } = useURLSearchParams(
    QUERY_CONSTANTS.ORDER_PLACED_DATE.START_DATE,
  );

  const { searchValue: endDate } = useURLSearchParams(
    QUERY_CONSTANTS.ORDER_PLACED_DATE.END_DATE,
  );

  if (search) searchParams.append(QUERY_CONSTANTS.SEARCH_QUERY, search);

  if (sortField) searchParams.append(QUERY_CONSTANTS.SORT.FIELD, sortField);

  if (sortOrder) searchParams.append(QUERY_CONSTANTS.SORT.ORDER, sortOrder);

  if (page) searchParams.append(QUERY_CONSTANTS.PAGE, page);

  if (limit) searchParams.append(QUERY_CONSTANTS.PAGE_SIZE, limit);

  if (startDate)
    searchParams.append(
      QUERY_CONSTANTS.ORDER_PLACED_DATE.START_DATE,
      startDate,
    );

  if (endDate)
    searchParams.append(QUERY_CONSTANTS.ORDER_PLACED_DATE.END_DATE, endDate);

  const queryString = searchParams.toString();

  let path = API_ENDPOINT.ORDER_DETAILS;

  if (queryString) {
    path = `${path}?${queryString}`;
  }

  return useQuery({
    queryKey: [
      TANSTACK_QUERY_KEY.ORDER,
      search,
      sortField,
      sortOrder,
      page,
      limit,
      startDate,
      endDate,
    ],
    queryFn: () => OrdersAPI.getOrderList(path)(),
    select: ({ data, meta }) => {
      const { total, page: currentPage, pageCount } = meta.pagination;

      const orders: TOrder[] = data.map((order) => ({
        id: order.orderId,
        product: order.noProducts,
        createdAt: order.createdAt,
        placedBy: order.orderedBy.firstName + ' ' + order.orderedBy.lastName,
        amount: order.orderTotal,
        customId: order.customId,
      }));

      return { orders, totalOrders: total, currentPage, totalPages: pageCount };
    },
  });
}
