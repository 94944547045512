import { ColorBadge } from '../badge';

export const ProductColorLabel = ({
  colourName,
  colourHex,
}: {
  colourName?: string | null | undefined;
  colourHex?: string | null | undefined;
}) => {
  return (
    <>
      {(colourName ?? colourHex) && (
        <>
          {colourHex ? (
            <ColorBadge
              variant='transparent'
              shoeProductColor={colourHex}
            >
              <span className='text-gray-800'>{colourName ?? 'N/A'}</span>
            </ColorBadge>
          ) : (
            <span className='text-gray-800'>{colourName ?? 'N/A'}</span>
          )}
        </>
      )}
    </>
  );
};
