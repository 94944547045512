import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/cn';
import { CheckBoxTick } from '@/components/ui/icons/regular/CheckboxTick';

const checkboxVariants = cva(
  "before:content[''] bg-white before:bg-blue-gray-500 peer relative size-5 cursor-pointer appearance-none rounded border border-gray-300 transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity disabled:cursor-not-allowed disabled:bg-gray-100",
  {
    variants: {
      variant: {
        primary:
          'checked:border-primary-500 checked:bg-primary-500 disabled:checked:border-primary-200 disabled:checked:bg-primary-200',
        blue: 'checked:border-blue-500 checked:bg-blue-500  disabled:checked:border-blue-200 disabled:checked:bg-blue-200',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof checkboxVariants> {}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, variant = 'blue', ...props }, ref) => {
    return (
      <div className='inline-flex items-center'>
        <div className='relative flex cursor-pointer items-center'>
          <input
            type='checkbox'
            ref={ref}
            className={cn(checkboxVariants({ variant }), className)}
            data-cy={`${variant}-checkbox`}
            {...props}
          />
          <span className='pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100'>
            <CheckBoxTick />
          </span>
        </div>
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';
