import React from 'react';
import { IconProps } from '../iconProps';

export const IconNotification: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.7938 16.4944C20.2735 15.5981 19.5 13.0622 19.5 9.75C19.5 7.76088 18.7098 5.85322 17.3033 4.4467C15.8968 3.04018 13.9891 2.25 12 2.25C10.0109 2.25 8.10323 3.04018 6.69671 4.4467C5.29019 5.85322 4.50001 7.76088 4.50001 9.75C4.50001 13.0631 3.72564 15.5981 3.20532 16.4944C3.07245 16.7222 3.00201 16.9811 3.00111 17.2449C3.0002 17.5086 3.06886 17.768 3.20017 17.9967C3.33147 18.2255 3.52077 18.4156 3.74899 18.5478C3.9772 18.6801 4.23625 18.7498 4.50001 18.75H8.32595C8.49899 19.5967 8.95916 20.3577 9.62864 20.9042C10.2981 21.4507 11.1358 21.7492 12 21.7492C12.8642 21.7492 13.7019 21.4507 14.3714 20.9042C15.0409 20.3577 15.501 19.5967 15.6741 18.75H19.5C19.7637 18.7496 20.0226 18.6798 20.2507 18.5475C20.4788 18.4151 20.668 18.225 20.7992 17.9963C20.9303 17.7676 20.9989 17.5083 20.998 17.2446C20.997 16.9809 20.9266 16.7222 20.7938 16.4944ZM12 20.25C11.5348 20.2499 11.0812 20.1055 10.7014 19.8369C10.3216 19.5683 10.0344 19.1886 9.87939 18.75H14.1206C13.9656 19.1886 13.6784 19.5683 13.2986 19.8369C12.9189 20.1055 12.4652 20.2499 12 20.25ZM4.50001 17.25C5.22189 16.0087 6.00001 13.1325 6.00001 9.75C6.00001 8.1587 6.63215 6.63258 7.75737 5.50736C8.88259 4.38214 10.4087 3.75 12 3.75C13.5913 3.75 15.1174 4.38214 16.2427 5.50736C17.3679 6.63258 18 8.1587 18 9.75C18 13.1297 18.7763 16.0059 19.5 17.25H4.50001Z'
        fill={fill ? fill : '#000000'}
      />
    </svg>
  );
};
export const IconNotificationFilled: React.FC<IconProps> = ({
  size = 16,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.3282 12.7453C14.8946 11.9984 14.2501 9.88516 14.2501 7.125C14.2501 5.4674 13.5916 3.87769 12.4195 2.70558C11.2474 1.53348 9.65767 0.875 8.00007 0.875C6.34247 0.875 4.75276 1.53348 3.58065 2.70558C2.40855 3.87769 1.75007 5.4674 1.75007 7.125C1.75007 9.88594 1.10476 11.9984 0.671165 12.7453C0.560439 12.9352 0.501739 13.1509 0.500984 13.3707C0.500229 13.5905 0.557447 13.8066 0.666867 13.9973C0.776287 14.1879 0.934039 14.3463 1.12422 14.4565C1.31439 14.5667 1.53027 14.6248 1.75007 14.625H4.93835C5.08255 15.3306 5.46603 15.9647 6.02392 16.4201C6.58182 16.8756 7.2799 17.1243 8.00007 17.1243C8.72025 17.1243 9.41832 16.8756 9.97622 16.4201C10.5341 15.9647 10.9176 15.3306 11.0618 14.625H14.2501C14.4698 14.6247 14.6856 14.5665 14.8757 14.4562C15.0657 14.346 15.2234 14.1875 15.3327 13.9969C15.442 13.8063 15.4992 13.5903 15.4984 13.3705C15.4976 13.1508 15.4389 12.9351 15.3282 12.7453ZM8.00007 15.875C7.61243 15.8749 7.23435 15.7546 6.91788 15.5308C6.60141 15.3069 6.3621 14.9905 6.23288 14.625H9.76726C9.63804 14.9905 9.39873 15.3069 9.08226 15.5308C8.76579 15.7546 8.38771 15.8749 8.00007 15.875Z'
        fill={fill ? fill : '#1F2937'}
      />
    </svg>
  );
};
