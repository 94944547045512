export const TokenExpired =
  'Token has expired. Please contact the admin for assistance.';
export const PassworReset = 'Password reset successfully';
export const InvitationLink = 'Invitation link has been resent successfully!';
export const LinkExpired =
  'Failed to resend the invitation link. Please try again.';
export const AccountCreated =
  'Account has been successfully created. Please login to continue.';
export const UserDelete = 'User deleted successfully';
export const PassworResetLink =
  'Password reset link has been sent successfully';
export const Password2faReset = '2fa reset link has been sent successfully';
export const TwoFaResetValidate =
  'You are already logged in with a different user ID. Please log out first and enable your 2FA.';
export const UserLogout = 'User logged out successfully';
export const TwoFaResetValidateExpired =
  '2FA reset link expired or already used. Request a new one.';
export const ShippingAddresAddSuccess =
  'Shipping information have been successfully added';
export const BillingAddresAddSuccess =
  'Billing information have been successfully added';
export const ShippingAddresUpdateSuccess =
  'Shipping information have been successfully updated';
export const BillingAddresUpdateSuccess =
  'Billing information have been successfully updated';
