import { useContext, useMemo, useState } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import { cn } from '@/utils/cn';
import { RoleOptions } from '@/data/Users.data';
import { Badge, Button, Select, IconsComponent } from '@/components';
import { CreateUserTableContext } from '@/pages/users-and-teams/users/modals/CreateUserModal';

export interface CreateUser {
  email: string;
  role: string;
  teams?: { value: string; label: string }[] | undefined;
}

function RolesColumn(info: CellContext<CreateUser, unknown>) {
  const role = info.row.original.role;

  const { setCreateUserList } = useContext(CreateUserTableContext);

  const [selectedRole, setSelectedRole] = useState(role);

  const handleRoleChange = (newValue: unknown) => {
    const newSelectedRole = (newValue as { value: unknown }).value as string;

    setSelectedRole(newSelectedRole);

    info.table.options.meta?.updateRowData(
      info.row.index,
      'roles',
      newSelectedRole,
    );

    setCreateUserList((prev) =>
      prev.map((item, index) =>
        index === info.row.index ? { ...item, roles: newSelectedRole } : item,
      ),
    );
  };

  return (
    <Select
      menuPosition='fixed'
      options={RoleOptions}
      onChange={handleRoleChange}
      value={RoleOptions.find((item) => item.value === selectedRole)}
      classNames={{
        valueContainer: () => '!pl-0',
        control: (state) =>
          cn(
            state.isFocused && '!border-transparent',
            '!pl-0 !border-transparent',
          ),
      }}
    />
  );
}

function TeamsColumn(info: CellContext<CreateUser, unknown>) {
  const teams = info.row.original.teams;

  const { setCreateUserList } = useContext(CreateUserTableContext);

  const handleRemove = (team: string) => {
    const newTeamList = teams?.filter((item) => item.value !== team);

    info.table.options.meta?.updateRowData(
      info.row.index,
      'teams',
      newTeamList,
    );

    setCreateUserList((prev) => {
      return prev.map((item, index) =>
        index === info.row.index ? { ...item, teams: newTeamList } : item,
      );
    });
  };

  if (teams === undefined || teams.length === 0) return 'N/A';

  return (
    <div className='flex flex-wrap items-center gap-1'>
      {teams.map((team) => (
        <Badge key={team.value}>
          {team.label}{' '}
          <Button
            intent='tertiary'
            size='icon'
            className='p-0'
            onClick={() => handleRemove(team.value)}
          >
            <IconsComponent
              icon='reg-close'
              size='xs'
            />
          </Button>
        </Badge>
      ))}
    </div>
  );
}

function ActionColumn(info: CellContext<CreateUser, unknown>) {
  const { setCreateUserList } = useContext(CreateUserTableContext);

  const handleRemove = () => {
    info.table.options.meta?.deleteRowData(info.row.index);

    setCreateUserList((prev) =>
      prev.filter((_, index) => index !== info.row.index),
    );
  };

  return (
    <Button
      intent='tertiary'
      size='xs'
      variant='red'
      onClick={handleRemove}
    >
      Remove
    </Button>
  );
}

/**
 * @description Custom hook to create columns for CreateUserTable
 */
export function useCreateUserListColumn() {
  const columns = useMemo<ColumnDef<CreateUser>[]>(
    () => [
      {
        accessorKey: 'email',
        header: 'EMAILS',
        enableSorting: false,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'role',
        header: 'ROLES',
        enableSorting: false,
        cell: (info) => <RolesColumn {...info} />,
      },
      {
        accessorKey: 'teams',
        header: 'TEAMS',
        enableSorting: false,
        cell: (info) => <TeamsColumn {...info} />,
      },
      {
        accessorKey: 'actions',
        enableSorting: false,
        header: '',
        cell: (info) => <ActionColumn {...info} />,
      },
    ],
    [],
  );
  return { columns };
}
