import React from 'react';
import { IconProps } from '../iconProps';

export const IconFlag: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.25 9.74995C23.2501 9.90574 23.2017 10.0577 23.1114 10.1847C23.0212 10.3117 22.8937 10.4075 22.7466 10.4587L6 16.2834V20.25C6 20.4489 5.92098 20.6396 5.78033 20.7803C5.63968 20.9209 5.44891 21 5.25 21C5.05109 21 4.86032 20.9209 4.71967 20.7803C4.57902 20.6396 4.5 20.4489 4.5 20.25V3.74995C4.49993 3.63023 4.52852 3.51224 4.58338 3.40584C4.63823 3.29943 4.71777 3.2077 4.81532 3.13832C4.91288 3.06894 5.02563 3.02392 5.14415 3.00702C5.26266 2.99012 5.3835 3.00184 5.49656 3.0412L22.7466 9.0412C22.8937 9.09242 23.0212 9.18818 23.1114 9.31519C23.2017 9.4422 23.2501 9.59416 23.25 9.74995Z'
        fill={fill}
      />
    </svg>
  );
};
