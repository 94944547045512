import { useQuery } from '@tanstack/react-query';

import { QUERY_CONSTANTS } from '@/utils/constants/query.constants';
import { AllProductsApi } from '@/services/api/products/all-products';

export const useProductComparisonList = ({
  search,
  categoryId,
}: {
  search?: string;
  categoryId?: string;
}) => {
  const searchParams = new URLSearchParams();

  if (search) searchParams.append(QUERY_CONSTANTS.QUERY_Q, search);
  searchParams.append('pageSize', '100');
  if (categoryId) searchParams.append(QUERY_CONSTANTS.CATEGORY_ID, categoryId);

  const queryString = searchParams.toString();
  const url = queryString ? `?${queryString}` : '';

  return useQuery({
    queryKey: [search, categoryId],
    queryFn: () => AllProductsApi.getAllProducts(url)(),
  });
};
