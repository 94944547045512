import { z } from 'zod';

import {
  GetPromotionDetailRequestSchema,
  GetPromotionDetailResponseSchema,
  GetPromotionListRequestSchema,
  GetPromotionListResponseSchema,
} from '@/schemas/api/marketing-resources/promotions/promotion.schema';
import { api } from '@/utils/helper';
import { API_ENDPOINT } from '@/utils/constants';

const getPromotionList = (path: string) =>
  api<
    z.infer<typeof GetPromotionListRequestSchema>,
    z.infer<typeof GetPromotionListResponseSchema>
  >({
    method: 'GET',
    path,
    requestSchema: GetPromotionListRequestSchema,
    responseSchema: GetPromotionListResponseSchema,
  });

const getPromotionDetail = api<
  z.infer<typeof GetPromotionDetailRequestSchema>,
  z.infer<typeof GetPromotionDetailResponseSchema>
>({
  method: 'GET',
  path: API_ENDPOINT.PROMOTIONS.LIST,
  requestSchema: GetPromotionDetailRequestSchema,
  responseSchema: GetPromotionDetailResponseSchema,
});

export type TPromotionDetailResponse = z.infer<
  typeof GetPromotionDetailResponseSchema
>;

export const PromotionAPI = {
  getPromotionList,
  getPromotionDetail,
};
