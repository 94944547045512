import React from 'react';

interface BackdropProps {
  className?: string;
}

export const Backdrop: React.FC<BackdropProps> = ({ className = '' }) => {
  return (
    <div className={`fixed inset-0 z-40 bg-black opacity-70 ${className}`} />
  );
};
