//**Dummy json data for testing */

export const RoleOptions = [
  {
    value: 'ADMIN',
    label: 'Admin',
  },
  {
    value: 'STAFF',
    label: 'Staff',
  },
];

export const StateOpions = [
  {
    value: 'england',
    label: 'England',
  },
  {
    value: 'scotland',
    label: 'Scotland',
  },
];

export const TeamOptions = [
  {
    value: 'marketing',
    label: 'Marketing',
  },
  {
    value: 'sales',
    label: 'Sales',
  },
  {
    value: 'accounts',
    label: 'Accounts',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
];

export const StatusOptions = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'INACTIVE',
    label: 'Inactive',
  },
  {
    value: 'INVITED',
    label: 'Invite Pending',
  },
];
