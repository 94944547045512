import React from 'react';
import { IconProps } from '../iconProps';

export const IconReset: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 4.50001V9.00001C21 9.19892 20.921 9.38969 20.7803 9.53034C20.6397 9.67099 20.4489 9.75001 20.25 9.75001H15.75C15.5511 9.75001 15.3603 9.67099 15.2197 9.53034C15.079 9.38969 15 9.19892 15 9.00001C15 8.8011 15.079 8.61033 15.2197 8.46968C15.3603 8.32903 15.5511 8.25001 15.75 8.25001H18.4397L17.0681 6.87845C15.6742 5.4782 13.7817 4.68821 11.8059 4.68188H11.7638C9.80454 4.67729 7.92227 5.44414 6.52406 6.81657C6.38083 6.95028 6.19096 7.02262 5.99507 7.01813C5.79918 7.01364 5.61283 6.93266 5.47588 6.79253C5.33893 6.65239 5.26226 6.46423 5.26227 6.26829C5.26228 6.07235 5.33897 5.88419 5.47594 5.74407C7.1705 4.08788 9.44983 3.16677 11.8193 3.18064C14.1887 3.1945 16.4571 4.14222 18.1322 5.81813L19.5 7.1897V4.50001C19.5 4.3011 19.579 4.11033 19.7197 3.96968C19.8603 3.82903 20.0511 3.75001 20.25 3.75001C20.4489 3.75001 20.6397 3.82903 20.7803 3.96968C20.921 4.11033 21 4.3011 21 4.50001ZM17.4759 17.1834C16.0639 18.5627 14.1651 19.3298 12.1912 19.3182C10.2173 19.3066 8.32762 18.5173 6.93188 17.1216L5.56031 15.75H8.25C8.44891 15.75 8.63968 15.671 8.78033 15.5303C8.92098 15.3897 9 15.1989 9 15C9 14.8011 8.92098 14.6103 8.78033 14.4697C8.63968 14.329 8.44891 14.25 8.25 14.25H3.75C3.55109 14.25 3.36032 14.329 3.21967 14.4697C3.07902 14.6103 3 14.8011 3 15V19.5C3 19.6989 3.07902 19.8897 3.21967 20.0303C3.36032 20.171 3.55109 20.25 3.75 20.25C3.94891 20.25 4.13968 20.171 4.28033 20.0303C4.42098 19.8897 4.5 19.6989 4.5 19.5V16.8103L5.87156 18.1819C7.54426 19.863 9.816 20.8112 12.1875 20.8181H12.2372C14.5885 20.8242 16.8476 19.9037 18.525 18.2559C18.662 18.1158 18.7387 17.9277 18.7387 17.7317C18.7387 17.5358 18.662 17.3476 18.5251 17.2075C18.3881 17.0674 18.2018 16.9864 18.0059 16.9819C17.81 16.9774 17.6201 17.0497 17.4769 17.1834H17.4759Z'
        fill={fill}
      />
    </svg>
  );
};
