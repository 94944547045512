import { IconProps } from '../iconProps';

export const Invoice: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.25 3.75H3.75C3.35218 3.75 2.97064 3.90804 2.68934 4.18934C2.40804 4.47064 2.25 4.85218 2.25 5.25V19.5C2.25007 19.6278 2.28281 19.7535 2.34511 19.8651C2.40741 19.9768 2.49721 20.0706 2.60597 20.1378C2.71474 20.2049 2.83887 20.2432 2.96657 20.2489C3.09427 20.2546 3.22131 20.2275 3.33563 20.1703L6 18.8381L8.66437 20.1703C8.76857 20.2225 8.88348 20.2496 9 20.2496C9.11652 20.2496 9.23143 20.2225 9.33563 20.1703L12 18.8381L14.6644 20.1703C14.7686 20.2225 14.8835 20.2496 15 20.2496C15.1165 20.2496 15.2314 20.2225 15.3356 20.1703L18 18.8381L20.6644 20.1703C20.7787 20.2275 20.9057 20.2546 21.0334 20.2489C21.1611 20.2432 21.2853 20.2049 21.394 20.1378C21.5028 20.0706 21.5926 19.9768 21.6549 19.8651C21.7172 19.7535 21.7499 19.6278 21.75 19.5V5.25C21.75 4.85218 21.592 4.47064 21.3107 4.18934C21.0294 3.90804 20.6478 3.75 20.25 3.75ZM16.5 13.5H7.5C7.30109 13.5 7.11032 13.421 6.96967 13.2803C6.82902 13.1397 6.75 12.9489 6.75 12.75C6.75 12.5511 6.82902 12.3603 6.96967 12.2197C7.11032 12.079 7.30109 12 7.5 12H16.5C16.6989 12 16.8897 12.079 17.0303 12.2197C17.171 12.3603 17.25 12.5511 17.25 12.75C17.25 12.9489 17.171 13.1397 17.0303 13.2803C16.8897 13.421 16.6989 13.5 16.5 13.5ZM16.5 10.5H7.5C7.30109 10.5 7.11032 10.421 6.96967 10.2803C6.82902 10.1397 6.75 9.94891 6.75 9.75C6.75 9.55109 6.82902 9.36032 6.96967 9.21967C7.11032 9.07902 7.30109 9 7.5 9H16.5C16.6989 9 16.8897 9.07902 17.0303 9.21967C17.171 9.36032 17.25 9.55109 17.25 9.75C17.25 9.94891 17.171 10.1397 17.0303 10.2803C16.8897 10.421 16.6989 10.5 16.5 10.5Z'
        fill={fill ?? 'white'}
      />
    </svg>
  );
};
