import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { Button, IconsComponent, InputError, Label } from '@/components';
import { useState } from 'react';

/**
 * @description ImageUpload input field component
 */

export function ImageUpload({
  name,
  profileImage,
  firstName,
}: {
  name: string;
  profileImage?: string;
  firstName?: string;
}) {
  const [uploadedImage, setUploadedImage] = useState<string | undefined>(
    profileImage,
  );
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const handleProfileImageUpload = () => {
    const profileImageUploadInput = document.getElementById(
      'image-upload',
    ) as HTMLInputElement;
    profileImageUploadInput.click();

    profileImageUploadInput.addEventListener('change', (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        if (file.type.split('/')[0] !== 'image') {
          return;
        }
        const reader = new FileReader();

        reader.onload = function () {
          setUploadedImage(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    });
  };

  return (
    <div
      className='space-y-1'
      data-cy='image upload field'
    >
      <div className='flex items-center gap-x-4'>
        {uploadedImage ? (
          <img
            src={uploadedImage}
            alt='preview'
            className='preview size-16 place-content-start rounded-full border border-solid object-cover'
          />
        ) : (
          <div className='flex size-16 items-center justify-center rounded-full bg-primary-50 text-3xl font-semibold text-primary-500'>
            {firstName?.charAt(0).toUpperCase() ?? 'P'}
          </div>
        )}
        <div>
          <input
            {...register(name)}
            type='file'
            accept='image/*'
            id='image-upload'
            name={name}
            hidden
            data-cy={`${name} image upload field`}
          />
          <Label
            htmlFor='image-upload'
            className='text-base font-semibold text-gray-800'
          >
            Change Image
          </Label>
          <Button
            variant='blue'
            intent='tertiary'
            size='xs'
            className='gap-x-0.5 p-0'
            onClick={(event) => {
              event.preventDefault();
              handleProfileImageUpload();
            }}
          >
            <IconsComponent
              icon='reg-upload'
              fill='#2563eb'
              size='sm'
            />{' '}
            Upload new
          </Button>
        </div>
      </div>

      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => <InputError message={message} />}
      />
      <p className='italic text-gray-400'>
        (Supported format JPEG, PNG. Max 4MB)
      </p>
    </div>
  );
}
