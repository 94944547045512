import { ColumnDef, flexRender, Table } from '@tanstack/react-table';

import {
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableLayout,
  TableHeader,
  Table as HTMLTable,
} from '@/components';

export interface SimpleTableProps<T> {
  table: Table<T>;
  columns: ColumnDef<T>[];
}

/**
 * @description Table component to render generic table based on the provided columns and data with out table border.
 */

export function SimpleTable<T>({ columns, table }: SimpleTableProps<T>) {
  return (
    <TableLayout className='border-0 pb-10 shadow-none'>
      <div className='max-h-56 overflow-y-auto'>
        <HTMLTable>
          <TableHeader className='[&_tr]:border-0'>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className='sticky top-0 z-10 bg-white pl-0 pr-32 text-xs font-semibold text-gray-400'
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </>
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className='[&_tr:last-child]:border-b'>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className='even:bg-white'
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className='pl-0'
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='text-center'
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </HTMLTable>
      </div>
    </TableLayout>
  );
}
