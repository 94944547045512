import { useEffect } from 'react';

import {
  Pagination,
  ProductCard,
  ProductDetailDrawer,
  ProductCardSkeletons,
  SearchInputForm,
} from '@/components';
import {
  useGetAllProducts,
  useToggleState,
  useURLSearchParams,
} from '@/components/hooks';
import {
  productBuyPrice,
  isProductDynamicPricing,
  productPriceType,
} from '@/utils/helper/product/product-price';
import { cn } from '@/utils/cn';
import { isEmpty } from '@/utils/helper';
import { useSidebarStore } from '@/store';
import { ProductType } from '@/interface';
import { QUERY_CONSTANTS } from '@/utils/constants';
import { EmptyMessage } from '@/components/ui/empty-message';
import { ErrorFound } from '@/components/ui/no-result/Error';
import { MediaType } from '@/utils/constants/app.key.constants';
import { CompareProducts } from '@/components/ui/compare-products';
import { PRICETYPE } from '@/utils/constants/product-stock.constants';
import { scrollToTop } from '@/components/hooks/utils/useScrollToTop';

const ProductList = ({
  currentCategoryName,
  isProductListLoading,
  productType,
}: {
  currentCategoryName: string;
  isProductListLoading: boolean;
  productType?: ProductType;
}) => {
  const { searchValue: currentPage } = useURLSearchParams(QUERY_CONSTANTS.PAGE);

  const { isSidebarIconOnlyView } = useSidebarStore();
  const {
    data: products,
    isLoading: isProductsLoading,
    isError: isProductsError,
  } = useGetAllProducts();

  const {
    state: showProductDetailDrawer,
    setState: setShowProductDetailDrawer,
    toggleState: viewRangeToggler,
  } = useToggleState();

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  return (
    <div>
      <div className='flex h-[60px] items-center justify-between'>
        {isProductListLoading ? (
          <div />
        ) : (
          <p className='text-xl font-semibold text-gray-800'>
            {currentCategoryName}
          </p>
        )}
        <SearchInputForm />
      </div>
      <hr />
      <div
        className={cn(
          'grid grid-cols-1 gap-x-4 gap-y-6 py-6 tablet:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 2xl:gap-6 3xl:grid-cols-3',
          !isSidebarIconOnlyView && 'lg:grid-cols-1 2lg:grid-cols-1',
          'auto-rows-fr',
          isProductsError && 'flex justify-center',
        )}
      >
        {(() => {
          if (isProductsLoading) {
            return <ProductCardSkeletons length={3} />;
          }

          if (isProductsError) {
            return <ErrorFound />;
          }

          if (isEmpty(products?.data)) {
            return <EmptyMessage message='No products found' />;
          }

          return products?.data.map((product) => (
            <div
              key={product.id}
              className='flex h-full max-w-[366px] flex-col'
            >
              <ProductCard
                id={product.id ?? ''}
                productCode={product.skuCode ?? ''}
                productName={product.name ?? ''}
                buyPrice={productBuyPrice(product.prices, Number(product.moq))}
                imageSrc={
                  product.medias
                    ? (product.medias.find(
                        (media) => media.type === MediaType.THUMBNAIL,
                      )?.url ?? '')
                    : ''
                }
                isProductNew={product.isNew ?? false}
                rrp={Number(product.price)}
                isDynamicPricing={isProductDynamicPricing(product.prices)}
                moq={product.moq ?? undefined}
                imageAlt={`${product.name} image`}
                viewRangeToggler={viewRangeToggler}
                productType={productType}
                priceType={
                  productPriceType(
                    product.prices,
                    Number(product.moq),
                  ) as PRICETYPE
                }
                productData={product}
              />
            </div>
          ));
        })()}
      </div>
      <ProductDetailDrawer
        showProductDetailDrawer={showProductDetailDrawer}
        setShowProductDetailDrawer={setShowProductDetailDrawer}
      />
      {products?.meta.pagination && (
        <Pagination
          totalCount={products.meta.pagination.total}
          totalPages={products.meta.pagination.pageCount}
        />
      )}
      <CompareProducts />
    </div>
  );
};

export default ProductList;
