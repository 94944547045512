import React from 'react';
import { IconProps } from '../iconProps';

export const IconSupport: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.75 12.0001V19.5001C21.75 20.4946 21.3549 21.4484 20.6517 22.1517C19.9484 22.855 18.9946 23.2501 18 23.2501H12.75C12.5511 23.2501 12.3603 23.171 12.2197 23.0304C12.079 22.8897 12 22.699 12 22.5001C12 22.3011 12.079 22.1104 12.2197 21.9697C12.3603 21.8291 12.5511 21.7501 12.75 21.7501H18C18.5967 21.7501 19.169 21.513 19.591 21.091C20.0129 20.6691 20.25 20.0968 20.25 19.5001H18C17.4033 19.5001 16.831 19.263 16.409 18.841C15.9871 18.4191 15.75 17.8468 15.75 17.2501V13.5001C15.75 12.9033 15.9871 12.331 16.409 11.9091C16.831 11.4871 17.4033 11.2501 18 11.2501H20.2172C20.0753 9.69094 19.4926 8.20438 18.5374 6.96404C17.5821 5.72369 16.2936 4.78075 14.8225 4.24537C13.3513 3.70998 11.7581 3.60425 10.2291 3.94053C8.70008 4.2768 7.2983 5.0412 6.1875 6.14443C4.80624 7.50747 3.9543 9.31701 3.78375 11.2501H6C6.59674 11.2501 7.16903 11.4871 7.59099 11.9091C8.01295 12.331 8.25 12.9033 8.25 13.5001V17.2501C8.25 17.8468 8.01295 18.4191 7.59099 18.841C7.16903 19.263 6.59674 19.5001 6 19.5001H4.5C3.90326 19.5001 3.33097 19.263 2.90901 18.841C2.48705 18.4191 2.25 17.8468 2.25 17.2501V12.0001C2.2521 10.0687 2.82715 8.1814 3.90235 6.57702C4.97755 4.97264 6.50456 3.72331 8.29012 2.98717C10.0757 2.25102 12.0395 2.06116 13.933 2.44162C15.8265 2.82207 17.5646 3.75574 18.9272 5.12443C19.8268 6.02847 20.5393 7.10114 21.0237 8.28098C21.5081 9.46083 21.7549 10.7247 21.75 12.0001Z'
        fill={fill}
      />
    </svg>
  );
};
