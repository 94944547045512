import { HTMLProps, useEffect, useRef } from 'react';

import { Checkbox } from '@/components';

/**
 * @description TableCheckbox component is used to render a checkbox in the table.
 */

export function TableCheckbox({
  indeterminate,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <Checkbox
      ref={ref}
      variant='blue'
      {...rest}
    />
  );
}
