import { Skeleton } from '@/components/lib/Skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

interface OrderTableSkeletonProps {
  rowCount?: number;
  cellCount?: number;
  headers?: string[];
}

export function OrderTableSkeleton({
  rowCount = 10,
  cellCount = 6,
  headers = [
    'Order ID',
    'No. of Products',
    'Order Placed Date',
    'Order Placed By',
    'Order Amount',
    'Action',
  ],
}: OrderTableSkeletonProps) {
  return (
    <Table className='order-table-skeleton mt-8 w-full overflow-auto'>
      <TableHeader>
        <TableRow>
          {headers.map((header, index) => (
            <TableHead key={index}>{header}</TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array.from({ length: rowCount }).map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {Array.from({ length: cellCount }).map((__, cellIndex) => (
              <TableCell key={cellIndex}>
                <Skeleton />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
