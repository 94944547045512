import { useEffect, useState } from 'react';
import { Form, useSearchParams } from 'react-router-dom';

import { cn } from '@/utils/cn';
import { isEmpty } from '@/utils/helper';
import { EmptyMessage } from '@/components/ui/empty-message';
import { HttpMethods, QUERY_CONSTANTS } from '@/utils/constants';
import { ApplyButton, FilterGroup, handleChange } from '../index';
import { ProductFilterKeys, useProductFilterStore } from '@/store';
import { IconsComponent, InputSkeletons, Label } from '@/components';
import { ProductFilterStateKeys } from '@/store/product-filter.store';
import { useFilterSubmit, useGetAllProducts } from '@/components/hooks';

export const ShoeColorFilter = () => {
  const {
    toggleFilter,
    showColourOptions,
    setProductFilter,
    shoeColorSelected,
    availableColourOptions,
    setAvailableColourOptions,
  } = useProductFilterStore();

  const {
    selectedOptions,
    setSelectedOptions,
    handleSubmit,
    isButtonDisabled,
    defaultSearchParams: urlParams,
  } = useFilterSubmit(QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_COLOR);

  const {
    data: allProductsData,
    isSuccess: allProductsDataFetchSuccess,
    isLoading: isAllProductDataLoading,
  } = useGetAllProducts();

  const [searchParams, setSearchParams] = useSearchParams();
  const [disableAnimation, setDisableAnimation] = useState(false);

  const handleShoeColorOptionClick = (id: string) => {
    handleChange(
      id,
      shoeColorSelected,
      setSelectedOptions,
      setProductFilter,
      ProductFilterStateKeys.ShoeColorOptionsSelected,
    );
    searchParams.delete(QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_COLOR, id);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const showApplyButton = () => {
    return (
      !isButtonDisabled &&
      selectedOptions.length === shoeColorSelected.length &&
      selectedOptions.length !== urlParams.length
    );
  };
  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableAnimation(true);
    try {
      handleSubmit(event);
    } finally {
      setDisableAnimation(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedOptions)) {
      if (!showColourOptions) {
        toggleFilter(ProductFilterKeys.ColourOptions);
      }
      setProductFilter(
        ProductFilterStateKeys.ShoeColorOptionsSelected,
        selectedOptions,
      );
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (!isEmpty(shoeColorSelected)) {
      setSelectedOptions(shoeColorSelected);
    }
  }, [searchParams]);

  useEffect(() => {
    const shoeColourData = allProductsData?.filterable?.colours.map(
      (colour) => ({
        ...colour,
        hex: colour.hex.startsWith('#') ? colour.hex.slice(1) : colour.hex,
      }),
    );

    setAvailableColourOptions(shoeColourData ?? []);
  }, [allProductsDataFetchSuccess, allProductsData]);

  return (
    <FilterGroup
      title='Colour'
      isActive={showColourOptions}
      toggleFilter={() => toggleFilter(ProductFilterKeys.ColourOptions)}
    >
      {isAllProductDataLoading ? (
        <InputSkeletons height={36} />
      ) : isEmpty(availableColourOptions) ? (
        <EmptyMessage
          message='Shoe colours not available'
          variant='400'
        />
      ) : (
        <Form
          method={HttpMethods.GET}
          onSubmit={onSubmitHandler}
        >
          <div className='flex flex-wrap items-center gap-2'>
            {availableColourOptions.map((shoeColor) => (
              <Label
                key={shoeColor.hex}
                className={cn(
                  'flex cursor-pointer items-center gap-1 rounded-[32px] border border-gray-200 py-1 pl-1 pr-2',
                  shoeColorSelected.includes(shoeColor.hex) &&
                    'border border-gray-100 bg-white shadow-sm',
                )}
              >
                <div
                  style={{ background: `#${shoeColor.hex}` }}
                  className='h-5 w-5 rounded-full'
                  data-cy={`${shoeColor.name} hex: ${shoeColor.hex} `}
                />
                <input
                  type='checkbox'
                  className='mr-1 hidden'
                  name={QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_COLOR}
                  value={shoeColor.hex}
                  checked={shoeColorSelected.includes(shoeColor.hex)}
                  onChange={() => {
                    handleShoeColorOptionClick(shoeColor.hex);
                  }}
                  data-cy={shoeColor.name}
                />
                {shoeColor.name}
                {shoeColorSelected.includes(shoeColor.hex) && (
                  <>
                    <IconsComponent
                      icon='reg-close'
                      fill='#6B7280'
                      size='xs'
                    />
                  </>
                )}
              </Label>
            ))}
          </div>
          <ApplyButton
            showApplyButton={showApplyButton}
            disableAnimation={disableAnimation}
          />
        </Form>
      )}
    </FilterGroup>
  );
};
