// store all hardcoded string keys for the application not URl keys,
// for URL keys we have query.constants.ts

export enum AppKeyConstants {
  PASSWORD = 'password',
  ACCESS_TOKEN = 'access_token',
  LOGOUT = 'logout',
  CART = 'cart',
  CHECKOUT = 'checkout',
  PER_PAGE = 'perPage',
  PAGE_SIZE = 'pageSize',
}

export enum AddressType {
  BILLING = 'BILLING',
  SHIPPING = 'SHIPPING',
}

export enum MediaType {
  IMAGE = 'IMAGE',
  THUMBNAIL = 'THUMBNAIL',
}
