import React from 'react';

import { Button } from '@/components/ui/button/Button';
import { DataTable } from '@/components/ui/table/DataTable';
import { useTable } from '@/components/hooks/utils/useTable';
import { useProductVariantColumn } from './ProductStockColumns';
import IconsComponent from '@/components/ui/icons/IconsComponent';
import { ShoeSizeConversionTable } from './ShoeSizeConversionTable';
import { TSingleOrderDetailItemVariantSchema } from '@/schemas/api/orders/single-order-detail.schema';

interface ShoeSizeConversionInterface {
  setIsShoeSizeGuideActive: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ProductVariantsTableProps extends ShoeSizeConversionInterface {
  isShoeSizeGuideActive: boolean;
  singleProductDetailVariants: TSingleOrderDetailItemVariantSchema[];
}

const ShoeSizeConversion: React.FC<ShoeSizeConversionInterface> = ({
  setIsShoeSizeGuideActive,
}) => {
  return (
    <>
      <div className='pb-10'>
        <Button
          intent='tertiary'
          size='xs'
          variant='gray'
          className='mr-auto px-3 text-gray-800 hover:bg-gray-100'
          onClick={() => {
            setIsShoeSizeGuideActive(false);
          }}
        >
          <IconsComponent
            icon='reg-arrow-left'
            size='md'
          />
          Back to Variants
        </Button>
      </div>
      <ShoeSizeConversionTable />
    </>
  );
};

export const ProductVariantsTable: React.FC<ProductVariantsTableProps> = ({
  isShoeSizeGuideActive,
  setIsShoeSizeGuideActive,
  singleProductDetailVariants,
}) => {
  const { columns } = useProductVariantColumn();
  const { table } = useTable(columns, singleProductDetailVariants);

  return (
    <>
      {isShoeSizeGuideActive ? (
        <ShoeSizeConversion
          setIsShoeSizeGuideActive={setIsShoeSizeGuideActive}
        />
      ) : (
        <>
          <div className='flex items-center justify-between pb-1'>
            <p className='py-1.5 text-base font-semibold text-gray-800'>
              Variants
            </p>
            <Button
              intent='tertiary'
              size='xs'
              variant='gray'
              className='px-3 text-gray-800 hover:bg-gray-100'
              onClick={() => setIsShoeSizeGuideActive(!isShoeSizeGuideActive)}
            >
              <IconsComponent
                icon='reg-ruler'
                size='md'
              />
              Size (UK) Guide
            </Button>
          </div>
          <DataTable
            table={table}
            columns={columns}
          />
        </>
      )}
    </>
  );
};
