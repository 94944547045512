import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Status } from '@/schemas';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';
import { Button, Label, Modal, Radio } from '@/components';
import {
  useAlert,
  scrollToTop,
  useBulkUpdateUserStatus,
} from '@/components/hooks';
import { handleApiError } from '@/utils/helper';

/**
 * @description UserStatusModal component
 */
export function UserStatusModal({
  selectedRowId,
  isInvited,
  isInvitePending,
  handleResetRowSelection,
}: {
  isInvited: boolean;
  isInvitePending?: boolean;
  selectedRowId: string[];
  handleResetRowSelection: () => void;
}) {
  const [showUserStatusModal, setShowUserStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');

  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useBulkUpdateUserStatus();

  const handleUpdateUserStatus = () => {
    const bulkUpdateUserStatus = {
      users: selectedRowId,
      accountStatus: selectedStatus as Status,
    };
    mutate(bulkUpdateUserStatus, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [TANSTACK_QUERY_KEY.USER] });
        handleResetRowSelection();
        showAlert('Update user status successfully', 'success');
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {
        scrollToTop();
        setShowUserStatusModal(false);
      },
    });
  };

  const isButtonDisabled = () => {
    if (isInvitePending) return true;
    return isInvited ? false : true;
  };

  return (
    <Modal
      showModal={showUserStatusModal}
      setShowModal={setShowUserStatusModal}
    >
      <Modal.Toggle
        size='xs'
        variant='blue'
        intent='outline'
        className='px-3'
      >
        Change Status
      </Modal.Toggle>
      <Modal.Content>
        <Modal.Title>
          <h6 className=''>Change User Status</h6>
        </Modal.Title>
        <Modal.Body className='space-y-6'>
          <form>
            <fieldset className='space-y-4'>
              <legend className='text-base font-normal text-gray-800'>
                Change{' '}
                <span className='font-bold'>{selectedRowId.length} user</span>{' '}
                status to
              </legend>

              <div className='flex items-center space-x-2'>
                <Radio
                  name='status'
                  id='active'
                  value='ACTIVE'
                  onChange={(e) => setSelectedStatus(e.target.value)}
                />
                <Label
                  htmlFor='active'
                  className='text-gray-800'
                >
                  Active
                </Label>
              </div>
              <div className='flex items-center space-x-2'>
                <Radio
                  name='status'
                  id='inactive'
                  value='INACTIVE'
                  onChange={(e) => setSelectedStatus(e.target.value)}
                />
                <Label
                  htmlFor='inactive'
                  className='text-gray-800'
                >
                  Inactive
                </Label>
              </div>
            </fieldset>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Modal.CloseButton>Cancel</Modal.CloseButton>
          <Button
            disabled={isButtonDisabled() || isPending}
            onClick={handleUpdateUserStatus}
          >
            Change Status
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
