import { BaseResponseSchema } from '@/schemas/common.schema';
import { Role, Status } from '@/schemas/user';
import { z } from 'zod';

export const LoginRequestSchema = z.object({
  email: z.string().email(),
  password: z.string(),
  rememberMe: z.boolean().optional(),
});

export type TLoginRequestSchema = z.infer<typeof LoginRequestSchema>;

export const LoginResponseSchema = z.object({
  token: z.string(),
  role: z.string(),
  isTwoFactorSet: z.boolean().nullable().optional(),
  mfaType: z.string().nullable().optional(),
  hasUserDetail: z.boolean().nullable(),
  isTwoFactorVerified: z.boolean().optional().nullable(),
});

export const Generate2FARequestSchema = z.void();

export const Generate2FAResponseSchema = z.object({
  message: z.string(),
  secret: z.string(),
  totpUrl: z.string(),
});

export const VerifyCodeRequestSchema = z.object({
  token: z.string(),
});

export type TVerifyCodeRequestSchema = z.infer<typeof VerifyCodeRequestSchema>;

export const VerifyCodeResponseSchema = z.object({
  valid: z.boolean(),
  message: z.string(),
});

export const VerifyTokenRequestSchema = z.object({
  token: z.string(),
  id: z.string().optional(),
});

export const VerifyTokenResponseSchema = BaseResponseSchema.extend({
  name: z.string().optional(),
  email: z.string().optional(),
  token: z.string().optional(),
});

export const GetValidate2faRequestSchema = z.string();
export const GetValidate2faResponseSchema = BaseResponseSchema.extend({
  isValid: z.boolean().optional(),
});

export type TVerifyTokenRequestSchema = z.infer<
  typeof VerifyTokenRequestSchema
>;

export const GetLoggedInUserDetailsRequestSchema = z.void();

export const GetLoggedInUserDetailsResponseSchema = z.object({
  message: z.string(),
  data: z.object({
    id: z.string(),
    email: z.string().email(),
    firstName: z.string(),
    lastName: z.string(),
    designation: z.string(),
    phone: z.string().nullable(),
    countryCode: z.string().nullable(),
    avatar: z.string().nullable(),
    role: z.nativeEnum(Role),
    accountStatus: z.nativeEnum(Status),
    isInvited: z.boolean().optional(),
  }),
  timestamp: z.string(),
});

export const UpdatePersonalInfoRequesetSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  designation: z.string().optional(),
  phone: z.string(),
  countryCode: z.string(),
});

export const UpdatePersonalInfoResponseSchema = z.object({
  message: z.string(),
  data: z.object({
    id: z.string(),
    email: z.string().email(),
    firstName: z.string(),
    lastName: z.string(),
    designation: z.string(),
    phone: z.string().nullable(),
    countryCode: z.string().nullable(),
    role: z.nativeEnum(Role),
  }),
  timestamp: z.string(),
});

export type TUpdatePersonalInfo = z.infer<
  typeof UpdatePersonalInfoRequesetSchema
>;

export const VerifyUserInviteTokenRequestSchema = z.object({
  token: z.string(),
  id: z.string().optional(),
});

export type TVerifyUserInviteRequestSchema = z.infer<
  typeof VerifyUserInviteTokenRequestSchema
>;

export const VerifyUserInviteTokenResponseSchema = z.object({
  message: z.string(),
  data: z.object({
    message: z.string(),
    id: z.string(),
    token: z.string(),
    email: z.string().email(),
  }),
  timestamp: z.string(),
});
