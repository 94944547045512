import { useNavigate } from 'react-router-dom';

import shoeAsset from '@/assets/images/Image.png';

import { cn } from '@/utils/cn';
import { AddProductModal } from './AddProductModal';
import useSidebarStore from '@/store/sidebar.store';
import { Button } from '@/components/ui/button/Button';
import { ROUTES } from '@/utils/constants/routes.constant';
import { MediaType } from '@/utils/constants/app.key.constants';
import IconsComponent from '@/components/ui/icons/IconsComponent';
import { AsyncLoaderImage } from '@/components/ui/image/AsyncImage';
import useCompareProductsStore from '@/store/compare-products.store';
import { COLOUR_CONSTANTS } from '@/utils/constants/colour-constants';
import { useToggleState } from '@/components/hooks/utils/useToggleState';
import { useIsDeviceTabletOrLarger } from '@/components/hooks/utils/useMediaQuery';

const ProductList = () => {
  const {
    productsToShowInRow,
    compareProducts,
    setCompareProducts,
    setProductsToShow,
  } = useCompareProductsStore();

  const {
    state: showAddProductModal,
    setState: setShowAddProductModal,
    toggleState: toggleShowAddProductModal,
  } = useToggleState();

  const handleToggleShowAddProductModal = () => {
    toggleShowAddProductModal();
    setCompareProducts(productsToShowInRow());
  };

  return (
    <div className='flex h-full items-center justify-around overflow-x-auto'>
      {productsToShowInRow().map((product) => (
        <div
          key={product.id}
          className='grid place-content-center border-r border-gray-200 px-16 py-6'
        >
          <div className='relative'>
            <AsyncLoaderImage
              className='lg:2-36 aspect-square h-20 w-24 rounded-none md:h-28 md:w-28 lg:h-36'
              style={{ objectFit: 'contain' }}
              src={
                product.medias?.find(
                  (media) => media.type === MediaType.THUMBNAIL,
                )?.url ?? shoeAsset
              }
            />
            <p className='mt-2 text-sm font-medium text-gray-800'>
              {product.name}
            </p>
            <Button
              intent={'tertiary'}
              className='absolute right-0 top-0 p-0'
              onClick={() => {
                const filteredProducts = compareProducts.filter(
                  (compareProduct) => compareProduct.id !== product.id,
                );
                setCompareProducts(filteredProducts);
                setProductsToShow(filteredProducts.length);
              }}
            >
              <div className='grid h-5 w-5 place-content-center rounded-full bg-gray-200'>
                <span className='text-xs font-normal text-gray-800'>X</span>
              </div>
            </Button>
          </div>
        </div>
      ))}
      {Array.from({ length: 5 - productsToShowInRow().length }).map(
        (_, index) => (
          <div
            key={index}
            className={cn(
              'px-16 py-6',
              index !== 5 - productsToShowInRow().length - 1
                ? 'border-r border-gray-200'
                : '',
            )}
          >
            <Button
              intent={'tertiary'}
              className='grid place-content-center p-0'
              onClick={handleToggleShowAddProductModal}
            >
              <div className='grid place-content-center rounded-sm border border-dashed border-gray-300 p-11'>
                <IconsComponent icon='reg-add' />
              </div>
              <p className='mt-2 text-center text-sm font-medium text-gray-800'>
                Select a Product
              </p>
            </Button>
          </div>
        ),
      )}
      <AddProductModal
        setShowAddProductModal={setShowAddProductModal}
        showAddProductModal={showAddProductModal}
      />
    </div>
  );
};

const ModalContent = () => {
  const navigate = useNavigate();

  return (
    <div className='modal-content flex bg-gray-100'>
      <div className='grid grid-cols-12'>
        <div className='sticky col-span-3'>
          <div className='grid h-full place-content-center items-center bg-white px-12'>
            <p className='mb-1 text-2xl font-semibold text-gray-800'>
              Compare Products
            </p>
            <p className='mb-6 text-base font-normal text-gray-600'>
              Select more products to compare
            </p>
            <Button
              intent='outline'
              variant='primary'
              onClick={() =>
                navigate(`/${ROUTES.PRODUCT.COMPARISON}`, {
                  state: { compareProducts: true },
                })
              }
            >
              <IconsComponent
                icon='reg-compare'
                fill={COLOUR_CONSTANTS.PRIMARY}
              />
              Start Comparing
            </Button>
          </div>
        </div>
        <div className='col-span-9'>
          <ProductList />
        </div>
      </div>
    </div>
  );
};

export const CompareProducts = () => {
  const {
    compareProducts,
    showCompareProductsModal,
    toggleShowCompareProductsModal: toggleCompareProducts,
  } = useCompareProductsStore();
  const { isSidebarIconOnlyView } = useSidebarStore();
  const isTabletOrLarger: boolean = useIsDeviceTabletOrLarger();

  return (
    <div
      data-cy='compare-products-bottom-slide-modal'
      className={cn(
        'modal fixed bottom-0 right-0 z-20 border-t-4 border-t-gray-800 bg-white transition-all duration-300',
        showCompareProductsModal ? 'translate-y-0' : 'translate-y-[100%]',
        isSidebarIconOnlyView ? 'left-[96px]' : 'left-[288px]',
        !isTabletOrLarger && 'left-0',
      )}
    >
      <ModalContent />
      {showCompareProductsModal && (
        <Button
          className='absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800'
          intent='solid'
          variant='gray'
          size='xs'
          onClick={toggleCompareProducts}
        >
          <p className='flex items-center gap-4 text-white'>
            <span className='text-sm font-medium'>
              Compare Products ({compareProducts.length})
            </span>
            <IconsComponent
              icon='reg-chevron-down'
              fill='white'
            />
          </p>
        </Button>
      )}
    </div>
  );
};
