import { ROUTES } from '@/utils/constants';
import { TPersonalDetails } from '@/schemas';
import { Breadcrumb, BreadcrumbItem } from '@/components';

export function ActionBar({ firstName, lastName }: TPersonalDetails) {
  const userName = `${firstName} ${lastName}`;

  return (
    <div className='space-y-3 pb-8'>
      <Breadcrumb>
        <BreadcrumbItem href={`/${ROUTES.OVERVIEW}`}>Dashboard</BreadcrumbItem>
        <BreadcrumbItem href={`/${ROUTES.USER_AND_TEAMS.USER.LIST}`}>
          Users
        </BreadcrumbItem>
        <BreadcrumbItem>User Details</BreadcrumbItem>
      </Breadcrumb>
      <h4>{userName}</h4>
    </div>
  );
}
