import React, { useEffect, useState, useRef } from 'react';

import { cn } from '@/utils/cn';
import { useAlertStore } from '@/store';
import { AlertIcon } from '@/components/ui/alert/AlertIcon';

export type AlertType = 'success' | 'error' | 'info' | 'warning' | 'default';

const defaultBttnClass = 'py-4 px-6 border-l-4 w-full rounded';

const AlertTypeClasses: Record<AlertType, string> = {
  success: 'bg-green-50 border-l-green-600',
  error: 'bg-red-50 border-l-red-600',
  info: 'bg-blue-50 border-l-blue-600',
  warning: 'bg-yellow-50 border-l-yellow-600',
  default: 'bg-gray-50 border-l-gray-600',
};

const AlertButtonsClass: Record<AlertType, string> = {
  success: 'text-green-950',
  error: 'text-red-950',
  info: 'text-blue-950',
  warning: 'text-yellow-950',
  default: 'text-gray-950',
};

const AlertIconBackgroundColor: Record<AlertType, string> = {
  success: 'bg-green-200',
  error: 'bg-red-200',
  info: 'bg-blue-200',
  warning: 'bg-yellow-200',
  default: 'bg-gray-200',
};

export interface AlertProps {
  type: AlertType;
  title?: string;
  description?: React.ReactNode;
  children?: React.ReactNode;
  link?: string;
  openState?: boolean;
  classes?: string;
  alertIcon?: React.ReactNode;
  setOpenState?: React.Dispatch<React.SetStateAction<boolean>>;
  showDismissButtons?: boolean;
  showViewDetailsButtons?: boolean;
  autoClose?: boolean;
  showStatusIcon?: boolean;
}

const Alert: React.FC<AlertProps> = ({
  type = 'default',
  title,
  description,
  link,
  classes,
  children,
  alertIcon,
  showDismissButtons = false,
  showViewDetailsButtons = false,
  autoClose = true,
  showStatusIcon = true,
}: AlertProps) => {
  const hasAnimated = useRef(false);
  const { isAlertVisible, setIsAlertVisible, setIsAlertDismissed } =
    useAlertStore();

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (autoClose) {
      if (!hasAnimated.current) {
        const slideUpTimer = setTimeout(() => {
          setIsAlertVisible(true);
          hasAnimated.current = true;
        }, 7000);

        const hideTimer = setTimeout(() => {
          setIsAlertVisible(false);
          setTimeout(() => {
            setIsVisible(false);
          }, 2000);
        }, 5500);
        return () => {
          clearTimeout(slideUpTimer);
          clearTimeout(hideTimer);
        };
      }
    }
  }, [isAlertVisible, setIsAlertVisible, autoClose]);

  return (
    <>
      {isVisible && (
        <div
          className={` ${defaultBttnClass} ${AlertTypeClasses[type]} ${classes}`}
          data-cy={`alert-${AlertTypeClasses[type]}`}
        >
          <div className={`relative ${showStatusIcon && 'pl-14'}`}>
            {showStatusIcon && (
              <div
                className={cn(
                  'absolute left-0 top-0 grid h-9 w-9 place-content-center rounded-full',
                  AlertIconBackgroundColor[type],
                )}
              >
                {alertIcon ? alertIcon : AlertIcon(type)}
              </div>
            )}
            <div className='flex items-start justify-between gap-2'>
              <div className='content'>
                {title && (
                  <h3
                    className='title m-0 mb-1 mt-1 text-base font-semibold'
                    data-cy={`alert-${title}`}
                  >
                    {title}
                  </h3>
                )}
                {description && (
                  <div
                    className='description text-base font-normal'
                    data-cy='alert-description'
                  >
                    {description}
                  </div>
                )}
              </div>
              <div className='flex items-center gap-1.5'>
                {showViewDetailsButtons && (
                  <a
                    href={link}
                    className={cn(
                      'text-xs font-semibold',
                      AlertButtonsClass[type],
                    )}
                  >
                    View Details
                  </a>
                )}
                {showDismissButtons && (
                  <button
                    className={cn(
                      'text-xs font-semibold',
                      AlertButtonsClass[type],
                    )}
                    onClick={() => {
                      setIsVisible(false);
                      setIsAlertVisible(false);
                      setIsAlertDismissed(true);
                    }}
                  >
                    Dismiss
                  </button>
                )}
              </div>
            </div>
            {children && <div>{children}</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default Alert;
