import { useUserStore } from '@/store';

const companySettings = useUserStore.getState().systemDefault;

/**
 *
 * This function retrieves the company's default currency settings from store
 * and formats the provided price with the currency unit.
 *
 * @param price - The price to be formatted.
 * @returns A string representing the formatted price with the company's default currency.
 */
const formatCompanyPrice = (price: number) => {
  return `${companySettings?.currency ?? ''} ${price}`;
};

export { formatCompanyPrice };
