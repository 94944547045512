import {
  useGetPromotionDetail,
  useGetPromotionInfiniteProductList,
} from '@/components/hooks/api/marketing-resource/usePromotion';
import { Badge } from '@/components';
import { MediaType } from '@/utils/constants';
import { formatDate } from '@/utils/helper/datetime.helper';
import { ErrorFound } from '@/components/ui/no-result/Error';
import { formatCompanyPrice } from '@/utils/helper/country-locale';
import { AsyncLoaderImage } from '@/components/ui/image/AsyncImage';
import { DiscountLabel } from '@/utils/constants/promotion.constant';
import { PromotionQuickViewSkeleton } from '@/components/ui/skeleton/PromotionQuickViewSkeleton';
import { PromotionCardList } from '@/pages/marketing-resources/flash-promotions/quick-view/ProductList';

export function Banner({
  isPromotionActive,
  promotionId,
}: {
  promotionId: string;
  isPromotionActive: boolean;
}) {
  const {
    data: promotionDetail,
    isLoading: isPromotionDetailLoading,
    isError: isPromotionDetailError,
    isSuccess: isPromotionDetailSuccess,
  } = useGetPromotionDetail(promotionId);

  useGetPromotionInfiniteProductList(promotionId);

  if (isPromotionDetailLoading) return <PromotionQuickViewSkeleton />;

  if (isPromotionDetailError || !isPromotionDetailSuccess)
    return <ErrorFound />;

  const promotion = promotionDetail.data;

  const bannerImage = promotion.medias.find(
    (item) => item.type === MediaType.IMAGE,
  );

  return (
    <>
      <figure className='h-[300px]'>
        <AsyncLoaderImage
          className='h-full w-full rounded-none rounded-t object-cover'
          src={bannerImage?.url}
          alt={bannerImage?.altText ?? ''}
        />
      </figure>
      <div className='max-h-[472px] overflow-y-auto px-10 py-6'>
        {isPromotionActive ? (
          <Badge
            variant='green'
            className='mb-1'
          >
            {promotion.discountLabel === DiscountLabel.FIXED
              ? `Sale ${formatCompanyPrice(Math.round(Number(promotion.discount)))} off`
              : `Sale up to ${promotion.discount}% off`}
          </Badge>
        ) : (
          <Badge
            variant='red'
            className='mb-1'
          >
            Expired
          </Badge>
        )}
        <div className='items-center justify-between pb-6 pt-2 sm:flex'>
          <p className='text-2xl font-bold text-gray-800'>{promotion.name}</p>
          <p className='text-base font-medium text-gray-800'>
            <span className='font-bold text-gray-600'>
              {isPromotionActive ? 'Expires' : 'Expired'} in:{' '}
            </span>
            {formatDate(promotion.endDate)}
          </p>
        </div>
        <p className='pb-2 text-xl font-semibold text-gray-800'>Description</p>
        <p className='text-sm text-gray-600'>{promotion.description}</p>
        <PromotionCardList promotionId={promotionId} />
      </div>
    </>
  );
}
