import { useSubmit } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import {
  HttpMethods,
  PRODUCT_FILTERS,
  QUERY_CONSTANTS,
} from '@/utils/constants';
import ProductList from './ProductList';
import { ProductType } from '@/interface';
import { useProductFilterStore } from '@/store';
import FilterSidebar from './sidebar/FilterSidebar';
import { useGetAllProducts, useQueryParams } from '@/components/hooks';

const FilterPage = ({
  currentCategoryName,
  isProductListLoading,
  productType,
}: {
  currentCategoryName: string;
  isProductListLoading: boolean;
  productType?: ProductType;
}) => {
  const submit = useSubmit();
  const { queryParams, updateQueryParams } = useQueryParams();

  const {
    shoePriceRange,
    shoeSizeSelected,
    shoeColorSelected,
    shoeTechnologySelected,
    shoeIndustryForSelected,
    shoeAvailableStockSelected,
    resetAllProductFilters,
  } = useProductFilterStore();

  useGetAllProducts();

  const previousQueryParamsCountRef = useRef(queryParams.size);
  const [animationClass, setAnimationClass] = useState('');

  const handleSubmit = () => {
    updateQueryParams(
      QUERY_CONSTANTS.PRICE_RANGE.FROM,
      String(shoePriceRange.inputFrom),
    );

    updateQueryParams(
      QUERY_CONSTANTS.PRICE_RANGE.TO,
      String(shoePriceRange.inputTo),
    );

    const filterMappings = [
      { key: PRODUCT_FILTERS.SHOE_SIZE, values: shoeSizeSelected },
      { key: PRODUCT_FILTERS.SHOE_COLOR, values: shoeColorSelected },
      {
        key: PRODUCT_FILTERS.SHOE_AVAILABLE_STOCKS,
        values: shoeAvailableStockSelected,
      },
      {
        key: PRODUCT_FILTERS.SHOE_INDUSTRY_FOR,
        values: shoeIndustryForSelected,
      },
      {
        key: PRODUCT_FILTERS.SHOE_TECHNOLOGY,
        values: shoeTechnologySelected,
      },
    ];

    filterMappings.forEach(({ key }) => queryParams.delete(key));

    filterMappings.forEach(({ key, values }) => {
      values.forEach((value) => queryParams.append(key, String(value)));
    });

    const currentQueryParamsCount = queryParams.size;
    const previousQueryParamsCount = previousQueryParamsCountRef.current;

    if (currentQueryParamsCount < previousQueryParamsCount) {
      setAnimationClass('animate-fade-up');
    }

    previousQueryParamsCountRef.current = currentQueryParamsCount;

    submit(queryParams, {
      method: HttpMethods.GET,
      action: location.pathname,
    });
  };

  useEffect(() => {
    return () => {
      resetAllProductFilters();
    };
  }, []);

  return (
    <div className='mt-0 max-tablet:mt-[-44px]'>
      <div className='grid gap-6 lg:grid-cols-12 xl:grid-cols-12 2xl:grid-cols-12 3xl:grid-cols-12'>
        <div className='col-span-1 lg:col-span-4 xl:col-span-3 2xl:col-span-3 3xl:col-span-3'>
          <FilterSidebar
            animationClass={animationClass}
            handleSubmit={handleSubmit}
          />
        </div>
        <div className='col-span-1 lg:col-span-8 xl:col-span-9 2xl:col-span-9 3xl:col-span-9'>
          <ProductList
            currentCategoryName={currentCategoryName}
            isProductListLoading={isProductListLoading}
            productType={productType}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterPage;
