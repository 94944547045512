import React from 'react';
import ReactDOM from 'react-dom/client';

import '@/index.css';
import 'react-loading-skeleton/dist/skeleton.css';

import App from './App';
import { ReactQueryWrapper } from '@/components/lib';

const rootElement = document.getElementById('root');

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <ReactQueryWrapper>
        <App />
      </ReactQueryWrapper>
    </React.StrictMode>,
  );
}
