import { Skeleton } from '@/components/lib/Skeleton';

export function AnalyticCardSkeletion() {
  return (
    <div className='grid gap-4 sm:grid-cols-2 md:grid-cols-3'>
      {...Array.from({ length: 2 }).map((_, index) => (
        <div key={index}>
          <Skeleton className='h-[120px]' />
        </div>
      ))}
    </div>
  );
}
