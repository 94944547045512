import { Skeleton } from '@/components/lib/Skeleton';
import { ProductCardSkeletons } from './ProductCardSkeletons';

export function PromotionQuickViewSkeleton() {
  return (
    <div className='w-full space-y-6'>
      <Skeleton className='aspect-[3/1] w-full rounded-lg' />

      <div className='max-h-[472px] space-y-6 overflow-y-auto p-4'>
        <div className='flex items-center justify-between'>
          <Skeleton className='h-6 w-24' />
          <Skeleton className='h-6 w-40' />
        </div>
        <div className='space-y-4'>
          <Skeleton className='h-4 w-[90%]' />
          <Skeleton className='h-4 w-[85%]' />
        </div>
        <div className='grid gap-4 sm:grid-cols-2 lg:grid-cols-3'>
          <ProductCardSkeletons length={3} />
        </div>
      </div>
    </div>
  );
}
