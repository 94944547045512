import { z } from 'zod';

const SingleColourSchema = z.object({
  hex: z.string(),
  name: z.string(),
});

const ColoursSchema = z.array(SingleColourSchema);

const SingleIndustryOrTechnologySchema = z.object({
  title: z.string(),
});

const IndustriesOrTechnologiesSchema = z.array(
  SingleIndustryOrTechnologySchema,
);

const ProductStockSchema = z.object({
  min: z.number().nullable(),
  max: z.number().nullable(),
});

const ProductPriceSchema = z.object({
  min: z.union([z.number(), z.string()]).nullable(),
  max: z.union([z.number(), z.string()]).nullable(),
});

const ProductSizeSchema = z.array(
  z.object({
    size: z.string(),
  }),
);

export const ProductFilterSchema = z.object({
  colours: ColoursSchema,
  industries: IndustriesOrTechnologiesSchema,
  price: ProductPriceSchema,
  sizes: ProductSizeSchema,
  stock: ProductStockSchema,
  technologies: IndustriesOrTechnologiesSchema,
});
