import React from 'react';
import { IconProps } from '../iconProps';

export const IconMarkettingResources: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.75 6.74996H15.0187C14.7459 6.73402 9.99188 6.39933 5.46469 2.60246C5.24607 2.41885 4.97959 2.30145 4.69656 2.26403C4.41353 2.22662 4.12571 2.27076 3.86689 2.39126C3.60807 2.51176 3.38902 2.70361 3.23546 2.94429C3.0819 3.18497 3.00022 3.46447 3 3.74996V18.75C3.00004 19.0355 3.0816 19.3152 3.23508 19.556C3.38857 19.7968 3.60761 19.9888 3.86646 20.1094C4.12532 20.23 4.41322 20.2742 4.69633 20.2369C4.97945 20.1995 5.24601 20.0821 5.46469 19.8984C9.00563 16.9284 12.6834 16.0771 14.25 15.839V18.8128C14.2497 19.0599 14.3105 19.3034 14.4269 19.5214C14.5434 19.7394 14.7119 19.9253 14.9175 20.0625L15.9487 20.7496C16.1481 20.8827 16.3764 20.966 16.6145 20.9927C16.8527 21.0194 17.0938 20.9886 17.3176 20.9029C17.5414 20.8173 17.7414 20.6793 17.901 20.5004C18.0605 20.3215 18.1748 20.1071 18.2344 19.875L19.3378 15.7162C20.4785 15.5761 21.5224 15.0048 22.2553 14.1196C22.9882 13.2343 23.3546 12.1022 23.2795 10.9554C23.2043 9.8086 22.6932 8.73402 21.8509 7.95205C21.0087 7.17007 19.8992 6.73997 18.75 6.74996ZM16.7812 19.4896V19.5L15.75 18.8128V15.75H17.775L16.7812 19.4896ZM18.75 14.25H15.75V8.24996H18.75C19.5456 8.24996 20.3087 8.56603 20.8713 9.12864C21.4339 9.69125 21.75 10.4543 21.75 11.25C21.75 12.0456 21.4339 12.8087 20.8713 13.3713C20.3087 13.9339 19.5456 14.25 18.75 14.25Z'
        fill={fill}
      />
    </svg>
  );
};
