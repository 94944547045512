import { useGenerate2FA } from '@/components/hooks';
import { TwoFactorAuthEnable } from '../twofa-enable';
import { LoadingContent } from '@/components/ui/loading/Loading';

export function Generate2FAPage() {
  const { data: secret2FAData, isLoading, isError } = useGenerate2FA();

  if (isLoading) return <LoadingContent />;
  if (isError) return <h6>Error on generating QR</h6>;

  return (
    <TwoFactorAuthEnable
      secret={secret2FAData?.secret ?? ''}
      totpUrl={secret2FAData?.totpUrl ?? ''}
    />
  );
}
