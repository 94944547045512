import React from 'react';
import { IconProps } from '../iconProps';

export const IconSidebarCollapsed: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='24'
        height='24'
        fill='white'
      />
      <path
        d='M3 6.75L17 6.75C17.4142 6.75 17.75 6.41421 17.75 6C17.75 5.58579 17.4142 5.25 17 5.25H3C2.58579 5.25 2.25 5.58579 2.25 6C2.25 6.41421 2.58579 6.75 3 6.75Z'
        fill={fill}
      />
      <path
        d='M3 11.25C2.58579 11.25 2.25 11.5858 2.25 12C2.25 12.4142 2.58579 12.75 3 12.75H12C12.4142 12.75 12.75 12.4142 12.75 12C12.75 11.5858 12.4142 11.25 12 11.25H3Z'
        fill={fill}
      />
      <path
        d='M17 18.75L3 18.75C2.58579 18.75 2.25 18.4142 2.25 18C2.25 17.5858 2.58579 17.25 3 17.25L17 17.25C17.4142 17.25 17.75 17.5858 17.75 18C17.75 18.4142 17.4142 18.75 17 18.75Z'
        fill={fill}
      />
      <path
        d='M21.5303 7.53033C21.8232 7.23744 21.8232 6.76256 21.5303 6.46967C21.2374 6.17678 20.7626 6.17678 20.4697 6.46967L15.4697 11.4697C15.1768 11.7626 15.1768 12.2374 15.4697 12.5303L20.4697 17.5303C20.7626 17.8232 21.2374 17.8232 21.5303 17.5303C21.8232 17.2374 21.8232 16.7626 21.5303 16.4697L17.0607 12L21.5303 7.53033Z'
        fill={fill}
      />
    </svg>
  );
};
