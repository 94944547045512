export const PHONE_NUMBER_REGEX = /^\+?[1-9]\d{1,}$/;

export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,15}$/;

export const PASSWORD_CRITERIEA_REGEX = {
  length: /^(?=.{8,15}$)/,
  uppercase: /^(?=.*[A-Z])/,
  number: /^(?=.*\d)/,
  special: /^(?=.*[!@#$%^&*])/,
};
