import { ROUTES } from '@/utils/constants';
import { Breadcrumb, BreadcrumbItem } from '@/components';

export const ActionBar = ({ promotionName }: { promotionName: string }) => {
  return (
    <div className='flex flex-col gap-3 pb-8 transition-all ease-out'>
      <Breadcrumb>
        <BreadcrumbItem
          href={`/${ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS}`}
        >
          Marketing Resources
        </BreadcrumbItem>
        <BreadcrumbItem
          href={`/${ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS}`}
        >
          Flash Promotions
        </BreadcrumbItem>
        <BreadcrumbItem>{promotionName}</BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};
