import LineChart from '@/components/ui/line-chart/LineChart';
import { ChartItem } from '@/pages/overview';

export function OrderCard({
  title,
  amount,
  description,
  children,
  dataChart,
}: {
  title: string;
  amount: number | string;
  description: string;
  children?: React.ReactNode;
  dataChart?: ChartItem[];
}) {
  return (
    <div className={`${dataChart && 'flex gap-4'} rounded bg-white px-4 py-3`}>
      <div>
        <p className='text-xs font-semibold uppercase text-gray-400'>{title}</p>
        <p className='text-xl font-extrabold text-gray-800 sm:text-3xl'>
          {amount}
        </p>
        <div className='flex items-center space-x-1 pt-5'>
          {children}
          <p className='text-sm text-gray-500'>{description}</p>
        </div>
      </div>
      {dataChart && (
        <div className='flex-shrink-0'>
          <LineChart
            bars={1}
            showShadow={true}
            items={dataChart}
            showScales={false}
            color='blue'
          />
        </div>
      )}
    </div>
  );
}
