import { useCallback } from 'react';

export const useScrollToTop = () => {
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return scrollToTop;
};

export const scrollToTop = (smooth = true) => {
  window.scrollTo({
    top: 0,
    behavior: smooth ? 'smooth' : 'auto',
  });
};

export function scrollToElement(
  elementId: string,
  offset = 750,
  behavior: ScrollBehavior = 'smooth',
): void {
  const element = document.getElementById(elementId);

  if (!element) {
    return;
  }

  const rect = element.getBoundingClientRect();
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const targetPosition = rect.top + scrollTop - offset;

  window.scrollTo({
    top: Math.max(0, targetPosition),
    behavior,
  });
}
