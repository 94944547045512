export function DropDownLoadingSkeleton({ count = 2 }: { count?: number }) {
  return (
    <div className='space-y-2 p-2'>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className='flex h-8 w-full animate-pulse items-center gap-x-2 rounded bg-gray-200'
        />
      ))}
    </div>
  );
}
