import { useSearchParams } from 'react-router-dom';

export const useQueryParams = () => {
  const [searchParams] = useSearchParams();

  const queryParams = new URLSearchParams(searchParams);

  const updateQueryParams = (key: string, value: string) => {
    queryParams.delete(key);
    queryParams.append(key, value);
  };

  return { queryParams, updateQueryParams };
};
