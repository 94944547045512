import { z } from 'zod';

import { passwordSchema } from '../common.schema';

export const ForgotPasswordFormSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Must be a valid email' }),
});

export type TForgotPasswordFormSchema = z.infer<
  typeof ForgotPasswordFormSchema
>;

export const ForgotPasswordFormVerifyCodeFormSchema = z.object({
  mfaToken: z.string(),
  verificationCode: z
    .string()
    .min(1, { message: 'Verification Code is required' }),
});

export type TForgotPasswordFormVerifyCodeFormSchema = z.infer<
  typeof ForgotPasswordFormVerifyCodeFormSchema
>;

export const ForgotPasswordFormVerifyCodeRequestSchema =
  ForgotPasswordFormVerifyCodeFormSchema.extend({
    mfaToken: z.string(),
  });

export const CreateNewPasswordFormSchema = z
  .object({
    password: passwordSchema,
    confirmPassword: passwordSchema,
  })
  .superRefine((arg, ctx) => {
    if (arg.confirmPassword !== arg.password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Password did not match, try again',
        path: ['confirmPassword'],
      });
    }
  });
