import { useContext } from 'react';

import { useTable } from '@/components/hooks';
import { SimpleTable } from '@/components/ui/table/NormalTable';
import { CreateUserTableContext } from '@/pages/users-and-teams/users/modals';
import { useCreateUserListColumn } from '@/pages/users-and-teams/users/create-user/CreateUserColumn';

/**
 * @description CreateUserTable component
 */
export function CreateUserTable() {
  const { createUserList } = useContext(CreateUserTableContext);

  const { columns } = useCreateUserListColumn();

  const { table } = useTable(columns, createUserList);

  const rowCount = table.getRowCount();

  if (rowCount <= 0)
    return (
      <div className='flex items-center justify-center py-36'>
        <p className='text-gray-400'>
          Once you&apos;ve added a user, they&apos;ll appear in this space.
        </p>
      </div>
    );

  return (
    <>
      <p className='text-lg font-semibold text-gray-800'>Users ({rowCount})</p>
      <SimpleTable
        table={table}
        columns={columns}
      />
    </>
  );
}
