import React from 'react';
import { IconProps } from '../iconProps';

export const IconRuler: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.0612 6.87842L17.1215 1.93967C16.9823 1.80035 16.8169 1.68983 16.6349 1.61443C16.4529 1.53902 16.2578 1.50021 16.0608 1.50021C15.8637 1.50021 15.6687 1.53902 15.4867 1.61443C15.3046 1.68983 15.1393 1.80035 15 1.93967L1.93873 15C1.7994 15.1393 1.68888 15.3047 1.61348 15.4867C1.53808 15.6687 1.49927 15.8638 1.49927 16.0608C1.49927 16.2578 1.53808 16.4529 1.61348 16.6349C1.68888 16.8169 1.7994 16.9823 1.93873 17.1216L6.87842 22.0603C7.01771 22.1996 7.18308 22.3101 7.36509 22.3855C7.5471 22.461 7.74219 22.4998 7.9392 22.4998C8.13621 22.4998 8.33129 22.461 8.5133 22.3855C8.69531 22.3101 8.86069 22.1996 8.99998 22.0603L22.0612 8.99999C22.2006 8.86069 22.3111 8.69532 22.3865 8.51331C22.4619 8.3313 22.5007 8.13622 22.5007 7.93921C22.5007 7.74219 22.4619 7.54711 22.3865 7.3651C22.3111 7.18309 22.2006 7.01772 22.0612 6.87842ZM7.93873 21L2.99998 16.0603L5.99998 13.0603L8.46935 15.5306C8.53904 15.6003 8.62176 15.6556 8.71281 15.6933C8.80385 15.731 8.90143 15.7504 8.99998 15.7504C9.09853 15.7504 9.19611 15.731 9.28715 15.6933C9.3782 15.6556 9.46092 15.6003 9.5306 15.5306C9.60029 15.4609 9.65556 15.3782 9.69327 15.2872C9.73099 15.1961 9.7504 15.0985 9.7504 15C9.7504 14.9014 9.73099 14.8039 9.69327 14.7128C9.65556 14.6218 9.60029 14.539 9.5306 14.4694L7.06029 12L8.99998 10.0603L11.4694 12.5306C11.6101 12.6713 11.801 12.7504 12 12.7504C12.199 12.7504 12.3899 12.6713 12.5306 12.5306C12.6713 12.3899 12.7504 12.199 12.7504 12C12.7504 11.801 12.6713 11.6101 12.5306 11.4694L10.0603 8.99999L12 7.0603L14.4694 9.53061C14.539 9.6003 14.6218 9.65557 14.7128 9.69328C14.8039 9.73099 14.9014 9.7504 15 9.7504C15.0985 9.7504 15.1961 9.73099 15.2872 9.69328C15.3782 9.65557 15.4609 9.6003 15.5306 9.53061C15.6003 9.46093 15.6556 9.3782 15.6933 9.28716C15.731 9.19611 15.7504 9.09853 15.7504 8.99999C15.7504 8.90144 15.731 8.80386 15.6933 8.71281C15.6556 8.62177 15.6003 8.53904 15.5306 8.46936L13.0603 5.99999L16.0603 2.99999L21 7.93967L7.93873 21Z'
        fill={fill}
      />
    </svg>
  );
};
