import { create } from 'zustand';
import { TSingleProductDetailResponseSchema } from '@/schemas/api/products/detail/singleProductDetail.schema';

interface CompareProductsState {
  showCompareProductsModal: boolean;
  compareProducts: TSingleProductDetailResponseSchema[];
  productsToShow: number;
}

interface CompareProductsActions {
  setCompareProducts: (products: TSingleProductDetailResponseSchema[]) => void;
  clearCompareProducts: () => void;
  setProductsToShow: (productsToShow: number) => void;
  productsToShowInRow: () => TSingleProductDetailResponseSchema[];
  setShowCompareProductsModal: (showCompareProductsModal: boolean) => void;
  toggleShowCompareProductsModal: () => void;
}

type TCompareProductsStore = CompareProductsState & CompareProductsActions;

const initialCompareProductsState: CompareProductsState = {
  compareProducts: [],
  productsToShow: 0,
  showCompareProductsModal: false,
};

const useCompareProductsStore = create<TCompareProductsStore>((set, get) => ({
  ...initialCompareProductsState,
  setCompareProducts: (products) => set({ compareProducts: products }),
  setProductsToShow: (productsToShow) => set({ productsToShow }),
  clearCompareProducts: () => set(initialCompareProductsState),
  productsToShowInRow: () => {
    const { compareProducts, productsToShow } = get();
    return compareProducts.slice(0, productsToShow);
  },
  setShowCompareProductsModal: (showCompareProductsModal) =>
    set({ showCompareProductsModal }),
  toggleShowCompareProductsModal: () => {
    const { showCompareProductsModal } = get();
    set({ showCompareProductsModal: !showCompareProductsModal });
  },
}));

export default useCompareProductsStore;
