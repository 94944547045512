export const STOCK_DIVISONS = [
  { options: 'Show All', threshold: 0 },
  { options: 'Under 100', threshold: 100 },
  { options: '200+', threshold: 200 },
  { options: '500+', threshold: 500 },
  { options: 'Over 1000 ', threshold: 1000 },
];

export enum PRODUCTTYPE {
  PRODUCT = 'PRODUCT',
  FLASH_PROMOTION = 'FLASH_PROMOTION',
}

export enum PRICETYPE {
  RRP = 'RRP',
  LIST = 'LIST',
  SPECIAL = 'SPECIAL',
  PROMO = 'PROMO',
}
