import { Link as ReactRouterLink } from 'react-router-dom';

import { cn } from '@/utils/cn';
import { ButtonProps, buttonVariants } from '@/components';

interface LinkProps
  extends React.ComponentProps<typeof ReactRouterLink>,
    Pick<ButtonProps, 'intent' | 'variant' | 'size'> {}

/**
 * @description Wrapper Link component to use react-router-dom Link
 */
export const Link = ({
  className,
  size,
  intent,
  variant,
  ...props
}: LinkProps) => (
  <ReactRouterLink
    className={cn(buttonVariants({ intent, variant, size }), className)}
    data-cy={`${variant} link`}
    {...props}
  />
);
