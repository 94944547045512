import { z } from 'zod';

import {
  BaseResponseSchema,
  LoginRequestSchema,
  LoginResponseSchema,
  VerifyCodeRequestSchema,
  VerifyCodeResponseSchema,
  RegisterCompanyRequestSchema,
  RegisterCompanyResponseSchema,
  TCompanyRegistrationResponsSchema,
  TCompanyRegistrationSchema,
  Generate2FARequestSchema,
  Generate2FAResponseSchema,
  TVerifyTokenRequestSchema,
  VerifyTokenRequestSchema,
  VerifyTokenResponseSchema,
  TCompanyRegistrationRequestSchema,
  GetLoggedInUserDetailsRequestSchema,
  GetLoggedInUserDetailsResponseSchema,
  UpdatePersonalInfoRequesetSchema,
  UpdatePersonalInfoResponseSchema,
  GetValidate2faRequestSchema,
  GetValidate2faResponseSchema,
} from '@/schemas';
import { api } from '@/utils/helper';
import { API_ENDPOINT, AUTHENTICATION, HttpMethods } from '@/utils/constants';

const login = api<
  z.infer<typeof LoginRequestSchema>,
  z.infer<typeof LoginResponseSchema>
>({
  type: 'public',
  path: AUTHENTICATION.LOGIN,
  method: HttpMethods.POST,
  requestSchema: LoginRequestSchema,
  responseSchema: LoginResponseSchema,
});

const updatePersonalDetails = api<
  z.infer<typeof UpdatePersonalInfoRequesetSchema>,
  z.infer<typeof UpdatePersonalInfoResponseSchema>
>({
  path: API_ENDPOINT.USERS.UPDATE_PROFILE,
  method: 'PATCH',
  requestSchema: UpdatePersonalInfoRequesetSchema,
  responseSchema: UpdatePersonalInfoResponseSchema,
});

const generate2FA = api<
  z.infer<typeof Generate2FARequestSchema>,
  z.infer<typeof Generate2FAResponseSchema>
>({
  path: API_ENDPOINT.AUTHENTICATION.GENERATE_2FA,
  method: 'GET',
  requestSchema: Generate2FARequestSchema,
  responseSchema: Generate2FAResponseSchema,
});

const verifyCode = api<
  z.infer<typeof VerifyCodeRequestSchema>,
  z.infer<typeof VerifyCodeResponseSchema>
>({
  path: AUTHENTICATION.VERIFY_OTP,
  method: HttpMethods.POST,
  requestSchema: VerifyCodeRequestSchema,
  responseSchema: VerifyCodeResponseSchema,
});

const getLoggedInUserDetails = api<
  z.infer<typeof GetLoggedInUserDetailsRequestSchema>,
  z.infer<typeof GetLoggedInUserDetailsResponseSchema>
>({
  path: API_ENDPOINT.USERS.GET_LOGGED_IN_PROFILE,
  method: HttpMethods.GET,
  requestSchema: GetLoggedInUserDetailsRequestSchema,
  responseSchema: GetLoggedInUserDetailsResponseSchema,
});

const LogoutRequestSchema = z.void();

const logout = api<
  z.infer<typeof LogoutRequestSchema>,
  z.infer<typeof BaseResponseSchema>
>({
  path: AUTHENTICATION.LOGOUT,
  method: HttpMethods.POST,
  requestSchema: LogoutRequestSchema,
  responseSchema: BaseResponseSchema,
});

const register = api<
  z.infer<typeof RegisterCompanyRequestSchema>,
  z.infer<typeof RegisterCompanyResponseSchema>
>({
  type: 'public', // Adjust based on your API setup
  path: AUTHENTICATION.REGISTER.REGISTER,
  method: HttpMethods.POST,
  requestSchema: RegisterCompanyRequestSchema,
  responseSchema: RegisterCompanyResponseSchema,
});

const completeRegistration = (
  companyId: string | undefined,
  payload: TCompanyRegistrationRequestSchema,
) => {
  return api<
    z.infer<typeof TCompanyRegistrationSchema>,
    z.infer<typeof TCompanyRegistrationResponsSchema>
  >({
    path: `companies/${companyId}/${AUTHENTICATION.REGISTER.COMPANY}`, // companyId in URL
    type: 'public',
    method: HttpMethods.PATCH, // PUT method for sending token in payload
    requestSchema: TCompanyRegistrationSchema,
    responseSchema: TCompanyRegistrationResponsSchema,
  })(payload);
};

/**
 * @description For registration verify code
 */
const verifyToken = (companyId: string, payload: TVerifyTokenRequestSchema) => {
  return api<
    z.infer<typeof VerifyTokenRequestSchema>,
    z.infer<typeof VerifyTokenResponseSchema>
  >({
    path: `companies/${companyId}/${AUTHENTICATION.VERIfY_TOKEN}`, // companyId in URL
    type: 'public',
    method: HttpMethods.POST, // POST method for sending token in payload
    requestSchema: VerifyTokenRequestSchema,
    responseSchema: VerifyTokenResponseSchema,
  })(payload); // Send token as request payload
};

const validate2faReset = (userId: string) =>
  api({
    method: HttpMethods.GET,
    path: AUTHENTICATION.VALIDATE_2FA_RESET,
    requestSchema: GetValidate2faRequestSchema,
    responseSchema: GetValidate2faResponseSchema,
  })('', userId); // Pass undefined for the request payload

export const AuthAPI = {
  login,
  logout,
  register,
  verifyCode,
  generate2FA,
  validate2faReset,
  verifyToken,
  updatePersonalDetails,
  completeRegistration,
  getLoggedInUserDetails,
};
