import { useState } from 'react';
import { useLocation, useSearchParams, useSubmit } from 'react-router-dom';

import {
  DateRangeWithSuggestion,
  Dropdown,
  FilterBadge,
  SearchInputForm,
  SlideInPanel,
} from '@/components';
import { QUERY_CONSTANTS } from '@/utils/constants';
import { DateRange } from 'react-day-picker';
import { formatDateToYearMonthDay } from '@/utils/helper/datetime.helper';

function DateFilter() {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const submit = useSubmit();

  const from = searchParams.get(QUERY_CONSTANTS.ORDER_PLACED_DATE.START_DATE);

  const to = searchParams.get(QUERY_CONSTANTS.ORDER_PLACED_DATE.END_DATE);

  const defaultSelectedDateRange: DateRange | undefined =
    from && to
      ? {
          from: new Date(from),
          to: new Date(to),
        }
      : undefined;

  const handleApply = (dateRange: DateRange | undefined) => {
    searchParams.delete(QUERY_CONSTANTS.ORDER_PLACED_DATE.START_DATE);
    searchParams.delete(QUERY_CONSTANTS.ORDER_PLACED_DATE.END_DATE);

    searchParams.append(
      QUERY_CONSTANTS.ORDER_PLACED_DATE.START_DATE,
      dateRange?.from ? formatDateToYearMonthDay(dateRange.from) : '',
    );

    searchParams.append(
      QUERY_CONSTANTS.ORDER_PLACED_DATE.END_DATE,
      dateRange?.to ? formatDateToYearMonthDay(dateRange.to) : '',
    );

    setSearchParams(searchParams);

    submit(searchParams, { action: location.pathname, method: 'GET' });

    setIsDropDownVisible(false);
  };

  return (
    <Dropdown
      isVisbile={isDropDownVisible}
      setIsVisible={setIsDropDownVisible}
    >
      <Dropdown.Toggle
        size='xs'
        showIcon
        variant='gray'
        className='px-3'
        intent='outline'
        iconFillColor='#4b5563'
      >
        Order Placed Date
      </Dropdown.Toggle>
      <Dropdown.Content
        position='right'
        className='pr-2 pt-5'
      >
        <DateRangeWithSuggestion
          handleApply={handleApply}
          defaultSelectedDateRange={defaultSelectedDateRange}
        />
      </Dropdown.Content>
    </Dropdown>
  );
}

export function FilterForm() {
  return (
    <>
      <div className='flex items-center justify-end py-3'>
        <div
          id='action-button'
          className='mr-auto'
        />
        <div className='z-10 flex items-center space-x-2'>
          <SearchInputForm />
          <SlideInPanel direction='right'>
            <DateFilter />
          </SlideInPanel>
        </div>
      </div>
      <FilterBadge />
    </>
  );
}
