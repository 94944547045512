export const IconMasterCard = () => {
  return (
    <svg
      width='41'
      height='28'
      viewBox='0 0 41 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.666016'
        y='0.5'
        width='39.8333'
        height='27'
        rx='3.5'
        fill='white'
        stroke='#D9D9D9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.875 20.0161C19.4848 21.2158 17.6816 21.94 15.7111 21.94C11.3143 21.94 7.75 18.3343 7.75 13.8865C7.75 9.43867 11.3143 5.83301 15.7111 5.83301C17.6816 5.83301 19.4848 6.55722 20.875 7.75688C22.2652 6.55722 24.0685 5.83301 26.0389 5.83301C30.4357 5.83301 34 9.43867 34 13.8865C34 18.3343 30.4357 21.94 26.0389 21.94C24.0685 21.94 22.2652 21.2158 20.875 20.0161Z'
        fill='#ED0006'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.875 20.0161C22.5867 18.5389 23.6721 16.3409 23.6721 13.8865C23.6721 11.432 22.5867 9.23404 20.875 7.75688C22.2652 6.55722 24.0684 5.83301 26.0389 5.83301C30.4357 5.83301 34 9.43867 34 13.8865C34 18.3343 30.4357 21.94 26.0389 21.94C24.0684 21.94 22.2652 21.2158 20.875 20.0161Z'
        fill='#F9A000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.8752 7.75684C22.5869 9.23399 23.6723 11.432 23.6723 13.8864C23.6723 16.3409 22.5869 18.5388 20.8752 20.016C19.1635 18.5388 18.0781 16.3409 18.0781 13.8864C18.0781 11.432 19.1635 9.23399 20.8752 7.75684Z'
        fill='#FF5E00'
      />
    </svg>
  );
};
