import React, { FormEvent, useState } from 'react';
import { Form, useLocation, useSubmit } from 'react-router-dom';

import { cn } from '@/utils/cn';
import { debounce, isEmpty } from '@/utils/helper';
import { inputVariants, IconsComponent } from '@/components';
import { HttpMethods, QUERY_CONSTANTS } from '@/utils/constants';
import { useQueryParams, useURLSearchParams } from '@/components/hooks';

export interface SearchInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  initialState?: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  initialState = false,
  placeholder = 'Search',
  className,
  ...props
}) => {
  const searchQuery = QUERY_CONSTANTS.SEARCH;

  const { searchValue } = useURLSearchParams(searchQuery);

  const [isExpanded, setIsExpanded] = useState(initialState);

  return (
    <div className='relative flex items-center'>
      <div
        className={cn('absolute top-1/2 -translate-y-1/2 transform', 'left-3')}
      >
        <IconsComponent
          fill='#1F2937'
          icon='reg-search'
          size='md'
        />
      </div>
      <input
        type='search'
        className={cn(
          inputVariants({}),
          'search min-h-9 rounded border-0 bg-transparent py-2 pl-10 text-base font-semibold text-gray-800 shadow-none outline-none duration-300 placeholder:text-sm placeholder:text-gray-800 hover:bg-gray-100 hover:shadow-none hover:outline-none focus:shadow-none',
          isExpanded ? 'w-60 bg-gray-100' : 'w-32 cursor-pointer',
          'transition-width ease-in-out',
          className,
        )}
        name={searchQuery}
        placeholder={placeholder}
        onFocus={() => setIsExpanded(true)}
        onBlur={() => setIsExpanded(false)}
        style={{ transition: 'width 0.3s ease-in-out' }}
        data-cy='search-input'
        defaultValue={searchValue}
        {...props}
      />
    </div>
  );
};

export function SearchInputForm(props: SearchInputProps) {
  const submit = useSubmit();
  const location = useLocation();
  const { queryParams, updateQueryParams } = useQueryParams();

  const debounceSubmit = debounce((form: HTMLFormElement) => {
    const formData = new FormData(form);
    const search = formData.get(QUERY_CONSTANTS.SEARCH) as string;

    if (isEmpty(search)) {
      queryParams.delete(QUERY_CONSTANTS.SEARCH);
    } else {
      updateQueryParams(QUERY_CONSTANTS.SEARCH, search);
    }

    submit(queryParams, { action: location.pathname });
  }, 300);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) =>
    debounceSubmit(event.currentTarget);
  return (
    <Form
      method={HttpMethods.GET}
      onChange={handleSubmit}
    >
      <SearchInput {...props} />
    </Form>
  );
}
