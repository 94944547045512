import { Button, Modal } from '@/components';
import {
  useAlert,
  scrollToTop,
  useBulkSendPasswordResetLink,
} from '@/components/hooks';
import { ModalProps } from '@/pages/users-and-teams/users/modals';
import { PassworResetLink, TANSTACK_QUERY_KEY } from '@/utils/constants';
import { handleApiError } from '@/utils/helper';
import { useQueryClient } from '@tanstack/react-query';

/**
 * @description SendPasswordResetLinkModal component
 */
export function SendPasswordResetLinkModal({
  showModal,
  setShowModal,
  selectedRowId,
  handleResetRowSelection,
}: ModalProps) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useBulkSendPasswordResetLink();

  const handleSendResetLink = () => {
    const bulkSendPasswordResetLink = {
      users: selectedRowId,
    };
    mutate(bulkSendPasswordResetLink, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [TANSTACK_QUERY_KEY.USER] });
        handleResetRowSelection();
        showAlert(PassworResetLink, 'success');
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {
        scrollToTop();
        setShowModal(false);
      },
    });
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <Modal.Content className='lg:min-w-[600px]'>
        <Modal.Title>
          <h6 className=''>Send Password Reset Link</h6>
        </Modal.Title>
        <Modal.Body className='space-y-4'>
          <p className='text-base text-gray-800'>
            You are about to reset password of{' '}
            <span className='font-bold'>{selectedRowId.length} Users.</span>
          </p>
          <p className='text-base font-bold text-gray-800'>
            Are you sure you want to send password reset link?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.CloseButton>Cancel</Modal.CloseButton>
          <Button
            disabled={isPending}
            onClick={handleSendResetLink}
          >
            Send Password Reset Link
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
