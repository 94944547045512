import { create } from 'zustand';

interface ShippingAddressInterface {
  shippingAddressId: string;
}

interface ShippingAddressActions {
  setShippingAddressId: (shippingAddressId: string) => void;
  clearShippingAddressId: () => void;
}

type TShippingAddress = ShippingAddressInterface & ShippingAddressActions;

const initialState: ShippingAddressInterface = {
  shippingAddressId: '',
};

export const useShippingAddressIdStore = create<TShippingAddress>((set) => ({
  ...initialState,
  setShippingAddressId: (shippingAddressId) => {
    set({ shippingAddressId });
  },
  clearShippingAddressId: () => {
    set({ ...initialState });
  },
}));
