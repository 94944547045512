import { PaymentFallBack } from '@/components/ui/stripe-fallback';
import OrderFailedIllustration from '@/assets/images/illustration/orderFailed.svg';
import { ROUTES } from '@/utils/constants';

export function PaymentErrorPage() {
  return (
    <div className='flex min-h-[70vh] items-center justify-center'>
      <PaymentFallBack
        illustration={OrderFailedIllustration}
        title='Order Processing Failed'
        description='Your payment couldn’t be processed, and your order has failed. Please check your payment details or try again.'
        redirect={{
          to: `/${ROUTES.CART}`,
          label: 'Back To Cart',
        }}
      />
    </div>
  );
}
