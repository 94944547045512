export function InvitationLoadingState() {
  return (
    <div className='mx-auto mt-8 w-full max-w-md rounded-lg bg-white p-6 shadow-md'>
      <div className='flex flex-col items-center justify-center py-8'>
        <div className='h-12 w-12 animate-spin rounded-full border-4 border-blue-500 border-t-transparent'></div>
        <p className='mt-4 text-lg font-medium text-gray-800'>
          Verifying your invitation...
        </p>
        <p className='text-sm text-gray-500'>This may take a few moments</p>
      </div>
    </div>
  );
}

export function TwoFaResetVerifyLoadingState() {
  return (
    <div className='mx-auto mt-8 w-full max-w-md rounded-lg bg-white p-6 shadow-md'>
      <div className='flex flex-col items-center justify-center py-8'>
        <div className='h-12 w-12 animate-spin rounded-full border-4 border-blue-500 border-t-transparent'></div>
        <p className='mt-4 text-lg font-medium text-gray-800'>
          Verifying your Reset 2FA...
        </p>
        <p className='text-sm text-gray-500'>This may take a few moments</p>
      </div>
    </div>
  );
}
