import { Button, IconsComponent } from '@/components';
import {
  Breadcrumb,
  BreadcrumbItem,
} from '@/components/ui/breadcrumb/Breadcrumb';
import { ROUTES } from '@/utils/constants';
import { ViewContactSupportModal } from '@/pages/orders/my-orders/modals';

interface ActionBarProps {
  customId: string;
  setShowReOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ActionBar = ({
  customId,
  setShowReOrderModal,
}: ActionBarProps) => {
  return (
    <>
      <div className='pb-8'>
        <Breadcrumb>
          <BreadcrumbItem href={`/${ROUTES.OVERVIEW}`}>Overview</BreadcrumbItem>
          <BreadcrumbItem href={`/${ROUTES.ORDERS.MY_ORDERS}`}>
            Orders
          </BreadcrumbItem>
          <BreadcrumbItem>#{customId}</BreadcrumbItem>
        </Breadcrumb>
        <div className='flex items-center justify-between'>
          <h4>#{customId}</h4>
          <div className='flex items-center space-x-4'>
            <Button
              variant='gray'
              intent='tertiary'
              className='px-4'
              onClick={() => setShowReOrderModal(true)}
            >
              <IconsComponent
                size='md'
                icon='reg-reset'
              />{' '}
              Re-order
            </Button>
            <ViewContactSupportModal customId={customId} />
          </div>
        </div>
      </div>
    </>
  );
};
