import { useNavigate } from 'react-router-dom';
import { Button } from '../button';
import NoResult from './NoResult';
import ErrorImg from '@/assets/images/notfound.png';

interface IErrorFoundProps {
  className?: string;
  navigationUrl?: string;
}

export const ErrorFound = ({ className, navigationUrl }: IErrorFoundProps) => {
  const navigate = useNavigate();

  return (
    <NoResult
      desc='The page you are looking for might have been removed or is temporarily unavailable.'
      imageSrc={ErrorImg}
      classes={className ? className : ''}
    >
      <Button
        intent='solid'
        variant={'primary'}
        onClick={() => (navigationUrl ? navigate(navigationUrl) : navigate(-1))}
      >
        Go Back
      </Button>
    </NoResult>
  );
};
