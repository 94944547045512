import { z } from 'zod';
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';

import {
  CreateNewPasswordResponseSchema,
  TCreateNewPasswordRequestSchema,
  TResetNewPasswordRequestSchema,
} from '@/schemas';
import { ForgotPasswordAPI } from '@/services';

export function useCreateNewPassword() {
  return useMutation<
    z.infer<typeof CreateNewPasswordResponseSchema>,
    AxiosError,
    TCreateNewPasswordRequestSchema
  >({
    mutationFn: (newPasswordData) => {
      const { codeId, userId } = newPasswordData;
      // Call the API passing newPasswordData and code_id
      return ForgotPasswordAPI.newPassword(codeId, userId)(newPasswordData);
    },
  });
}

export function useResetNewPassword() {
  return useMutation<
    z.infer<typeof CreateNewPasswordResponseSchema>,
    AxiosError,
    TResetNewPasswordRequestSchema
  >({
    mutationFn: (newPasswordData) =>
      ForgotPasswordAPI.resetPassword(newPasswordData),
  });
}
