import { QUERY_CONSTANTS, ROUTES, TANSTACK_QUERY_KEY } from '@/utils/constants';
import { useQueryParams } from '@/components/hooks';
import { ErrorPageContent } from '@/pages/errorPage';
import { PaymentErrorPage } from '@/pages/payment/error-page';
import { PaymentSuccessPage } from '@/pages/payment/success-page';
import { useGetOrderByCompany } from '@/components/hooks/api/orders/useGetOrderByCompany';
import { LoadingContent } from '@/components/ui/loading/Loading';
import { usePaymentConfirm } from '@/components/hooks/api/payment/usePayment';
import { PaymentCallbackType } from '@/utils/constants/payment.constant';
import { useQueryClient } from '@tanstack/react-query';

export function StripeCallbackPage() {
  const { queryParams } = useQueryParams();
  const queryClient = useQueryClient();

  const callbackType = queryParams.get(QUERY_CONSTANTS.STRIPE.CALLBACK_TYPE);

  const sessionId = queryParams.get(QUERY_CONSTANTS.STRIPE.SESSION_ID);

  const {
    data: orderDetails,
    isPending: isGetOrderByCompanyPending,
    isError: isGetOrderByCompanyError,
  } = useGetOrderByCompany();

  const {
    isLoading: isPaymentConfirmLoading,
    isSuccess: isPaymentConfirmSuccess,
    data: paymentConfirmDetails,
  } = usePaymentConfirm({
    cartId: orderDetails?.id ?? '',
    sessionId: sessionId!,
  });

  if (isGetOrderByCompanyPending || isPaymentConfirmLoading)
    return <LoadingContent />;

  if (callbackType === PaymentCallbackType.SUCCESS && isPaymentConfirmSuccess) {
    queryClient.invalidateQueries({
      queryKey: [TANSTACK_QUERY_KEY.PENDING_ORDERS],
    });
    return <PaymentSuccessPage orderId={paymentConfirmDetails.data} />;
  }

  if (callbackType === PaymentCallbackType.ERROR && isGetOrderByCompanyError)
    return <PaymentErrorPage />;

  return (
    <ErrorPageContent
      route={`/${ROUTES.PRODUCT.ALL_PRODUCTS}`}
      pageTitle='Products'
    />
  );
}
