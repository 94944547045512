import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import { useAlert } from '@/components/hooks';
import { TForgotPasswordFormSchema } from '@/schemas';
import { ForgotPasswordStep, ROUTES } from '@/utils/constants';
import { useForgotPassword } from '@/components/hooks/api/auth';
import { Button, IconsComponent, Input, Label } from '@/components';
import { IconSpinner } from '@/components/ui/icons/regular/spinner';
import { handleApiError } from '@/utils/helper';

interface ForgotPasswordFormProps {
  setForgotPasswordStep: React.Dispatch<
    React.SetStateAction<ForgotPasswordStep>
  >;
}

const ForgotPasswordEmailBody: FC<ForgotPasswordFormProps> = (props) => {
  const { setForgotPasswordStep } = props;
  const { showAlert } = useAlert();

  const { handleSubmit } = useFormContext<TForgotPasswordFormSchema>();

  const {
    isPending: isForgotPasswordEmailSubmitLoading,
    mutate: handleForgotPasswordEmailSubmit,
  } = useForgotPassword();

  const handleForgotPasswordSubmit: SubmitHandler<TForgotPasswordFormSchema> = (
    data,
  ) => {
    handleForgotPasswordEmailSubmit(data, {
      onSuccess: () => {
        setForgotPasswordStep(ForgotPasswordStep.RECOVERY_LINK_SENT);
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {},
    });
  };

  return (
    <>
      <div className='space-y-1'>
        <form onSubmit={handleSubmit(handleForgotPasswordSubmit)}>
          <Label htmlFor='companyEmail'> Email</Label>
          <Input
            type='email'
            name='email'
            placeholder='Enter company email'
          />
          <Button
            className='mt-6 w-full'
            type='submit'
            disabled={isForgotPasswordEmailSubmitLoading}
          >
            {isForgotPasswordEmailSubmitLoading && (
              <IconSpinner
                fill='white'
                size={24}
              />
            )}
            {isForgotPasswordEmailSubmitLoading ? 'Sending' : 'Send'} Recovery
            Link
          </Button>
        </form>
      </div>
    </>
  );
};

const BackToLoginPage = () => {
  return (
    <Link
      to={`/${ROUTES.LOGIN}`}
      className='flex items-center gap-2 p-0 text-sm font-semibold uppercase tracking-wider text-primary-600'
    >
      <IconsComponent
        icon='reg-chevron-left'
        size='xs'
        fill='#6e2534'
      />
      <span>Back to login</span>
    </Link>
  );
};

const ForgotPasswordBody = () => {
  return (
    <>
      <h4>Forgot your password? </h4>
      <div>
        Enter the email address associated with your account and we’ll send you
        a link to reset your password.
      </div>
    </>
  );
};

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = (props) => {
  const { setForgotPasswordStep } = props;

  return (
    <div className='flex w-[496px] flex-col gap-8 p-10'>
      <div className='flex flex-col gap-4'>
        <BackToLoginPage />
        <ForgotPasswordBody />
      </div>
      <ForgotPasswordEmailBody setForgotPasswordStep={setForgotPasswordStep} />
    </div>
  );
};

export default ForgotPasswordForm;
