import { FormEvent, useEffect } from 'react';
import { Form, useSubmit } from 'react-router-dom';

import {
  PAGINATION_CONSTANTS,
  HttpMethods,
  PRODUCTS_PAGINATION_CONSTANTS,
  ROUTES,
} from '@/utils/constants';
import { range } from '@/utils/helper';
import { useQueryParams } from '@/components/hooks';
import { PaginationNext, PaginationPrevious } from '@/components';

export function Pagination({
  totalCount,
  totalPages,
}: {
  totalCount: number;
  totalPages: number;
}) {
  const submit = useSubmit();
  const { queryParams } = useQueryParams();

  const isPathProducts =
    window.location.pathname.includes(ROUTES.PRODUCT.ALL_PRODUCTS) ||
    window.location.pathname.includes(
      ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS,
    );

  const PAGINATION_OBJECT = isPathProducts
    ? PRODUCTS_PAGINATION_CONSTANTS
    : PAGINATION_CONSTANTS;

  const pageSize =
    Number(queryParams.get(PAGINATION_OBJECT.PAGE_LIMIT)) ||
    PAGINATION_OBJECT.DEFAULT_PAGE_LIMIT;

  const paginationRange = range(1, totalPages);

  const currentPage = Number(queryParams.get(PAGINATION_OBJECT.PAGE)) || 1;

  const lastPage = paginationRange[paginationRange.length - 1];

  const previousQuery = new URLSearchParams(queryParams);
  previousQuery.set(PAGINATION_OBJECT.PAGE, String(currentPage - 1));

  const nextQuery = new URLSearchParams(queryParams);
  nextQuery.set(PAGINATION_OBJECT.PAGE, String(currentPage + 1));

  const isPreviousButtonDisabled = currentPage === 1;
  const isNextButtonDisabled = currentPage >= Number(lastPage);

  const startIndex = (currentPage - 1) * pageSize + 1;
  const endIndex = Math.min(startIndex + pageSize - 1, totalCount);

  const handleFormChange = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    queryParams.forEach((value, key) => {
      if (!formData.has(key)) {
        formData.append(key, value);
      }
    });

    submit(formData, { action: location.pathname });
  };

  useEffect(() => {
    const pageParam = queryParams.get(PAGINATION_OBJECT.PAGE);

    if (!pageParam || totalPages < Number(pageParam)) {
      const newQuery = new URLSearchParams(queryParams);
      newQuery.delete(PAGINATION_OBJECT.PAGE);
      submit(newQuery, { action: location.pathname });
    }
  }, []);

  if (totalCount <= PAGINATION_OBJECT.DEFAULT_PAGE_LIMIT) return <></>;

  return (
    <div
      className='flex items-center justify-between py-3'
      data-cy='pagination-component'
    >
      <Form
        method={HttpMethods.GET}
        className='flex items-center space-x-2'
        onChange={handleFormChange}
      >
        <label
          htmlFor='pageSize'
          className='text-sm font-normal text-black'
        >
          Show
        </label>
        <select
          defaultValue={pageSize}
          name={PAGINATION_OBJECT.PAGE_LIMIT}
          className='rounded border border-gray-200 px-2 py-1 text-base font-semibold text-black focus-visible:outline-none'
          data-cy={`Current Page: ${pageSize} `}
        >
          {PAGINATION_OBJECT.PAGE_LIMIT_OPTIONS.map((option) => (
            <option
              key={option}
              value={option}
            >
              {option}
            </option>
          ))}
        </select>
        <span className='text-sm font-normal text-black'>
          {isPathProducts ? 'products per page' : 'rows per page'}
        </span>
      </Form>
      <ul className='flex items-center gap-x-2'>
        <li className='w-28 text-end'>
          <p className='font-semibold text-black'>
            {startIndex} {' - '} {endIndex}{' '}
            <span className='font-normal text-gray-400'>of {totalCount}</span>
          </p>
        </li>
        <li>
          <PaginationPrevious
            to={`?${previousQuery.toString()}`}
            aria-disabled={isPreviousButtonDisabled}
            tabIndex={isPreviousButtonDisabled ? -1 : undefined}
          />
        </li>
        <li>
          <PaginationNext
            to={`?${nextQuery.toString()}`}
            aria-disabled={isNextButtonDisabled}
            tabIndex={isNextButtonDisabled ? -1 : undefined}
          />
        </li>
      </ul>
    </div>
  );
}
