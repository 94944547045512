import { Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { MediaType } from '@/utils/constants';
import { ErrorFound } from '@/components/ui/no-result/Error';
import { Notfound } from '@/components/ui/no-result/NotFound';
import { FlashPromotionProductCard, ProductCardSkeletons } from '@/components';
import { useGetPromotionInfiniteProductList } from '@/components/hooks/api/marketing-resource/usePromotion';

export function PromotionCardList({ promotionId }: { promotionId: string }) {
  const { ref, inView } = useInView();

  const { data, isError, isLoading, isFetchingNextPage, fetchNextPage } =
    useGetPromotionInfiniteProductList(promotionId);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  if (isLoading)
    return (
      <div className='mt-2 grid gap-4 sm:grid-cols-2 lg:grid-cols-3'>
        <ProductCardSkeletons length={3} />
      </div>
    );

  if (isError) return <ErrorFound />;

  if (data?.pages.every((page) => page.data.length === 0))
    return (
      <Notfound description='It looks like there is no products available' />
    );

  return (
    <>
      <p className='pb-3 pt-8 text-xl font-semibold text-gray-800'>
        Items In Bundle ({data?.pages[0].meta.pagination.total})
      </p>
      <div className='grid gap-4 sm:grid-cols-2 lg:grid-cols-3'>
        {data?.pages.map((page) => (
          <Fragment key={page.meta.pagination.page}>
            {page.data.map((product, index) => {
              const imageSrc = product.medias
                ? (product.medias.find(
                    (media) => media.type === MediaType.THUMBNAIL,
                  )?.url ?? '')
                : '';

              const imageAlt = product.medias
                ? (product.medias.find(
                    (media) => media.type === MediaType.THUMBNAIL,
                  )?.altText ?? '')
                : '';
              return (
                <FlashPromotionProductCard
                  key={product.id}
                  buyPrice={Number(product.price)}
                  imageSrc={imageSrc}
                  imageAlt={imageAlt}
                  productCode={product.skuCode ?? ''}
                  productName={product.name ?? ''}
                  rrp={null}
                  isProductNew={false}
                  moq={undefined}
                  id={String(index + 1)}
                />
              );
            })}
          </Fragment>
        ))}
      </div>
      <div ref={ref}>
        {isFetchingNextPage && (
          <div className='grid gap-4 sm:grid-cols-2 lg:grid-cols-3'>
            <ProductCardSkeletons length={3} />
          </div>
        )}
      </div>
    </>
  );
}
