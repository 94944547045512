import { z } from 'zod';

import { FILE } from '@/utils/constants';
import { emailSchema, phoneNumberSchema } from '@/schemas';

const CommonSchema = z.object({
  firstName: z
    .string()
    .min(1, { message: 'First Name is required' })
    .max(50, { message: 'First Name is too long' })
    .trim(),
  lastName: z
    .string()
    .min(1, { message: 'Last Name is required' })
    .max(50, { message: 'Last Name is too long' })
    .trim(),
  phone: phoneNumberSchema,
});

export const PersonalSettingsFormSchema = CommonSchema.extend({
  avatar: z
    .custom<File[] | string>()
    .optional()
    .refine((files) => {
      if (files === undefined) return true;

      const file = files[0];
      if (file instanceof File) {
        return file.size <= FILE.MAX_SIZE_MB * 1024 * 1024;
      }
      return true;
    }, 'Max file size is 4MB.')
    .refine((files) => {
      if (files === undefined) return true;

      const file = files[0];
      if (file instanceof File) {
        return FILE.ACCEPTED_IMAGE_TYPES.includes(file.type);
      }
      return true;
    }, 'Please choose .jpg , .jpeg or .png files.'),

  email: emailSchema,
});

export type TPersonalSettings = z.infer<typeof PersonalSettingsFormSchema>;

export const PersonalInfoFormSchema = CommonSchema.extend({
  designation: z
    .string()
    .min(1, { message: 'Designation is required' })
    .max(50, { message: 'Designation Name is too long' })
    .trim(),
});

export type TPersonalInfoForm = z.infer<typeof PersonalInfoFormSchema>;
