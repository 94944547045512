import { useMutation, useQuery } from '@tanstack/react-query';

import { AuthAPI, CompanyAPI } from '@/services';
import {
  TVerifyCodeRequestSchema,
  TLoginRequestSchema,
  TUpdatePersonalInfo,
} from '@/schemas';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';

export const useUserLogin = () => {
  return useMutation({
    mutationFn: (loginData: TLoginRequestSchema) => AuthAPI.login(loginData),
  });
};

export const useUpdatePersonalInfo = () => {
  return useMutation({
    mutationFn: (personalSettingDetail: TUpdatePersonalInfo) =>
      AuthAPI.updatePersonalDetails(personalSettingDetail),
  });
};

export const useGenerate2FA = () => {
  return useQuery({
    queryKey: ['generate-2fa'],
    queryFn: () => AuthAPI.generate2FA(),
    refetchOnWindowFocus: false,
  });
};

export const useUserVerifyCode = () => {
  return useMutation({
    mutationFn: (verifyCodeData: TVerifyCodeRequestSchema) =>
      AuthAPI.verifyCode(verifyCodeData),
  });
};

export const useGetLoggedInUser = () => {
  return useQuery({
    queryKey: ['logged-user'],
    queryFn: () => AuthAPI.getLoggedInUserDetails(),
    enabled: false,
  });
};

export const useUserLogout = () => {
  return useMutation({
    mutationFn: () => AuthAPI.logout(),
  });
};

export const useGetLoggedInUserCompanySetting = () => {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.COMPANY_DETAILS],
    queryFn: () => CompanyAPI.getCompanyDetails(),
    select({ timezone, timeFormat, dateFormat, currency, shoeSizeType }) {
      return { timezone, timeFormat, dateFormat, currency, shoeSizeType };
    },
    enabled: false,
  });
};

/**
 * @description Verify token during registration process
 */
export const useVerifyToken = () => {
  return useMutation({
    mutationFn: ({ companyId, token }: { companyId: string; token: string }) =>
      AuthAPI.verifyToken(companyId, { token }),
  });
};

export function useValidate2faReset(userId: string) {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.USER_2FA, userId],
    queryFn: () => AuthAPI.validate2faReset(userId),
    retry: false, // No retries or refetching if an error occurs
  });
}
