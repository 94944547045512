import { useNavigate } from 'react-router-dom';
import React, { Dispatch, SetStateAction } from 'react';

import { Button, Modal } from '@/components';
import { handleApiError } from '@/utils/helper';
import { useUserStore } from '@/store/user-store-new';
import { ROUTES, UserLogout } from '@/utils/constants';
import { useAlert, useUserLogout } from '@/components/hooks';

interface LogoutModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

export const LogoutModal: React.FC<LogoutModalProps> = ({
  showModal,
  setShowModal,
}) => {
  const navigate = useNavigate();
  const { removeStorage } = useUserStore();
  const { showAlert } = useAlert();

  const { mutate, isPending } = useUserLogout();

  const handleLogout = () => {
    mutate(undefined, {
      onSuccess: () => {
        navigate(ROUTES.LOGIN, { replace: true });
        removeStorage();
        showAlert(UserLogout, 'success');
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {
        setShowModal(false);
      },
    });
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <Modal.Content className='md:min-w-[600px]'>
        <Modal.Title>
          <h6>Confirm Logout</h6>
        </Modal.Title>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer className='space-x-8'>
          <Modal.CloseButton
            data-cy='modal-close-button'
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Modal.CloseButton>
          <Button
            type='submit'
            form='logoutForm'
            data-cy='apply-logout-button'
            onClick={handleLogout}
            disabled={isPending}
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
