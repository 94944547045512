import { useState } from 'react';
import { DateRange } from 'react-day-picker';
import { isBefore, isAfter } from 'date-fns';

import { Button, DatePicker, DateSuggestionInput } from '@/components';
import { formatDateToMonthDayYear } from '@/utils/helper/datetime.helper';

export function DateRangeWithSuggestion({
  handleApply,
  defaultSelectedDateRange,
}: {
  handleApply: (dateRange: DateRange | undefined) => void;
  defaultSelectedDateRange: DateRange | undefined;
}) {
  const [month, setMonth] = useState(new Date());

  const startDateDefaultValue = defaultSelectedDateRange?.from
    ? {
        value: defaultSelectedDateRange.from,
        label: formatDateToMonthDayYear(defaultSelectedDateRange.from),
      }
    : null;

  const endDateDefaultValue = defaultSelectedDateRange?.to
    ? {
        value: defaultSelectedDateRange.to,
        label: formatDateToMonthDayYear(defaultSelectedDateRange.to),
      }
    : null;

  const [startDate, setStartDate] = useState<unknown>(startDateDefaultValue);
  const [endDate, setEndDate] = useState<unknown>(endDateDefaultValue);

  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >(defaultSelectedDateRange);

  const handleReset = () => {
    setSelectedDateRange(undefined);
    setStartDate(null);
    setEndDate(null);
  };

  const handleDateChange = (date: DateRange | undefined) => {
    if (!date) return handleReset();

    setSelectedDateRange(date);

    if (date.from && date.to) {
      setStartDate({
        value: date.from,
        label: formatDateToMonthDayYear(date.from),
      });
      setEndDate({ value: date.to, label: formatDateToMonthDayYear(date.to) });
    }
  };

  const handleInputChangeFrom = (newValue: unknown) => {
    const selectedValue = (newValue as { value: unknown }).value as Date;

    if (selectedDateRange?.to && isAfter(selectedValue, selectedDateRange.to))
      return handleReset();

    setStartDate(newValue);
    setMonth(selectedValue);
    setSelectedDateRange({ from: selectedValue, to: selectedDateRange?.to });
  };

  const handleInputChangeTo = (newValue: unknown) => {
    const selectedValue = (newValue as { value: unknown }).value as Date;

    if (
      selectedDateRange?.from &&
      isBefore(selectedValue, selectedDateRange.from)
    )
      return handleReset();

    setEndDate(newValue);
    setMonth(selectedValue);
    setSelectedDateRange({ from: selectedDateRange?.from, to: selectedValue });
  };

  return (
    <>
      <DatePicker
        mode='range'
        month={month}
        pagedNavigation
        numberOfMonths={2}
        showOutsideDays={true}
        onMonthChange={setMonth}
        onSelect={handleDateChange}
        selected={selectedDateRange}
      />
      <div className='flex justify-between'>
        <div className='flex w-3/4'>
          <DateSuggestionInput
            value={startDate}
            onChange={handleInputChangeFrom}
          />
          <DateSuggestionInput
            value={endDate}
            onChange={handleInputChangeTo}
          />
        </div>
        <Button
          variant='blue'
          disabled={!startDate || !endDate}
          onClick={() => handleApply(selectedDateRange)}
        >
          Apply
        </Button>
      </div>
    </>
  );
}
