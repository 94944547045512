import { z } from 'zod';

import {
  CreateNewPasswordFormSchema,
  ForgotPasswordFormSchema,
} from '@/schemas/authorization';
import { BaseResponseSchema } from '@/schemas/common.schema';

export const ForgotPasswordRequestSchema = ForgotPasswordFormSchema;

export const ForgotPasswordResponseSchema = z.object({
  message: z.string(),
});

export const ForgotPasswordFormVerifyCodeResponseSchema = BaseResponseSchema;

export type TCreateNewPasswordFormSchema = z.infer<
  typeof CreateNewPasswordFormSchema
>;

export const CreateNewPasswordResponseSchema = BaseResponseSchema;

export const CreateNewPasswordRequestSchema = z.object({
  password: z.string(),
  userId: z.string(),
  codeId: z.string(),
});

export type TCreateNewPasswordRequestSchema = z.infer<
  typeof CreateNewPasswordRequestSchema
>;

export const ResetNewPasswordRequestSchema = z.object({
  password: z.string(),
  id: z.string(),
  token: z.string(),
});

export type TResetNewPasswordRequestSchema = z.infer<
  typeof ResetNewPasswordRequestSchema
>;
