/**
 * @description Builds a query string based on the current URL parameters and appends it to the given path.
 * @param path - The path to append the query string to.
 * @returns The updated path with the query string appended.
 */

import { QUERY_CONSTANTS } from '@/utils/constants/query.constants';

// Add the constants to the array if they have multiple values in the url
const MULTIPLE_KEY_VALUE = [
  QUERY_CONSTANTS.TEAMS.valueOf(),
  QUERY_CONSTANTS.STATUS.valueOf(),
  QUERY_CONSTANTS.ROLES.valueOf(),
];

export function buildQuery(path: string): string {
  const queryParams = new URLSearchParams();
  const params = new URLSearchParams(window.location.search);

  const isValidParam = (value: unknown) =>
    value !== '' && value !== null && value !== undefined;

  const paramMap = new Map<string, string[]>();

  for (const [key, value] of params.entries()) {
    if (isValidParam(value)) {
      if (!paramMap.has(key)) {
        paramMap.set(key, []);
      }
      paramMap.get(key)!.push(value);
    }
  }

  for (const [key, values] of paramMap.entries()) {
    if (MULTIPLE_KEY_VALUE.includes(key)) {
      for (const value of values) {
        queryParams.append(`${key}[]`, value);
      }
    } else {
      queryParams.append(key, values.join(','));
    }
  }

  const queryString = queryParams.toString();

  return queryString ? `${path}?${queryString}` : path;
}
