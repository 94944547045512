import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';
import { InputError } from '@/components/ui/input-field/InputError';
import { IconCheckCircle } from '../../icons/regular/checkCircle';

interface PhoneNumberInputInterface extends PhoneInputProps {
  name: string;
}

export function PhoneNumberInput({
  name,
  country,
  ...props
}: PhoneNumberInputInterface) {
  const {
    control,
    getFieldState,
    formState: { errors },
  } = useFormContext();

  const phoneNumberInputStyles: React.CSSProperties = {
    outline: 'none',
    width: '100%',
    borderWidth: '0',
    fontSize: '1rem',
    color: '#1f2937',
    minHeight: '3rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
  };

  const phoneNumberContainerStyles: React.CSSProperties = {
    border: errors[name] ? '1px solid  #dc2626 ' : '1px solid #e5e7eb',
    background: errors[name]
      ? 'var(--input-error, #FEF2F2)'
      : 'var(--neutral-white, #FFF)',
    width: '100%',
    margin: '0.25rem 0',
    borderRadius: '0.25rem',
  };

  const dropDownButtonStyles: React.CSSProperties = {
    borderWidth: '0',
    borderRightWidth: '1px',
    borderColor: '#e5e7eb',
  };

  return (
    <div data-cy='phone-number-input'>
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, onChange, onBlur, ...rest } }) => (
          <div className='relative'>
            <PhoneInput
              {...rest}
              inputProps={{
                ref,
              }}
              onChange={(value: string, countryData: CountryData) => {
                const reducedPhone = value.replace(countryData.countryCode, '');
                const phoneNumberWithCode = `${countryData.countryCode}-${reducedPhone}`;
                onChange(phoneNumberWithCode);
              }}
              onBlur={onBlur}
              country={
                typeof country === 'string' ? country.toLowerCase() : 'gb'
              }
              countryCodeEditable={false}
              inputStyle={phoneNumberInputStyles}
              buttonStyle={dropDownButtonStyles}
              containerStyle={phoneNumberContainerStyles}
              containerClass='duration-300 focus-within:!border-gray-800 focus-within:!shadow-base'
              inputClass='hover:shadow-sm placeholder:!text-gray-400 duration-300'
              {...props}
              enableSearch={true}
            />
            {!getFieldState(name).invalid && (
              <div className='absolute right-1.5 top-4'>
                <IconCheckCircle
                  size={20}
                  fill='#16A34A'
                />
              </div>
            )}
          </div>
        )}
      />
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => <InputError message={message} />}
      />
    </div>
  );
}
