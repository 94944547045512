export enum LoginStep {
  LOGIN = 0,
  VERIFY = 1,
  TWOFA = 2,
  PERSONAL_SETTINGS = 3,
}

export enum RegisterStep {
  REGISTER,
  REGISTER_INVITATION_SUCCESSFUL,
  COMPLETE_REGISTRATION,
}

export enum ForgotPasswordStep {
  FORGOT_PASSWORD,
  RECOVERY_LINK_SENT,
  FORGOT_PASSWORD_VERIFY_CODE,
  CREATE_NEW_PASSWORD,
}
