import { ROUTES } from '@/utils/constants';
import { Breadcrumb, BreadcrumbItem } from '@/components';

export const ActionBar = () => {
  return (
    <div className='flex flex-col gap-3 pb-8 transition-all ease-out'>
      <Breadcrumb>
        <BreadcrumbItem
          href={`/${ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS}`}
        >
          Marketing Resources
        </BreadcrumbItem>
        <BreadcrumbItem>Flash Promotion</BreadcrumbItem>
      </Breadcrumb>
      <div className='flex items-center justify-between'>
        <h4>Flash Promotion</h4>
      </div>
    </div>
  );
};
