import { z } from 'zod';

import {
  AddOrderToSessionByIdRequsetSchema,
  AddOrderToSessionByIdResponseSchema,
  TAddOrderToSessionByIdRequsetSchema,
  RemoveProductFromOrderRequestSchema,
  TRemoveProductFromOrderRequestSchema,
  SingleUserOrderSessionIdRequestSchema,
  SingleUserOrderSessionIdResponseSchema,
  RemoveProductVariantFromOrderRequestSchema,
  TRemoveProductVariantFromOrderRequestSchema,
  ContactSupportRequestSchema,
  ContactSupportResponseSchema,
} from '@/schemas/api/orders';
import { api } from '@/utils/helper';
import { VoidSchema } from '@/schemas/common.schema';
import { API_ENDPOINT, HttpMethods } from '@/utils/constants';
import {
  GetOrderDetailRequestSchema,
  GetOrderDetailResponseSchema,
  ReorderProductRequestSchema,
  ReorderProductResponseSchema,
} from '@/schemas/api/orders/single-order-detail.schema';
import {
  ExportOrderListRequestSchema,
  ExportOrderListResponseSchema,
  GetOrderAnalyticsRequestSchema,
  GetOrderAnalyticsResponseSchema,
  GetOrderListRequestSchema,
  GetOrderListResponseSchema,
} from '@/schemas/api/orders/order-list.schema';

const getUserOrderSessionId = api<
  z.infer<typeof SingleUserOrderSessionIdRequestSchema>,
  z.infer<typeof SingleUserOrderSessionIdResponseSchema>
>({
  path: API_ENDPOINT.ORDERS,
  method: HttpMethods.POST,
  requestSchema: SingleUserOrderSessionIdRequestSchema,
  responseSchema: SingleUserOrderSessionIdResponseSchema,
});

const addOrderToSessionById = (
  sessionId: string,
  data: TAddOrderToSessionByIdRequsetSchema,
) =>
  api<
    z.infer<typeof AddOrderToSessionByIdRequsetSchema>,
    z.infer<typeof AddOrderToSessionByIdResponseSchema>
  >({
    path: `${API_ENDPOINT.ORDERS}/${sessionId}`,
    method: HttpMethods.PATCH,
    requestSchema: AddOrderToSessionByIdRequsetSchema,
    responseSchema: AddOrderToSessionByIdResponseSchema,
  })(data);

const deleteProductFromOrder = (
  sessionId: string,
  productId: TRemoveProductFromOrderRequestSchema,
) =>
  api<
    z.infer<typeof RemoveProductFromOrderRequestSchema>,
    z.infer<typeof VoidSchema>
  >({
    path: `${API_ENDPOINT.ORDERS}/${sessionId}/delete-product`,
    method: HttpMethods.DELETE,
    requestSchema: RemoveProductFromOrderRequestSchema,
    responseSchema: VoidSchema,
  })(productId);

const deleteProductVariantFromOrder = (
  sessionId: string,
  deleteProductVaraintPayload: TRemoveProductVariantFromOrderRequestSchema,
) =>
  api<
    z.infer<typeof RemoveProductFromOrderRequestSchema>,
    z.infer<typeof VoidSchema>
  >({
    path: `${API_ENDPOINT.ORDERS}/${sessionId}/delete-variant`,
    method: HttpMethods.DELETE,
    requestSchema: RemoveProductVariantFromOrderRequestSchema,
    responseSchema: VoidSchema,
  })(deleteProductVaraintPayload);

const getOrderDetail = api<
  z.infer<typeof GetOrderDetailRequestSchema>,
  z.infer<typeof GetOrderDetailResponseSchema>
>({
  method: 'GET',
  path: API_ENDPOINT.ORDER_DETAILS,
  requestSchema: GetOrderDetailRequestSchema,
  responseSchema: GetOrderDetailResponseSchema,
});

const sendContactSupport = api<
  z.infer<typeof ContactSupportRequestSchema>,
  z.infer<typeof ContactSupportResponseSchema>
>({
  method: HttpMethods.POST,
  path: API_ENDPOINT.CONTACT_SUPPORT,
  requestSchema: ContactSupportRequestSchema,
  responseSchema: ContactSupportResponseSchema,
});

const getOrderList = (path: string) =>
  api<
    z.infer<typeof GetOrderListRequestSchema>,
    z.infer<typeof GetOrderListResponseSchema>
  >({
    method: HttpMethods.GET,
    path,
    requestSchema: GetOrderListRequestSchema,
    responseSchema: GetOrderListResponseSchema,
  });

const reOrderProduct = (orderId: string) =>
  api({
    method: HttpMethods.GET,
    path: API_ENDPOINT.REORDER_PRODUCTS,
    requestSchema: ReorderProductRequestSchema,
    responseSchema: ReorderProductResponseSchema,
  })('', orderId);

const getOrderAnalytics = api<
  z.infer<typeof GetOrderAnalyticsRequestSchema>,
  z.infer<typeof GetOrderAnalyticsResponseSchema>
>({
  method: HttpMethods.GET,
  path: API_ENDPOINT.ORDER_ANALYTICS,
  requestSchema: GetOrderAnalyticsRequestSchema,
  responseSchema: GetOrderAnalyticsResponseSchema,
});

const exportOrderList = api<
  z.infer<typeof ExportOrderListRequestSchema>,
  z.infer<typeof ExportOrderListResponseSchema>
>({
  method: HttpMethods.GET,
  path: API_ENDPOINT.ORDER_EXPORT,
  requestSchema: ExportOrderListRequestSchema,
  responseSchema: ExportOrderListResponseSchema,
});

export const OrdersAPI = {
  getUserOrderSessionId,
  getOrderDetail,
  addOrderToSessionById,
  deleteProductFromOrder,
  deleteProductVariantFromOrder,
  sendContactSupport,
  getOrderList,
  reOrderProduct,
  getOrderAnalytics,
  exportOrderList,
};
