import { Alert, Badge, DataTable, Pagination } from '@/components';
import { ActionBar } from './ActionBar';
import { OrderCard } from './OrderCard';
import { useMyOrderColumn } from './MyOrderColumn';
import { useAlert, useTable } from '@/components/hooks';
import { TOrder } from '@/schemas/orders/my-orders';
import { FilterForm } from './FilterForm';
import { useGetOrderList } from '@/components/hooks/api/orders/useGetOrderList';
import { ErrorFound } from '@/components/ui/no-result/Error';
import { OrderTableSkeleton } from '@/components/ui/skeleton/OrderTableSkeleton';
import { useGetOrderAnalytics } from '@/components/hooks/api/orders/useGetOrderAnalytics';
import { AnalyticCardSkeletion } from '@/components/ui/skeleton/AnalyticCardSkeleton';

function OrderAnalytics() {
  const {
    data: orderAnalytics,
    isPending: isGetOrderAnalyticsPending,
    isError: isGetOrderAnalyticsError,
  } = useGetOrderAnalytics();

  if (isGetOrderAnalyticsPending) return <AnalyticCardSkeletion />;

  if (isGetOrderAnalyticsError) return;

  return (
    <div className='grid gap-4 sm:grid-cols-2 md:grid-cols-3'>
      <OrderCard
        title='total orders placed'
        amount={orderAnalytics.totalOrders}
        description='more than last week'
      >
        <Badge variant='green'>{orderAnalytics.percentOrders ?? 0}%</Badge>
      </OrderCard>
      <OrderCard
        title='total order amount'
        amount={`£${orderAnalytics.totalAmount}`}
        description='less than last week'
      >
        <Badge variant='red'>{orderAnalytics.percentAmount}%</Badge>
      </OrderCard>
    </div>
  );
}

function OrderTable({
  orders,
  totalOrders,
  totalPages,
}: {
  orders: TOrder[];
  totalOrders: number;
  totalPages: number;
}) {
  const { columns } = useMyOrderColumn();
  const { table } = useTable(columns, orders, 'id');

  return (
    <>
      <DataTable
        table={table}
        columns={columns}
        className='overflow-x-auto sm:overflow-visible'
      />
      <Pagination
        totalCount={totalOrders}
        totalPages={totalPages}
      />
    </>
  );
}

function OrderList() {
  const {
    data: orderList,
    isPending: isGetOrderListPending,
    isError: isGetOrderListError,
  } = useGetOrderList();

  if (isGetOrderListPending) return <OrderTableSkeleton />;

  if (isGetOrderListError) return <ErrorFound />;

  return (
    <OrderTable
      orders={orderList.orders}
      totalOrders={orderList.totalOrders}
      totalPages={orderList.totalPages}
    />
  );
}

export default function MyOrders() {
  const { isAlertVisible, isAlertDismissed, alertMessage, isType } = useAlert();

  useGetOrderAnalytics();
  useGetOrderList();

  return (
    <>
      {isAlertVisible && !isAlertDismissed && (
        <Alert
          type={isType}
          classes='mb-4'
          title={alertMessage}
        />
      )}
      <ActionBar />
      <OrderAnalytics />
      <FilterForm />
      <OrderList />
    </>
  );
}
