import React from 'react';
import { IconProps } from '../iconProps';

export const IconPoint: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 7.5C11.11 7.5 10.24 7.76392 9.49994 8.25839C8.75991 8.75285 8.18314 9.45566 7.84254 10.2779C7.50195 11.1002 7.41283 12.005 7.58647 12.8779C7.7601 13.7508 8.18869 14.5526 8.81802 15.182C9.44736 15.8113 10.2492 16.2399 11.1221 16.4135C11.995 16.5872 12.8998 16.4981 13.7221 16.1575C14.5443 15.8169 15.2471 15.2401 15.7416 14.5001C16.2361 13.76 16.5 12.89 16.5 12C16.5 10.8065 16.0259 9.66193 15.182 8.81802C14.3381 7.97411 13.1935 7.5 12 7.5ZM12 13.125C11.7775 13.125 11.56 13.059 11.375 12.9354C11.19 12.8118 11.0458 12.6361 10.9606 12.4305C10.8755 12.225 10.8532 11.9988 10.8966 11.7805C10.94 11.5623 11.0472 11.3618 11.2045 11.2045C11.3618 11.0472 11.5623 10.94 11.7805 10.8966C11.9988 10.8532 12.225 10.8755 12.4305 10.9606C12.6361 11.0458 12.8118 11.19 12.9354 11.375C13.059 11.56 13.125 11.7775 13.125 12C13.125 12.2984 13.0065 12.5845 12.7955 12.7955C12.5845 13.0065 12.2984 13.125 12 13.125Z'
        fill={fill}
      />
    </svg>
  );
};
