import { z } from 'zod';

export const MemberSchema = z.object({
  id: z.union([z.string(), z.number()]).optional(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  designation: z.string().optional().nullable(),
  avatar: z.string().nullable().optional(),
  email: z.string().email().optional(),
  role: z.string().optional(),
});
export type TMembersSchema = z.infer<typeof MemberSchema>;
export const TeamListSchema = z.object({
  id: z.union([z.string(), z.number()]),
  name: z.string(),
});

export type TTeamListSchema = z.infer<typeof TeamListSchema>;

export const memberSchemaToAdd = z.object({
  _id: z.string().optional(),
  memberId: z.string(),
  name: z.string().optional(),
  profilePicture: z.string(),
  email: z.string().email().optional(),
});

export const TeamAddMembersSchema = z.object({
  userIds: z.array(z.string()),
});

export type TTeamAddMembersSchema = z.infer<typeof TeamAddMembersSchema>;

export const TeamAddMembersResponseSchema = z.any();

export const TeamSchema = z.object({
  id: z.string(),
  companyId: z.string(),
  name: z.string().nullable(),
  description: z.string().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  users: z.array(MemberSchema).optional(),
});

export type TTeamSchema = z.infer<typeof TeamSchema>;

const memberSchema = z.object({
  id: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string().email(),
  avatar: z.string().nullable(),
  companyId: z.string(),
  users: z.array(z.any()).optional(),
});

export type TMemberSchema = z.infer<typeof memberSchema>;

export const GetMembersRequestSchema = z.void();
export const GetMembersResponseSchema = z.object({
  // status: z.boolean(),
  message: z.string(),
  timestamp: z.string().optional(),
  data: z.array(memberSchema),
});
// export const GetMembersResponseSchema = z.any();
export const GetTeamsResponseSchema = z.object({
  // status: z.boolean().optional(),
  message: z.string(),
  timestamp: z.string().optional(),
  data: z.array(TeamSchema), // Updating payload to have teams array
  meta: z.object({
    pagination: z.object({
      total: z.number(),
      page: z.number(),
      pageSize: z.number(),
      pageCount: z.number(),
    }),
  }),
});

export const GetTeamsRequestSchema = z.void();

export type TMember = z.infer<typeof MemberSchema>;
export type TTeam = z.infer<typeof TeamSchema>;

export type TGetMembersResponse = z.infer<typeof GetMembersResponseSchema>;
export type TTeamResponse = z.infer<typeof GetTeamsResponseSchema>;

export const GetRequestSingleTeamRequestSchema = z.string();
export const GetTeamsSingleResponseSchema = z.object({
  //   status: z.boolean(),
  message: z.string(),
  timestamp: z.string().optional(),
  data: z.object({
    id: z.string(),
    companyId: z.string(),
    name: z.string(),
    description: z.string().optional(),
    createdAt: z.string().optional(),
    updatedAt: z.string().optional(),
  }),
});

export type TGetTeamResponse = z.infer<typeof GetTeamsSingleResponseSchema>;

export const BulkExportTeamRequestSchema = z.object({
  teamIds: z.array(z.string()),
});

export type TBulkExportTeam = z.infer<typeof BulkExportTeamRequestSchema>;

export const BulkExportTeamResponseSchema = z.string();

const userDataSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  avatar: z.string().nullable().optional(),
});

export type TUserData = z.infer<typeof userDataSchema>;

export const GetAddedMemberResSchema = z.object({
  // status: z.boolean(),
  message: z.string(),
  timestamp: z.string().optional(),
  data: z.array(userDataSchema),
});
