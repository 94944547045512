import React from 'react';
import { IconProps } from '../iconProps';

export const IconChat: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.125 10.5C16.125 10.6989 16.046 10.8897 15.9053 11.0303C15.7647 11.171 15.5739 11.25 15.375 11.25H9C8.80109 11.25 8.61032 11.171 8.46967 11.0303C8.32902 10.8897 8.25 10.6989 8.25 10.5C8.25 10.3011 8.32902 10.1103 8.46967 9.96967C8.61032 9.82902 8.80109 9.75 9 9.75H15.375C15.5739 9.75 15.7647 9.82902 15.9053 9.96967C16.046 10.1103 16.125 10.3011 16.125 10.5ZM15.375 12.75H9C8.80109 12.75 8.61032 12.829 8.46967 12.9697C8.32902 13.1103 8.25 13.3011 8.25 13.5C8.25 13.6989 8.32902 13.8897 8.46967 14.0303C8.61032 14.171 8.80109 14.25 9 14.25H15.375C15.5739 14.25 15.7647 14.171 15.9053 14.0303C16.046 13.8897 16.125 13.6989 16.125 13.5C16.125 13.3011 16.046 13.1103 15.9053 12.9697C15.7647 12.829 15.5739 12.75 15.375 12.75ZM21.75 11.625C21.7473 14.1106 20.7587 16.4935 19.0011 18.2511C17.2435 20.0087 14.8606 20.9973 12.375 21H4.5C4.10218 21 3.72064 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V11.625C3 9.1386 3.98772 6.75403 5.74587 4.99587C7.50403 3.23772 9.8886 2.25 12.375 2.25C14.8614 2.25 17.246 3.23772 19.0041 4.99587C20.7623 6.75403 21.75 9.1386 21.75 11.625ZM20.25 11.625C20.25 9.53642 19.4203 7.53338 17.9435 6.05653C16.4666 4.57969 14.4636 3.75 12.375 3.75C10.2864 3.75 8.28338 4.57969 6.80653 6.05653C5.32968 7.53338 4.5 9.53642 4.5 11.625V19.5H12.375C14.4629 19.4978 16.4646 18.6674 17.941 17.191C19.4174 15.7146 20.2478 13.7129 20.25 11.625Z'
        fill={fill ? fill : '#000000'}
      />
    </svg>
  );
};
