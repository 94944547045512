import { useCallback } from 'react';
import { useAlert } from './useAlert';
import { AxiosError } from 'axios';

interface ApiErrorResponse {
  errors?: Record<string, string[]>;
  message?: string;
}

export const useErrorHandler = () => {
  const { showAlert } = useAlert();

  const handleError = useCallback(
    (error: AxiosError) => {
      try {
        const errorData = error.response?.data as ApiErrorResponse;

        if (errorData.errors && typeof errorData.errors === 'object') {
          // Process all keys dynamically
          const errorMessages = Object.entries(errorData.errors)
            .flatMap(([key, messages]) =>
              messages.map((msg) => `${key.replace('.', ' ')}: ${msg}`),
            )
            .join(', ');

          showAlert(errorMessages, 'error');
        } else if (errorData.message) {
          // Handle general error message
          showAlert(errorData.message, 'error');
        } else {
          // Fallback message
          showAlert('An unknown error occurred', 'error');
        }
      } catch (e) {
        console.error('Error processing error response:', e);
        showAlert(
          'An error occurred while processing the error response',
          'error',
        );
      }
    },
    [showAlert],
  );

  return { handleError };
};
