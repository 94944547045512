import { z } from 'zod';

export const SingleCountry = z.object({
  capital: z.string(),
  currency: z.string(),
  emoji: z.string(),
  id: z.number(),
  iso2: z.string(),
  iso3: z.string(),
  name: z.string(),
  native: z.string(),
  phonecode: z.string(),
});
export type TSingleCountry = z.infer<typeof SingleCountry>;

export const CountriesResponseSchema = z.array(SingleCountry);
export type TCountriesResponseSchema = z.infer<typeof CountriesResponseSchema>;

export const SingleState = z.object({
  id: z.string(),
  name: z.string(),
  iso2: z.string(),
});

export const SingleStateResponse = z.array(SingleState);
export type TSingleStateResponseSchema = z.infer<typeof SingleStateResponse>;
