import { Button } from '@/components';

export const ApplyButton = ({
  disableAnimation,
  showApplyButton,
}: {
  disableAnimation: boolean;
  showApplyButton: () => boolean;
}) => {
  return (
    showApplyButton() && (
      <Button
        intent={'tertiary'}
        variant={'blue'}
        className={`${disableAnimation ? '' : 'animate-fade'} pb-4 pl-3 pt-6`}
        role='button'
      >
        Apply
      </Button>
    )
  );
};
