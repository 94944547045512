import { cva, VariantProps } from 'class-variance-authority';
import { createContext, useContext, useRef, useState } from 'react';

import { cn } from '@/utils/cn';
import { useOutsideClick } from '@/components/hooks';
import { Button, ButtonProps, IconsComponent } from '@/components';

export const DropDownContext = createContext<{
  isVisbile: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dropdownRef: React.RefObject<HTMLElement>;
}>({
  isVisbile: false,
  setIsVisible: () => {},
  dropdownRef: { current: null },
});

const dropDownContentVariants = cva(
  'dropdown-content absolute top-full mt-0.5 z-50 rounded border border-gray-100 bg-white p-2 shadow-lg',
  {
    variants: {
      position: {
        right: 'right-0',
        center: 'left-1/2 -translate-x-1/2',
        left: 'left-0',
      },
    },
    defaultVariants: {
      position: 'left',
    },
  },
);

interface DropdownContentProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof dropDownContentVariants> {}

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  isVisbile?: boolean;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Dropdown({
  children,
  className,
  isVisbile: externalShowModal,
  setIsVisible: setExternalShowModal,
}: DropdownProps) {
  const [internalShowDropdown, setInternalShowDropdown] = useState(false);

  const isVisbile = externalShowModal ?? internalShowDropdown;
  const setIsVisible = setExternalShowModal ?? setInternalShowDropdown;

  const dropdownRef = useRef<HTMLElement>(null);

  useOutsideClick(dropdownRef, () => {
    setIsVisible(false);
  });

  return (
    <DropDownContext.Provider value={{ isVisbile, setIsVisible, dropdownRef }}>
      <div
        className={cn('dropdown-toggle relative', className)}
        data-cy='dropdown component'
      >
        {children}
      </div>
    </DropDownContext.Provider>
  );
}

interface DropdownToggleProps extends ButtonProps {
  showIcon?: boolean;
  iconFillColor?: string;
}

export function DropdownToggle({
  showIcon,
  children,
  iconFillColor,
  ...props
}: DropdownToggleProps) {
  const { isVisbile, setIsVisible, dropdownRef } = useContext(DropDownContext);
  return (
    <>
      <Button
        ref={dropdownRef as React.RefObject<HTMLButtonElement>}
        onClick={() => setIsVisible((prev) => !prev)}
        {...props}
      >
        {children}{' '}
        {showIcon && (
          <IconsComponent
            size='xs'
            fill={iconFillColor}
            icon={isVisbile ? 'reg-chevron-up' : 'reg-chevron-down'}
          />
        )}
      </Button>
    </>
  );
}

export function DropdownContent({
  children,
  position,
  className,
  ...props
}: DropdownContentProps) {
  const { isVisbile, dropdownRef } = useContext(DropDownContext);

  return (
    isVisbile && (
      <>
        <div
          ref={dropdownRef as React.RefObject<HTMLDivElement>}
          className={cn(dropDownContentVariants({ position }), className)}
          {...props}
        >
          {children}
        </div>
        <div className='fixed inset-0 z-40 bg-transparent' />
      </>
    )
  );
}

Dropdown.Toggle = DropdownToggle;
Dropdown.Content = DropdownContent;

export { Dropdown };
