import { z } from 'zod';

import {
  SingleProductDownloadSchema,
  SingleProductAttributesSchema,
  SingleProductBaseResponseSchema,
  SingleProductDetailIndustrySchema,
  SingleProductDetailTechnologySchema,
  SingleProductDetailDynamicPriceSchema,
  SingleProductMediaSchema,
  SingleProductVariantSchema,
} from '../products.common.schema';

const TimestampsSchema = z.object({
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  publishedAt: z.string().optional(),
});

const PricesSchema = z.union([
  z.array(SingleProductDetailDynamicPriceSchema),
  z.null(),
]);
export type TPricesSchema = z.infer<typeof PricesSchema>;

export const SingleProductDetailSchema = z
  .object({
    prices: PricesSchema.nullable().optional(),
    industries: z
      .array(SingleProductDetailIndustrySchema)
      .nullable()
      .optional(),
    technologies: z
      .array(SingleProductDetailTechnologySchema)
      .nullable()
      .optional(),
    medias: z.array(SingleProductMediaSchema).nullable().optional(),
    downloads: z.array(SingleProductDownloadSchema).nullable().optional(),
    variants: z.array(SingleProductVariantSchema).nullable().optional(),
  })
  .merge(SingleProductBaseResponseSchema)
  .merge(SingleProductAttributesSchema)
  .merge(TimestampsSchema);

export const SingleProductDetailRequestSchema = z.string().uuid();

export type TSingleProductDetailRequestSchema = z.infer<
  typeof SingleProductDetailRequestSchema
>;

export const SingleProductDetailResponseSchema = z.object({
  data: SingleProductDetailSchema,
  message: z.string(),
});

export type TSingleProductDetailResponseSchema = z.infer<
  typeof SingleProductDetailSchema
>;
