import React from 'react';
import { IconProps } from '../iconProps';

export const IconWarning: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.2 17.6334L14.0016 3.39556C13.7967 3.04674 13.5042 2.75752 13.1532 2.55656C12.8021 2.3556 12.4046 2.24988 12 2.24988C11.5955 2.24988 11.198 2.3556 10.8469 2.55656C10.4958 2.75752 10.2033 3.04674 9.99847 3.39556L1.80003 17.6334C1.60291 17.9708 1.49902 18.3545 1.49902 18.7453C1.49902 19.136 1.60291 19.5197 1.80003 19.8571C2.00228 20.2081 2.29425 20.4989 2.64599 20.6997C2.99773 20.9005 3.39658 21.0041 3.80159 20.9999H20.1985C20.6032 21.0038 21.0016 20.9 21.353 20.6992C21.7044 20.4984 21.9961 20.2078 22.1982 19.8571C22.3956 19.5199 22.4998 19.1363 22.5001 18.7455C22.5004 18.3548 22.3969 17.9709 22.2 17.6334ZM11.25 9.74994C11.25 9.55103 11.329 9.36026 11.4697 9.21961C11.6104 9.07896 11.8011 8.99994 12 8.99994C12.1989 8.99994 12.3897 9.07896 12.5304 9.21961C12.671 9.36026 12.75 9.55103 12.75 9.74994V13.4999C12.75 13.6989 12.671 13.8896 12.5304 14.0303C12.3897 14.1709 12.1989 14.2499 12 14.2499C11.8011 14.2499 11.6104 14.1709 11.4697 14.0303C11.329 13.8896 11.25 13.6989 11.25 13.4999V9.74994ZM12 17.9999C11.7775 17.9999 11.56 17.934 11.375 17.8103C11.19 17.6867 11.0458 17.511 10.9607 17.3055C10.8755 17.0999 10.8532 16.8737 10.8966 16.6555C10.9401 16.4372 11.0472 16.2368 11.2045 16.0794C11.3619 15.9221 11.5623 15.815 11.7806 15.7716C11.9988 15.7281 12.225 15.7504 12.4305 15.8356C12.6361 15.9207 12.8118 16.0649 12.9354 16.2499C13.059 16.4349 13.125 16.6524 13.125 16.8749C13.125 17.1733 13.0065 17.4595 12.7955 17.6704C12.5845 17.8814 12.2984 17.9999 12 17.9999Z'
        fill={fill}
      />
    </svg>
  );
};
