import { useQuery } from '@tanstack/react-query';

import { AllProductsApi } from '@/services/api/products/all-products';
import { TANSTACK_QUERY_KEY } from '@/utils/constants/query-key.constant';
import { TSingleProductDetailRequestSchema } from '@/schemas/api/products/detail/singleProductDetail.schema';

export const useGetProductById = (
  productId: TSingleProductDetailRequestSchema,
  cartQuantity = false,
) => {
  return useQuery({
    queryKey: [
      TANSTACK_QUERY_KEY.PRODUCTS.PRODUCT_BY_ID,
      productId,
      cartQuantity,
    ],
    queryFn: () => {
      return AllProductsApi.getProductById(productId, cartQuantity)();
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
    gcTime: 0,
    enabled: !!productId,
    select: (data) => data.data,
  });
};
