import React from 'react';
import { IconProps } from '../iconProps';

export const IconOrders: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.97 6.20158L12.72 1.68752C12.4996 1.56572 12.2518 1.50183 12 1.50183C11.7482 1.50183 11.5004 1.56572 11.28 1.68752L3.03 6.20345C2.7944 6.33237 2.59772 6.52217 2.46052 6.75304C2.32331 6.98391 2.25061 7.24739 2.25 7.51595V16.4822C2.25061 16.7508 2.32331 17.0142 2.46052 17.2451C2.59772 17.476 2.7944 17.6658 3.03 17.7947L11.28 22.3106C11.5004 22.4324 11.7482 22.4963 12 22.4963C12.2518 22.4963 12.4996 22.4324 12.72 22.3106L20.97 17.7947C21.2056 17.6658 21.4023 17.476 21.5395 17.2451C21.6767 17.0142 21.7494 16.7508 21.75 16.4822V7.51689C21.7499 7.24785 21.6774 6.98379 21.5402 6.75238C21.403 6.52096 21.206 6.33072 20.97 6.20158ZM12 3.00002L19.5328 7.12502L16.7409 8.6522L9.20813 4.5272L12 3.00002ZM12 11.25L4.46719 7.12502L7.64625 5.38408L15.1791 9.50908L12 11.25ZM20.25 16.486L12.75 20.5913V12.5466L15.75 10.905V14.25C15.75 14.4489 15.829 14.6397 15.9697 14.7803C16.1103 14.921 16.3011 15 16.5 15C16.6989 15 16.8897 14.921 17.0303 14.7803C17.171 14.6397 17.25 14.4489 17.25 14.25V10.0838L20.25 8.4422V16.4822V16.486Z'
        fill={fill}
      />
    </svg>
  );
};
