import { Checkbox, Label } from '@/components';

/**
 * @description FilterCheckboxOption component renders a checkbox option for filtering.
 */
export function FilterCheckboxOption({
  name,
  value,
  label,
  children,
  selectedOptions,
  setSelectedOptions,
}: {
  value: string;
  label: string;
  name: string;
  children?: React.ReactNode;
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedOptions((previous) => {
      return event.target.checked
        ? [...previous, value]
        : previous.filter((item) => item !== value);
    });

  const isChecked = selectedOptions.includes(value);

  return (
    <div className='flex items-center gap-x-2 rounded px-3 py-2 hover:bg-gray-100'>
      <Checkbox
        id={value}
        name={name}
        variant='blue'
        checked={isChecked}
        value={selectedOptions.filter((item) => item === value).toString()}
        onChange={handleChange}
      />
      <Label
        htmlFor={value}
        className={`flex cursor-pointer items-center gap-x-1 text-sm normal-case text-gray-800 ${isChecked ? 'font-semibold' : 'font-normal'}`}
      >
        {children}
        {label}
      </Label>
    </div>
  );
}
