import { z } from 'zod';

import {
  GetCompanyInfoRequestSchema,
  GetCompanyInfoResponseSchema,
  GetCompanyResendInvitationResponseSchema,
} from '@/schemas';
import { api } from '@/utils/helper';
import { API_ENDPOINT, HttpMethods } from '@/utils/constants';
import {
  CompanyDetailsData,
  CompanyDetailsRequestSchema,
  CompanyDetailsResponseSchema,
  CompanyDetailsSchemaResponse,
} from '@/schemas/companyDetails.schema';

const getCompanyById = api<
  z.infer<typeof GetCompanyInfoRequestSchema>,
  z.infer<typeof GetCompanyInfoResponseSchema>
>({
  type: 'public',
  path: API_ENDPOINT.AUTHENTICATION.REGISTER.COMPANY,
  method: HttpMethods.GET,
  requestSchema: GetCompanyInfoRequestSchema,
  responseSchema: GetCompanyInfoResponseSchema,
});

const getCompanyByIdForRegistration = api<
  z.infer<typeof GetCompanyInfoRequestSchema>,
  z.infer<typeof GetCompanyInfoResponseSchema>
>({
  type: 'public',
  path: API_ENDPOINT.COMPANY_REGISTER,
  method: HttpMethods.GET,
  requestSchema: GetCompanyInfoRequestSchema,
  responseSchema: GetCompanyInfoResponseSchema,
});

const getCompanyDetails = api<
  z.infer<typeof CompanyDetailsRequestSchema>,
  z.infer<typeof CompanyDetailsResponseSchema>
>({
  path: API_ENDPOINT.COMPANY.DETAILS,
  method: HttpMethods.GET,
  requestSchema: CompanyDetailsRequestSchema,
  responseSchema: CompanyDetailsResponseSchema,
});

const updateCompanyDetails = (id: string) =>
  api<
    z.infer<typeof CompanyDetailsData>,
    z.infer<typeof CompanyDetailsSchemaResponse>
  >({
    method: 'PATCH',
    path: `${API_ENDPOINT.COMPANY.COMPANIES}/${id}`,
    requestSchema: CompanyDetailsData,
    responseSchema: CompanyDetailsSchemaResponse,
  });

const resendRegistrationLink = (companyId: string) =>
  api({
    type: 'public',
    path: API_ENDPOINT.COMPANY_REGISTER_RESEND,
    method: HttpMethods.GET,
    requestSchema: GetCompanyInfoRequestSchema,
    responseSchema: GetCompanyResendInvitationResponseSchema,
  })('', companyId); // Pass undefined for the request payload

export const CompanyAPI = {
  getCompanyById,
  getCompanyByIdForRegistration,
  getCompanyDetails,
  updateCompanyDetails,
  resendRegistrationLink,
};
