export const ORDER_CONSTANTS = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
} as const;

export const ORDER_CONSTANTS_KEYS = [
  ORDER_CONSTANTS.PENDING,
  ORDER_CONSTANTS.PROCESSING,
  ORDER_CONSTANTS.COMPLETED,
  ORDER_CONSTANTS.CANCELLED,
] as const;

export type OrderStatusType = (typeof ORDER_CONSTANTS_KEYS)[number];
