import { TUser } from '@/schemas';
import { useAlert, useTable, useGetAllUser } from '@/components/hooks';
import { useUserColumn } from '@/pages/users-and-teams/users/UserColumn';
import { Alert, DataTable, Pagination, UserTableSkeleton } from '@/components';
import {
  ActionBar,
  ActionButton,
} from '@/pages/users-and-teams/users/ActionBar';
import { ErrorFound } from '@/components/ui/no-result/Error';

function UserTable({
  users,
  totalPages,
  totalUsers,
}: {
  users: TUser[];
  totalPages: number;
  totalUsers: number;
}) {
  const { columns } = useUserColumn();
  const { table } = useTable(columns, users, 'id');

  return (
    <>
      <ActionButton table={table} />
      <DataTable
        table={table}
        columns={columns}
      />
      <Pagination
        totalCount={totalUsers}
        totalPages={totalPages}
      />
    </>
  );
}

const Users = () => {
  const { data: userList, isLoading, isError } = useGetAllUser();

  const { isAlertVisible, isAlertDismissed, alertMessage, isType } = useAlert();
  return (
    <div className='personal-details-wrapper'>
      {isAlertVisible && !isAlertDismissed && (
        <Alert
          type={isType}
          classes='mb-4'
          title={alertMessage}
        />
      )}
      <ActionBar />
      {(() => {
        switch (true) {
          case isLoading:
            return <UserTableSkeleton />;
          case isError:
            return <ErrorFound />;
          default:
            return (
              <UserTable
                users={userList?.users ?? []}
                totalUsers={userList?.totalUsers ?? 0}
                totalPages={userList?.totalPages ?? 0}
              />
            );
        }
      })()}
    </div>
  );
};
export default Users;
