export const PAGINATION_CONSTANTS = {
  PAGE: 'page',
  PAGE_LIMIT: 'limit',
  DEFAULT_PAGE_LIMIT: 10,
  MAX_PAGE_LIMIT: 100,
  PAGE_LIMIT_OPTIONS: [10, 50, 100],
};

export const PRODUCTS_PAGINATION_CONSTANTS = {
  PAGE: 'page',
  PAGE_LIMIT: 'limit',
  DEFAULT_PAGE_LIMIT: 12,
  MAX_PAGE_LIMIT: 100,
  PAGE_LIMIT_OPTIONS: [12, 48, 100],
};
