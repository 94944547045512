import React from 'react';
import { IconProps } from '../iconProps';

export const IconArrowCounterClockwise: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 12C21.0002 14.3662 20.0686 16.6372 18.4069 18.3216C16.7451 20.006 14.4869 20.9682 12.1209 21H12C9.70147 21.0057 7.48901 20.1261 5.82187 18.5438C5.75028 18.476 5.69273 18.3949 5.6525 18.3049C5.61227 18.215 5.59016 18.118 5.58741 18.0195C5.58467 17.921 5.60136 17.8229 5.63652 17.7309C5.67168 17.6388 5.72463 17.5546 5.79234 17.483C5.86006 17.4114 5.94121 17.3538 6.03116 17.3136C6.12112 17.2734 6.21812 17.2513 6.31662 17.2485C6.41512 17.2458 6.5132 17.2625 6.60525 17.2976C6.69731 17.3328 6.78153 17.3857 6.85312 17.4534C7.92544 18.4647 9.27195 19.1376 10.7245 19.3881C12.177 19.6385 13.6711 19.4554 15.0202 18.8617C16.3692 18.2679 17.5134 17.2898 18.3098 16.0495C19.1062 14.8092 19.5195 13.3618 19.4981 11.888C19.4766 10.4142 19.0214 8.97943 18.1892 7.76285C17.3571 6.54628 16.1849 5.60187 14.8192 5.04761C13.4534 4.49334 11.9546 4.3538 10.51 4.6464C9.06537 4.939 7.739 5.65078 6.69656 6.69282C6.6889 6.70111 6.68076 6.70893 6.67219 6.71626L4.18031 9.00001H6.75C6.94891 9.00001 7.13968 9.07903 7.28033 9.21968C7.42098 9.36033 7.5 9.5511 7.5 9.75001C7.5 9.94892 7.42098 10.1397 7.28033 10.2803C7.13968 10.421 6.94891 10.5 6.75 10.5H2.25C2.05109 10.5 1.86032 10.421 1.71967 10.2803C1.57902 10.1397 1.5 9.94892 1.5 9.75001V5.25001C1.5 5.0511 1.57902 4.86033 1.71967 4.71968C1.86032 4.57903 2.05109 4.50001 2.25 4.50001C2.44891 4.50001 2.63968 4.57903 2.78033 4.71968C2.92098 4.86033 3 5.0511 3 5.25001V8.04376L5.64844 5.62501C6.90842 4.36992 8.512 3.51626 10.2567 3.17179C12.0015 2.82732 13.8092 3.00748 15.4516 3.68953C17.0941 4.37158 18.4976 5.52495 19.4851 7.00402C20.4726 8.4831 20.9997 10.2216 21 12Z'
        fill={fill}
      />
    </svg>
  );
};
