import { Skeleton } from '@/components/lib/Skeleton';
export function InputSkeletons({ height = 50 }: { height?: number }) {
  return (
    <div
      className='w-full'
      data-cy='product-card-skeleton'
    >
      <div className='h-full animate-pulse'>
        <Skeleton className={`h-[${height}px] w-full bg-gray-200`} />
      </div>
    </div>
  );
}
