import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { IconSpinner } from '@/components/ui/icons/regular/spinner';

import {
  CompanyRegistrationFormSchema,
  TCompanyRegistrationFormSchema,
  TCountriesResponseSchema,
  TSingleCountry,
} from '@/schemas';
import {
  scrollToTop,
  useAlert,
  useCompanyRegistration,
  useGetCachedQueryData,
  useGetCompanyDetailsById,
  useURLSearchParams,
  useVerifyToken,
} from '@/components/hooks';
import {
  Button,
  CountrySelector,
  Input,
  Label,
  PasswordInput,
  Alert,
  StateSelector,
  InvitationLoadingState,
  PhoneNumberInput,
} from '@/components';
import {
  QUERY_CONSTANTS,
  ROUTES,
  V12_PRIVACY_POLICY_URL,
  V12_TERMS_AND_CONDITIONS_URL,
} from '@/utils/constants';
import WaitingForApproval from './WaitingForApproval';
import { handleApiError } from '@/utils/helper';
import { useErrorHandler } from '@/components/hooks/utils/useErrorHandler';

export interface ErrorResponse {
  message?: string;
}

interface CompleteRegistrationFormLeftSectionProps {
  detail: string;
  information: string;
}

const CompleteRegistrationFormLeftSection: React.FC<
  CompleteRegistrationFormLeftSectionProps
> = ({ detail, information }) => {
  return (
    <div className='mr-6 flex flex-col gap-2'>
      <div className='text-xl font-bold'>{detail}</div>
      <div className='text-sm text-gray-600'>{information}</div>
      <div className='text-sm italic text-gray-400'>
        (All fields are mandatory)
      </div>
    </div>
  );
};

export interface CompanyDetailError {
  message?: string | null;
}

const CompleteRegistrationFormCompanyDetails = () => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<TCompanyRegistrationFormSchema>();
  const { showAlert } = useAlert();
  const { searchValue: companyId } = useURLSearchParams(QUERY_CONSTANTS.ID);
  const { searchValue: code } = useURLSearchParams(QUERY_CONSTANTS.CODE);

  const [isCountryPickerOpen, setIsCountryPickerOpen] =
    useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('GB');

  const countries = useGetCachedQueryData<TCountriesResponseSchema | undefined>(
    'countries',
  );

  const countriesMap = countries
    ? new Map(countries.map((country) => [country.iso2, country]))
    : new Map<string, TCountriesResponseSchema>();

  const selectedCountryDetail: TSingleCountry = (countriesMap.get(
    selectedCountry,
  ) as TSingleCountry | undefined) ?? {
    capital: 'London',
    currency: 'GBP',
    emoji: '🇬🇧',
    id: 826,
    iso2: 'GB',
    iso3: 'GBR',
    name: 'United Kingdom',
    native: 'United Kingdom',
    phonecode: '44',
  };

  const {
    data: companyInfo,
    isSuccess,
    error: getCompanyDetailsByIdError,
  } = useGetCompanyDetailsById(companyId, code, 'registration');

  useEffect(() => {
    if (isSuccess) {
      setValue('name', companyInfo.payload.companyName);
      setValue('email', companyInfo.payload.companyEmail);
      setValue('accountEmail', companyInfo.payload.companyEmail);
    }
  }, [isSuccess]);

  useEffect(() => {
    showAlert(handleApiError(getCompanyDetailsByIdError), 'error');
  }, [getCompanyDetailsByIdError]);

  return (
    <div className='mb-8 mt-8 grid grid-cols-[366px_auto]'>
      <div className='mr-6 flex flex-col gap-2'>
        <CompleteRegistrationFormLeftSection
          detail='Company Details'
          information="Provide your company's contact information."
        />
      </div>
      <div className='flex flex-col gap-4'>
        <div className='space-y-1'>
          <Label htmlFor='companyName'>Company Name</Label>
          <Input
            type='text'
            name='name'
            placeholder='Enter company name'
          />
        </div>
        <div className='space-y-1'>
          <Label htmlFor='companyEmail'>Company Email</Label>
          <Input
            type='email'
            name='email'
            placeholder='Enter company email'
            disabled
          />
        </div>
        <div className='space-y-1'>
          <Label htmlFor='companyWebsite'>Website</Label>
          <Input
            type='text'
            name='companyWebsite'
            placeholder='Enter company website'
          />
        </div>
        <div className='space-y-1'>
          <Label htmlFor='companyBusinessRegistrationNumber'>
            Business Registration Number
          </Label>
          <Input
            type='text'
            className='remove-apperance'
            name='companyBusinessRegistrationNumber'
            placeholder='Enter company business registration number'
          />
        </div>
        <CountrySelector<TCompanyRegistrationFormSchema>
          label='Country'
          name='country'
          errors={errors}
          control={control}
          errorFieldName='country'
          id={'country-selector'}
          open={isCountryPickerOpen}
          onToggle={() => {
            setIsCountryPickerOpen(!isCountryPickerOpen);
            if (selectedCountry !== watch('country')) {
              setValue('state', '');
            }
          }}
          handleCountryChange={setSelectedCountry}
          selectedValue={selectedCountryDetail}
        />
        <div className='flex justify-between gap-8'>
          <div className='w-full space-y-1'>
            <StateSelector<TCompanyRegistrationFormSchema>
              label='State'
              name='state'
              errors={errors}
              control={control}
              errorFieldName='state'
              selectedCountry={selectedCountry}
            />
          </div>
          <div className='w-full space-y-1'>
            <Label htmlFor='suburb'>City / Suburbs</Label>
            <Input
              type='text'
              name='suburb'
              placeholder='Enter city/suburbs name'
            />
          </div>
        </div>
        <div className='space-y-1'>
          <Label htmlFor='companyPhoneNumber'>Phone Number</Label>
          <PhoneNumberInput
            name='companyPhoneNumber'
            country={selectedCountry}
          />
        </div>
      </div>
    </div>
  );
};

const CompleteRegistrationFormAccountDetails = () => {
  return (
    <div className='mt-8 grid grid-cols-[366px_auto]'>
      <div className='mr-6 flex flex-col gap-2'>
        <CompleteRegistrationFormLeftSection
          detail='Account Details'
          information='Enter the account details , This will be your ownership account'
        />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='space-y-1'>
          <Label htmlFor='accountEmail'>Email</Label>
          <Input
            type='text'
            name='accountEmail'
            placeholder='Enter your email'
            disabled
          />
        </div>
        <div className='space-y-1'>
          <Label htmlFor='accountPassword'>Password</Label>
          <PasswordInput<TCompanyRegistrationFormSchema>
            name='accountPassword'
            errorFieldName='accountPassword'
            placeholder='Enter your password'
          />
        </div>
      </div>
    </div>
  );
};

const CompleteRegistrationForms = () => {
  return (
    <>
      <CompleteRegistrationFormCompanyDetails />
      <hr />
      <CompleteRegistrationFormAccountDetails />
    </>
  );
};

const CompleteRegistrationSubmit = ({
  isRegistrationLoading,
}: {
  isRegistrationLoading: boolean;
}) => {
  return (
    <div className='grid grid-cols-[366px_auto] py-6'>
      <div />
      <div>
        <div className='text-gray-600'>
          By clicking &lsquo;Complete Registration&rsquo;, you agree to your
          acceptance of our
        </div>
        <a
          className='text-primary-600'
          href={V12_TERMS_AND_CONDITIONS_URL}
          target='_blank'
          rel='noreferrer'
        >
          Terms
        </a>
        <div className='text-primary-600'>and</div>
        <a
          className='text-primary-600'
          href={V12_PRIVACY_POLICY_URL}
          target='_blank'
          rel='noreferrer'
        >
          Privacy Policy.
        </a>
        <Button
          size='md'
          className='mt-3 w-full'
          type='submit'
          disabled={isRegistrationLoading}
        >
          {isRegistrationLoading && (
            <IconSpinner
              fill='white'
              size={24}
            />
          )}
          Complete Registration
        </Button>
      </div>
    </div>
  );
};

const CompleteRegistrationHeader = () => {
  return (
    <section>
      <h4>Complete your registration</h4>
      <div className='mb-8 mt-2 text-sm text-gray-600'>
        Once you have completed the registration process, a confirmation link
        will be sent to your email inbox.
      </div>
    </section>
  );
};

const CompleteRegistration = () => {
  const [isApprovalPage, setShowApprovalPage] = useState(false);
  const navigate = useNavigate();

  const { companyId, token } = useParams<{
    companyId: string;
    token: string;
  }>();

  const completeRegistrationMethod = useForm<TCompanyRegistrationFormSchema>({
    mode: 'all',
    defaultValues: {
      name: '',
      email: '',
      companyWebsite: 'https://',
      companyBusinessRegistrationNumber: undefined,
      country: 'GB',
      state: '',
      suburb: '',
      companyPhoneNumber: undefined,
      accountEmail: '',
      accountPassword: '',
      registrationComplete: true,
    },
    resolver: zodResolver(CompanyRegistrationFormSchema),
  });
  const { handleSubmit, getValues, setValue } = completeRegistrationMethod;
  const { isAlertVisible, isAlertDismissed, alertMessage, isType } = useAlert(); // Use the custom hook

  const { showAlert } = useAlert();

  // Add useVerifyToken hook
  const { mutate: verifyToken, isPending, isSuccess } = useVerifyToken();

  const { mutate: handleRegistration, isPending: isRegistrationLoading } =
    useCompanyRegistration();

  useEffect(() => {
    // Trigger token verification when the component loads
    if (companyId && token) {
      verifyToken(
        { companyId, token },
        {
          onSuccess: (response) => {
            const { name, email } = response;
            // Set the name and email in the form
            setValue('name', name ?? '');
            setValue('email', email ?? '');
            setValue('accountEmail', email ?? '');
          },
          onError: (error) => {
            navigate(`/${ROUTES.LOGIN}`);
            setTimeout(() => {
              showAlert(handleApiError(error), 'error');
            }, 150);
          },
        },
      );
    }
  }, [companyId, token]);
  const { handleError } = useErrorHandler();
  const onSubmit = () => {
    const {
      name,
      email,
      companyWebsite,
      companyBusinessRegistrationNumber,
      country,
      state,
      suburb,
      companyPhoneNumber,
      accountPassword,
      accountEmail,
    } = getValues();

    const companyData = {
      token,
      name,
      email,
      user: {
        email: accountEmail,
        password: accountPassword,
      },
      website: companyWebsite,
      registrationNumber: companyBusinessRegistrationNumber,
      country,
      state: String(state),
      city: suburb,
      phone: companyPhoneNumber.split('-')[1],
      countryCode: country,
      registrationComplete: true,
    };

    handleRegistration(
      { companyData, companyId },
      {
        onSuccess: () => {
          setShowApprovalPage(true);
        },
        onError: (error) => {
          scrollToTop();
          handleError(error as AxiosError<unknown, string>);
        },
      },
    );
  };

  if (isPending) {
    return (
      <div className='flex h-screen items-center justify-center'>
        {/* Full screen loading */}
        <InvitationLoadingState />
      </div>
    );
  }

  return (
    <>
      {isAlertVisible && !isAlertDismissed && (
        <Alert
          type={isType}
          classes='mt-4 mb-1.5'
          title={alertMessage}
        />
      )}
      {isSuccess && (
        <div className='container max-w-5xl py-10'>
          <CompleteRegistrationHeader />
          <hr />
          {isApprovalPage ? (
            <WaitingForApproval />
          ) : (
            <FormProvider {...completeRegistrationMethod}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CompleteRegistrationForms />
                <CompleteRegistrationSubmit
                  isRegistrationLoading={isRegistrationLoading}
                />
              </form>
            </FormProvider>
          )}
        </div>
      )}
    </>
  );
};

export default CompleteRegistration;
