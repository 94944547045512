import { Role, Status } from '@/schemas';
import {
  TDate,
  TShoeSize,
  TTimeFormat,
} from '@/schemas/systemSetting/systemSetting.schema';
import { clearLocalStorage } from '@/utils/helper';
import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

/**
 * @todo This file new store should replace the old store after full migration
 */
interface Auth {
  token?: string;
  role?: string;
  isTwoFactorSet?: boolean | undefined | null;
  mfaType?: string | undefined | null;
  hasUserDetail?: boolean | undefined | null;
  valid?: boolean;
}

interface User {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  designation?: string;
  role?: Role;
  phone?: string | null;
  countryCode?: string | null;
  avatar?: string | null;
  accountStatus?: Status;
  isInvited?: boolean;
}

interface SystemDefault {
  timezone?: string;
  currency?: string;
  timeFormat?: TTimeFormat;
  dateFormat?: TDate;
  shoeSizeType?: TShoeSize;
}

interface UserDetails {
  auth: Auth | null;
  user: User | null;
  systemDefault: SystemDefault | null;
  setAuth: (credential: Auth) => void;
  updateAuth: (credential: Partial<Auth>) => void;
  setUser: (user: User) => void;
  updateUser: (user: Partial<User>) => void;
  setSystemDefault: (systemDefault: SystemDefault) => void;
  updateSystemDefault: (systemDefault: Partial<SystemDefault>) => void;
  removeStorage: () => void;
}

const STORAGE_KEY = 'user';

const userStoreSlice: StateCreator<UserDetails> = (set) => ({
  auth: null,
  user: null,
  systemDefault: null,
  setAuth: (credential) => set({ auth: credential }),
  updateAuth: (credential: Partial<Auth>) =>
    set((state) => ({
      auth: {
        ...state.auth,
        ...credential,
      },
    })),
  setUser: (user) => set({ user }),
  updateUser: (user: Partial<User>) =>
    set((state) => ({
      user: {
        ...state.user,
        ...user,
      },
    })),
  setSystemDefault: (systemDefault) => set({ systemDefault }),
  updateSystemDefault: (systemDefault: Partial<SystemDefault>) =>
    set((state) => ({
      systemDefault: {
        ...state.systemDefault,
        ...systemDefault,
      },
    })),
  removeStorage: () => {
    set({ auth: null, user: null, systemDefault: null });
    clearLocalStorage();
  },
});

const persistedUserStore = persist<UserDetails>(userStoreSlice, {
  name: STORAGE_KEY,
});

export const useUserStore = create(persistedUserStore);
