import { z } from 'zod';

import { emailSchema, phoneNumberSchema } from '@/schemas';
import { PASSWORD_REGEX } from '@/utils/constants';

// before company verification
export const RegisterCompanyFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Company Name is required' })
    .refine((value) => value.length > 0, {
      message: 'Company Name cannot be empty or just spaces',
    }),
  email: emailSchema,
});

export type TRegisterCompanyFormSchema = z.infer<
  typeof RegisterCompanyFormSchema
>;

// after company verification
export const CompanyRegistrationFormSchema = RegisterCompanyFormSchema.extend({
  companyWebsite: z
    .string()
    .min(1, { message: 'Website is required' })
    .url({ message: 'Enter a valid URL' }),
  companyBusinessRegistrationNumber: z
    .string()
    .min(1, { message: 'Business Registration Number is required' })
    .min(6, { message: 'Minimum Business Registration Number length is 6' })
    .max(10, { message: 'Maximum Business Registration Number length is 10' })
    .regex(/^[a-zA-Z0-9]*$/, {
      message: 'Business Registration Number must be alphanumeric',
    }),
  country: z.string().min(1, { message: 'Country is required' }),
  state: z.union([z.number(), z.string()]).optional(),
  suburb: z.string().min(1, { message: 'Suburb is required' }),
  companyPhoneNumber: phoneNumberSchema,
  accountEmail: emailSchema,
  accountPassword: z
    .string()
    .min(1, { message: 'Password is required' })
    .max(15, { message: 'Password over 15 characters' })
    .regex(PASSWORD_REGEX, {
      message: 'This password is not valid',
    }),
  registrationComplete: z.boolean(),
});

export type TCompanyRegistrationFormSchema = z.infer<
  typeof CompanyRegistrationFormSchema
>;
