import { useEffect, useState } from 'react';
import { Form, useSearchParams } from 'react-router-dom';

import { isEmpty } from '@/utils/helper';
import { InputSkeletons, Label } from '@/components';
import { EmptyMessage } from '@/components/ui/empty-message';
import { HttpMethods, QUERY_CONSTANTS } from '@/utils/constants';
import { ApplyButton, FilterGroup, handleChange } from '../index';
import { ProductFilterKeys, useProductFilterStore } from '@/store';
import { ProductFilterStateKeys } from '@/store/product-filter.store';
import { useFilterSubmit, useGetAllProducts } from '@/components/hooks';

export const ShoeSizeFilter = () => {
  const {
    toggleFilter,
    showShoeSizeOptions,
    setProductFilter,
    shoeSizeSelected,
  } = useProductFilterStore();

  const {
    selectedOptions,
    setSelectedOptions,
    handleSubmit,
    isButtonDisabled,
    defaultSearchParams: urlParams,
  } = useFilterSubmit(QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_SIZE);

  const { data: allProductsData, isLoading: isAllProductDataLoading } =
    useGetAllProducts();

  const shoeSizeData = allProductsData?.filterable?.sizes;

  const [searchParams, setSearchParams] = useSearchParams();
  const [disableAnimation, setDisableAnimation] = useState(false);

  const handleShoeSizeOptionClick = (id: string) => {
    handleChange(
      id,
      shoeSizeSelected,
      setSelectedOptions,
      setProductFilter,
      ProductFilterStateKeys.ShoeSizeOptionsSelected,
    );
    searchParams.delete(QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_SIZE, id);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const showApplyButton = () => {
    return (
      !isButtonDisabled &&
      selectedOptions.length === shoeSizeSelected.length &&
      selectedOptions.length !== urlParams.length
    );
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableAnimation(true);
    try {
      handleSubmit(event);
    } finally {
      setDisableAnimation(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedOptions)) {
      if (!showShoeSizeOptions) {
        toggleFilter(ProductFilterKeys.ShoeSizeOptions);
      }
      setProductFilter(
        ProductFilterStateKeys.ShoeSizeOptionsSelected,
        selectedOptions,
      );
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (!isEmpty(shoeSizeSelected)) {
      setSelectedOptions(shoeSizeSelected);
    }
  }, [searchParams]);

  return (
    <FilterGroup
      title='Size (UK)'
      isActive={showShoeSizeOptions}
      toggleFilter={() => toggleFilter(ProductFilterKeys.ShoeSizeOptions)}
    >
      {isAllProductDataLoading ? (
        <InputSkeletons height={36} />
      ) : isEmpty(shoeSizeData) ? (
        <EmptyMessage
          message='Shoe sizes not available'
          variant='400'
        />
      ) : (
        <Form
          method={HttpMethods.GET}
          onSubmit={onSubmitHandler}
        >
          <div className='grid grid-cols-5 gap-3'>
            {shoeSizeData?.map((shoeSize) => (
              <Label
                key={shoeSize.size}
                className={`grid h-9 cursor-pointer place-content-center rounded border border-gray-300 p-3 text-sm font-bold ${
                  shoeSizeSelected.includes(shoeSize.size)
                    ? 'bg-gray-800 text-white'
                    : 'text-gray-900 hover:bg-gray-800 hover:text-white'
                }`}
              >
                <input
                  type='checkbox'
                  className='hidden'
                  name={QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_SIZE}
                  value={shoeSize.size}
                  checked={shoeSizeSelected.includes(shoeSize.size)}
                  onChange={() => {
                    handleShoeSizeOptionClick(shoeSize.size);
                  }}
                  data-cy={shoeSize.size}
                />
                {shoeSize.size}
              </Label>
            ))}
          </div>
          <ApplyButton
            showApplyButton={showApplyButton}
            disableAnimation={disableAnimation}
          />
        </Form>
      )}
    </FilterGroup>
  );
};
