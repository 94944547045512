import { useNavigate, useParams } from 'react-router-dom';

import { Alert, Avatar, Button, Modal } from '@/components';
import { ActionBar } from '@/pages/orders/my-orders/order-details/ActionBar';
import { LineItems } from '@/pages/orders/my-orders/order-details/LineItems';
import { OrderSummary } from '@/pages/orders/my-orders/order-details/OrderSummary';
import { DeliveryBilling } from '@/pages/orders/my-orders/order-details/DeliveryBillingDetails';
import { scrollToTop, useAlert } from '@/components/hooks';
import { useGetOrderDetail } from '@/components/hooks/api/orders/userGetOrderDetailById';
import { transformOrderDetail } from '@/utils/helper/transformOrderDatail.helper';
import { LoadingContent } from '@/components/ui/loading/Loading';
import { ErrorFound } from '@/components/ui/no-result/Error';
import { useState } from 'react';
import { useReOrderProduct } from '@/components/hooks/api/orders/userReorderProduct';
import { ROUTES } from '@/utils/constants';
import { handleApiError } from '@/utils/helper';
interface OrderDetailProps {
  label: string;
  value: string;
  isStatus?: boolean;
  avatar?: string;
}

const OrderDetail: React.FC<OrderDetailProps> = ({
  label,
  value,
  isStatus = false,
  avatar,
}) => (
  <div className='space-y-2'>
    <p className='text-sm'>{label}</p>
    {isStatus ? (
      <span className='inline-block rounded bg-green-100 px-2 py-1 text-xs font-medium text-green-700'>
        {value}
      </span>
    ) : (
      <div className='flex items-center space-x-3'>
        {label === 'Ordered By' && (
          <Avatar
            avatar={[
              {
                imageSrc: avatar,
                name: value,
              },
            ]}
            defaultAvatarLetter={value.charAt(0).toUpperCase()}
            size={22}
          />
        )}

        <div>
          <p className='text-base font-bold text-gray-800'>{value}</p>
        </div>
      </div>
    )}
  </div>
);

export default function OrderDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { isAlertVisible, isAlertDismissed, alertMessage, isType, showAlert } =
    useAlert();
  const { data: rawOrderDetail, isError, isLoading } = useGetOrderDetail(id!);
  const orderDetail = rawOrderDetail?.data
    ? transformOrderDetail(rawOrderDetail.data)
    : null;

  const [showReOrderModal, setShowReOrderModal] = useState(false);

  const { mutate: reOrderProduct, isPending: isReOrdering } =
    useReOrderProduct();

  const handleReOrder = () => {
    if (id) {
      reOrderProduct(id, {
        onSuccess: () => {
          setShowReOrderModal(false);
          navigate(`/${ROUTES.CART}`);
        },
        onError: (error) => {
          setShowReOrderModal(false);
          showAlert(handleApiError(error), 'error');
          scrollToTop();
        },
      });
    }
  };

  if (isLoading) return <LoadingContent />;

  if (isError) return <ErrorFound />;

  return (
    <>
      {isAlertVisible && !isAlertDismissed && (
        <Alert
          type={isType}
          classes='mb-4'
          title={alertMessage}
        />
      )}
      <ActionBar
        customId={orderDetail?.orderInfo.customId ?? ''}
        setShowReOrderModal={setShowReOrderModal}
      />
      <div className='grid bg-white p-4 shadow-sm md:grid-cols-1'>
        <div className='flex justify-between'>
          <OrderDetail
            label='Order Date'
            value={orderDetail?.orderInfo.orderDate ?? ''}
          />
          <OrderDetail
            label='Ordered By'
            value={orderDetail?.orderInfo.orderedBy ?? ''}
            avatar={orderDetail?.orderInfo.orderedByAvatar}
          />
          <OrderDetail
            label='Order Progress'
            value={orderDetail?.orderInfo.orderProgress ?? ''}
          />
          <OrderDetail
            label='Payment Status'
            value={orderDetail?.orderInfo.paymentStatus ?? ''}
            isStatus
          />
          <OrderDetail
            label='Total Amount'
            value={`£${orderDetail?.orderInfo.totalAmount.toLocaleString(
              'en-GB',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },
            )}`}
          />
        </div>
      </div>
      <DeliveryBilling
        deliveryInfo={{
          fullName: orderDetail?.deliveryInfo.fullName,
          location: orderDetail?.deliveryInfo.location,
          address: orderDetail?.deliveryInfo.address,
          contact: orderDetail?.deliveryInfo.contact,
          countryCode: orderDetail?.deliveryInfo.countryCode,
        }}
        billingInfo={{
          fullName: orderDetail?.billingInfo.fullName,
          location: orderDetail?.billingInfo.location,
          address: orderDetail?.billingInfo.address,
          contact: orderDetail?.billingInfo.contact,
          countryCode: orderDetail?.billingInfo.countryCode,
        }}
      />
      <LineItems items={orderDetail?.lineItems} />
      <OrderSummary
        summary={orderDetail?.orderSummary}
        setShowReOrderModal={setShowReOrderModal}
        customId={orderDetail?.orderInfo.customId ?? ''}
      />
      <Modal
        showModal={showReOrderModal}
        setShowModal={setShowReOrderModal}
      >
        <Modal.Content className='lg:max-w-[600px]'>
          <Modal.Title>
            <h6>Re-Order</h6>
          </Modal.Title>
          <Modal.Body className='space-y-4'>
            <p className='text-base text-gray-800'>
              You are about to re-order product from order ID{' '}
              <span className='font-bold'>
                #{orderDetail?.orderInfo.customId}
              </span>
              . Are you sure you want to continue to re-order?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Modal.CloseButton>Cancel</Modal.CloseButton>
            <Button
              onClick={handleReOrder}
              disabled={isReOrdering}
            >
              {isReOrdering ? 'Processing...' : 'Confirm Reorder'}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}
