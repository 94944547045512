import { useFormContext } from 'react-hook-form';

import RecoveryLinkSentIllustration from '@/assets/images/illustration/recoveryLinkSent.svg';

import { TForgotPasswordFormSchema } from '@/schemas';
import ImageComponent from '@/components/ui/image/Image';

const RecoveryLinkSentBody = ({ maskedEmail }: { maskedEmail: string }) => {
  return (
    <>
      <h4 className='mb-4 text-center'>Recovery Link sent</h4>
      <div className='text-center'>
        Kindly check your inbox for an email sent to{' '}
        <span className='font-bold text-primary-700'>{maskedEmail}.</span>
        &nbsp;Click the link in the email to reset your password.
      </div>
    </>
  );
};

const RecoveryLinkSent = () => {
  const { getValues } = useFormContext<TForgotPasswordFormSchema>();

  const email = getValues('email');
  const [localPart] = email.split('@');
  const maskedEmail = `${localPart.charAt(0)}***@******.com`;

  return (
    <div className='flex w-[496px] flex-col'>
      <ImageComponent
        src={RecoveryLinkSentIllustration}
        alt='Recovery Link Sent illustration'
        classes='w-[400px] h-[240px] mb-8 ml-auto mr-auto'
      />
      <RecoveryLinkSentBody maskedEmail={maskedEmail} />
    </div>
  );
};

export default RecoveryLinkSent;
