import { z } from 'zod';

export const RangeSliderSchema = z
  .object({
    minValue: z.coerce
      .number()
      .nonnegative({ message: 'Minimum Number should be positive' })
      .min(1, { message: 'Minimum Number is required' }),
    maxValue: z.coerce
      .number()
      .positive({ message: 'Maximum Number should be positive' })
      .min(1, { message: 'Maximum Number is required' }),
  })
  .superRefine((arg, ctx) => {
    if (arg.minValue >= arg.maxValue) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Minimum Number cannot be greater than Maximum Number',
        path: ['minValue'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Minimum Number cannot be greater than Maximum Number',
        path: ['maxValue'],
      });
    }
  });

export type TRangeSliderSchema = z.infer<typeof RangeSliderSchema>;
