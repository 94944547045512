/**
 * Checks if a given value is empty.
 *
 * The function considers the following values as empty:
 * - `null`
 * - `undefined`
 * - An empty string (after trimming whitespace)
 * - An empty array
 * - An empty object (i.e., an object with no own properties)
 *
 * @param obj - The value to check for emptiness. Can be of any type.
 * @returns `true` if the value is empty, `false` otherwise.
 */

export const isEmpty = <T>(obj: T | null | undefined): boolean => {
  if (
    obj === null ||
    obj === undefined ||
    (typeof obj === 'number' && isNaN(obj))
  )
    return true;

  if (typeof obj === 'string' && obj.trim().length === 0) return true;
  if (Array.isArray(obj) && obj.length === 0) return true;
  if (typeof obj === 'object' && Object.keys(obj).length === 0) return true;

  return false;
};

/**
 * Formats a user name by capitalizing each part of the name.
 *
 * The function splits the input string by dots ('.') and capitalizes
 * the first letter of each part while making the rest of the letters lowercase.
 * The formatted parts are then joined with a space.
 *
 * For example:
 * - `'marie.curie'` will be formatted as `'Marie Curie'`.
 * - `'antonio.moreno'` will be formatted as `'Antonio Moreno'`.
 * - `'hans'` will be formatted as `'Hans'`.
 *
 * @param name - The input name string, which can contain one or more parts separated by dots.
 * @returns The formatted name string with each part capitalized and joined by spaces.
 */
export const getUserFullName = (name: string): string => {
  return name
    .split('.')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');
};

/**
 * Returns the provided image URL if it is valid, otherwise returns a fallback URL.
 *
 * @param url - The URL of the image to load. Can be `null` or `undefined`.
 * @param fallback - The fallback URL to use if the image fails to load.
 * @returns image.
 */
export const getImageOrFallback = (
  url: string | null | undefined,
  fallback: string,
): Promise<string> => {
  return new Promise((resolve) => {
    if (!url) {
      resolve(fallback);
      return;
    }

    const img = new Image();
    img.src = url;
    img.onload = () => resolve(url);
    img.onerror = () => resolve(fallback);
  });
};

/**
 * Sorts an array of objects by a specified key in ascending order.
 *
 * @param array - The array of objects to be sorted.
 * @param key - The key within each object to sort by. The values associated with this key should be numbers.
 * @returns A new array sorted by the specified key in ascending order.
 */
export const sortArray = <T>(array: T[], key: keyof T) => {
  return [...array].sort((a, b) => {
    const valueA = a[key] as unknown as number;
    const valueB = b[key] as unknown as number;
    return valueA - valueB;
  });
};

/**
 * Shuffles an array randomly using the Fisher-Yates algorithm.
 *
 * @param array - The array to shuffle.
 * @returns A new array with the elements shuffled.
 */
export const fisherYatesShuffle = <T>(array: T[]): T[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1));
    [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
  }
  return array;
};
