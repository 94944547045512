import { useEffect, useState } from 'react';

import shoeAsset from '@/assets/images/Image.png';

import { getImageOrFallback } from '@/utils/helper';

export function useImageFallback(
  imageUrl: string | null,
  fallbackImage: string = shoeAsset,
) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const loadImage = async () => {
      const image = await getImageOrFallback(imageUrl, fallbackImage);
      setImageSrc(image);
    };
    loadImage();
  }, [imageUrl, fallbackImage]);

  return imageSrc;
}
