import { useAlert, useValidate2faReset } from '@/components/hooks';
import { Generate2FAPage } from './Generate2FAPage';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES, TwoFaResetValidate } from '@/utils/constants';
import { TwoFaResetVerifyLoadingState } from '@/components';

const TwoFaValidateResetPage = () => {
  const navigate = useNavigate();

  const { showAlert } = useAlert();
  const { userId } = useParams<{
    userId: string;
  }>();
  const { data, isError, isSuccess, isLoading } = useValidate2faReset(userId!);

  const handleNavigationWithAlert = (message: string) => {
    navigate(`/${ROUTES.PRODUCT.ALL_PRODUCTS}`);
    setTimeout(() => {
      showAlert(message, 'error');
    }, 150);
  };

  if (isSuccess && !data.isValid) {
    handleNavigationWithAlert(data.message);
    return null;
  }

  if (isError) {
    handleNavigationWithAlert(TwoFaResetValidate);
    return null;
  }

  if (isLoading) {
    return (
      <div className='flex h-screen items-center justify-center'>
        {/* Full screen loading */}
        <TwoFaResetVerifyLoadingState />
      </div>
    );
  }

  return (
    <div className='flex min-h-[calc(100vh_-_76px)] items-center justify-center py-24'>
      <Generate2FAPage />
    </div>
  );
};

export default TwoFaValidateResetPage;
