import { cn } from '@/utils/cn';
import { PASSWORD_CRITERIEA_REGEX } from '@/utils/constants';
import { useIsDeviceMediumOrLarger } from '@/components/hooks';

interface PasswordCriteriaProps {
  password: string;
}

export const PasswordCriteria: React.FC<PasswordCriteriaProps> = ({
  password,
}) => {
  const checkCriteria = (regex: RegExp) => regex.test(password);
  const isDeviceMediumOrLarger = useIsDeviceMediumOrLarger();

  return (
    <div
      className='absolute left-0 top-full z-10 mt-4 px-6 2lg:left-[100%] 2lg:top-1/2 2lg:mt-0 2lg:-translate-y-1/2'
      data-cy='password criteria tooltip'
    >
      <div
        className={cn(
          'w-[289px] rounded-lg bg-gray-950 p-4 text-sm font-bold text-gray-50 before:absolute before:border-[8px] before:border-transparent',
          isDeviceMediumOrLarger
            ? 'absolute top-1/2 -translate-y-1/2 before:-left-4 before:top-1/2 before:-translate-y-1/2 before:transform before:border-r-gray-950'
            : 'relative before:left-1/2 before:top-0 before:-translate-x-1/2 before:-translate-y-full before:border-b-gray-950',
        )}
      >
        <div className='text-sm font-bold text-gray-50'>
          Password must contain
        </div>
        <ul className='mt-2 flex list-disc flex-col gap-2 pl-5'>
          <li
            className={
              checkCriteria(PASSWORD_CRITERIEA_REGEX.length)
                ? 'text-sm font-medium text-gray-500 line-through'
                : 'text-sm font-medium text-white'
            }
          >
            Length: 8 to 15 characters
          </li>
          <li
            className={
              checkCriteria(PASSWORD_CRITERIEA_REGEX.uppercase)
                ? 'text-sm font-medium text-gray-500 line-through'
                : 'text-sm font-medium text-white'
            }
          >
            At least one uppercase letter
          </li>
          <li
            className={
              checkCriteria(PASSWORD_CRITERIEA_REGEX.number)
                ? 'text-sm font-medium text-gray-500 line-through'
                : 'text-sm font-medium text-white'
            }
          >
            At least one number
          </li>
          <li
            className={
              checkCriteria(PASSWORD_CRITERIEA_REGEX.special)
                ? 'text-sm font-medium text-gray-500 line-through'
                : 'text-sm font-medium text-white'
            }
          >
            At least one special character or symbol
          </li>
        </ul>
      </div>
    </div>
  );
};
