import { z } from 'zod';

import { BaseResponseSchema } from '../common.schema';

export const TeamDetailsSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Team Name is required' })
    .max(255, {
      message: 'Team Name must be less than 255 characters',
    }),
  description: z
    .string()
    .trim()
    .max(1000, {
      message: 'Team Description must be less than 1000 characters',
    })
    .optional(),
});

export type TTeamDetails = z.infer<typeof TeamDetailsSchema>;

export const EditTeamSchema = TeamDetailsSchema;
export type TEditTeamSchema = z.infer<typeof EditTeamSchema>;
export const TeamDetailsResponseSchema = z.object({
  message: z.string(),
  timestamp: z.string().optional(),
  data: z.object({
    id: z.string(),
    companyId: z.string(),
    name: z.string(),
    description: z.string().optional(),
    createdAt: z.string().optional(),
    updatedAt: z.string().optional(),
  }),
});

const userSchema = z.object({
  _id: z.string(),
  email: z.string().email(),
  name: z.string(),
});

export const GetUserOnTeamRequestSchema = z.string();
export const GetUserOnTeamResponseSchema = BaseResponseSchema.extend({
  payload: z.array(userSchema),
});

export const memberSchema = z.object({
  _id: z.string(),
  memberId: z.string(),
  name: z.string(),
  image: z.string(),
});

export const teamAddMembersRequestSchema = z.array(memberSchema);
export const teamAddMembersResponseSchema = BaseResponseSchema.extend({
  payload: z.object({
    _id: z.string(),
    name: z.string(),
    description: z.string(),
    members: z.array(memberSchema),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  }),
});
