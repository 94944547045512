import React from 'react';

import { cn } from '@/utils/cn';
import { useMediaQuery } from '@/components/hooks';

export interface AvatarProps {
  classes?: string;
  avatar: {
    id?: React.Key | null | undefined;
    imageSrc?: string | undefined | null;
    name: string | undefined;
  }[];
  size?: number;
  borderWidth?: number;
  defaultAvatarLetter?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  classes,
  avatar,
  size = 32,
  borderWidth = 1,
  defaultAvatarLetter,
}: AvatarProps) => {
  const isBigScreen: boolean = useMediaQuery('(min-width: 1200px)');
  const isDesktopOrLaptop: boolean = useMediaQuery('(min-width: 991px)');
  const isTablet: boolean = useMediaQuery('(min-width: 768px)');
  const isMobile: boolean = useMediaQuery('(min-width: 300px)');

  if (avatar.length < 1) return null;

  let maxAvatarsToShow = 2;
  if (isBigScreen) {
    maxAvatarsToShow = 5;
  } else if (isDesktopOrLaptop) {
    maxAvatarsToShow = 4;
  } else if (isTablet) {
    maxAvatarsToShow = 3;
  } else if (isMobile) {
    maxAvatarsToShow = 2;
  }

  const avatarsToShow = avatar.slice(0, maxAvatarsToShow);
  const remainingCount = avatar.length - maxAvatarsToShow;

  const defaultAvatarClass =
    "rounded-full border border-gray-500 overflow-hidden block first:ml-0 -ml-2 before:content-[''] before:block before:pt-[100%] before:bg-white relative";

  return (
    <div className='avatar'>
      {
        <div className='avatar-wrap flex items-center'>
          {avatarsToShow.map((item, itemIndex) => (
            <figure
              className={cn(defaultAvatarClass, classes)}
              key={itemIndex}
              style={{
                width: size ? `${String(size)}px` : '70px',
                borderWidth: borderWidth && `${String(borderWidth)}px`,
                borderColor: '#D1D5DB',
              }}
              data-cy={`avatar-${itemIndex}`}
            >
              {item.imageSrc ? (
                <img
                  src={item.imageSrc}
                  alt={item.name}
                  className='absolute left-0 top-0 h-full w-full object-cover'
                  data-cy={item.name}
                />
              ) : defaultAvatarLetter ? (
                <div className='absolute left-0 right-0 top-0 flex h-full items-center justify-center rounded-full bg-primary-50 object-cover'>
                  <p className='text-xs font-semibold text-primary-500'>
                    {defaultAvatarLetter}
                  </p>
                </div>
              ) : (
                <div className='absolute left-0 right-0 top-0 flex h-full items-center justify-center rounded-full bg-primary-50 object-cover'>
                  <p className='text-xs font-semibold text-primary-500'>
                    {item.name?.charAt(0).toUpperCase()}
                  </p>
                </div>
              )}
            </figure>
          ))}
          {remainingCount > 0 && (
            <span className='ml-2 text-sm'>+{remainingCount} more</span>
          )}
        </div>
      }
    </div>
  );
};

export default Avatar;
