import { useState } from 'react';

import { Button } from '@/components/ui/button';
import { V12_FAQ_URL } from '@/utils/constants';
import IconsComponent from '@/components/ui/icons/IconsComponent';

export interface Size {
  size: string | number;
  quantity: number;
  amount: number;
}

export interface LineItem {
  id: string;
  sku?: string | null;
  name?: string | null;
  color?: string | null;
  colorCode?: string | null;
  quantity: number;
  amount: number | string;
  sizes?: Size[];
  image?: string | null;
}

export interface OrderSummaryProps {
  subtotal: number;
  carriageCost: number;
  estimatedVAT: number;
  orderTotal: number;
}

interface LineItemsProps {
  items: LineItem[] | undefined;
}

export function LineItems({ items }: LineItemsProps) {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const toggleItem = (id: string) => {
    setExpandedItems((prev) =>
      prev.includes(id)
        ? prev.filter((itemId) => itemId !== id)
        : [...prev, id],
    );
  };

  return (
    <div className='mt-5 bg-white shadow-sm'>
      <div className='flex items-center justify-between p-4'>
        <h2 className='text-xl font-extrabold text-gray-800'>Line Items</h2>
        <div className='flex items-center gap-2'>
          <span className='text-sm text-gray-500'>Got Questions?</span>
          <a
            className='p-0 text-sm text-blue-600'
            href={V12_FAQ_URL}
            target='_blank'
            rel='noreferrer'
          >
            Read FAQ
          </a>
        </div>
      </div>
      <hr className='' />

      <div className='px-4'>
        {items?.map((item) => (
          <div
            key={item.id}
            className='box-border border-b py-4 last:border-b-0'
          >
            <div
              className='grid cursor-pointer grid-cols-3 items-center gap-4'
              onClick={() => toggleItem(item.id)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleItem(item.id);
                }
              }}
              tabIndex={0} // Makes the div focusable
              role='button' // Indicates that this is a button-like element
            >
              {/* Column 1: Image and product details */}
              <div className='flex items-center gap-4'>
                <img
                  src={item.image!}
                  alt={item.name!}
                  width={60}
                  height={60}
                  className='rounded-md'
                />
                <div>
                  <p className='text-base font-bold text-gray-800'>
                    {item.name}
                  </p>
                  <div className='flex items-center gap-2 text-sm'>
                    <span className='text-gray-500'>SKU</span>{' '}
                    <span className='text-gray-800'>{item.sku}</span>
                    <div className='flex items-center gap-1'>
                      <div
                        className='h-3 w-3 rounded-full'
                        style={{ backgroundColor: item.colorCode! }}
                      />
                      <span className='text-xs'>{item.color}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Column 2: Quantity */}
              <div className='justify-self-center'>
                <div className='text-sm text-gray-500'>Quantity</div>
                <div className='font-bold'>{item.quantity}</div>
              </div>

              {/* Column 3: Amount and toggle button */}
              <div className='flex items-center justify-end gap-4'>
                <div>
                  <div className='text-sm text-gray-500'>Amount</div>
                  <div className='font-bold'>£{item.amount}</div>
                </div>
                <Button
                  size='icon'
                  intent='tertiary'
                >
                  {expandedItems.includes(item.id) ? (
                    <IconsComponent icon='reg-chevron-up' />
                  ) : (
                    <IconsComponent icon='reg-chevron-down' />
                  )}
                </Button>
              </div>
            </div>

            {/* Expanded section with smooth open/close animation */}
            <div
              className={`overflow-hidden transition-all duration-500 ease-in-out ${
                expandedItems.includes(item.id) ? 'max-h-[1000px]' : 'max-h-0'
              }`}
            >
              {expandedItems.includes(item.id) && (
                <div className='mt-4'>
                  <table className='w-full'>
                    <thead className='bg-gray-100'>
                      <tr>
                        <th className='px-6 py-2 text-left text-xs font-semibold text-gray-600'>
                          Size (UK)
                        </th>
                        <th className='px-4 py-2 text-right text-xs font-semibold text-gray-600'>
                          Quantity
                        </th>
                        <th className='px-8 py-2 text-right text-xs font-semibold text-gray-600'>
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.sizes!.map((size, rowIndex) => (
                        <tr
                          key={size.size}
                          className={
                            rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          }
                        >
                          <td className='px-6 py-2 text-sm'>{size.size}</td>
                          <td className='px-4 py-2 text-right text-sm'>
                            {size.quantity}
                          </td>
                          <td className='px-8 py-2 text-right text-sm'>
                            £{size.amount.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
