import { Skeleton } from '@/components/lib/Skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

interface VariantTableSkeletonProps {
  rowCount?: number;
  cellCount?: number;
}

export function VariantTableSkeleton({
  rowCount = 5,
  cellCount = 5,
}: VariantTableSkeletonProps) {
  return (
    <Table className='VariantTable w-full overflow-auto'>
      <TableHeader>
        <TableRow>
          <TableHead className='w-full md:w-14'>SIZE</TableHead>
          <TableHead>Stock Status</TableHead>
          <TableHead>Available Stocks</TableHead>
          <TableHead>Lead Time</TableHead>
          <TableHead className='w-full md:w-[62px]'>Quantity</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array.from({ length: rowCount }).map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {Array.from({ length: cellCount }).map((__, cellIndex) => (
              <TableCell
                key={cellIndex}
                className='px-6 py-4'
              >
                <Skeleton className='h-6 w-full' />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
