import { useSearchParams } from 'react-router-dom';

import { QUERY_CONSTANTS } from '@/utils/constants';
import { Color } from '@/pages/real-time-stock/RealTimeStockColumn';
import { limitNameWithLength } from '@/utils/helper/limitNameWithLength';
import { Badge, Button, ColorBadge, IconsComponent } from '@/components';
import { formatDate } from '@/utils/helper/datetime.helper';

/**
 * @description Component to display filter badge with remove functionality.
 */
export function FilterBadge() {
  const [searchParams, setSearchParams] = useSearchParams();

  const badges = new Set<JSX.Element>();

  const BadgesWithRemoveButton = ({
    queryKey,
    value,
    name,
    children,
  }: {
    queryKey: string;
    value: string;
    name: string;
    children?: React.ReactNode;
  }) => {
    return (
      <Badge className='rounded-full px-5 py-2 text-sm text-gray-400'>
        {name.toLowerCase()} {children}{' '}
        <span className='font-semibold text-gray-900'>
          {(() => {
            switch (queryKey) {
              case QUERY_CONSTANTS.UPDATED_DATE:
                return formatDate(value);
              case QUERY_CONSTANTS.STATUS:
                if (value === 'invited') return 'Invite Pending';
                return value;
              default:
                return limitNameWithLength(value, 50);
            }
          })()}
        </span>
        <Button
          size='icon'
          variant='gray'
          intent='tertiary'
          className='p-0'
          onClick={() => {
            searchParams.delete(queryKey, value);
            setSearchParams(searchParams);
          }}
        >
          <IconsComponent
            icon='reg-close'
            size='sm'
          />
        </Button>
      </Badge>
    );
  };

  const RangeBadgesWithRemoveButton = ({
    query,
    name,
    children,
  }: {
    query: {
      from: { key: string; value: string };
      to: { key: string; value: string };
    };
    name: string;
    children?: React.ReactNode;
  }) => {
    const badgeValue = `${query.from.value} - ${query.to.value}`;

    return (
      <Badge className='rounded-full px-5 py-2 text-sm text-gray-400'>
        {name} {children}{' '}
        <span className='font-semibold text-gray-900'>
          {badgeValue.toLowerCase()}
        </span>
        <Button
          size='icon'
          variant='gray'
          intent='tertiary'
          className='p-0'
          onClick={() => {
            searchParams.delete(query.from.key, query.from.value);
            searchParams.delete(query.to.key, query.to.value);
            setSearchParams(searchParams);
          }}
        >
          <IconsComponent
            icon='reg-close'
            size='sm'
          />
        </Button>
      </Badge>
    );
  };

  const handleClearAllFilters = () => {
    badges.forEach(
      (badge: {
        props: {
          queryKey?: string;
          query?: { from: { key: string }; to: { key: string } };
        };
      }) => {
        if (badge.props.queryKey) {
          searchParams.delete(badge.props.queryKey);
        } else if (badge.props.query) {
          searchParams.delete(badge.props.query.from.key);
          searchParams.delete(badge.props.query.to.key);
        }
      },
    );
    setSearchParams(searchParams);
  };

  searchParams.forEach((value, key) => {
    switch (key) {
      case QUERY_CONSTANTS.TEAMS:
        badges.add(
          <BadgesWithRemoveButton
            key={`${key}-${value}`}
            queryKey={key}
            value={value}
            name='Team Name'
          />,
        );
        break;
      case QUERY_CONSTANTS.TEAM_NAME:
        badges.add(
          <BadgesWithRemoveButton
            key={`${key}-${value}`}
            queryKey={key}
            value={value}
            name='Team Name'
          />,
        );
        break;
      case QUERY_CONSTANTS.ROLES:
        badges.add(
          <BadgesWithRemoveButton
            key={`${key}-${value}`}
            queryKey={key}
            value={value}
            name='Roles'
          />,
        );
        break;
      case QUERY_CONSTANTS.STATUS:
        badges.add(
          <BadgesWithRemoveButton
            key={`${key}-${value}`}
            queryKey={key}
            value={value}
            name='Status'
          />,
        );
        break;
      case QUERY_CONSTANTS.UPDATED_DATE:
        badges.add(
          <BadgesWithRemoveButton
            key={`${key}-${value}`}
            queryKey={key}
            value={value}
            name='Updated Date'
          />,
        );
        break;
      case QUERY_CONSTANTS.CATEGORIES:
        badges.add(
          <BadgesWithRemoveButton
            key={`${key}-${value}`}
            queryKey={key}
            value={value}
            name='Categories'
          />,
        );
        break;
      case QUERY_CONSTANTS.COLOR:
        badges.add(
          <BadgesWithRemoveButton
            key={`${key}-${value}`}
            queryKey={key}
            value={value}
            name='Color'
          >
            <ColorBadge variant={value as Color} />
          </BadgesWithRemoveButton>,
        );
        break;
      case QUERY_CONSTANTS.SIZE:
        badges.add(
          <BadgesWithRemoveButton
            key={`${key}-${value}`}
            queryKey={key}
            value={value}
            name='Size'
          />,
        );
        break;
      case QUERY_CONSTANTS.PRICE_RANGE.FROM:
        {
          const priceRangeTo = {
            key: QUERY_CONSTANTS.PRICE_RANGE.TO,
            value: searchParams.get(QUERY_CONSTANTS.PRICE_RANGE.TO) ?? '',
          };

          badges.add(
            <RangeBadgesWithRemoveButton
              key={`${key}-${value}`}
              query={{
                from: { key, value },
                to: priceRangeTo,
              }}
              name='Price Range'
            />,
          );
        }
        break;
      case QUERY_CONSTANTS.STOCK_RANGE.FROM:
        {
          const stockRangeTo = {
            key: QUERY_CONSTANTS.STOCK_RANGE.TO,
            value: searchParams.get(QUERY_CONSTANTS.STOCK_RANGE.TO) ?? '',
          };
          badges.add(
            <RangeBadgesWithRemoveButton
              key={`${key}-${value}`}
              query={{
                from: { key, value },
                to: stockRangeTo,
              }}
              name='Stock Range'
            />,
          );
        }
        break;
      case QUERY_CONSTANTS.ORDER_PLACED_DATE.START_DATE:
        {
          const END_DATE = {
            key: QUERY_CONSTANTS.ORDER_PLACED_DATE.END_DATE,
            value:
              searchParams.get(QUERY_CONSTANTS.ORDER_PLACED_DATE.END_DATE) ??
              '',
          };
          badges.add(
            <RangeBadgesWithRemoveButton
              key={`${key}-${value}`}
              query={{
                from: { key, value },
                to: END_DATE,
              }}
              name='Order Placed Date'
            />,
          );
        }
        break;
      default:
        break;
    }
  });

  if (badges.size <= 0) return null;

  return (
    <div className='flex flex-wrap gap-2 py-4'>
      {badges}
      {badges.size > 0 && (
        <Button
          variant='blue'
          intent='tertiary'
          size='xs'
          onClick={handleClearAllFilters}
        >
          Clear All Filters
        </Button>
      )}
    </div>
  );
}
