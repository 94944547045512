import { cn } from '@/utils/cn';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {}

export const Label: React.FC<LabelProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <label
      className={cn(
        'block text-sm font-normal capitalize text-gray-600',
        className,
      )}
      data-cy='label'
      {...props}
    >
      {children}
    </label>
  );
};
