import { RefObject, useEffect } from 'react';

/**
 * This is a TypeScript React function that listens for clicks outside of a specified element and
 * triggers a callback function when detected.
 * @param ref - A reference to the HTML element that the outside click event should be detected on.
 * @param onClickOut - onClickOut is a callback function that will be called when a click event occurs
 * outside of the element referenced by the ref parameter.
 */

export const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  onClickOut: () => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        (event.target as Node).nodeName.toLowerCase() !== 'path' &&
        (event.target as Node).nodeName.toLowerCase() !== 'svg'
      ) {
        onClickOut();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOut, ref]);
};
