import React from 'react';
import { IconProps } from '../iconProps';

export const IconCheck: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.5307 7.28063L9.53068 19.2806C9.46102 19.3504 9.3783 19.4057 9.28726 19.4434C9.19621 19.4812 9.09861 19.5006 9.00005 19.5006C8.90149 19.5006 8.80389 19.4812 8.71285 19.4434C8.6218 19.4057 8.53908 19.3504 8.46943 19.2806L3.21943 14.0306C3.0787 13.8899 2.99963 13.699 2.99963 13.5C2.99963 13.301 3.0787 13.1101 3.21943 12.9694C3.36016 12.8286 3.55103 12.7496 3.75005 12.7496C3.94907 12.7496 4.13995 12.8286 4.28068 12.9694L9.00005 17.6897L20.4694 6.21938C20.6102 6.07865 20.801 5.99958 21.0001 5.99958C21.1991 5.99958 21.3899 6.07865 21.5307 6.21938C21.6714 6.36011 21.7505 6.55098 21.7505 6.75C21.7505 6.94902 21.6714 7.1399 21.5307 7.28063Z'
        fill={fill}
      />
    </svg>
  );
};
