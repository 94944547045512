import { z } from 'zod';

import { ORDER_CONSTANTS_KEYS } from '@/utils/constants/order.constants';
import { SingleOrderDetailBaseSchema } from './single-order-detail.schema';
import { BaseResponseSchema } from '@/schemas/common.schema';

export const SingleUserOrderSessionIdRequestSchema = z.object({});

export type TSingleUserOrderSessionIdRequestSchema = z.infer<
  typeof SingleUserOrderSessionIdRequestSchema
>;

export const SingleUserOrderSessionIdResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    companyId: z.string(),
    status: z.enum(ORDER_CONSTANTS_KEYS),
  }),
});

export type TSingleUserOrderSessionIdResponseSchema = z.infer<
  typeof SingleUserOrderSessionIdResponseSchema
>;

export const AddOrderToSessionByIdRequsetSchema = z.array(
  z.object({
    productId: z.string().uuid(),
    variants: z.array(
      z.object({
        id: z.union([z.string(), z.number()]).nullable().optional(),
        quantity: z.number().int().positive().nullable().optional(),
      }),
    ),
  }),
);

export type TAddOrderToSessionByIdRequsetSchema = z.infer<
  typeof AddOrderToSessionByIdRequsetSchema
>;

export const AddOrderToSessionByIdResponseSchema =
  SingleUserOrderSessionIdResponseSchema;

export type TAddOrderToSessionByIdResponseSchema = z.infer<
  typeof AddOrderToSessionByIdResponseSchema
>;

export const RemoveProductFromOrderRequestSchema = z.object({
  productId: z.union([z.string(), z.number()]),
});

export type TRemoveProductFromOrderRequestSchema = z.infer<
  typeof RemoveProductFromOrderRequestSchema
>;

export const RemoveProductVariantFromOrderRequestSchema = z.object({
  productId: z.union([z.string(), z.number()]),
  variantId: z.union([z.string(), z.number()]),
});

export type TRemoveProductVariantFromOrderRequestSchema = z.infer<
  typeof RemoveProductVariantFromOrderRequestSchema
>;

export const GetCompanyOrderDetailsRequestSchema = z.void();

export const GetCompanyOrderDetailsResponseSchema = z.object({
  data: SingleOrderDetailBaseSchema,
});

export type TGetCompanyOrderDetailsResponseSchema = z.infer<
  typeof GetCompanyOrderDetailsResponseSchema
>;

export const ContactSupportSchema = z.object({
  // companyName: z.string(),
  // orderId: z.string(),
  message: z
    .string()
    .trim()
    .max(1000, {
      message: 'Message must be less than 1000 characters',
    })
    .optional(),
});

export const ContactSupportRequestSchemaForm = z.object({
  orderId: z.string().optional(),
  message: z.string(),
});

export type OContactSupport = z.infer<typeof ContactSupportRequestSchemaForm>;
export const ContactSupportRequestSchema = ContactSupportRequestSchemaForm;

export const ContactSupportResponseSchema = BaseResponseSchema.extend({
  data: z.any(),
  timestamp: z.string(),
});
