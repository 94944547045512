import { Role } from '@/schemas';
import { API_ENDPOINT, HttpMethods } from '@/utils/constants';
import { api } from '@/utils/helper';
import { z } from 'zod';

const UploadProfileImageRequestSchema = z.any();

const UploadProfileImageResponseSchema = z.object({
  message: z.string(),
  data: z.object({
    id: z.string(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    designation: z.string(),
    role: z.nativeEnum(Role),
    avatar: z.string(),
  }),
  timestamp: z.string(),
});

const uploadUserProfileImage = (
  data: z.infer<typeof UploadProfileImageRequestSchema>,
  userId: string,
) =>
  api({
    method: HttpMethods.POST,
    path: API_ENDPOINT.USERS.UPLOAD_USER_PROFILE_IMAGE,
    requestSchema: UploadProfileImageRequestSchema,
    responseSchema: UploadProfileImageResponseSchema,
  })(data, userId);

export const FileUploadAPI = {
  uploadUserProfileImage,
};
