import { z } from 'zod';

import {
  FILE,
  PASSWORD_CRITERIEA_REGEX,
  PHONE_NUMBER_REGEX,
} from '@/utils/constants';
import { emailSchema } from '@/schemas';
import { BaseResponseSchema, phoneNumberSchema } from '@/schemas/common.schema';

export const PersonalSettingsSchema = z.object({
  profileImage: z
    .custom<File[] | string>()
    .optional()
    .refine((files) => {
      if (files === undefined) return true;

      const file = files[0];
      if (file instanceof File) {
        return file.size <= FILE.MAX_SIZE_MB * 1024 * 1024;
      }
      return true;
    }, 'Max file size is 4MB.')
    .refine((files) => {
      if (files === undefined) return true;

      const file = files[0];
      if (file instanceof File) {
        return FILE.ACCEPTED_IMAGE_TYPES.includes(file.type);
      }
      return true;
    }, 'Please choose .jpg , .jpeg or .png files.'),

  email: emailSchema,
  firstName: z
    .string()
    .min(1, { message: 'First Name is required' })
    .max(50, { message: 'First Name is too long' })
    .trim(),
  lastName: z
    .string()
    .min(1, { message: 'Last Name is required' })
    .max(50, { message: 'Last Name is too long' })
    .trim(),
  phoneNumber: z
    .string()
    .trim()
    .transform((value) => value.replace(/[\s+()]/g, ''))
    .refine(
      (value) => {
        const parts = value.split('-');
        return parts.length === 2 && PHONE_NUMBER_REGEX.test(parts[1]);
      },
      {
        message: 'Enter a valid phone number with country code',
      },
    ),
});

export const PersonalSettingPhoneNumber = z.object({
  phoneNumber: phoneNumberSchema,
});

export const PasswordSchema = z.object({
  // email: emailSchema,
  password: z
    .string()
    .min(1, { message: 'Password is required' })
    .max(15, { message: 'Password over 15 characters' })
    .refine((val) => PASSWORD_CRITERIEA_REGEX.length.test(val), {
      message: 'Password must be between 8 and 15 characters long',
    })
    .refine((val) => PASSWORD_CRITERIEA_REGEX.uppercase.test(val), {
      message: 'Password must contain at least one uppercase letter',
    })
    .refine((val) => PASSWORD_CRITERIEA_REGEX.number.test(val), {
      message: 'Password must contain at least one number',
    })
    .refine((val) => PASSWORD_CRITERIEA_REGEX.special.test(val), {
      message: 'Password must contain at least one special character',
    }),
});

export type TPasswordSchema = z.infer<typeof PasswordSchema>;

export type TPersonalSettingPhoneNumber = z.infer<
  typeof PersonalSettingPhoneNumber
>;

export const VerifiyCodePersonalSettingsSchema = z.object({
  verificationCode: z
    .string()
    .length(6, { message: 'Verification Code must be exactly 6 digits' }),
});

export type TVerifiyCodePersonalSettingsSchema = z.infer<
  typeof VerifiyCodePersonalSettingsSchema
>;
export const VerifiyCodePersonalSettingsAPPSchema = z.object({
  verificationCode: z
    .string()
    .min(1, { message: 'Verification Code is required' }),
});

export type TVerifiyCodePersonalSettingsAPPSchema = z.infer<
  typeof VerifiyCodePersonalSettingsAPPSchema
>;

export const EditPersonalSettingRequestSchema = PersonalSettingsSchema.omit({
  email: true,
});

export const CompanySchema = z.object({
  companyId: z.string(),
  companyName: z.string(),
});

export const PersonalSettingResponseSchema = z.object({
  authId: z.string(),
  company: CompanySchema,
  _id: z.string(),
  email: z.string().email(),
  roles: z.string(),
  invited: z.boolean(),
  passwordSet: z.boolean(),
  status: z.string(),
  profilePicture: z.string(),
  deleted: z.boolean(),
  teams: z.array(z.any()),
  mfa: z.array(z.any()),
  createdAt: z.string(),
  updatedAt: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
});

export const EditPersonalSettingResponseSchema = BaseResponseSchema.extend({
  payload: z.union([PersonalSettingResponseSchema, z.null()]),
});

export type TEditPersonalSettingRequestSchema = z.infer<
  typeof EditPersonalSettingRequestSchema
>;

// Define the schema for the login response
export const EnrollAuthenticatorResponseSchema = BaseResponseSchema.extend({
  payload: z.object({
    mfaToken: z.string(),
    barcode_uri: z.string().optional(),
    authenticator_type: z.string().optional(),
    secret: z.string().optional(),
  }),
});
