import { useMutation } from '@tanstack/react-query';

import {
  TRegisterCompanyFormSchema,
  TCompanyRegistrationRequestSchema,
} from '@/schemas';
import { AuthAPI } from '@/services';

export const useCompanyRegistration = () => {
  return useMutation({
    mutationFn: ({
      companyId,
      companyData,
    }: {
      companyId: string | undefined;
      companyData: TCompanyRegistrationRequestSchema;
    }) => AuthAPI.completeRegistration(companyId, companyData),
  });
};

export const useRegistration = () => {
  return useMutation({
    mutationFn: (companyData: TRegisterCompanyFormSchema) =>
      AuthAPI.register(companyData),
  });
};
