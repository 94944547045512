import { PAGINATION_CONSTANTS } from '../constants';

/**
 * @description Generates an array of page size options based on the total number of items.
 * @returns An array of page size options.
 */
export function generatePageLimitOptions(
  totalItems: number,
  basePageLimit = PAGINATION_CONSTANTS.DEFAULT_PAGE_LIMIT,
  maxPageLimit = PAGINATION_CONSTANTS.MAX_PAGE_LIMIT,
): number[] {
  const calculatedMaxPageLimit = Math.min(maxPageLimit, totalItems);

  const pageLimitOptions = [basePageLimit];

  for (const option of PAGINATION_CONSTANTS.PAGE_LIMIT_OPTIONS) {
    if (
      option <= calculatedMaxPageLimit &&
      !pageLimitOptions.includes(option)
    ) {
      pageLimitOptions.push(option);
    }
  }

  return pageLimitOptions;
}

/**
 * @description Generates an array of numbers within a specified    range. The range is inclusive of both the start and end values.
 */
export function range(start: number, end: number) {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
}
