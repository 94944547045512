import React from 'react';
import { IconProps } from '../iconProps';

export const IconTrenUp: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.5001 5.25V11.25C22.5001 11.4489 22.421 11.6397 22.2804 11.7803C22.1397 11.921 21.949 12 21.7501 12C21.5511 12 21.3604 11.921 21.2197 11.7803C21.0791 11.6397 21.0001 11.4489 21.0001 11.25V7.06031L13.2807 14.7806C13.211 14.8504 13.1283 14.9057 13.0373 14.9434C12.9462 14.9812 12.8486 15.0006 12.7501 15.0006C12.6515 15.0006 12.5539 14.9812 12.4628 14.9434C12.3718 14.9057 12.2891 14.8504 12.2194 14.7806L9.00005 11.5603L2.78068 17.7806C2.63995 17.9214 2.44907 18.0004 2.25005 18.0004C2.05103 18.0004 1.86016 17.9214 1.71943 17.7806C1.5787 17.6399 1.49963 17.449 1.49963 17.25C1.49963 17.051 1.5787 16.8601 1.71943 16.7194L8.46943 9.96937C8.53908 9.89964 8.6218 9.84432 8.71285 9.80658C8.80389 9.76884 8.90149 9.74941 9.00005 9.74941C9.09861 9.74941 9.19621 9.76884 9.28726 9.80658C9.3783 9.84432 9.46102 9.89964 9.53068 9.96937L12.7501 13.1897L19.9397 6H15.7501C15.5511 6 15.3604 5.92098 15.2197 5.78033C15.0791 5.63968 15.0001 5.44891 15.0001 5.25C15.0001 5.05109 15.0791 4.86032 15.2197 4.71967C15.3604 4.57902 15.5511 4.5 15.7501 4.5H21.7501C21.949 4.5 22.1397 4.57902 22.2804 4.71967C22.421 4.86032 22.5001 5.05109 22.5001 5.25Z'
        fill={fill}
      />
    </svg>
  );
};
