import { useNavigate } from 'react-router-dom';
import { Button } from '../button';
import NoResult from './NoResult';
import ErrorImg from '@/assets/images/notfound.png';

const defaultDescription =
  'It looks like the page you’re looking for doesn’t exist or you don’t have access to it.';

export const Notfound = ({
  className,
  description = defaultDescription,
  navigationUrl,
}: {
  className?: string;
  description?: string;
  navigationUrl?: string;
}) => {
  const navigate = useNavigate();

  const navigateBack = () =>
    navigationUrl ? navigate(navigationUrl) : navigate(-1);

  return (
    <NoResult
      desc={description}
      imageSrc={ErrorImg}
      classes={className ? className : ''}
    >
      <Button
        intent='solid'
        variant={'primary'}
        onClick={navigateBack}
      >
        Go Back
      </Button>
    </NoResult>
  );
};
