import { cn } from '@/utils/cn';

export function FormGroup({
  isMandatory = false,
  title,
  children,
  description,
  className,
}: {
  title: string;
  description: string;
  isMandatory?: boolean;
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={cn('grid gap-6 md:grid-cols-2', className)}
      data-cy='form-group'
    >
      <div className='space-y-2'>
        <p
          className='text-xl font-bold text-gray-800'
          data-cy={`form-group-${title}`}
        >
          {title}
        </p>
        <p className='text-gray-600'>{description}</p>
        {isMandatory && (
          <p className='italic text-gray-400'>(All fields are mandatory)</p>
        )}
      </div>
      {children}
    </div>
  );
}
