import { z } from 'zod';

import { SingleProductDetailSchema } from '..';

export const RecommendedProductsResponseSchema = z.object({
  message: z.string(),
  data: SingleProductDetailSchema.extend({
    recommendedProducts: z.array(SingleProductDetailSchema),
  }),
});

export type TRecommendedProductsResponseSchema = z.infer<
  typeof RecommendedProductsResponseSchema
>;
