import { z } from 'zod';

import {
  AllProductsRequestSchema,
  AllProductsResponseSchema,
  RecommendedProductsResponseSchema,
  SingleProductDetailRequestSchema,
  SingleProductDetailResponseSchema,
} from '@/schemas';
import { api } from '@/utils/helper';
import { API_ENDPOINT, HttpMethods } from '@/utils/constants';

const getAllProducts = (url?: string) =>
  api<
    z.infer<typeof AllProductsRequestSchema>,
    z.infer<typeof AllProductsResponseSchema>
  >({
    path: `${API_ENDPOINT.PRODUCTS.ALL_PRODUCTS}${url}`,
    method: HttpMethods.GET,
    requestSchema: AllProductsRequestSchema,
    responseSchema: AllProductsResponseSchema,
  });

const getProductById = (
  productId: z.infer<typeof SingleProductDetailRequestSchema>,
  cartQuantity = false,
) => {
  const path = `${API_ENDPOINT.PRODUCTS.ALL_PRODUCTS}/${productId}?cartVariantQty=${cartQuantity}`;

  return api<
    z.infer<typeof AllProductsRequestSchema>,
    z.infer<typeof SingleProductDetailResponseSchema>
  >({
    path,
    method: HttpMethods.GET,
    requestSchema: AllProductsRequestSchema,
    responseSchema: SingleProductDetailResponseSchema,
  });
};

const getRecommendedProducts = (productId: string) =>
  api<
    z.infer<typeof AllProductsRequestSchema>,
    z.infer<typeof RecommendedProductsResponseSchema>
  >({
    path: `${API_ENDPOINT.PRODUCTS.ALL_PRODUCTS}/${productId}?recommended=true`,
    method: HttpMethods.GET,
    requestSchema: AllProductsRequestSchema,
    responseSchema: RecommendedProductsResponseSchema,
  });

export const AllProductsApi = {
  getAllProducts,
  getProductById,
  getRecommendedProducts,
};
