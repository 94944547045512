import { z } from 'zod';

import { PaginationResponseSchema } from '@/schemas/common.schema';

export const GetOrderListRequestSchema = z.void();

export const GetOrderListResponseSchema = z.object({
  message: z.string(),
  data: z.array(
    z.object({
      orderId: z.string().uuid(),
      noProducts: z.number(),
      createdAt: z.string(),
      updatedAt: z.string(),
      orderTotal: z.string(),
      orderedBy: z.object({
        firstName: z.string(),
        lastName: z.string(),
      }),
      customId: z.string(),
    }),
  ),
  meta: z.object({
    pagination: PaginationResponseSchema,
  }),
  timestamp: z.string(),
});

export const GetOrderAnalyticsRequestSchema = z.void();

export const GetOrderAnalyticsResponseSchema = z.object({
  message: z.string(),
  data: z.object({
    totalOrders: z.number(),
    percentOrders: z.number().nullable(),
    totalAmount: z.number(),
    percentAmount: z.number(),
  }),
  timestamp: z.string(),
});

export const ExportOrderListRequestSchema = z.void();

export const ExportOrderListResponseSchema = z.string();
