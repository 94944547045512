import { useNavigate } from 'react-router-dom';

import {
  Button,
  PrivateRouteLayout,
  RestrictedRouteLayout,
} from '@/components';
import { ROUTES } from '@/utils/constants';
import { useUserStore } from '@/store/user-store-new';

interface ErrorPageProps {
  route: string;
  pageTitle: string;
}

export const ErrorPageContent: React.FC<ErrorPageProps> = ({
  route,
  pageTitle,
}) => {
  const navigate = useNavigate();

  return (
    <div className='flex min-h-[700px] items-center justify-center'>
      <div className='flex flex-col items-center gap-14'>
        <p className='text-gray-800'>
          It looks like the page you’re looking for doesn’t exist or has been
          moved.
        </p>
        <Button
          type='submit'
          data-cy='save-changes-button'
          onClick={() => navigate(route)}
        >
          Go To {pageTitle}
        </Button>
      </div>
    </div>
  );
};

export const ErrorPage: React.FC = () => {
  const { auth } = useUserStore();

  const isUserLoggedIn = auth?.valid;

  return isUserLoggedIn ? (
    <PrivateRouteLayout>
      <ErrorPageContent
        route={`/${ROUTES.PRODUCT.ALL_PRODUCTS}`}
        pageTitle='Products'
      />
    </PrivateRouteLayout>
  ) : (
    <RestrictedRouteLayout>
      <ErrorPageContent
        route={`/${ROUTES.LOGIN}`}
        pageTitle='Login Page'
      />
    </RestrictedRouteLayout>
  );
};
