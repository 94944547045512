import {
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableLayout,
  Table,
} from '@/components/ui/table';
import { ShoeSizeData } from '@/data/SingleProduct.data';

export const ShoeSizeConversionTable = () => {
  return (
    <TableLayout>
      <Table>
        <TableBody className='text-lg font-normal text-gray-800'>
          <TableRow>
            <TableHead className='font-extrabold text-yellow-700'>UK</TableHead>
            {ShoeSizeData.map((size) => (
              <TableCell
                key={size.id}
                className='font-bold'
              >
                {size.ukSize}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableHead className='font-extrabold text-yellow-700'>EU</TableHead>
            {ShoeSizeData.map((size) => (
              <TableCell key={size.id}>{size.euSize}</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableHead className='font-extrabold text-yellow-700'>US</TableHead>
            {ShoeSizeData.map((size) => (
              <TableCell key={size.id}>{size.usSize}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableLayout>
  );
};
