import { useMutation, useQuery } from '@tanstack/react-query';

import { CompanyAPI } from '@/services';
import { CompanyDetailsData, CompanyDetailsSchemaResponse } from '@/schemas';
import { AxiosError } from 'axios';
import { z } from 'zod';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';

export const useGetCompanyDetailsById = (
  companyId: string | null,
  code?: string | null,
  type: 'normal' | 'registration' = 'normal',
) => {
  return useQuery({
    queryKey: ['companyDetail', companyId, type],
    queryFn: () => {
      if (!companyId) {
        throw new Error('Company Id not provided');
      }

      if (type === 'registration') {
        return CompanyAPI.getCompanyByIdForRegistration(
          decodeURIComponent(`${companyId}?code=${code}`),
        );
      }

      return CompanyAPI.getCompanyById(companyId);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export function useGetCompanyDetails() {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.COMPANY_DETAILS],
    queryFn: () => CompanyAPI.getCompanyDetails(),
    select: (data) => data,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
}
export function useUpdateCompany() {
  return useMutation<
    z.infer<typeof CompanyDetailsSchemaResponse>,
    AxiosError,
    { id: string; data: z.infer<typeof CompanyDetailsData> }
  >({
    mutationFn: ({ id, data }) => CompanyAPI.updateCompanyDetails(id)(data),
  });
}
