import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Button, Label, Textarea } from '@/components';
import { ContactSupportSchema, OContactSupport } from '@/schemas/api/orders';
import { useSendContactSupport } from '@/components/hooks/api/orders/useSendContactSupport';
import { useAlert } from '@/components/hooks';
import { handleApiError } from '@/utils/helper';
import { useParams } from 'react-router-dom';

interface ContactSupportFormProps {
  setShowContactSupportModal: (show: boolean) => void;
  companyName: string;
  customId: string;
}

export function ContactSupportForm({
  setShowContactSupportModal,
  companyName,
  customId,
}: ContactSupportFormProps) {
  const { showAlert } = useAlert();
  const { id } = useParams();

  const methods = useForm<OContactSupport>({
    mode: 'all',
    resolver: zodResolver(ContactSupportSchema),
  });

  const { handleSubmit } = methods;
  const { isPending, mutate } = useSendContactSupport();

  const onSubmit: SubmitHandler<OContactSupport> = (formData) => {
    const data = {
      ...formData,
      orderId: id,
    };
    mutate(data, {
      onSuccess: () => {
        showAlert('Message send to contact support successfully', 'success');
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {
        setShowContactSupportModal(false);
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='space-y-6'
      >
        <div className='grid grid-cols-2 gap-4'>
          <div className='space-y-2'>
            <Label htmlFor='companyName'>Company Name</Label>
            <p className='text-base font-bold text-gray-800'>{companyName}</p>
          </div>
          <div className='space-y-2'>
            <Label htmlFor='orderId'>Order ID</Label>
            <p className='text-base font-bold text-gray-800'>#{customId}</p>
          </div>
        </div>
        <div className='space-y-2'>
          <Label htmlFor='message'>Message</Label>
          <Textarea
            name='message'
            id='message'
            required
            placeholder='Please enter your questions or concerns about this order here.'
            className='min-h-[120px]'
          />
        </div>
        <div className='flex justify-end gap-3'>
          <Button
            type='button'
            intent='tertiary'
            onClick={() => setShowContactSupportModal(false)}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            className='bg-[#722F37] hover:bg-[#722F37]/90'
            disabled={isPending}
          >
            Submit
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
