import { z } from 'zod';

import {
  GetCompanyOrderDetailsRequestSchema,
  GetCompanyOrderDetailsResponseSchema,
} from '@/schemas/api/orders';
import { api } from '@/utils/helper/api/api.helpers';
import { API_ENDPOINT } from '@/utils/constants/api-endpoint.constant';

export const getCompanyOrderDetails = api<
  z.infer<typeof GetCompanyOrderDetailsRequestSchema>,
  z.infer<typeof GetCompanyOrderDetailsResponseSchema>
>({
  path: `${API_ENDPOINT.ORDERS}/pending`,
  method: 'GET',
  requestSchema: GetCompanyOrderDetailsRequestSchema,
  responseSchema: GetCompanyOrderDetailsResponseSchema,
});
