import { useMutation } from '@tanstack/react-query';

import { CompanyAPI } from '@/services';

export const useResendCompanyRegistrationLink = () => {
  return useMutation({
    mutationFn: (companyId: string) =>
      CompanyAPI.resendRegistrationLink(companyId),
  });
};
