import Skeleton from 'react-loading-skeleton';

export const ProductDrawerHeader = () => {
  return (
    <div>
      <div>
        <div className='max-w-[200px]'>
          <Skeleton className='h-4' />
        </div>
        <div className='max-w-[430px]'>
          <Skeleton className='h-4' />
        </div>
      </div>
    </div>
  );
};
