import { IconProps } from '../iconProps';

export const LoudSpeaker: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 21 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.75 4.74984H12.0187C11.7459 4.7339 6.99188 4.39921 2.46469 0.602336C2.24607 0.418729 1.97959 0.301324 1.69656 0.263912C1.41353 0.226501 1.12571 0.270637 0.866889 0.391135C0.608073 0.511634 0.38902 0.70349 0.235461 0.944167C0.081903 1.18484 0.00021725 1.46434 0 1.74984V16.7498C3.88961e-05 17.0354 0.0815953 17.315 0.235084 17.5558C0.388572 17.7967 0.607614 17.9887 0.866465 18.1093C1.12532 18.2299 1.41322 18.2741 1.69633 18.2367C1.97945 18.1994 2.24601 18.0819 2.46469 17.8983C6.00563 14.9283 9.68344 14.077 11.25 13.8389V16.8126C11.2497 17.0598 11.3105 17.3032 11.4269 17.5213C11.5434 17.7393 11.7119 17.9252 11.9175 18.0623L12.9487 18.7495C13.1481 18.8826 13.3764 18.9659 13.6145 18.9926C13.8527 19.0193 14.0938 18.9885 14.3176 18.9028C14.5414 18.8172 14.7414 18.6791 14.901 18.5003C15.0605 18.3214 15.1748 18.107 15.2344 17.8748L16.3378 13.7161C17.4785 13.5759 18.5224 13.0047 19.2553 12.1194C19.9882 11.2342 20.3546 10.1021 20.2795 8.95529C20.2043 7.80848 19.6932 6.7339 18.8509 5.95192C18.0087 5.16995 16.8992 4.73985 15.75 4.74984ZM13.7812 17.4895V17.4998L12.75 16.8126V13.7498H14.775L13.7812 17.4895ZM15.75 12.2498H12.75V6.24984H15.75C16.5456 6.24984 17.3087 6.56591 17.8713 7.12852C18.4339 7.69112 18.75 8.45419 18.75 9.24984C18.75 10.0455 18.4339 10.8085 17.8713 11.3712C17.3087 11.9338 16.5456 12.2498 15.75 12.2498Z'
        fill={fill ?? 'white'}
      />
    </svg>
  );
};
