import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { AppKeyConstants } from '@/utils/constants';

interface Team {
  teamId?: string;
  name?: string;
  _id?: string;
}

interface Company {
  companyId?: string;
  companyName?: string;
}

interface User {
  company?: Company;
  _id?: string;
  email?: string;
  roles?: string;
  invited?: boolean;
  passwordSet?: boolean;
  status?: string;
  authId?: string;
  deleted?: boolean;
  teams?: Team[];
  createdAt?: string;
  updatedAt?: string;
  profilePicture?: string;
  mfa?: unknown[];
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  nickname?: string;
}

interface UserDetailInterface {
  accessToken?: string;
  refreshToken?: string;
  expiresIn?: number;
  tokenType?: string;
  user?: User;
}

type TUserDetail = UserDetailInterface | null;

interface UserDetailInterfaceActions {
  userDetails: TUserDetail;
  setUserDetails: (userDetails: TUserDetail) => void;
  updateUser: (updatedFields: Partial<User>) => void;
  setCredentials: (accessToken: string) => void;
  removeUserDetails: () => void;
}

const initialState: UserDetailInterfaceActions = {
  userDetails: null,
  setUserDetails: () => {},
  updateUser: () => {},
  setCredentials: () => {},
  removeUserDetails: () => {},
};

const useUserDetailsStore = create<UserDetailInterfaceActions>()(
  persist(
    (set) => ({
      ...initialState,
      setUserDetails: (userDetails: TUserDetail) => {
        set({ userDetails });
      },
      updateUser: (updatedFields: Partial<User>) => {
        set((state) => ({
          userDetails: {
            ...state.userDetails,
            user: {
              ...state.userDetails?.user,
              ...updatedFields,
            },
          },
        }));
      },
      setCredentials: (accessToken: string) => {
        set((state) => ({
          userDetails: {
            ...state.userDetails,
            accessToken,
          },
        }));
      },
      removeUserDetails: () => {
        set({ userDetails: null });
        localStorage.setItem(AppKeyConstants.LOGOUT, Date.now().toString());
      },
    }),
    {
      name: 'user-detail',
    },
  ),
);

export default useUserDetailsStore;
