import { useMutation, useQuery } from '@tanstack/react-query';

import {
  TPasswordSchema,
  TVerifiyCodeFormSchema,
  TVerifiyCodePersonalSettingsSchema,
} from '@/schemas';
import { personalSettingAPI } from '@/services/api/personal-setting/personalSetting.service';
import { FileUploadAPI } from '@/services/api/file-upload/file-upload.service';

export const useSinglePersonalSetting = ({ userId }: { userId: string }) => {
  return useQuery({
    queryKey: ['person', userId],
    queryFn: () => {
      return personalSettingAPI.getPersonalDetails({ userId });
    },
    // staleTime: 60000,
    enabled: !!userId,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
};

export const useUpdatePassword2FA = () => {
  return useMutation({
    mutationFn: ({
      updatePassword2FA,
      userId,
    }: {
      updatePassword2FA: TPasswordSchema;
      userId: string;
    }) => {
      return personalSettingAPI.addPassword2FA(updatePassword2FA, userId);
    },
  });
};

export const useUpdatePasswordSMS = () => {
  return useMutation({
    mutationFn: (updatePasswordSMS: TPasswordSchema) =>
      personalSettingAPI.addPasswordSMS(updatePasswordSMS),
  });
};

export const useQRCodeVerification = () => {
  return useMutation({
    mutationFn: (verificationCode: TVerifiyCodeFormSchema) =>
      personalSettingAPI.addVerificationCode(verificationCode),
  });
};

export const useVerificationSMS = () => {
  return useMutation({
    mutationFn: (verificationCode: TVerifiyCodePersonalSettingsSchema) =>
      personalSettingAPI.addVerificationCodeSMS(verificationCode),
  });
};

export const useUploadUserProfileImage = () => {
  return useMutation({
    mutationFn: ({ file, userId }: { file: File; userId: string }) => {
      const formData = new FormData();
      formData.append('file', file);
      return FileUploadAPI.uploadUserProfileImage(formData, userId);
    },
  });
};
