import { useState } from 'react';

export const useToggleState = (initialState = false) => {
  const [state, setState] = useState<boolean>(initialState);

  const toggleState = () => {
    setState((prevState) => !prevState);
  };

  return { state, setState, toggleState };
};
