import { useQueryParams } from '@/components/hooks';
import { ErrorPageContent } from '@/pages/errorPage';
import { QUERY_CONSTANTS, ROUTES } from '@/utils/constants';

import { PaymentCallbackType } from '@/utils/constants/payment.constant';
import { PaymentSuccessPage } from '@/pages/payment/success-page';
import { PaymentErrorPage } from '@/pages/payment/error-page';

export function CreditPaymentCallbackPage() {
  const { queryParams } = useQueryParams();

  const callbackType = queryParams.get(QUERY_CONSTANTS.STRIPE.CALLBACK_TYPE);
  const orderId = queryParams.get(QUERY_CONSTANTS.STRIPE.ORDER_ID);

  if (callbackType === PaymentCallbackType.SUCCESS)
    return <PaymentSuccessPage orderId={orderId} />;

  if (callbackType === PaymentCallbackType.ERROR) return <PaymentErrorPage />;

  return (
    <ErrorPageContent
      route={`/${ROUTES.PRODUCT.ALL_PRODUCTS}`}
      pageTitle='Products'
    />
  );
}
