export const IconAmex = () => {
  return (
    <svg
      width='42'
      height='28'
      viewBox='0 0 42 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1'
        y='0.5'
        width='39.8333'
        height='27'
        rx='3.5'
        fill='#1F72CD'
        stroke='#D9D9D9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.29528 9.91699L4.58398 18.3715H9.02693L9.57772 17.0235H10.8367L11.3875 18.3715H16.2779V17.3427L16.7137 18.3715H19.2434L19.6792 17.3209V18.3715H29.85L31.0867 17.0585L32.2447 18.3715L37.4686 18.3824L33.7456 14.1679L37.4686 9.91699H32.3257L31.1218 11.2057L30.0003 9.91699H18.9358L17.9857 12.0992L17.0133 9.91699H12.5797V10.9108L12.0864 9.91699H8.29528ZM23.4832 11.1175H29.3238L31.1101 13.1039L32.9541 11.1175H34.7404L32.0262 14.1667L34.7404 17.1807H32.873L31.0867 15.1712L29.2333 17.1807H23.4832V11.1175ZM24.9255 13.4811V12.3737V12.3726H28.5698L30.16 14.1437L28.4994 15.9245H24.9255V14.7155H28.1118V13.4811H24.9255ZM9.15497 11.1175H11.3207L13.7824 16.8506V11.1175H16.1548L18.0562 15.2281L19.8085 11.1175H22.1691V17.1842H20.7327L20.721 12.4304L18.6269 17.1842H17.3421L15.2363 12.4304V17.1842H12.2813L11.7211 15.8242H8.6946L8.13556 17.183H6.55235L9.15497 11.1175ZM9.21138 14.5669L10.2085 12.144L11.2045 14.5669H9.21138Z'
        fill='white'
      />
    </svg>
  );
};
