import React from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '@/utils/cn';

export interface NumberInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const numberInputVariants = cva(
  'w-full rounded border border-gray-200 px-3 py-1 text-base font-normal text-gray-800 outline-none placeholder:text-gray-400 disabled:cursor-not-allowed disabled:bg-gray-100',
);

export function NumberInput({ className, ...props }: NumberInputProps) {
  return (
    <input
      type='number'
      className={cn(numberInputVariants({}), className)}
      {...props}
    />
  );
}
