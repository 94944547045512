import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/cn';

export const buttonVariants = cva(
  'flex justify-center items-center gap-x-2 rounded bg-transparent font-semibold whitespace-nowrap duration-300 disabled:cursor-not-allowed disabled:pointer-events-auto',
  {
    variants: {
      intent: {
        solid: 'text-white',
        outline: 'border',
        tertiary: 'border-none',
      },
      variant: {
        primary:
          'border-primary-600 disabled:bg-primary-50 disabled:text-primary-300 disabled:border-primary-50',
        yellow:
          'border-yellow-600 disabled:bg-yellow-50 disabled:text-yellow-300 disabled:border-yellow-50',
        gray: 'border-gray-600 disabled:bg-gray-50 disabled:text-gray-300 disabled:border-gray-50',
        red: 'border-red-600 disabled:bg-red-50 disabled:text-red-300 disabled:border-red-50',
        green:
          'border-green-600 disabled:bg-green-50 disabled:text-green-300 disabled:border-green-50',
        blue: 'border-blue-600 disabled:bg-blue-50 disabled:text-blue-300 disabled:border-blue-50',
      },
      size: {
        xs: 'px-9 py-2 text-sm',
        md: 'px-9 py-[11px] text-base',
        lg: 'px-9 py-4 text-xl',
        icon: 'p-2.5',
      },
    },
    compoundVariants: [
      {
        intent: 'solid',
        variant: 'primary',
        className:
          'bg-primary-600 hover:bg-primary-500 hover:border-primary-500 focus:bg-primary-800 focus:border-primary-800 border',
      },
      {
        intent: 'outline',
        variant: 'primary',
        className: 'border-primary-600 text-primary-600',
      },
      {
        intent: 'tertiary',
        variant: 'primary',
        className: 'text-primary-600',
      },

      {
        intent: 'solid',
        variant: 'yellow',
        className:
          'bg-yellow-600 hover:bg-yellow-500 hover:border-yellow-500 focus:bg-yellow-800 focus:border-yellow-800 border',
      },
      {
        intent: 'outline',
        variant: 'yellow',
        className: 'border-yellow-600 text-yellow-600',
      },
      {
        intent: 'tertiary',
        variant: 'yellow',
        className: 'text-yellow-600',
      },

      {
        intent: 'solid',
        variant: 'gray',
        className:
          'bg-gray-600 hover:bg-gray-500 hover:border-gray-500 focus:bg-gray-800 focus:border-gray-800 border',
      },
      {
        intent: 'outline',
        variant: 'gray',
        className: 'border-gray-600 text-gray-600',
      },
      {
        intent: 'tertiary',
        variant: 'gray',
        className: 'text-gray-600',
      },

      {
        intent: 'solid',
        variant: 'red',
        className:
          'bg-red-600 hover:bg-red-500 hover:border-red-400 focus:bg-red-800 focus:border-red-800 border',
      },
      {
        intent: 'outline',
        variant: 'red',
        className: 'border-red-600 text-red-600',
      },
      {
        intent: 'tertiary',
        variant: 'red',
        className: 'text-red-600',
      },

      {
        intent: 'solid',
        variant: 'green',
        className:
          'bg-green-600 hover:bg-green-500 hover:border-green-500 focus:bg-green-800 border focus:border-green-800',
      },
      {
        intent: 'outline',
        variant: 'green',
        className: 'border-green-600 text-green-600',
      },
      {
        intent: 'tertiary',
        variant: 'green',
        className: 'text-green-600',
      },

      {
        intent: 'solid',
        variant: 'blue',
        className:
          'bg-blue-600 hover:bg-blue-500 hover:border-blue-500 focus:bg-blue-800 border focus:border-blue-800',
      },
      {
        intent: 'outline',
        variant: 'blue',
        className: 'border-blue-600 text-blue-600',
      },
      {
        intent: 'tertiary',
        variant: 'blue',
        className: 'text-blue-600',
      },
    ],
    defaultVariants: {
      intent: 'solid',
      variant: 'primary',
      size: 'md',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, intent, ...props }, ref) => {
    return (
      <button
        className={cn(buttonVariants({ intent, variant, size }), className)}
        ref={ref}
        data-cy={`${variant}-button, type ${intent}`}
        {...props}
      />
    );
  },
);

Button.displayName = 'Button';
