import React from 'react';
import { IconProps } from '../iconProps';

export const IconThumb: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.9375 7.51125C21.7263 7.27193 21.4666 7.08028 21.1757 6.94903C20.8847 6.81778 20.5692 6.74994 20.25 6.75H15V5.25C15 4.25544 14.6049 3.30161 13.9017 2.59835C13.1984 1.89509 12.2446 1.5 11.25 1.5C11.1107 1.4999 10.9741 1.53862 10.8555 1.61181C10.7369 1.685 10.6411 1.78977 10.5787 1.91438L7.03687 9H3C2.60218 9 2.22064 9.15804 1.93934 9.43934C1.65804 9.72064 1.5 10.1022 1.5 10.5V18.75C1.5 19.1478 1.65804 19.5294 1.93934 19.8107C2.22064 20.092 2.60218 20.25 3 20.25H19.125C19.6732 20.2502 20.2025 20.0503 20.6137 19.6878C21.0249 19.3253 21.2896 18.8251 21.3581 18.2812L22.4831 9.28125C22.523 8.9644 22.495 8.64268 22.4009 8.3375C22.3068 8.03232 22.1488 7.75066 21.9375 7.51125ZM3 10.5H6.75V18.75H3V10.5ZM20.9944 9.09375L19.8694 18.0938C19.8465 18.275 19.7583 18.4418 19.6212 18.5626C19.4842 18.6834 19.3077 18.7501 19.125 18.75H8.25V9.92719L11.6916 3.04313C12.2016 3.14521 12.6606 3.4209 12.9903 3.82326C13.32 4.22562 13.5001 4.7298 13.5 5.25V7.5C13.5 7.69891 13.579 7.88968 13.7197 8.03033C13.8603 8.17098 14.0511 8.25 14.25 8.25H20.25C20.3564 8.24996 20.4616 8.27258 20.5587 8.31634C20.6557 8.36011 20.7423 8.42402 20.8127 8.50383C20.8831 8.58363 20.9357 8.67752 20.967 8.77923C20.9984 8.88094 21.0077 8.98816 20.9944 9.09375Z'
        fill={fill}
      />
    </svg>
  );
};
