import {
  Control,
  Controller,
  FieldErrors,
  FieldName,
  FieldValues,
  Path,
} from 'react-hook-form';
import {
  ErrorMessage,
  FieldValuesFromFieldErrors,
} from '@hookform/error-message';
import { useEffect } from 'react';

import { Select } from '../../select';
import { Label } from '@/components/ui/label';
import { useFetchStateByCountry } from '@/components/hooks';

export interface StateSelectorProps<T extends FieldValues> {
  open?: boolean;
  disabled?: boolean;
  onToggle?: () => void;
  label?: string;
  name: Path<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  errorFieldName: FieldName<FieldValuesFromFieldErrors<T>>;
  selectedCountry: string;
}

export const StateSelector = <T extends FieldValues>({
  label,
  control,
  name,
  errors,
  errorFieldName,
  selectedCountry,
  disabled = false,
}: StateSelectorProps<T>) => {
  const { mutate: fetchStates, data: countryStates } = useFetchStateByCountry();

  const stateOptions = countryStates?.map((state) => ({
    value: state.name,
    label: state.name,
  }));

  useEffect(() => {
    fetchStates(selectedCountry);
  }, [selectedCountry]);

  return (
    <div data-cy='state picker'>
      {label && <Label className='mb-1'>{label}</Label>}
      <Controller
        name={name}
        control={control}
        render={({ field: { name: fieldName, onChange, onBlur, value } }) => {
          return (
            <>
              <Select
                name={fieldName}
                onBlur={onBlur}
                options={stateOptions}
                placeholder={'Select State'}
                closeMenuOnSelect
                value={stateOptions?.filter((role) => role.value === value)}
                onChange={(newValue: unknown) => {
                  // Handle the string value directly
                  const selectedValue =
                    (newValue as { value: string }).value || '';
                  onChange(selectedValue);
                }}
                className={errors.state ? '!border-red-600 !bg-red-50' : ''}
                isDisabled={!selectedCountry || disabled}
                data-cy='state-select'
              />
              <ErrorMessage
                errors={errors}
                name={errorFieldName}
                render={({ message }) => (
                  <div className="errorinfo error-message before:text-danger-500 mt-2 text-red-500 before:mr-2 before:inline before:content-['⚠']">
                    {' ' + message}
                  </div>
                )}
              />
            </>
          );
        }}
      />
    </div>
  );
};
