import React from 'react';
import { IconProps } from '../iconProps';

export const IconCrown: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.6469 6.90001C21.3535 6.75623 21.021 6.71312 20.7007 6.77735C20.3804 6.84158 20.0902 7.00956 19.875 7.25532L16.7184 10.6575L13.3622 3.13032C13.362 3.1272 13.362 3.12407 13.3622 3.12094C13.2422 2.86064 13.0501 2.64018 12.8087 2.48565C12.5673 2.33112 12.2866 2.24899 12 2.24899C11.7134 2.24899 11.4327 2.33112 11.1913 2.48565C10.9499 2.64018 10.7578 2.86064 10.6378 3.12094C10.638 3.12407 10.638 3.1272 10.6378 3.13032L7.28155 10.6575L4.12499 7.25532C3.9084 7.00952 3.61728 6.84138 3.29613 6.77661C2.97499 6.71184 2.64146 6.75399 2.34653 6.89662C2.05159 7.03926 1.81146 7.27453 1.66283 7.56649C1.51419 7.85845 1.46523 8.19104 1.52343 8.51344C1.52343 8.52376 1.52343 8.53313 1.52999 8.54344L3.65624 18.2813C3.72169 18.6242 3.90469 18.9335 4.17371 19.1559C4.44272 19.3784 4.7809 19.5001 5.12999 19.5H18.8709C19.2199 19.4999 19.5578 19.3781 19.8266 19.1556C20.0955 18.9332 20.2783 18.624 20.3437 18.2813L22.47 8.54344C22.47 8.53313 22.47 8.52376 22.4766 8.51344C22.5359 8.19064 22.4866 7.85728 22.3365 7.5654C22.1864 7.27353 21.9439 7.03955 21.6469 6.90001ZM18.8766 17.97L18.8709 18H5.12905L5.12343 17.97L2.99999 8.25001L3.01312 8.26501L6.95061 12.5063C7.03493 12.5974 7.14054 12.6661 7.25797 12.7064C7.37541 12.7466 7.50099 12.7571 7.62346 12.7368C7.74593 12.7165 7.86146 12.6662 7.95968 12.5903C8.05791 12.5144 8.13575 12.4153 8.18624 12.3019L12 3.75001L15.8147 12.3047C15.8652 12.4181 15.943 12.5172 16.0412 12.5931C16.1395 12.669 16.255 12.7194 16.3775 12.7396C16.4999 12.7599 16.6255 12.7494 16.7429 12.7092C16.8604 12.6689 16.966 12.6002 17.0503 12.5091L20.9878 8.26782L21 8.25001L18.8766 17.97Z'
        fill={fill ? fill : '#000000'}
      />
    </svg>
  );
};
