import { useSearchParams } from 'react-router-dom';
import { ColumnDef, flexRender, Table } from '@tanstack/react-table';

import {
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableLayout,
  TableHeader,
  Table as HTMLTable,
} from '@/components';
import { cn } from '@/utils/cn';
import { TABLE } from '@/utils/constants';
import IconsComponent from '@/components/ui/icons/IconsComponent';
import { AppKeyConstants } from '@/utils/constants/app.key.constants';

export interface DataTableProps<T> {
  table: Table<T>;
  columns: ColumnDef<T>[];
  className?: string;
}

/**
 * @description DataTable component to render generic table based on the provided columns and data.
 */

export function DataTable<T>({ columns, table, className }: DataTableProps<T>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = new URLSearchParams(searchParams);

  const orderBy = params.get(TABLE.ORDER_BY) ?? '';

  const order = params.get(TABLE.ORDER) ?? '';

  const toggleSorting = (columnId: string) => {
    let orderState = TABLE.ASC;

    if (orderBy === columnId) {
      if (order === TABLE.ASC) {
        orderState = TABLE.DESC;
      } else {
        params.delete(TABLE.ORDER_BY);
        params.delete(TABLE.ORDER);

        setSearchParams(params);
        return;
      }
    }

    params.set(TABLE.ORDER_BY, columnId);
    params.set(TABLE.ORDER, orderState);

    setSearchParams(params);
  };
  const renderSortingIcon = (columnId: string) => {
    if (orderBy === columnId) {
      return order === TABLE.ASC ? (
        <IconsComponent
          icon='reg-sort-ascending'
          fill='gray'
          size='sm'
        />
      ) : (
        <IconsComponent
          icon='reg-sort-descending'
          fill='gray'
          size='sm'
        />
      );
    }
    return;
  };

  return (
    <TableLayout>
      <div
        className={cn(
          'w-full border border-gray-100 shadow-sm',
          !window.location.pathname.includes(AppKeyConstants.CART) &&
            'overflow-x-auto',
          className,
        )}
      >
        <HTMLTable>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      data-cy={`table-header ${header.column.id}`}
                    >
                      {header.isPlaceholder ? null : (
                        <button
                          {...{
                            className: `${header.column.getCanSort() && 'gap-x-1 cursor-pointer'} flex items-center cursor-default`,
                            onClick: () => {
                              if (!header.column.getCanSort()) return;
                              toggleSorting(header.id);
                            },
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          <span>
                            {header.column.getCanSort()
                              ? renderSortingIcon(header.id)
                              : null}
                          </span>
                        </button>
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='text-center'
                >
                  No matching records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </HTMLTable>
      </div>
    </TableLayout>
  );
}
