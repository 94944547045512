import { ROUTES } from '@/utils/constants';
import { PaymentFallBack } from '@/components/ui/stripe-fallback';
import OrderSuccessIllustration from '@/assets/images/illustration/orderSuccess.svg';

export function PaymentSuccessPage({ orderId }: { orderId: string | null }) {
  return (
    <div className='flex min-h-[70vh] items-center justify-center'>
      <PaymentFallBack
        illustration={OrderSuccessIllustration}
        title='Order Placed Successfully'
        description='Your order has been received and is being processed. You will receive a confirmation email shortly with your order details.'
        redirect={{
          to: `/${ROUTES.ORDERS.MY_ORDERS}/${orderId}`,
          label: 'View Order Details',
        }}
      />
    </div>
  );
}
