import React from 'react';
import { IconProps } from '../iconProps';

export const IconPhone: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.8472 14.8556L16.4306 12.8766L16.4184 12.8709C16.1892 12.7729 15.939 12.7335 15.6907 12.7564C15.4424 12.7793 15.2037 12.8638 14.9963 13.0022C14.9718 13.0183 14.9484 13.0358 14.9259 13.0547L12.6441 15C11.1984 14.2978 9.70595 12.8166 9.00376 11.3897L10.9519 9.07312C10.9706 9.04968 10.9884 9.02624 11.0053 9.00093C11.1407 8.79403 11.2229 8.55686 11.2445 8.31053C11.2661 8.0642 11.2264 7.81636 11.1291 7.58906V7.57781L9.14438 3.15374C9.0157 2.8568 8.79444 2.60944 8.51362 2.44859C8.2328 2.28774 7.9075 2.22202 7.58626 2.26124C6.31592 2.42841 5.14986 3.05228 4.30588 4.01633C3.4619 4.98039 2.99771 6.2187 3.00001 7.49999C3.00001 14.9437 9.05626 21 16.5 21C17.7813 21.0023 19.0196 20.5381 19.9837 19.6941C20.9477 18.8501 21.5716 17.6841 21.7388 16.4137C21.7781 16.0926 21.7125 15.7674 21.5518 15.4866C21.3911 15.2058 21.144 14.9845 20.8472 14.8556ZM16.5 19.5C13.3185 19.4965 10.2682 18.2311 8.01856 15.9814C5.76888 13.7318 4.50348 10.6815 4.50001 7.49999C4.49648 6.58451 4.82631 5.69905 5.42789 5.00897C6.02947 4.31888 6.86167 3.87137 7.76907 3.74999C7.7687 3.75373 7.7687 3.7575 7.76907 3.76124L9.73782 8.16749L7.80001 10.4869C7.78034 10.5095 7.76247 10.5336 7.74657 10.5591C7.60549 10.7755 7.52273 11.0248 7.5063 11.2827C7.48988 11.5406 7.54035 11.7983 7.65282 12.0309C8.5022 13.7681 10.2525 15.5053 12.0084 16.3537C12.2428 16.4652 12.502 16.5139 12.7608 16.4952C13.0196 16.4764 13.2692 16.3909 13.485 16.2469C13.5091 16.2307 13.5322 16.2131 13.5544 16.1944L15.8334 14.25L20.2397 16.2234C20.2397 16.2234 20.2472 16.2234 20.25 16.2234C20.1301 17.1321 19.6833 17.966 18.9931 18.5691C18.3028 19.1721 17.4166 19.5031 16.5 19.5Z'
        fill={fill}
      />
    </svg>
  );
};
