import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/cn';
import { InputError } from '@/components';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {
  name: string;
}

export const textareaVariants = cva(
  'outline-none w-full duration-300 border rounded border-gray-200 px-4 py-3 text-base font-normal hover:shadow-sm focus:border-gray-800 focus:shadow-base placeholder:text-gray-400 text-gray-800 disabled:bg-gray-100 disabled:cursor-not-allowed h-[136px]',
);

export function Textarea({ className, name, ...props }: TextareaProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <textarea
        {...register(name)}
        {...props}
        className={cn(
          textareaVariants({}),
          Object.prototype.hasOwnProperty.call(errors, name) &&
            'border-red-600 bg-red-50',
          className,
        )}
        data-cy='textarea'
      />
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => <InputError message={message} />}
      />
    </>
  );
}
