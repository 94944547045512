import { useQuery } from '@tanstack/react-query';

import { OrdersAPI } from '@/services/api/orders';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';

export function useGetOrderAnalytics() {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.ORDER_ANALYTICS],
    queryFn: () => OrdersAPI.getOrderAnalytics(),
    select: ({ data }) => data,
  });
}
