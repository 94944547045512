import { AsyncImage } from 'loadable-image';
import placholderImage from '@/assets/images/placeholder-image.png';

interface AsyncImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

/**
 * @description AsyncLoaderImage component is used to load images asynchronously
 */
export function AsyncLoaderImage({ src, ...props }: AsyncImageProps) {
  return (
    <AsyncImage
      {...props}
      src={src ?? placholderImage}
      loader={<div className='animate-pulse bg-gray-200' />}
      error={<div style={{ background: '#eee' }} />}
    />
  );
}
