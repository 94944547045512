import { IconProps } from '../iconProps';

export const UsersAndTeams: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.125 9.25C14.125 10.0658 13.8831 10.8634 13.4298 11.5417C12.9766 12.2201 12.3323 12.7488 11.5786 13.061C10.8248 13.3732 9.99543 13.4549 9.19526 13.2957C8.39508 13.1366 7.66008 12.7437 7.08319 12.1668C6.5063 11.5899 6.11343 10.8549 5.95427 10.0547C5.7951 9.25458 5.87679 8.42518 6.189 7.67143C6.50121 6.91769 7.02992 6.27345 7.70828 5.82019C8.38663 5.36693 9.18416 5.125 10 5.125C11.0936 5.12624 12.1421 5.56124 12.9154 6.33455C13.6888 7.10787 14.1238 8.15636 14.125 9.25ZM19.75 10C19.75 11.9284 19.1782 13.8134 18.1068 15.4168C17.0355 17.0202 15.5127 18.2699 13.7312 19.0078C11.9496 19.7458 9.98919 19.9389 8.09787 19.5627C6.20656 19.1865 4.46928 18.2579 3.10571 16.8943C1.74215 15.5307 0.813554 13.7934 0.437348 11.9021C0.061142 10.0108 0.254225 8.05042 0.992179 6.26884C1.73013 4.48726 2.97982 2.96451 4.58319 1.89317C6.18657 0.821828 8.07164 0.25 10 0.25C12.585 0.25273 15.0634 1.28084 16.8913 3.10872C18.7192 4.93661 19.7473 7.41498 19.75 10ZM18.25 10C18.2488 8.88956 18.0237 7.79077 17.5881 6.76934C17.1525 5.7479 16.5154 4.82481 15.7148 4.05525C14.9143 3.2857 13.9668 2.68549 12.929 2.29053C11.8911 1.89556 10.7843 1.71395 9.67469 1.75656C5.25907 1.92719 1.73782 5.605 1.75 10.0234C1.75424 12.0349 2.49609 13.9749 3.835 15.4759C4.38028 14.6851 5.07292 14.0068 5.875 13.4781C5.94339 13.433 6.02469 13.4114 6.10646 13.4169C6.18824 13.4223 6.26599 13.4543 6.32782 13.5081C7.34705 14.3897 8.6496 14.8749 9.99719 14.8749C11.3448 14.8749 12.6473 14.3897 13.6666 13.5081C13.7284 13.4543 13.8061 13.4223 13.8879 13.4169C13.9697 13.4114 14.051 13.433 14.1194 13.4781C14.9225 14.0065 15.6161 14.6848 16.1622 15.4759C17.5077 13.9694 18.251 12.0199 18.25 10Z'
        fill={fill ?? 'white'}
      />
    </svg>
  );
};
