import axios from 'axios';

import {
  V12_COUNTRY_STATE_CITY_KEY,
  V12_COUNTRY_STATE_CITY_URL,
} from '@/utils/constants';

const countryStateCityApi = axios.create({
  baseURL: V12_COUNTRY_STATE_CITY_URL,
  headers: {
    'X-CSCAPI-KEY': V12_COUNTRY_STATE_CITY_KEY,
  },
});

export function countryStateCityFetchApi(
  endpoint: string,
  params?: string | object,
) {
  return countryStateCityApi.get(endpoint, { params });
}

export function countryStateCityPostApi(
  endpoint: string,
  params?: string | object,
) {
  return countryStateCityApi.post(endpoint, params);
}
