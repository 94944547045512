import React from 'react';

import { IconProps } from '../iconProps';

export const Lock: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.95422 4.03641C9.95422 4.09831 9.95422 4.15608 9.9501 4.21798C9.4718 4.11481 8.97288 4.03228 8.45747 3.96626C8.42037 2.63337 7.33182 1.56458 5.99175 1.56458C4.65169 1.56458 3.55902 2.63337 3.52603 3.96626C3.01062 4.03228 2.5117 4.11481 2.0334 4.21798C2.02928 4.15608 2.02928 4.09418 2.02928 4.03228C2.02928 1.84106 3.80229 0.0625 5.99588 0.0625C8.17709 0.0707532 9.95422 1.84519 9.95422 4.03641ZM6.00825 15.9375C13.0096 12.9911 11.9416 5.48897 11.9416 5.48897C10.3088 4.87823 8.24719 4.51509 6 4.51509C3.75281 4.51509 1.69117 4.87823 0.0583529 5.48897C0.0583529 5.48897 -1.00958 12.9911 5.99175 15.9375H6.00825Z'
        fill={fill}
      />
    </svg>
  );
};
