import { Link } from 'react-router-dom';

import { cn } from '@/utils/cn';
import { buttonVariants, ButtonProps } from '@/components';
import IconsComponent from '@/components/ui/icons/IconsComponent';

interface PaginationLinkProps
  extends React.ComponentProps<typeof Link>,
    Pick<ButtonProps, 'size'> {}

const PaginationLink = ({
  className,
  size = 'icon',
  ...props
}: PaginationLinkProps) => (
  <Link
    className={cn(
      buttonVariants({
        intent: 'outline',
        size,
      }),
      'border-gray-200 p-1 aria-disabled:pointer-events-none aria-disabled:border-gray-50 aria-disabled:bg-gray-50',
      className,
    )}
    {...props}
  />
);

export const PaginationPrevious = ({
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label='Go to previous page'
    {...props}
  >
    <IconsComponent
      icon='reg-chevron-left'
      size='sm'
    />
  </PaginationLink>
);

export const PaginationNext = ({
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label='Go to next page'
    {...props}
  >
    <IconsComponent
      icon='reg-chevron-right'
      size='sm'
    />
  </PaginationLink>
);
