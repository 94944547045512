import React from 'react';

import { IconProps } from '../iconProps';

export const GoogleAuthenticator: React.FC<IconProps> = ({
  size,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.5 16.0001C27.5 17.0702 26.6326 17.9376 25.5625 17.9376H18.875L16 12.1245L19.1032 6.74981C19.6382 5.82317 20.8231 5.50566 21.7497 6.04061L21.7501 6.04082C22.6768 6.57581 22.9944 7.76078 22.4594 8.6875L19.3563 14.0626H25.5625C26.6326 14.0626 27.5 14.9301 27.5 16.0001Z'
        fill='#1A73E8'
      />
      <path
        d='M21.7506 25.9593L21.7503 25.9595C20.8236 26.4945 19.6387 26.177 19.1037 25.2503L16.0005 19.8756L12.8974 25.2503C12.3623 26.177 11.1775 26.4945 10.2508 25.9595L10.2504 25.9593C9.32367 25.4243 9.00611 24.2394 9.54112 23.3126L12.6443 17.9375L16.0005 17.8125L19.3568 17.9375L22.4599 23.3126C22.9949 24.2394 22.6774 25.4243 21.7506 25.9593Z'
        fill='#EA4335'
      />
      <path
        d='M16.0005 12.1245L15.1255 14.5001L12.6443 14.0626L9.54112 8.6875C9.00611 7.76078 9.32367 6.57581 10.2504 6.04082L10.2508 6.04061C11.1775 5.50566 12.3623 5.82317 12.8974 6.74981L16.0005 12.1245Z'
        fill='#FBBC04'
      />
      <path
        d='M15.5 14.0625L13.25 17.9375H6.4375C5.36745 17.9375 4.5 17.0701 4.5 16C4.5 14.93 5.36745 14.0625 6.4375 14.0625H15.5Z'
        fill='#34A853'
      />
      <path
        d='M19.357 17.9376H12.6445L16.0008 12.1245L19.357 17.9376Z'
        fill='#185DB7'
      />
    </svg>
  );
};
