import { z } from 'zod';

//Time format
export const timeFormatEnum = z.enum(['HOUR_12', 'HOUR_24']);

export type TTimeFormat = z.infer<typeof timeFormatEnum>;

export const dateEnum = z.enum([
  'MM_DD_YYYY',
  'DD_MM_YYYY',
  'YYYY_MM_DD',
  'DD_MMMM_YYYY',
]);
export type TDate = z.infer<typeof dateEnum>;

// Date format
export const DateFormatSchema = z.object({
  dateFormat: dateEnum,
});

export type TDateFormat = z.infer<typeof DateFormatSchema>;

// Shoe size
export const shoeSizeType = z.enum(['UK', 'US', 'EU']);
export type TShoeSize = z.infer<typeof shoeSizeType>;

export const ShoeSizeFormat = z.object({
  shoeSizeType: shoeSizeType,
});
export type TShoeSizeFormat = z.infer<typeof ShoeSizeFormat>;
