import { QUERY_CONSTANTS } from '@/utils/constants';
import { STATUS } from '@/utils/constants/promotion.constant';
import { ActionBar } from '@/pages/marketing-resources/flash-promotions/ActionBar';
import { FlashPromotion } from '@/pages/marketing-resources/flash-promotions/FlashPromotion';
import { TabSection } from '@/components/ui/tab/TabSection';

const sections = [
  { name: 'Ongoing Promotions', id: STATUS.ACTIVE },
  { name: 'Expired Promotions', id: STATUS.INACTIVE },
];

const FlashPromptionPage = () => {
  return (
    <>
      <ActionBar />
      <TabSection
        sections={sections}
        queryParam={QUERY_CONSTANTS.STATUS}
      />
      <FlashPromotion />
    </>
  );
};

export default FlashPromptionPage;
