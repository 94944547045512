import React from 'react';
import { IconProps } from '../iconProps';

export const IconOrderConfirmation: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.2891 17.075L19.9464 18.4167C19.7796 18.5833 19.5711 18.6583 19.3543 18.6583C19.1375 18.6583 18.929 18.575 18.7622 18.4167C18.437 18.0917 18.437 17.5667 18.7622 17.2417L19.838 16.1667H13.6668C13.2082 16.1667 12.8329 15.7917 12.8329 15.3333C12.8329 14.875 13.2082 14.5 13.6668 14.5H19.838L18.7622 13.425C18.437 13.1 18.437 12.575 18.7622 12.25C19.0875 11.925 19.6128 11.925 19.9381 12.25L21.2807 13.5917C22.2398 14.55 22.2398 16.1167 21.2807 17.0833L21.2891 17.075ZM12.8412 8.66667H18.2952C18.0033 7.90833 17.5613 7.20833 16.9692 6.61667L14.0671 3.70833C13.475 3.11667 12.7745 2.675 12.0156 2.38333V7.83333C12.0156 8.29167 12.3909 8.66667 12.8496 8.66667H12.8412ZM16.8525 17.8333H13.6752C12.2992 17.8333 11.1734 16.7083 11.1734 15.3333C11.1734 13.9583 12.2992 12.8333 13.6752 12.8333H16.8525C16.8525 12.1917 17.0943 11.55 17.5864 11.0667C17.8949 10.7583 18.2702 10.5417 18.6621 10.4333V10.3333H12.8412C11.4652 10.3333 10.3394 9.20833 10.3394 7.83333V2.01667C10.206 2.00833 10.0726 2 9.93078 2H6.16971C3.86803 2 2 3.86667 2 6.16667V17.8333C2 20.1333 3.86803 22 6.16971 22H14.5091C16.0186 22 17.3445 21.1917 18.0784 19.9833C17.9033 19.875 17.7365 19.75 17.5864 19.6C17.1027 19.1167 16.8525 18.475 16.8525 17.8333Z'
        fill={fill}
      />
    </svg>
  );
};
