import { useState } from 'react';

import { Button, Modal, FormGroup } from '@/components';
import {
  useAlert,
  useScrollToTop,
  useSendPasswordResetLink,
} from '@/components/hooks';

export function ResetPassword({
  email,
  isInvited,
  isActive,
}: {
  email: string | undefined;
  isInvited?: boolean;
  isActive?: boolean;
}) {
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const { showAlert } = useAlert();
  const scrollToTop = useScrollToTop();

  const { mutate, isPending } = useSendPasswordResetLink();

  const handleSendPasswordResetLink = () => {
    mutate(
      { email },
      {
        onSuccess: ({ message }) => {
          showAlert(message as string, 'success');
        },
        onError: (error) => {
          showAlert(error.message, 'error');
        },
        onSettled: () => {
          scrollToTop();
          setShowResetPasswordModal(false);
        },
      },
    );
  };

  return (
    <FormGroup
      title='Reset Password'
      description='User will receive password reset link. After the password is reset, user will be automatically deactivated.'
      className='items-center'
    >
      <Modal
        showModal={showResetPasswordModal}
        setShowModal={setShowResetPasswordModal}
      >
        <Modal.Toggle
          intent='outline'
          variant='blue'
          className='w-full sm:w-auto'
          disabled={!isInvited || !isActive}
        >
          Send Password Reset Link
        </Modal.Toggle>
        <Modal.Content className='lg:min-w-[600px]'>
          <Modal.Title>
            <h6 className=''>Send Password Reset Link</h6>
          </Modal.Title>
          <Modal.Body className='space-y-4'>
            <p className='text-base font-bold text-gray-800'>
              Are you sure you want to send password reset link?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Modal.CloseButton>Cancel</Modal.CloseButton>
            <Button
              disabled={isPending}
              onClick={handleSendPasswordResetLink}
            >
              Send Password Reset Link
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </FormGroup>
  );
}
