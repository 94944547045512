import IconsComponent from '@/components/ui/icons/IconsComponent';

export function InputError({ message }: { message: string }) {
  return (
    <div
      className='flex items-center space-x-1'
      data-cy={`input-error ${message}`}
    >
      <IconsComponent
        size='md'
        icon='reg-question'
        fill='#dc2626'
      />
      <p className='font-normal text-red-600'>{message}</p>
    </div>
  );
}
