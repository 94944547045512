import { FC } from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';

import RoutesPage from '@/pages/index';
import {
  AbilityContext,
  defineAbilitiesForAdmin,
  defineAbilitiesForStaff,
} from './utils/helper/roles';
import { useUserStore } from './store/user-store-new';
import { Role } from './schemas';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path='/*'
      element={<RoutesPage />}
    />,
  ),
);
const App: FC = () => {
  const { user } = useUserStore();

  const userRole = user?.role;
  // Define ability based on user role
  const ability =
    userRole === Role.ADMIN || userRole === Role.SUPER_ADMIN
      ? defineAbilitiesForAdmin()
      : defineAbilitiesForStaff();

  return (
    // Provide AbilityContext only when the user is authenticated
    <AbilityContext.Provider value={ability}>
      <RouterProvider router={router} />
    </AbilityContext.Provider>
  );
};

export default App;
