import { useSearchParams } from 'react-router-dom';

export const useURLSearchParams = (paramName: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchValue = searchParams.get(paramName) ?? '';

  return {
    searchValue,
    searchParams,
    setSearchParams,
  };
};
