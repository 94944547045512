import { z } from 'zod';

import { PaginationResponseSchema } from '@/schemas/common.schema';

const SingleProductImage = z.object({
  altText: z.string().nullable(),
  id: z.union([z.string(), z.number()]),
  sort: z.number().nullable().optional(),
  type: z.string().nullable().optional(),
  url: z.string().nullable().optional(),
});

const SingleProduct = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string().nullable(),
  medias: z.array(SingleProductImage).nullable().optional(),
  sort: z.number().nullable().optional(),
});

const ProductTypesSchema = SingleProduct.extend({
  isNew: z.boolean().nullable().optional(),
  _count: z.object({
    products: z.number().nullable().optional(),
  }),
});

const ProductTypesArraySchema = z.array(ProductTypesSchema);

export type TSingleProduct = z.infer<typeof SingleProduct>;
export type TProductTypesSchema = z.infer<typeof ProductTypesSchema>;

export const AllCategoriesRequestSchema = z.void();

export const AllCategoriesResponseSchema = z.object({
  data: z.array(
    SingleProduct.extend({
      productTypes: ProductTypesArraySchema,
    }),
  ),

  meta: z.object({
    pagination: PaginationResponseSchema,
  }),
});

export const CategoriesByIdRequestSchema = z.string().uuid();
export type TCategoriesByIdRequestSchema = z.infer<
  typeof CategoriesByIdRequestSchema
>;

export const CategoriesByIdResponseSchema = z.object({
  data: SingleProduct.extend({
    productTypes: ProductTypesArraySchema,
  }),
});

export type TProductsByIdResponseSchema = z.infer<
  typeof CategoriesByIdResponseSchema
>;
