import { useEffect } from 'react';

import { useAlertStore } from '@/store';
import { isEmpty } from '@/utils/helper';
import { AlertType } from '@/components/ui/alert';

export const useAlert = () => {
  const {
    isAlertVisible,
    isAlertDismissed,
    setIsAlertVisible,
    setIsAlertDismissed,
    alertMessage,
    setAlertMessage,
    isType,
    setType,
  } = useAlertStore();

  useEffect(() => {
    if (isAlertVisible) {
      setIsAlertVisible(true);
      const timer = setTimeout(() => {
        setIsAlertVisible(false);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [isAlertVisible]);

  const showAlert = (message: string, type: AlertType) => {
    if (!isEmpty(message)) {
      setAlertMessage(message);
      setType(type);
      setIsAlertVisible(true);
      setIsAlertDismissed(false);
    }
  };

  return {
    isAlertVisible,
    isAlertDismissed,
    alertMessage,
    showAlert,
    isType: isType ?? 'default',
  };
};
