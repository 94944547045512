import { z } from 'zod';

import { emailSchema } from '@/schemas/common.schema';

export const LoginFormSchema = z.object({
  email: emailSchema,
  password: z.string().trim().min(1, { message: 'Password is required' }),
  rememberMe: z.boolean(),
});

export type TLoginFormSchema = z.infer<typeof LoginFormSchema>;

export const VerifiyCodeFormSchema = z.object({
  verificationCode: z
    .string()
    .min(1, { message: 'Verification Code is required' }),
  // mfaToken: z.string(),
});

export type TVerifiyCodeFormSchema = z.infer<typeof VerifiyCodeFormSchema>;
