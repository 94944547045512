import React from 'react';
import { IconProps } from '../iconProps';

export const IconCaretUpDown: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.80729 7.78685C6.75044 7.64983 6.73551 7.49902 6.76438 7.3535C6.79325 7.20799 6.86462 7.0743 6.96948 6.96935L11.4695 2.46935C11.5391 2.39962 11.6218 2.3443 11.7129 2.30656C11.8039 2.26882 11.9015 2.24939 12.0001 2.24939C12.0987 2.24939 12.1963 2.26882 12.2873 2.30656C12.3784 2.3443 12.4611 2.39962 12.5307 2.46935L17.0307 6.96935C17.1357 7.07425 17.2073 7.20794 17.2362 7.3535C17.2652 7.49906 17.2504 7.64996 17.1936 7.78707C17.1368 7.92419 17.0405 8.04137 16.9171 8.12377C16.7936 8.20617 16.6485 8.2501 16.5001 8.24998H7.5001C7.35177 8.24995 7.20677 8.20593 7.08345 8.1235C6.96013 8.04106 6.86403 7.92391 6.80729 7.78685ZM16.5001 15.75H7.5001C7.35168 15.7499 7.20656 15.7938 7.08312 15.8762C6.95967 15.9586 6.86345 16.0758 6.80664 16.2129C6.74982 16.35 6.73497 16.5009 6.76396 16.6465C6.79294 16.792 6.86447 16.9257 6.96948 17.0306L11.4695 21.5306C11.5391 21.6003 11.6218 21.6557 11.7129 21.6934C11.8039 21.7311 11.9015 21.7506 12.0001 21.7506C12.0987 21.7506 12.1963 21.7311 12.2873 21.6934C12.3784 21.6557 12.4611 21.6003 12.5307 21.5306L17.0307 17.0306C17.1357 16.9257 17.2073 16.792 17.2362 16.6465C17.2652 16.5009 17.2504 16.35 17.1936 16.2129C17.1368 16.0758 17.0405 15.9586 16.9171 15.8762C16.7936 15.7938 16.6485 15.7499 16.5001 15.75Z'
        fill={fill}
      />
    </svg>
  );
};
