import { z } from 'zod';

import {
  BaseResponseSchema,
  PaginationResponseSchema,
  passwordSchema,
} from '@/schemas/common.schema';

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INVITE_PENDING = 'INVITED',
}

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'STAFF',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export const UserSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  phoneNumber: z.string(),
  teams: z.array(z.string()),
  designation: z.string(),
  image: z.string().optional().nullable(),
  status: z.nativeEnum(Status),
  role: z.nativeEnum(Role),
  countryCode: z.string().nullable(),
  isInvited: z.boolean().optional(),
});

export type TUser = z.infer<typeof UserSchema>;

// Define the user team schema
const TeamSchema = z.object({
  name: z.string(),
  id: z.string(),
});

const UserAPISchema = z.object({
  accountStatus: z.nativeEnum(Status),
  avatar: z.string().nullable(),
  countryCode: z.string().nullable(),
  designation: z.string().nullable(),
  email: z.string().email(),
  firstName: z.string().nullable(),
  id: z.string().uuid(),
  isInvited: z.boolean().optional(),
  lastName: z.string().nullable(),
  phone: z.string().nullable(),
  role: z.nativeEnum(Role),
  teams: z.array(TeamSchema),
});

export const GetAllUserRequestSchema = z.void();

export const GetAllUserResponseSchema = BaseResponseSchema.extend({
  data: z.array(UserAPISchema),
  meta: z.object({
    pagination: PaginationResponseSchema,
  }),
  timestamp: z.string(),
});

export const GetUserDetailRequestSchema = z.string();

export const GetUserDetailResponseSchema = BaseResponseSchema.extend({
  data: UserAPISchema.extend({
    twoFactorSetAt: z.string().optional().nullable(),
  }),
  timestamp: z.string(),
});

// export const PersonalInfoSchema = UserSchema.pick({
//   firstName: true,
//   lastName: true,
//   phoneNumber: true,
//   designation: true,
// });

// export type TPersonalInfoForm = z.infer<typeof PersonalInfoSchema>;

// Define the company schema
const CompanySchema = z.object({
  companyId: z.string(),
  companyName: z.string(),
});

// Define the user schema
const LoginUserSchema = z.object({
  company: CompanySchema,
  _id: z.string(),
  email: z.string().email(),
  roles: z.string(),
  invited: z.boolean(),
  passwordSet: z.boolean(),
  status: z.string(),
  authId: z.string(),
  deleted: z.boolean(),
  teams: z.array(TeamSchema),
  createdAt: z.string(),
  updatedAt: z.string(),
  profilePicture: z.string(),
  mfa: z.array(z.unknown()), // Adjust this based on expected structure of mfa
});

// Define the main payload schema
export const VerifyResponsePayloadSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
  expiresIn: z.number(),
  tokenType: z.string(),
  user: LoginUserSchema,
});

export const BulkDeleteUserRequestSchema = z.object({
  users: z.array(z.string()),
});

export type TBulkDeleteUser = z.infer<typeof BulkDeleteUserRequestSchema>;

export const BulkDeleteUserResponseSchema = BaseResponseSchema;

export const BulkUpdateUserStatusRequestSchema = z.object({
  users: z.array(z.string()),
  accountStatus: z.nativeEnum(Status),
});

export type TBulkUserChangeStatus = z.infer<
  typeof BulkUpdateUserStatusRequestSchema
>;
export const BulkUpdateUserStatusResponseSchema = BaseResponseSchema;

export const BulkSendPassword2faResetLinkRequestSchema = z.object({
  users: z.array(z.string()),
});

export type TBulkSendPassword2faResetLink = z.infer<
  typeof BulkSendPassword2faResetLinkRequestSchema
>;

export const BulkSendPassword2faResetLinkResponseSchema = BaseResponseSchema;

export const BulkExportUserRequestSchema = z.object({
  users: z.array(z.string()),
});

export type TBulkExportUser = z.infer<typeof BulkExportUserRequestSchema>;

export const BulkExportUserResponseSchema = z.string(); // This now just validates that the response is a string

export const UserAcceptInviteRequestSchema = z.object({
  code: z.string(),
  email: z.string().email(),
  password: passwordSchema,
});

export type TUserAcceptInviteRequestSchema = z.infer<
  typeof UserAcceptInviteRequestSchema
>;

export const UserAcceptInviteResponseSchema = BaseResponseSchema.extend({
  payload: z.object({
    _id: LoginUserSchema.shape._id,
    status: LoginUserSchema.shape.status,
    roles: LoginUserSchema.shape.roles,
    teams: LoginUserSchema.shape.teams,
  }),
});

export const CheckEmailExistRequestSchema = z.object({
  email: z.string().email(),
});

export const CheckEmailExistResponseSchema = BaseResponseSchema.extend({
  data: z.object({
    isEmailExist: z.boolean(),
  }),
});
