import { useMutation } from '@tanstack/react-query';

import { OrdersAPI } from '@/services/api/orders';
import { TRemoveProductVariantFromOrderRequestSchema } from '@/schemas/api/orders';

export const useDeleteProductVariantFromOrder = () => {
  return useMutation({
    mutationFn: ({
      sessionId,
      deleteProductVaraintPayload,
    }: {
      sessionId: string;
      deleteProductVaraintPayload: TRemoveProductVariantFromOrderRequestSchema;
    }) =>
      OrdersAPI.deleteProductVariantFromOrder(
        sessionId,
        deleteProductVaraintPayload,
      ),
  });
};
