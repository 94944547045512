import React from 'react';
import { IconProps } from '../iconProps';

export const IconWebsite: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.747 9.41505 20.7188 6.93683 18.891 5.109C17.0632 3.28116 14.585 2.25298 12 2.25ZM20.2144 11.25H16.4803C16.3125 7.82719 14.9944 5.40656 13.9134 3.975C15.5918 4.37804 17.102 5.29628 18.2321 6.60095C19.3623 7.90562 20.0558 9.53129 20.2153 11.25H20.2144ZM9.02157 12.75H14.9784C14.7619 16.6509 12.8944 19.0416 12 19.9688C11.1047 19.0406 9.23813 16.65 9.02157 12.75ZM9.02157 11.25C9.23813 7.34906 11.1056 4.95844 12 4.03125C12.8953 4.96219 14.7619 7.35281 14.9784 11.25H9.02157ZM13.9134 20.025C14.9944 18.5934 16.3097 16.1728 16.4803 12.75H20.2153C20.0558 14.4687 19.3623 16.0944 18.2321 17.399C17.102 18.7037 15.5918 19.622 13.9134 20.025Z'
        fill={fill}
      />
    </svg>
  );
};
