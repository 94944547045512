import { z } from 'zod';

export const CreateTeamSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Team Name is required' })
    .max(255, {
      message: 'Team Name must be less than 255 characters',
    }),
  description: z
    .string()
    .trim()
    .max(1000, {
      message: 'Team Description must be less than 1000 characters',
    })
    .optional(),
});

export const CreateTeamRequestSchema = CreateTeamSchema;
export type TCreateTeamRequestSchema = z.infer<typeof CreateTeamRequestSchema>;
export const CreateTeamResponseSchema = z.object({
  message: z.string(),
  timestamp: z.string(),
  data: z.object({
    id: z.string(),
    companyId: z.string(),
    name: z.string(),
    description: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
  }),
});

export type TCreateTeam = z.infer<typeof CreateTeamSchema>;
