import { useEffect, useState } from 'react';
import { Form, useSearchParams } from 'react-router-dom';

import {
  ApplyButton,
  FilterGroup,
  handleChange,
  getStockDivisions,
} from '../index';
import {
  useFilterSubmit,
  useGetAllProducts,
  useURLSearchParams,
} from '@/components/hooks';
import { isEmpty } from '@/utils/helper';
import { EmptyMessage } from '@/components/ui/empty-message';
import { Checkbox, InputSkeletons, Label } from '@/components';
import { HttpMethods, QUERY_CONSTANTS } from '@/utils/constants';
import { ProductFilterKeys, useProductFilterStore } from '@/store';
import { ProductFilterStateKeys } from '@/store/product-filter.store';

export const AvailableStocksFilter = () => {
  const {
    toggleFilter,
    showAvailableStocks,
    setProductFilter,
    shoeAvailableStockSelected,
    setAvailableStocksOptions,
    availableStocksOptions,
  } = useProductFilterStore();

  const {
    selectedOptions,
    setSelectedOptions,
    handleSubmit,
    isButtonDisabled,
  } = useFilterSubmit(QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_AVAILABLE_STOCKS);
  const { searchValue: urlAvailableStockId } = useURLSearchParams('stock');

  const {
    data: allProductsData,
    isSuccess: allProductsDataFetchSuccess,
    isLoading: isAllProductDataLoading,
  } = useGetAllProducts();

  const [searchParams, setSearchParams] = useSearchParams();
  const [disableAnimation, setDisableAnimation] = useState(false);

  const handleShoeAvailableStocksOptionClick = (id: string) => {
    handleChange(
      id,
      shoeAvailableStockSelected,
      setSelectedOptions,
      setProductFilter,
      ProductFilterStateKeys.ShoeAvailableStockOptionsSelected,
    );

    searchParams.delete(
      QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_AVAILABLE_STOCKS,
      id,
    );
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const showApplyButton = () => {
    return (
      !isButtonDisabled && urlAvailableStockId !== shoeAvailableStockSelected[0]
    );
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableAnimation(true);
    try {
      handleSubmit(event);
    } finally {
      setDisableAnimation(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedOptions)) {
      if (!showAvailableStocks) {
        toggleFilter(ProductFilterKeys.AvailableStocks);
      }
      setProductFilter(
        ProductFilterStateKeys.ShoeAvailableStockOptionsSelected,
        selectedOptions,
      );
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (!isEmpty(shoeAvailableStockSelected)) {
      setSelectedOptions(shoeAvailableStockSelected);
    }
  }, [searchParams]);

  useEffect(() => {
    const shoeStockData = allProductsData?.filterable?.stock;

    setAvailableStocksOptions(
      isEmpty(shoeStockData)
        ? []
        : getStockDivisions({
            min: shoeStockData?.min ?? 0,
            max: shoeStockData?.max ?? 0,
          }).map((stock) => ({
            ...stock,
            threshold: stock.threshold.toString(),
          })),
    );
  }, [allProductsData, allProductsDataFetchSuccess]);

  return (
    <FilterGroup
      title='Available Stocks'
      isActive={showAvailableStocks}
      toggleFilter={() => toggleFilter(ProductFilterKeys.AvailableStocks)}
    >
      {isAllProductDataLoading ? (
        <InputSkeletons height={36} />
      ) : isEmpty(availableStocksOptions) ? (
        <EmptyMessage
          message='Shoe stocks not available'
          variant='400'
        />
      ) : (
        <Form
          method={HttpMethods.GET}
          onSubmit={onSubmitHandler}
        >
          <div className='flex flex-col flex-wrap gap-4'>
            {availableStocksOptions.map((availableStocks) => (
              <div
                className='flex gap-2'
                key={availableStocks.threshold}
              >
                <Checkbox
                  id={availableStocks.threshold}
                  name={QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_AVAILABLE_STOCKS}
                  variant='blue'
                  value={shoeAvailableStockSelected
                    .filter((item) => item === availableStocks.threshold)
                    .toString()}
                  onChange={() => {
                    handleShoeAvailableStocksOptionClick(
                      availableStocks.threshold,
                    );
                  }}
                  checked={shoeAvailableStockSelected.includes(
                    availableStocks.threshold,
                  )}
                />
                <Label
                  htmlFor={availableStocks.threshold}
                  className='font-normal text-gray-800'
                >
                  {availableStocks.options}
                </Label>
              </div>
            ))}
          </div>
          <ApplyButton
            showApplyButton={showApplyButton}
            disableAnimation={disableAnimation}
          />
        </Form>
      )}
    </FilterGroup>
  );
};
