import { Link } from '@/components/ui/link';
import ImageComponent from '@/components/ui/image/Image';
import { ROUTES } from '@/utils/constants';

interface PaymentFallBackProps {
  title: string;
  description: string;
  illustration: string;
  redirect: {
    to: string;
    label: string;
  };
}

export function PaymentFallBack({
  title,
  description,
  illustration,
  redirect,
}: PaymentFallBackProps) {
  return (
    <div className='flex flex-col items-center justify-center gap-14'>
      <ImageComponent
        src={illustration}
        alt='Stipe fallback illustration'
      />
      <div className='space-y-4 text-center'>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
      <div className='flex items-center gap-3'>
        <Link to={redirect.to}>{redirect.label}</Link>
        <Link
          to={`/${ROUTES.PRODUCT.ALL_PRODUCTS}`}
          intent='outline'
        >
          Continue Shopping
        </Link>
      </div>
    </div>
  );
}
