import { z } from 'zod';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  API_ENDPOINT,
  QUERY_CONSTANTS,
  TANSTACK_QUERY_KEY,
} from '@/utils/constants';
import {
  TUser,
  TReset2FA,
  TBulkDeleteUser,
  TPersonalDetails,
  TUpdateUserRoles,
  TUpdateAssignTeam,
  TUpdateUserStatus,
  TCreateUserRequest,
  TBulkUserChangeStatus,
  TSendPasswordResetLink,
  TBulkExportUser,
  UserAcceptInviteRequestSchema,
  TBulkSendPassword2faResetLink,
  TVerifyUserInviteRequestSchema,
} from '@/schemas';
import { buildQuery } from '@/utils/helper';
import { useAlert } from '@/components/hooks';
import { instanceWithInterceptor } from '@/components/lib';
import { getSearchParams } from '@/components/hooks/router';
import { UserAPI } from '@/services/api/users/users.service';

export function useGetAllUser() {
  const { teams, roles, search, status, sortField, sortOrder, limit, page } =
    getSearchParams([
      QUERY_CONSTANTS.TEAMS,
      QUERY_CONSTANTS.STATUS,
      QUERY_CONSTANTS.ROLES,
      QUERY_CONSTANTS.SEARCH,
      QUERY_CONSTANTS.SORT.FIELD,
      QUERY_CONSTANTS.SORT.ORDER,
      QUERY_CONSTANTS.PAGE,
      QUERY_CONSTANTS.LIMIT,
    ]);

  const path = buildQuery(API_ENDPOINT.USERS.LIST);

  return useQuery({
    queryKey: [
      TANSTACK_QUERY_KEY.USER,
      teams,
      roles,
      search,
      status,
      page,
      limit,
      sortField,
      sortOrder,
    ],
    queryFn: () => UserAPI.getAllUser(path)(),
    select: ({ data, meta }) => {
      const { total, page: currentPage, pageCount } = meta.pagination;
      const normalizedData: TUser[] = data.map((user) => ({
        email: user.email,
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        designation: user.designation?.length ? user.designation : 'N/A',
        image: user.avatar,
        id: user.id,
        phoneNumber: user.phone?.length ? user.phone : 'N/A',
        teams: user.teams.map((team) => team.name),
        status: user.accountStatus,
        role: user.role,
        countryCode: user.countryCode,
        isInvited: user.isInvited,
      }));

      return {
        currentPage,
        users: normalizedData,
        totalUsers: total,
        totalPages: pageCount,
      };
    },
  });
}

export function useSendInvitation() {
  return useMutation({
    mutationFn: (data: TCreateUserRequest[]) => UserAPI.sendInvitation(data),
  });
}

export function useDeleteUser() {
  return useMutation({
    mutationFn: (data: string) => UserAPI.deleteUser(data),
  });
}

export function useGetUserDetail(userId: string) {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.USER_DETAIL, userId],
    queryFn: () => UserAPI.getUserDetail(userId),
    select: ({ data }) => data,
  });
}

export function useUpdatePersonalDetails() {
  const { showAlert } = useAlert();
  return useMutation({
    mutationFn: (data: TPersonalDetails & { id: string | undefined }) => {
      if (data.profileImage) {
        const file = data.profileImage[0];
        if (file instanceof File) {
          const formData = new FormData();
          formData.append('file', file);
          instanceWithInterceptor
            .put(
              `${API_ENDPOINT.USERS.UPLOAD_PROFILE_IMAGE}/${data.id}`,
              formData,
            )
            .then((res) => {
              //TODO
              console.log(res.data);
            })
            .catch((err: unknown) => {
              console.error(err);
              showAlert('Failed to upload profile image', 'error');
            });
        }
      }
      return UserAPI.updatePersonalDetails(data, data.id);
    },
  });
}

export function useUpdateUserDetails() {
  return useMutation({
    mutationFn: (data: TPersonalDetails & { userId: string | undefined }) => {
      return UserAPI.updateUserDetails(data, data.userId);
    },
  });
}

export function useUpdateUserRoles() {
  return useMutation({
    mutationFn: (data: TUpdateUserRoles) => UserAPI.updateUserRoles(data),
  });
}

export function useUpdateAssignTeam(userId: string) {
  return useMutation({
    mutationFn: (data: TUpdateAssignTeam) =>
      UserAPI.updateAssignTeam(data, userId),
  });
}

export function useUpdateUserStatus() {
  return useMutation({
    mutationFn: (data: TUpdateUserStatus) =>
      UserAPI.updateUserStatus(data, data.userId),
  });
}

export function useReset2FA() {
  return useMutation({
    mutationFn: (data: TReset2FA) => UserAPI.reset2FA(data),
  });
}

export function useSendPasswordResetLink() {
  return useMutation({
    mutationFn: (data: TSendPasswordResetLink) =>
      UserAPI.sendPasswordResetLink(data),
  });
}

export function useResendInvitationLink(userId: string) {
  const path = `${API_ENDPOINT.USERS.DETAILS}/${userId}/resend-invitation`;

  return useMutation({
    mutationFn: () => UserAPI.resendInvitationLink(path)(),
  });
}

export function useBulkDeleteUser() {
  return useMutation({
    mutationFn: (data: TBulkDeleteUser) => UserAPI.bulkDeleteUser(data),
  });
}

export function useBulkUpdateUserStatus() {
  return useMutation({
    mutationFn: (data: TBulkUserChangeStatus) =>
      UserAPI.bulkUpdateUserStatus(data),
  });
}

export function useBulkSendPasswordResetLink() {
  return useMutation({
    mutationFn: (data: TBulkSendPassword2faResetLink) =>
      UserAPI.bulkSendPasswordResetLink(data),
  });
}

export function useBulkSend2faResetLink() {
  return useMutation({
    mutationFn: (data: TBulkSendPassword2faResetLink) =>
      UserAPI.bulkSend2faResetLink(data),
  });
}

export function useBulkExportUser() {
  return useMutation({
    mutationFn: (data: TBulkExportUser) => UserAPI.bulkExportUser(data),
  });
}

export function useAcceptUserInvite() {
  return useMutation({
    mutationFn: (data: {
      userId: string;
      requestData: z.infer<typeof UserAcceptInviteRequestSchema>;
    }) => {
      const { userId, requestData } = data;
      return UserAPI.userAcceptInvite(userId)(requestData);
    },
  });
}

export function useCheckEmailExist() {
  return useMutation({
    mutationFn: ({ email }: { email: string }) =>
      UserAPI.userEmailExist({ email }),
  });
}

export const useUserInviteVerifyToken = () => {
  return useMutation({
    mutationFn: (verifyTokenData: TVerifyUserInviteRequestSchema) =>
      UserAPI.verifyUserInviteToken(verifyTokenData),
  });
};
