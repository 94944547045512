import { z } from 'zod';

export const CheckoutSchema = z.object({
  shippingAddress: z.union([z.number(), z.string()]),
  billingAddress: z.union([z.number(), z.string()]),
  cartId: z.string().uuid(),
  orderTotal: z.number().nullable(),
  paymentType: z.enum(['DIRECT', 'CREDIT']),
});

export type TCheckout = z.infer<typeof CheckoutSchema>;
