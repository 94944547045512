import { FC, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ROUTES } from '@/utils/constants';
import AuthPage from './authentication/login';
import { ErrorPage } from '@/pages/errorPage';
import Register from '@/pages/authentication/register';
import Users from '@/pages/users-and-teams/users/index';
import { RouteGuard, RouteType } from '@/components/guards';
import { useSyncLoginAcrossTabs } from '@/components/hooks';
import ForgotPassword from './authentication/forgot-passwod';
import WaitingForApproval from './authentication/register/WaitingForApproval';
import UserDetails from '@/pages/users-and-teams/users/user-details/UserDetails';
import CreateNewPassword from './authentication/forgot-passwod/CreateNewPassword';
import CompleteRegistration from './authentication/register/CompleteRegistration';
import CreateAccountNewPasswordPage from '@/pages/authentication/user/CreateNewPassword';
import TwoFaValidateResetPage from './authentication/login/TwoFaValidateResetPage';

import FlashPromotions from '@/pages/marketing-resources/flash-promotions';
import FlashPromotionsDetails from '@/pages/marketing-resources/flash-promotion-details';
import MyOrders from '@/pages/orders/my-orders';
import OrderDetails from './orders/my-orders/order-details/OrderDetails';
import { StripeCallbackPage } from '@/pages/payment/stripe-callback';
import { CreditPaymentCallbackPage } from '@/pages/payment/credit-callback';
const Overview = lazy(() => {
  return import('./overview');
});

const AllProducts = lazy(() => {
  return import('./products/all-products/categories');
});

const ProductFilters = lazy(() => {
  return import('./products/all-products/filters');
});

const ProductDetailsPage = lazy(() => {
  return import('./products/all-products/details');
});

const Comparison = lazy(() => {
  return import('./products/comparison');
});

const RealTimeStock = lazy(() => {
  return import('./real-time-stock');
});

const Invoices = lazy(() => {
  return import('./invoices');
});

const TeamDetails = lazy(() => {
  return import('./users-and-teams/teams/team-details/TeamDetails');
});
const GeneralSettings = lazy(() => {
  return import('./settings/general');
});

const Teams = lazy(() => {
  return import('./users-and-teams/teams');
});
const CreateTeamPage = lazy(() => {
  return import('./users-and-teams/teams/create-teams/CreateTeamPage');
});

const PersonalSettings = lazy(() => {
  return import('./settings/personal/PersonalSettings');
});

const CreditInformation = lazy(() => {
  return import('./settings/credit-information');
});

const Cart = lazy(() => {
  return import('./cart');
});

const Checkout = lazy(() => {
  return import('./checkout');
});

/**
 * @description This event listener is used to reload the page when it fails to load dynamic imports.
 * For more information on load error handling, visit: https://vite.dev/guide/build.html#load-error-handling
 */
window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

const menuItems = [
  {
    id: 'overview',
    key: 'overview',
    path: ROUTES.OVERVIEW,
    element: <Overview />,
  },
  {
    id: 'products',
    key: 'products',
    path: ROUTES.PRODUCT.ALL_PRODUCTS,
    element: <AllProducts />,
  },
  {
    id: 'all-products',
    key: 'all-products',
    path: ROUTES.PRODUCT.ALL_PRODUCTS,
    element: <AllProducts />,
  },
  {
    id: 'product-filters',
    key: 'product-filters',
    path: ROUTES.PRODUCT.FILTERS,
    element: <ProductFilters />,
  },
  {
    id: 'product-details',
    key: 'product-details',
    path: ROUTES.PRODUCT.DETAILS,
    element: <ProductDetailsPage />,
  },
  {
    id: 'comparison',
    key: 'products',
    path: ROUTES.PRODUCT.COMPARISON,
    element: <Comparison />,
  },
  {
    id: 'real-time-stock',
    key: 'real-time-stock',
    path: ROUTES.REAL_TIME_STOCK,
    element: <RealTimeStock />,
  },
  {
    id: 'my-orders',
    key: 'my-orders',
    path: ROUTES.ORDERS.MY_ORDERS,
    element: <MyOrders />,
  },
  {
    id: 'order-details',
    key: 'order-details',
    path: ROUTES.ORDERS.MY_DETAILS,
    element: <OrderDetails />,
  },
  {
    id: 'invoices',
    key: 'invoices',
    path: ROUTES.INVOICES,
    element: <Invoices />,
  },
  {
    id: 'users',
    key: 'users',
    path: ROUTES.USER_AND_TEAMS.USER.LIST,
    element: <Users />,
  },
  {
    id: 'user-details',
    key: 'user-details',
    path: ROUTES.USER_AND_TEAMS.USER.DETAILS,
    element: <UserDetails />,
  },
  {
    id: 'teams',
    key: 'teams',
    path: ROUTES.USER_AND_TEAMS.TEAM.LIST,
    element: <Teams />,
  },
  {
    id: 'team-details',
    key: 'team-details',
    path: ROUTES.USER_AND_TEAMS.TEAM.DETAILS,
    element: <TeamDetails />,
  },
  {
    id: 'create-team',
    key: 'create-team',
    path: ROUTES.USER_AND_TEAMS.TEAM.CREATE,
    element: <CreateTeamPage />,
  },
  {
    id: 'flash-promotions',
    key: 'flash-promotions',
    path: ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS,
    element: <FlashPromotions />,
  },
  {
    id: 'flash-promotions-details',
    key: 'flash-promotions-details',
    path: ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS_DETAILS,
    element: <FlashPromotionsDetails />,
  },
  {
    id: 'flash-promotions-product-details',
    key: 'flash-promotions-product-details',
    path: ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS_PRODUCT_DETAILS,
    element: <ProductDetailsPage />,
  },
  {
    id: 'general-settings',
    key: 'general-settings',
    path: ROUTES.SETTINGS.GENERAL_SETTINGS,
    element: <GeneralSettings />,
  },
  {
    id: 'personal-settings',
    key: 'personal-settings',
    path: ROUTES.SETTINGS.PERSONAL_SETTINGS,
    element: <PersonalSettings />,
  },
  {
    id: 'credit-information',
    key: 'credit-information',
    path: ROUTES.SETTINGS.CREDIT_INFORMATION_SETTINGS,
    element: <CreditInformation />,
    // permission: [RESTRICTED_SECTIONS.CREDIT_INFORMATION], // Example permission required for this route
  },
  {
    id: 'cart',
    key: 'cart',
    path: ROUTES.CART,
    element: <Cart />,
  },
  {
    id: 'two-factor-auth-reset-verify',
    key: 'twoFactorAuthResetVerify',
    path: ROUTES.TWOFA_RESET_VERIFY,
    element: <TwoFaValidateResetPage />,
  },
  {
    id: 'checkout',
    key: 'checkout',
    path: ROUTES.CHECKOUT,
    element: <Checkout />,
  },
  {
    id: 'stripe-callback',
    key: 'stripe-callback',
    path: ROUTES.PAYMENT.STRIPE_CALLBACK,
    element: <StripeCallbackPage />,
  },
  {
    id: 'credit-callback',
    key: 'credit-callback',
    path: ROUTES.PAYMENT.CREDIT_CALLBACK,
    element: <CreditPaymentCallbackPage />,
  },
];

const appConfigRoutes = [
  {
    id: 'main',
    key: 'main',
    path: '/',
    element: <RouteGuard type={RouteType.Private} />,
    children: [
      ...menuItems,
      {
        id: 'logout',
        key: 'logout',
        path: '/logout',
        element: <>Logout component</>,
      },
    ],
  },
  {
    id: 'authentication',
    key: 'authentication',
    path: '/',
    element: <RouteGuard type={RouteType.Restricted} />,
    children: [
      {
        id: 'login',
        key: 'login',
        path: ROUTES.LOGIN,
        element: <AuthPage />,
      },

      {
        id: 'forgot-password',
        key: 'forgot-password',
        path: ROUTES.FORGOT_PASSWORD.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        id: 'forgot-password/create-new',
        key: 'forgot-password/create-new',
        path: ROUTES.FORGOT_PASSWORD.CREATE_NEW,
        element: <CreateNewPassword />,
      },
      {
        id: 'register',
        key: 'register',
        path: ROUTES.REGISTER.REGISTER,
        element: <Register />,
      },
      {
        id: 'complete-registration',
        key: 'complete-registration',
        path: ROUTES.REGISTER.COMPLETE_REGISTRATION,
        element: <CompleteRegistration />,
      },
      {
        id: 'register-complete',
        key: 'register-complete',
        path: ROUTES.REGISTER.REGISTER_COMPLETE,
        element: <WaitingForApproval />,
      },
      {
        id: 'create-account/new-password',
        key: 'create-account/new-password',
        path: ROUTES.CREATE_ACCOUNT.NEW_PASSWORD,
        element: <CreateAccountNewPasswordPage />,
      },
    ],
  },
];

const RoutesPage: FC = () => {
  useSyncLoginAcrossTabs(); // Sync login across tabs
  const getAdminRoutes = () => {
    return appConfigRoutes.map((appConfigRouteItem) => {
      return (
        <Route
          id={appConfigRouteItem.id}
          key={appConfigRouteItem.key}
          path={appConfigRouteItem.path}
          element={appConfigRouteItem.element}
        >
          {[...appConfigRouteItem.children].map((childAppConfigRoute) => {
            return (
              <Route
                id={childAppConfigRoute.id}
                key={childAppConfigRoute.key}
                path={childAppConfigRoute.path}
                element={childAppConfigRoute.element}
              />
            );
          })}
        </Route>
      );
    });
  };
  return (
    <Routes>
      {getAdminRoutes()}
      <Route
        path='/*'
        element={<ErrorPage />}
      />
    </Routes>
  );
};

export default RoutesPage;
