import {
  Control,
  Controller,
  FieldErrors,
  FieldName,
  FieldValues,
  Path,
} from 'react-hook-form';
import {
  ErrorMessage,
  FieldValuesFromFieldErrors,
} from '@hookform/error-message';
import { useRef, useState } from 'react';

import { TSingleCountry } from '@/schemas';
import { Label } from '@/components/ui/label';
import { useFetchAllCountryData, useOutsideClick } from '@/components/hooks';
import { handleEnterKeyPress } from '@/utils/helper';

interface CountryInfo {
  capital: string;
  currency: string;
  emoji: string;
  id: number;
  iso2: string;
  iso3: string;
  name: string;
  native: string;
  phonecode: string;
}

export interface CountrySelectorProps<T extends FieldValues> {
  id: string;
  open: boolean;
  disabled?: boolean;
  onToggle: () => void;
  handleCountryChange: (countryCode: string) => void;
  selectedValue: CountryInfo;
  label?: string;
  name: Path<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  errorFieldName: FieldName<FieldValuesFromFieldErrors<T>>;
}

export const CountrySelector = <T extends FieldValues>({
  id,
  open,
  disabled = false,
  onToggle,
  selectedValue,
  label,
  control,
  name,
  errors,
  errorFieldName,
  handleCountryChange,
}: CountrySelectorProps<T>) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const { data: countries, error, isLoading } = useFetchAllCountryData();

  const [query, setQuery] = useState<string>('');

  useOutsideClick(inputRef, () => {
    if (open) {
      onToggle();
    }
  });

  return (
    <div data-cy='country picker'>
      {label && <Label className='mb-1'>{label}</Label>}
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <>
            <div
              className='relative'
              ref={inputRef}
            >
              <button
                type='button'
                className={`${
                  disabled ? 'bg-neutral-100' : 'bg-white'
                } relative h-[50px] w-full cursor-default rounded-md border px-4 pl-3 pr-10 text-left shadow-sm focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-200 sm:text-sm`}
                aria-haspopup='listbox'
                aria-expanded='true'
                aria-labelledby='listbox-label'
                onClick={() => {
                  onToggle();
                  setQuery(''); // Clear query when the button is clicked
                }}
                disabled={disabled}
              >
                <div className='flex items-center gap-2 px-2'>
                  <span className='emoji'>{selectedValue.emoji}</span>
                  <span className='text-base text-gray-800'>
                    {selectedValue.name}
                  </span>
                </div>
                <span
                  className={`pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4 ${
                    disabled ? 'hidden' : ''
                  }`}
                >
                  <svg
                    width='14'
                    height='9'
                    viewBox='0 0 14 9'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M13.6925 1.94254L7.44254 8.19254C7.38449 8.25065 7.31556 8.29675 7.23969 8.3282C7.16381 8.35965 7.08248 8.37584 7.00035 8.37584C6.91821 8.37584 6.83688 8.35965 6.76101 8.3282C6.68514 8.29675 6.61621 8.25065 6.55816 8.19254L0.30816 1.94254C0.190885 1.82526 0.125 1.6662 0.125 1.50035C0.125 1.3345 0.190885 1.17544 0.30816 1.05816C0.425435 0.940884 0.584495 0.875 0.750347 0.875C0.9162 0.875 1.07526 0.940884 1.19253 1.05816L7.00035 6.86675L12.8082 1.05816C12.8662 1.00009 12.9352 0.954028 13.011 0.922601C13.0869 0.891175 13.1682 0.875 13.2503 0.875C13.3325 0.875 13.4138 0.891175 13.4897 0.922601C13.5655 0.954028 13.6345 1.00009 13.6925 1.05816C13.7506 1.11623 13.7967 1.18517 13.8281 1.26104C13.8595 1.33691 13.8757 1.41823 13.8757 1.50035C13.8757 1.58247 13.8595 1.66379 13.8281 1.73966C13.7967 1.81553 13.7506 1.88447 13.6925 1.94254Z'
                      fill='#4B5563'
                    />
                  </svg>
                </span>
              </button>
              {open && (
                <div className='absolute z-10 w-full bg-white'>
                  <div className='sticky top-0 z-10 bg-white'>
                    <div className='relative cursor-default select-none px-3 py-2 text-gray-900'>
                      <input
                        type='search'
                        name='search'
                        autoComplete='off'
                        className='block w-full border p-2 focus:border-blue-500 focus:outline-none sm:text-sm'
                        placeholder='Search a country'
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyDown={handleEnterKeyPress}
                      />
                    </div>
                    <hr />
                  </div>
                  <div className='scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin max-h-64 overflow-y-scroll'>
                    {isLoading ? (
                      <li className='relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900'>
                        Loading countries...
                      </li>
                    ) : error ? (
                      <li className='relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900'>
                        Failed to load countries
                      </li>
                    ) : countries?.filter((country: TSingleCountry) =>
                        country.name
                          .toLowerCase()
                          .startsWith(query.toLowerCase()),
                      ).length === 0 ? (
                      <div className='relative flex cursor-default select-none items-center justify-center py-2 text-gray-900'>
                        No countries found
                      </div>
                    ) : (
                      countries
                        ?.filter((country: TSingleCountry) =>
                          country.name
                            .toLowerCase()
                            .startsWith(query.toLowerCase()),
                        )
                        .map((value: TSingleCountry, index: number) => (
                          <li
                            key={`${id}-${index}`}
                            className='relative flex cursor-default select-none items-center py-2 pl-3 pr-9 text-gray-900 transition hover:bg-gray-50'
                            id={`listbox-option-${index}`}
                            role='option'
                            aria-selected={value.iso2 === selectedValue.iso2}
                            onClick={() => {
                              onChange(value.iso2);
                              handleCountryChange(value.iso2);
                              setQuery('');
                              onToggle();
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                onChange(value.iso2);
                                handleCountryChange(value.iso2);
                                setQuery('');
                                onToggle();
                              }
                            }}
                          >
                            <span className='emoji mr-2'>{value.emoji}</span>
                            <span className='truncate font-normal'>
                              {value.name}
                            </span>
                            {value.iso2 === selectedValue.iso2 && (
                              <span className='absolute inset-y-0 right-0 flex items-center pr-8 text-blue-600'>
                                <svg
                                  className='h-5 w-5'
                                  xmlns='http://www.w3.org/2000/svg'
                                  viewBox='0 0 20 20'
                                  fill='currentColor'
                                  aria-hidden='true'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                    clipRule='evenodd'
                                  />
                                </svg>
                              </span>
                            )}
                          </li>
                        ))
                    )}
                  </div>
                </div>
              )}
            </div>
            <ErrorMessage
              errors={errors}
              name={errorFieldName}
              render={({ message }) => (
                <div className="errorinfo error-message before:text-danger-500 mt-2 text-red-500 before:mr-2 before:inline before:content-['⚠']">
                  {' ' + message}
                </div>
              )}
            />
          </>
        )}
      />
    </div>
  );
};
