import { PRICETYPE } from '@/utils/constants/product-stock.constants';
import { z } from 'zod';

const ProductCardPriceDetailsSchema = z.object({
  isDynamicPricing: z.boolean(),
  buyPrice: z.number(),
  priceType: z.nativeEnum(PRICETYPE).nullable().optional(),
  rrp: z.number().nullable().optional(),
});

export type TProductCardPriceDetailsSchema = z.infer<
  typeof ProductCardPriceDetailsSchema
>;

const ProductCardPriceDetailsMoqAndRrpSchema = z.object({
  rrp: z.number().optional().nullable(),
  moq: z.union([z.number(), z.string()]).optional().nullable(),
});

export type TProductCardPriceDetailsMoqAndRrpSchema = z.infer<
  typeof ProductCardPriceDetailsMoqAndRrpSchema
>;

// dependent upon single product schema
export const ProductCardSchema = z
  .object({
    id: z.string().uuid(),
    productCode: z.string().optional().nullable(),
    productName: z.string(),
    imageSrc: z.string(),
    isProductNew: z.boolean(),
    imageAlt: z.string().nullable(),
    categoryId: z.string().uuid().nullable().optional(),
  })
  .merge(ProductCardPriceDetailsSchema)
  .merge(ProductCardPriceDetailsMoqAndRrpSchema);

export type TProductCardSchema = z.infer<typeof ProductCardSchema>;
