import React from 'react';
import { IconProps } from '../iconProps';

export const IconStack: React.FC<IconProps> = ({ size, fill }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.6478 16.125C21.7469 16.2969 21.7738 16.501 21.7226 16.6927C21.6715 16.8844 21.5465 17.0481 21.375 17.1478L12.375 22.3978C12.2603 22.4647 12.1299 22.4999 11.9972 22.4999C11.8644 22.4999 11.7341 22.4647 11.6194 22.3978L2.61938 17.1478C2.45034 17.0462 2.32811 16.8822 2.27916 16.6911C2.2302 16.5001 2.25846 16.2974 2.35781 16.1271C2.45717 15.9567 2.61962 15.8324 2.81 15.7809C3.00037 15.7295 3.20336 15.7551 3.375 15.8522L12 20.8819L20.625 15.8522C20.7969 15.7531 21.0011 15.7262 21.1927 15.7773C21.3844 15.8285 21.5481 15.9535 21.6478 16.125ZM20.625 11.3522L12 16.3819L3.375 11.3522C3.20423 11.2671 3.00748 11.2504 2.82479 11.3053C2.6421 11.3602 2.4872 11.4827 2.39163 11.6478C2.29606 11.8129 2.26699 12.0082 2.31035 12.194C2.3537 12.3798 2.46622 12.5421 2.625 12.6478L11.625 17.8978C11.7397 17.9647 11.8701 17.9999 12.0028 17.9999C12.1356 17.9999 12.2659 17.9647 12.3806 17.8978L21.3806 12.6478C21.4671 12.5989 21.5429 12.5333 21.6038 12.4548C21.6647 12.3764 21.7094 12.2866 21.7353 12.1907C21.7612 12.0948 21.7679 11.9948 21.7548 11.8963C21.7417 11.7979 21.7092 11.703 21.6592 11.6172C21.6091 11.5314 21.5426 11.4564 21.4633 11.3965C21.384 11.3367 21.2937 11.2932 21.1975 11.2685C21.1013 11.2439 21.0011 11.2386 20.9028 11.2529C20.8046 11.2673 20.7101 11.301 20.625 11.3522ZM2.25 7.49999C2.2503 7.36862 2.2851 7.23964 2.35091 7.12595C2.41672 7.01227 2.51124 6.91786 2.625 6.85217L11.625 1.60217C11.7397 1.5353 11.8701 1.50006 12.0028 1.50006C12.1356 1.50006 12.2659 1.5353 12.3806 1.60217L21.3806 6.85217C21.4938 6.91823 21.5878 7.0128 21.6531 7.12646C21.7183 7.24012 21.7527 7.36891 21.7527 7.49999C21.7527 7.63106 21.7183 7.75985 21.6531 7.87351C21.5878 7.98718 21.4938 8.08175 21.3806 8.1478L12.3806 13.3978C12.2659 13.4647 12.1356 13.4999 12.0028 13.4999C11.8701 13.4999 11.7397 13.4647 11.625 13.3978L2.625 8.1478C2.51124 8.08212 2.41672 7.98771 2.35091 7.87402C2.2851 7.76033 2.2503 7.63135 2.25 7.49999ZM4.48875 7.49999L12 11.8819L19.5112 7.49999L12 3.11811L4.48875 7.49999Z'
        fill={fill}
      />
    </svg>
  );
};
