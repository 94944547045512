import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface PersonalSettingInterface {
  isAuthenticatorAppEnabled: boolean;
  isSMSEnabled: boolean;
  setIsAuthenticatorAppEnabled: (isAuthenticatorAppEnabled: boolean) => void;
  setIsSMSEnabled: (isSMSEnabled: boolean) => void;
}

export const usePersonalSettingStore = create<PersonalSettingInterface>()(
  persist(
    (set) => ({
      isAuthenticatorAppEnabled: false,
      isSMSEnabled: false,
      setIsAuthenticatorAppEnabled: (isAuthenticatorAppEnabled: boolean) => {
        set({ isAuthenticatorAppEnabled });
      },
      setIsSMSEnabled: (isSMSEnabled: boolean) => {
        set({ isSMSEnabled });
      },
    }),
    {
      name: 'personal-settings-storage',
    },
  ),
);
