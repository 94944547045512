import { useMutation } from '@tanstack/react-query';

import { ForgotPasswordAPI } from '@/services';
import {
  TForgotPasswordFormSchema,
  TVerifyTokenRequestSchema,
} from '@/schemas';

export function useForgotPassword() {
  return useMutation({
    mutationFn: (forgotPasswordData: TForgotPasswordFormSchema) =>
      ForgotPasswordAPI.forgotPassword(forgotPasswordData),
  });
}

export const useResetPasswordVerifyToken = () => {
  return useMutation({
    mutationFn: (verifyTokenData: TVerifyTokenRequestSchema) =>
      ForgotPasswordAPI.verifyResetPasswordToken(verifyTokenData),
  });
};
