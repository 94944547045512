import { QUERY_CONSTANTS } from '@/utils/constants';
import { FormEvent, useEffect, useState } from 'react';
import { useLocation, useSearchParams, useSubmit } from 'react-router-dom';

/**
 * @description Custom hook to handle form submission for filtering.
 * @returns An object containing the selected options states, isButtonDisabled and the handleSubmit function.
 */
export function useFilterSubmit(queryKey: string) {
  const submit = useSubmit();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const queryParams = new URLSearchParams(searchParams);
  const defaultSearchParams = searchParams.getAll(queryKey);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const isButtonDisabled =
    defaultSearchParams.length === 0 && selectedOptions.length === 0;

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    queryParams.delete(queryKey);
    queryParams.delete(QUERY_CONSTANTS.PAGE);

    formData.forEach((value, key) => {
      queryParams.append(key, String(value));
    });

    submit(queryParams, {
      action: location.pathname,
    });
  }

  const handleFilterApply = () => {
    queryParams.delete(queryKey);

    queryParams.delete(QUERY_CONSTANTS.PAGE);

    selectedOptions.forEach((value) => {
      queryParams.append(queryKey, value);
    });

    submit(queryParams, {
      action: location.pathname,
    });
  };

  useEffect(() => {
    setSelectedOptions(defaultSearchParams);
  }, [searchParams]);

  return {
    submit,
    location,
    handleSubmit,
    searchParams,
    selectedOptions,
    isButtonDisabled,
    handleFilterApply,
    setSelectedOptions,
    defaultSearchParams,
  };
}
