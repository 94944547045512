import React from 'react';

import { isEmpty } from '@/utils/helper';
import { useProductFilterStore } from '@/store';
import { ProductFilterStateKeys } from '@/store/product-filter.store';
import { STOCK_DIVISONS } from '@/utils/constants/product-stock.constants';

/**
 * checks each state of product filters.
 * @returns boolean
 */
export const useIsProductFiltersSelected = () => {
  const {
    shoeSizeSelected,
    shoeColorSelected,
    shoeTechnologySelected,
    shoeIndustryForSelected,
    shoeAvailableStockSelected,
    isPriceRangeFilterApplied,
  } = useProductFilterStore();

  return (
    isPriceRangeFilterApplied ||
    !isEmpty(shoeAvailableStockSelected) ||
    !isEmpty(shoeSizeSelected) ||
    !isEmpty(shoeColorSelected) ||
    !isEmpty(shoeIndustryForSelected) ||
    !isEmpty(shoeTechnologySelected)
  );
};

/**
 * Toggles the selection of an item within an array of options.
 * For Available Stocks filter, only one option can be selected at a time.
 * If the `id` is present in the `options` array, it is removed; otherwise, it is added.
 *
 * @param options - The array of selected option strings.
 * @param id - The string ID to be toggled within the `options` array.
 * @returns A new array with the updated selection of options.
 */
type ToggleSelection = (
  options: string[],
  filterKey: ProductFilterStateKeys,
  id: string,
) => string[];

export const toggleSelection: ToggleSelection = (options, filterKey, id) =>
  options.includes(id)
    ? options.filter((option) => option !== id)
    : filterKey === ProductFilterStateKeys.ShoeAvailableStockOptionsSelected
      ? [id]
      : [...options, id];

/**
 * Handles the change event for a checkbox by toggling the selected options
 * and updating the corresponding product filter state.
 *
 * @param id - The string ID of the checkbox that triggered the change event.
 * @param selectedOptions - The current array of selected options.
 * @param setSelectedOptions - The state setter function to update the selected URL options.
 * @param setProductFilter - A function to update the product filter Zustand state.
 * @param filterKey - The key representing the specific product filter to be updated.
 */
export const handleChange = (
  id: string,
  selectedOptions: string[],
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>,
  setProductFilter: (key: ProductFilterStateKeys, value: string[]) => void,
  filterKey: ProductFilterStateKeys,
) => {
  const updatedOptions = toggleSelection(selectedOptions, filterKey, id);

  setSelectedOptions(updatedOptions);
  setProductFilter(filterKey, updatedOptions);
};

export const getStockDivisions = (stock: { max: number; min: number }) => {
  return STOCK_DIVISONS.filter(
    (division) => division.threshold <= stock.max,
  ).map((division) => ({
    ...division,
  }));
};
