import { useMutation } from '@tanstack/react-query';

import { OrdersAPI } from '@/services/api/orders';
import { TAddOrderToSessionByIdRequsetSchema } from '@/schemas/api/orders';

export const useAddProductToOrderSession = () => {
  return useMutation({
    mutationFn: ({
      sessionId,
      data,
    }: {
      sessionId: string;
      data: TAddOrderToSessionByIdRequsetSchema;
    }) => OrdersAPI.addOrderToSessionById(sessionId, data),
  });
};
