import React from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/utils/cn';

const toolTipVariants = cva(
  "tooltip-content invisible opacity-0 bg-gray-950 transition-opacity duration-500 w-60 p-4 text-white shadow-lg rounded absolute z-10 after:content-[''] after:absolute after:border-[6px] after:border-solid after:border-transparent",
  {
    variants: {
      position: {
        top: 'bottom-full mb-1.5 left-1/2 -translate-x-1/2 after:top-full after:inset-x-1/2 after:border-t-gray-950',
        right:
          'top-1/2 -translate-y-1/2 ml-1.5 left-full after:inset-y-1/2 after:-translate-y-1/2 after:right-full after:border-r-gray-950',
        bottom:
          'top-full mt-1.5 left-1/2 -translate-x-1/2 after:bottom-full after:inset-x-1/2 after:border-b-gray-950',
        left: 'top-1/2 mr-1.5 -translate-y-1/2 right-full after:inset-y-1/2 after:-translate-y-1/2 after:left-full after:border-l-gray-950',
      },
      intent: {
        hover: 'group-hover:visible group-hover:opacity-100',
        focus: 'group-focus-within:visible group-focus-within:opacity-100',
      },
    },
    defaultVariants: {
      position: 'top',
      intent: 'hover',
    },
  },
);

interface TooltipProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof toolTipVariants> {
  className?: string;
}

function Tooltip({ children, className }: TooltipProps) {
  return (
    <span className={cn('group relative inline-block', className)}>
      {children}
    </span>
  );
}

interface TooltipTriggerProps extends React.HTMLAttributes<HTMLDivElement> {}

function TooltipTrigger({ children }: TooltipTriggerProps) {
  return <>{children}</>;
}

interface TooltipContentProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof toolTipVariants> {}

function TooltipContent({
  children,
  position,
  className,
  intent,
}: TooltipContentProps) {
  return (
    <div
      className={cn(toolTipVariants({ position, intent, className }))}
      data-cy={`tooltip, direction: ${position}`}
    >
      {children}
    </div>
  );
}

Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;

export { Tooltip };
