import { useEffect, useRef, useState } from 'react';

import shoeAsset from '@/assets/images/Image.png';
import placholderImage from '@/assets/images/placeholder-image.png';

import { cn } from '@/utils/cn';
import { isEmpty } from '@/utils/helper';

interface ImageComponentProps {
  src?: string;
  classes?: string;
  alt?: string;
  width?: number;
  height?: number;
  isIcon?: boolean;
}

const ImageComponent = ({
  src,
  classes,
  alt,
  width,
  height,
  isIcon = false,
}: ImageComponentProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const baseImageClass =
    'max-w-full transition duration-150 ease-out object-contain rounded-lg';
  const imageWidth = width ? width : '';
  const imageHeight = height ? height : '';

  const imageSourceUrl = () => {
    if (!isIcon) {
      return src;
    } else {
      if (isEmpty(src)) {
        return shoeAsset;
      } else if (isVisible) {
        return src;
      } else {
        return placholderImage;
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 },
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <img
      ref={imgRef}
      loading='lazy'
      src={imageSourceUrl()}
      alt={alt ? alt : 'image'}
      className={cn(baseImageClass, classes)}
      style={{
        width: isIcon ? 40 : imageWidth,
        height: isIcon ? 40 : imageHeight,
      }}
      data-cy={`image ${alt}`}
    />
  );
};

export default ImageComponent;
