import { z } from 'zod';

import { BaseResponseSchema, emailSchema } from '@/schemas';

export const CreateUserSchema = z.object({
  email: emailSchema.max(100, { message: 'Email must be max 100 characters' }),
  role: z.string().min(1, { message: 'Role is required' }).trim(),
  teams: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      }),
    )
    .optional(),
});

export type TCreateUser = z.infer<typeof CreateUserSchema>;

const RequestSchema = z.object({
  email: z.string().email(),
  role: z.string(),
  teams: z.array(z.string()).optional(),
});

export type TCreateUserRequest = z.infer<typeof RequestSchema>;

export const CreateUserRequestSchema = z.array(RequestSchema);

export const CreateUserResponseSchema = BaseResponseSchema;
