import { Modal } from '@/components/ui/modal/Modal';
import { Button } from '@/components/ui/button/Button';

interface DeleteModalProps {
  path?: string;
  showDeleteModal: boolean;
  setshowDeleteModal: (value: boolean) => void;
  title: string;
  message: string;
  primaryButtonActionMessage: string;
  primaryButtonAction: () => void;
  isLoading: boolean;
}

export const CartDeleteModal = ({
  title,
  message,
  showDeleteModal,
  setshowDeleteModal,
  primaryButtonActionMessage,
  primaryButtonAction,
  isLoading = false,
}: DeleteModalProps) => {
  return (
    <Modal
      showModal={showDeleteModal}
      setShowModal={() => setshowDeleteModal(false)}
    >
      <Modal.Content className='md:min-w-[600px]'>
        <Modal.Title>
          <span className='text-xl font-semibold'>{title}</span>
        </Modal.Title>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer className='space-x-8'>
          <Modal.CloseButton onClick={() => setshowDeleteModal(false)}>
            Cancel
          </Modal.CloseButton>
          <Button
            onClick={primaryButtonAction}
            disabled={isLoading}
            className='h-auto'
            variant={'red'}
          >
            {primaryButtonActionMessage}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
