import { create } from 'zustand';

export enum ProductFilterKeys {
  PriceRangeSlider = 'showPriceRangeSlider',
  ShoeSizeOptions = 'showShoeSizeOptions',
  ColourOptions = 'showColourOptions',
  AvailableStocks = 'showAvailableStocks',
  IndustrySuitedFor = 'showIndustrySuitedFor',
  ShoeTechnology = 'showShoeTechnology',
}

export enum ProductFilterStateKeys {
  ShoeSizeOptionsSelected = 'shoeSizeSelected',
  ShoeColorOptionsSelected = 'shoeColorSelected',
  ShoeAvailableStockOptionsSelected = 'shoeAvailableStockSelected',
  ShoeIndustryForOptionsSelected = 'shoeIndustryForSelected',
  ShoeTechnologyOptionsSelected = 'shoeTechnologySelected',
}

interface AvailableStocksInterface {
  options: string;
  threshold: string;
}
interface AvailableColourInterface {
  name: string;
  hex: string;
}

interface ProductFilterInterface {
  shoeSizeSelected: string[];
  shoeColorSelected: string[];
  shoeAvailableStockSelected: string[];
  shoeIndustryForSelected: string[];
  shoeTechnologySelected: string[];
  availableStocksOptions: AvailableStocksInterface[];
  availableColourOptions: AvailableColourInterface[];
}

interface ProductToggleInterface {
  showPriceRangeSlider: boolean;
  showShoeSizeOptions: boolean;
  showColourOptions: boolean;
  showAvailableStocks: boolean;
  showIndustrySuitedFor: boolean;
  showShoeTechnology: boolean;
  isPriceRangeFilterApplied: boolean;
}

interface ProductFilterStateInterface
  extends ProductFilterInterface,
    ProductToggleInterface {
  shoePriceRange: { inputFrom: number; inputTo: number };
  initialShoePriceRange: { inputFrom: number; inputTo: number };
  toggleFilter: (key: keyof ProductToggleInterface) => void;
  setProductFilter: (
    key: keyof ProductFilterInterface,
    value: string[],
  ) => void;
  resetAllFilters: () => void;
  resetAllToggles: () => void;
  setShoePriceRange: (inputFrom: number, inputTo: number) => void;
  setInitialShoePriceRange: (inputFrom: number, inputTo: number) => void;
  setAvailableStocksOptions: (options: AvailableStocksInterface[]) => void;
  setAvailableColourOptions: (options: AvailableColourInterface[]) => void;
  resetAllProductFilters: () => void;
  setIsPriceRangeFilterApplied: (value: boolean) => void;
}

const productFilterInitialState: ProductFilterInterface = {
  shoeSizeSelected: [],
  shoeColorSelected: [],
  shoeAvailableStockSelected: [],
  shoeIndustryForSelected: [],
  shoeTechnologySelected: [],
  availableStocksOptions: [],
  availableColourOptions: [],
};

const productToggleInitialState: ProductToggleInterface = {
  showPriceRangeSlider: false,
  showShoeSizeOptions: false,
  showColourOptions: false,
  showAvailableStocks: false,
  showIndustrySuitedFor: false,
  showShoeTechnology: false,
  isPriceRangeFilterApplied: false,
};

const useProductFilterStore = create<ProductFilterStateInterface>((set) => ({
  ...productFilterInitialState,
  ...productToggleInitialState,
  shoePriceRange: { inputFrom: 0, inputTo: 0 },
  initialShoePriceRange: { inputFrom: 0, inputTo: 0 },
  toggleFilter: (key) => set((state) => ({ [key]: !state[key] })),
  setProductFilter: (key, value) =>
    set((state) => ({ ...state, [key]: value })),
  resetAllFilters: () =>
    set((state) => ({ ...state, ...productFilterInitialState })),
  resetAllToggles: () =>
    set((state) => ({ ...state, ...productToggleInitialState })),
  setShoePriceRange: (inputFrom, inputTo) =>
    set(() => ({ shoePriceRange: { inputFrom, inputTo } })),
  setInitialShoePriceRange: (inputFrom, inputTo) =>
    set(() => ({ initialShoePriceRange: { inputFrom, inputTo } })),
  setAvailableStocksOptions: (options) =>
    set(() => ({ availableStocksOptions: options })),
  setAvailableColourOptions: (options) =>
    set(() => ({ availableColourOptions: options })),
  setIsPriceRangeFilterApplied: (value) =>
    set(() => ({ isPriceRangeFilterApplied: value })),
  resetAllProductFilters: () =>
    set((state) => ({
      ...productFilterInitialState,
      ...productToggleInitialState,
      shoePriceRange: { inputFrom: 0, inputTo: 0 },
      initialShoePriceRange: { inputFrom: 0, inputTo: 0 },
      availableStocksOptions: state.availableStocksOptions,
      availableColourOptions: state.availableColourOptions,
    })),
}));

export default useProductFilterStore;
