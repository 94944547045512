import { PRICETYPE } from '@/utils/constants/product-stock.constants';
import { TPricesSchema } from '@/schemas/api/products/detail/singleProductDetail.schema';

const PRIORITY_ORDER = ['PROMO', 'SPECIAL', 'LIST', 'RRP'];

const getProductPricingDetailsByPriority = (
  prices: TPricesSchema = [],
  productMoq = 0,
): {
  bestPrice: number;
  bestType: string;
  moqCount: number;
  discountPercentage: number;
} => {
  if (!prices || prices.length === 0) {
    throw new Error('Products array is empty or undefined');
  }

  let bestPrice: number | undefined;
  let bestType: string | undefined;
  let moqCount: number | undefined;
  let discountPercentage: number | undefined;
  let bestPriority = Infinity;

  const rrpPrice = prices.find(
    (price) => price.priceType === ('RRP' as PRICETYPE),
  );

  for (const price of prices) {
    const currentPriority = PRIORITY_ORDER.indexOf(price.priceType ?? '');

    if (currentPriority === -1) continue;

    if (price.priceType === ('SPECIAL' as PRICETYPE)) {
      if (Number(price.moq ?? 0) >= productMoq) {
        return {
          bestPrice: price.salePrice ?? 0,
          bestType: price.priceType,
          moqCount: Number(price.moq ?? 0),
          discountPercentage: price.discount ?? 0,
        };
      } else if (rrpPrice) {
        return {
          bestPrice: rrpPrice.salePrice ?? 0,
          bestType: rrpPrice.priceType ?? '',
          moqCount: Number(rrpPrice.moq ?? 0),
          discountPercentage: rrpPrice.discount ?? 0,
        };
      }
    } else if (currentPriority < bestPriority) {
      bestPriority = currentPriority;

      return {
        bestPrice: price.salePrice ?? 0,
        bestType: price.priceType ?? '',
        moqCount: Number(price.moq ?? 0),
        discountPercentage: price.discount ?? 0,
      };
    }
  }

  return {
    bestPrice: bestPrice ?? 0,
    bestType: bestType ?? '',
    moqCount: moqCount ?? 0,
    discountPercentage: discountPercentage ?? 0,
  };
};

export const productBuyPrice = (
  prices: TPricesSchema = [],
  productMoq = 0,
): number => {
  return getProductPricingDetailsByPriority(prices, productMoq).bestPrice;
};

export const productPriceType = (
  prices: TPricesSchema = [],
  productMoq = 0,
): string => {
  return getProductPricingDetailsByPriority(prices, productMoq).bestType;
};

export const productPriceMoq = (
  prices: TPricesSchema = [],
  productMoq = 0,
): number => {
  return getProductPricingDetailsByPriority(prices, productMoq).moqCount;
};

export const productDiscountPercentage = (
  prices: TPricesSchema = [],
  productMoq = 0,
): number => {
  return getProductPricingDetailsByPriority(prices, productMoq)
    .discountPercentage;
};

export const isProductDynamicPricing = (
  prices: TPricesSchema = [],
): boolean => {
  if (!prices || prices.length === 0) {
    throw new Error('Products array is empty or undefined');
  }

  const priceTypes = new Set(prices.map((product) => product.priceType));

  return priceTypes.has('RRP' as PRICETYPE) && priceTypes.size > 1;
};

export const isProductSpecialPrice = (
  prices: TPricesSchema | null = null,
  productMoq = 0,
): boolean => {
  if (!prices || prices.length === 0) {
    return false;
  }

  for (const price of prices) {
    const currentPriority = PRIORITY_ORDER.indexOf(price.priceType ?? '');

    if (currentPriority === -1) continue;

    if (price.priceType === ('SPECIAL' as PRICETYPE)) {
      if (Number(price.moq ?? 0) >= productMoq) {
        return true;
      }
    }
  }

  return false;
};
