import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/cn';
import { InputError } from '@/components';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  name: string;
}

export const inputVariants = cva(
  'outline-none w-full duration-300 border rounded border-gray-200 px-4 py-3 text-base font-normal hover:shadow-sm focus:border-gray-800 focus:shadow-base placeholder:text-gray-400 text-gray-800 disabled:bg-gray-100 disabled:cursor-not-allowed',
);

export function Input({ className, name, type, ...props }: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <input
        {...register(name)}
        {...props}
        type={type}
        className={cn(
          inputVariants({}),
          Object.prototype.hasOwnProperty.call(errors, name) &&
            'border-red-600 bg-red-50',
          className,
        )}
        data-cy={`${name} input with ${type} type`}
      />
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => <InputError message={message} />}
      />
    </>
  );
}
