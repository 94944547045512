import { z } from 'zod';

import {
  CreateUserRequestSchema,
  CreateUserResponseSchema,
  DeleteUserRequestSchema,
  DeleteUserResponseSchema,
  GetAllUserRequestSchema,
  GetAllUserResponseSchema,
  GetUserDetailRequestSchema,
  GetUserDetailResponseSchema,
  Reset2FARequestSchema,
  Reset2FAResponseSchema,
  SendPasswordResetLinkRequestSchema,
  SendPasswordResetLinkResponseSchema,
  UpdateUserRolesRequestSchema,
  UpdateUserRolesResponseSchema,
  UpdateUserStatusRequestSchema,
  UpdateUserStatusResponseSchema,
  UpdatePersonalDetailsRequestSchema,
  UpdatePersonalDetailsResponseSchema,
  UpdateAssignTeamRequestSchema,
  UpdateAssignTeamResponseSchema,
  BulkDeleteUserRequestSchema,
  BulkDeleteUserResponseSchema,
  BulkUpdateUserStatusRequestSchema,
  BulkUpdateUserStatusResponseSchema,
  BulkExportUserRequestSchema,
  BulkExportUserResponseSchema,
  UserAcceptInviteRequestSchema,
  UserAcceptInviteResponseSchema,
  CheckEmailExistRequestSchema,
  CheckEmailExistResponseSchema,
  UpdateUserDetailsRequestSchema,
  UpdateUserDetailsResponseSchema,
  BulkSendPassword2faResetLinkRequestSchema,
  BulkSendPassword2faResetLinkResponseSchema,
  ResendInvitationLinkRequestSchema,
  ResendInvitationLinkResponseSchema,
  VerifyUserInviteTokenRequestSchema,
  VerifyUserInviteTokenResponseSchema,
} from '@/schemas';
import { api } from '@/utils/helper';
import { API_ENDPOINT, HttpMethods } from '@/utils/constants';

const getAllUser = (path: string) =>
  api<
    z.infer<typeof GetAllUserRequestSchema>,
    z.infer<typeof GetAllUserResponseSchema>
  >({
    method: 'GET',
    path,
    requestSchema: GetAllUserRequestSchema,
    responseSchema: GetAllUserResponseSchema,
  });

const sendInvitation = api<
  z.infer<typeof CreateUserRequestSchema>,
  z.infer<typeof CreateUserResponseSchema>
>({
  method: HttpMethods.PATCH,
  path: API_ENDPOINT.USERS.SEND_INVITATION,
  requestSchema: CreateUserRequestSchema,
  responseSchema: CreateUserResponseSchema,
});

const updatePersonalDetails = api<
  z.infer<typeof UpdatePersonalDetailsRequestSchema>,
  z.infer<typeof UpdatePersonalDetailsResponseSchema>
>({
  method: HttpMethods.PATCH,
  path: API_ENDPOINT.USERS.DETAILS,
  requestSchema: UpdatePersonalDetailsRequestSchema,
  responseSchema: UpdatePersonalDetailsResponseSchema,
});

const updateUserDetails = api<
  z.infer<typeof UpdateUserDetailsRequestSchema>,
  z.infer<typeof UpdateUserDetailsResponseSchema>
>({
  method: HttpMethods.PATCH,
  path: API_ENDPOINT.USERS.UPDATE,
  requestSchema: UpdateUserDetailsRequestSchema,
  responseSchema: UpdateUserDetailsResponseSchema,
});

const deleteUser = api<
  z.infer<typeof DeleteUserRequestSchema>,
  z.infer<typeof DeleteUserResponseSchema>
>({
  method: 'DELETE',
  path: API_ENDPOINT.USERS.LIST,
  requestSchema: DeleteUserRequestSchema,
  responseSchema: DeleteUserResponseSchema,
});

const getUserDetail = api<
  z.infer<typeof GetUserDetailRequestSchema>,
  z.infer<typeof GetUserDetailResponseSchema>
>({
  method: 'GET',
  path: API_ENDPOINT.USERS.DETAILS,
  requestSchema: GetUserDetailRequestSchema,
  responseSchema: GetUserDetailResponseSchema,
});

const updateUserRoles = api<
  z.infer<typeof UpdateUserRolesRequestSchema>,
  z.infer<typeof UpdateUserRolesResponseSchema>
>({
  method: HttpMethods.PATCH,
  path: API_ENDPOINT.USERS.UPDATE_ROLE,
  requestSchema: UpdateUserRolesRequestSchema,
  responseSchema: UpdateUserRolesResponseSchema,
});

const updateAssignTeam = (
  data: z.infer<typeof UpdateAssignTeamRequestSchema>, // The payload data (teams)
  userId: string, // The userId to be included in the URL, not in the payload
) =>
  api({
    method: HttpMethods.PATCH,
    path: API_ENDPOINT.USERS.ADD_TEAMS, // Path that contains the userId placeholder
    requestSchema: UpdateAssignTeamRequestSchema,
    responseSchema: UpdateAssignTeamResponseSchema,
  })(data, userId); // Pass userId separately to be used in the URL, not in the payload

const updateUserStatus = api<
  z.infer<typeof UpdateUserStatusRequestSchema>,
  z.infer<typeof UpdateUserStatusResponseSchema>
>({
  method: 'PUT',
  path: API_ENDPOINT.USERS.DETAILS,
  requestSchema: UpdateUserStatusRequestSchema,
  responseSchema: UpdateUserStatusResponseSchema,
});

const reset2FA = api<
  z.infer<typeof Reset2FARequestSchema>,
  z.infer<typeof Reset2FAResponseSchema>
>({
  method: 'PUT',
  path: API_ENDPOINT.USERS.LIST,
  requestSchema: Reset2FARequestSchema,
  responseSchema: Reset2FAResponseSchema,
});

const sendPasswordResetLink = api<
  z.infer<typeof SendPasswordResetLinkRequestSchema>,
  z.infer<typeof SendPasswordResetLinkResponseSchema>
>({
  method: 'POST',
  path: API_ENDPOINT.AUTHENTICATION.FORGOT_PASSWORD.RESET,
  requestSchema: SendPasswordResetLinkRequestSchema,
  responseSchema: SendPasswordResetLinkResponseSchema,
});

const resendInvitationLink = (path: string) =>
  api<
    z.infer<typeof ResendInvitationLinkRequestSchema>,
    z.infer<typeof ResendInvitationLinkResponseSchema>
  >({
    method: 'PATCH',
    path,
    requestSchema: ResendInvitationLinkRequestSchema,
    responseSchema: ResendInvitationLinkResponseSchema,
  });

const bulkDeleteUser = api<
  z.infer<typeof BulkDeleteUserRequestSchema>,
  z.infer<typeof BulkDeleteUserResponseSchema>
>({
  method: HttpMethods.DELETE,
  path: API_ENDPOINT.USERS.DELETE,
  requestSchema: BulkDeleteUserRequestSchema,
  responseSchema: BulkDeleteUserResponseSchema,
});

const bulkUpdateUserStatus = api<
  z.infer<typeof BulkUpdateUserStatusRequestSchema>,
  z.infer<typeof BulkUpdateUserStatusResponseSchema>
>({
  method: HttpMethods.PATCH,
  path: API_ENDPOINT.USERS.UPDATE_STATUS,
  requestSchema: BulkUpdateUserStatusRequestSchema,
  responseSchema: BulkUpdateUserStatusResponseSchema,
});

const bulkSendPasswordResetLink = api<
  z.infer<typeof BulkSendPassword2faResetLinkRequestSchema>,
  z.infer<typeof BulkSendPassword2faResetLinkResponseSchema>
>({
  method: HttpMethods.POST,
  path: API_ENDPOINT.USERS.BULK_PASSWORD_RESET,
  requestSchema: BulkSendPassword2faResetLinkRequestSchema,
  responseSchema: BulkSendPassword2faResetLinkResponseSchema,
});

const bulkSend2faResetLink = api<
  z.infer<typeof BulkSendPassword2faResetLinkRequestSchema>,
  z.infer<typeof BulkSendPassword2faResetLinkResponseSchema>
>({
  method: HttpMethods.POST,
  path: API_ENDPOINT.USERS.BULK_2FA_RESET,
  requestSchema: BulkSendPassword2faResetLinkRequestSchema,
  responseSchema: BulkSendPassword2faResetLinkResponseSchema,
});

const bulkExportUser = api<
  z.infer<typeof BulkExportUserRequestSchema>,
  z.infer<typeof BulkExportUserResponseSchema>
>({
  method: HttpMethods.POST,
  path: API_ENDPOINT.USERS.EXPORT,
  requestSchema: BulkExportUserRequestSchema,
  responseSchema: BulkExportUserResponseSchema,
});

const userAcceptInvite = (userId: string) =>
  api<
    z.infer<typeof UserAcceptInviteRequestSchema>,
    z.infer<typeof UserAcceptInviteResponseSchema>
  >({
    method: 'PUT',
    path: `${API_ENDPOINT.USERS.ACCEPT_INVITE_USER}/${userId}`,
    requestSchema: UserAcceptInviteRequestSchema,
    responseSchema: UserAcceptInviteResponseSchema,
  });

const userEmailExist = api<
  z.infer<typeof CheckEmailExistRequestSchema>,
  z.infer<typeof CheckEmailExistResponseSchema>
>({
  method: HttpMethods.POST,
  path: API_ENDPOINT.USERS.CHECK_EMAIL_EXIST,
  requestSchema: CheckEmailExistRequestSchema,
  responseSchema: CheckEmailExistResponseSchema,
});

const verifyUserInviteToken = api<
  z.infer<typeof VerifyUserInviteTokenRequestSchema>,
  z.infer<typeof VerifyUserInviteTokenResponseSchema>
>({
  type: 'public',
  path: API_ENDPOINT.USERS.VERIFY_TOKEN,
  method: HttpMethods.PATCH,
  requestSchema: VerifyUserInviteTokenRequestSchema,
  responseSchema: VerifyUserInviteTokenResponseSchema,
});

export const UserAPI = {
  reset2FA,
  deleteUser,
  getAllUser,
  getUserDetail,
  sendInvitation,
  updateUserRoles,
  userAcceptInvite,
  bulkDeleteUser,
  bulkExportUser,
  updateAssignTeam,
  updateUserStatus,
  userEmailExist,
  verifyUserInviteToken,
  bulkUpdateUserStatus,
  updatePersonalDetails,
  updateUserDetails,
  sendPasswordResetLink,
  bulkSendPasswordResetLink,
  bulkSend2faResetLink,
  resendInvitationLink,
};
