import { useAlert } from '../../utils/useAlert';
import { TBulkExportUser } from '@/schemas';
import { downloadCSV } from '../../utils/useDownloadCsv';
import { useBulkExportUser } from './useUsers';

export const useExportUsers = () => {
  const { showAlert } = useAlert();
  const { mutate, isPending: isExporting } = useBulkExportUser();

  const handleExport = (data: TBulkExportUser) => {
    mutate(data, {
      onSuccess: (payload: string) => {
        // The payload is directly a string
        // Use the downloadCSV function to download the file
        downloadCSV(payload, 'exported_users.csv'); // Use the CSV string and a suggested filename
      },
      onError: (error) => {
        showAlert(error.message || 'Error exporting file', 'error');
      },
    });
  };

  return { handleExport, isExporting };
};
