import shoeAsset from '@/assets/images/Image.png';

export interface ShoeSizeDataProps {
  id: number;
  ukSize: string;
  euSize: string;
  usSize: string;
}

export interface ShoesType {
  id: string;
  name: string;
}

export interface ShoeSizeInterface {
  id: string;
  name: string;
}

export interface SuggestedProductProps {
  id: string;
  name: string;
  imageSrc: string;
  price: string;
  availableSizes: ShoeSizeInterface[];
  type: ShoesType[];
}
export const ShoeSizeData: ShoeSizeDataProps[] = [
  {
    id: 1,
    ukSize: '3',
    euSize: '35.5',
    usSize: '5',
  },
  {
    id: 2,
    ukSize: '4',
    euSize: '37',
    usSize: '6',
  },
  {
    id: 3,
    ukSize: '5',
    euSize: '38',
    usSize: '7',
  },
  {
    id: 4,
    ukSize: '6',
    euSize: '39',
    usSize: '8',
  },
  {
    id: 5,
    ukSize: '7',
    euSize: '40',
    usSize: '9',
  },
  {
    id: 6,
    ukSize: '8',
    euSize: '42',
    usSize: '10',
  },
  {
    id: 7,
    ukSize: '9',
    euSize: '43',
    usSize: '11',
  },
  {
    id: 8,
    ukSize: '10',
    euSize: '44.5',
    usSize: '12',
  },
  {
    id: 9,
    ukSize: '11',
    euSize: '46',
    usSize: '13',
  },
  {
    id: 10,
    ukSize: '12',
    euSize: '47',
    usSize: '14',
  },
];
export const SuggestedProductData: SuggestedProductProps[] = [
  {
    id: '1',
    name: 'VS150 Energise II Insole',
    imageSrc: shoeAsset,
    price: '41.88',
    availableSizes: [
      { id: 'sb5', name: '12' },
      { id: '5.5wegr', name: '5.5 ' },
      { id: '13', name: '13' },
    ],
    type: [
      { id: '1', name: 'XS (3/4) / Grey' },
      { id: '2', name: 'S (5/6) / Blue' },
      { id: '3', name: 'M (7/8) / Black' },
      { id: '4', name: 'L (9/10) / Red' },
      { id: '5', name: 'XL (11/12) / White' },
    ],
  },
  {
    id: '2',
    name: 'ComfortMax Sport Insole',
    imageSrc: shoeAsset,
    price: '34.99',
    availableSizes: [
      { id: 'sb5', name: '12' },
      { id: '5.5wegr', name: '5.5 ' },
      { id: '13', name: '13' },
    ],
    type: [
      { id: '1', name: 'XS (3/4) / Grey' },
      { id: '2', name: 'S (5/6) / Blue' },
      { id: '3', name: 'M (7/8) / Black' },
      { id: '4', name: 'L (9/10) / Red' },
      { id: '5', name: 'XL (11/12) / White' },
    ],
  },
  {
    id: '3',
    name: 'GelFit Pro Insole',
    imageSrc: shoeAsset,
    price: '29.95',
    availableSizes: [
      { id: 'sb5', name: '12' },
      { id: '5.5wegr', name: '5.5 ' },
      { id: '13', name: '13' },
    ],
    type: [
      { id: '1', name: 'XS (3/4) / Grey' },
      { id: '2', name: 'S (5/6) / Blue' },
      { id: '3', name: 'M (7/8) / Black' },
      { id: '4', name: 'L (9/10) / Red' },
      { id: '5', name: 'XL (11/12) / White' },
    ],
  },
];
