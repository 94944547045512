import { useQuery } from '@tanstack/react-query';

import { AllProductsCategoriesApi } from '@/services';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';

export const useGetAllCategories = () => {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.PRODUCTS.ALL_PRODUCTS_CATEGORY],
    queryFn: () => {
      return AllProductsCategoriesApi.getAllCategories();
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
  });
};
