import { useGetAllProducts } from '@/components/hooks';
import { ErrorFound } from '@/components/ui/no-result/Error';
import { LoadingContent } from '@/components/ui/loading/Loading';
import { PRODUCTTYPE } from '@/utils/constants/product-stock.constants';
import FilterPage from '@/pages/products/all-products/filters/FilterPage';
import { Banner } from '@/pages/marketing-resources/flash-promotion-details/Banner';
import { ActionBar } from '@/pages/marketing-resources/flash-promotion-details/ActionBar';
import { useGetPromotionDetail } from '@/components/hooks/api/marketing-resource/usePromotion';
import { useParams } from 'react-router-dom';

export default function FlashPromptionDetailsPage() {
  const { promotionId } = useParams();

  const {
    data: promotionDetail,
    isError: isPromotionDetailError,
    isPending: isPromotionDetailPending,
  } = useGetPromotionDetail(promotionId ?? '');

  const { isError: isProductsError, isPending: isProductsPending } =
    useGetAllProducts();

  if (isPromotionDetailPending) return <LoadingContent />;

  if (isPromotionDetailError || isProductsError) return <ErrorFound />;

  return (
    <>
      <ActionBar promotionName={promotionDetail.data.name} />
      <Banner promo={promotionDetail.data} />
      <div className='pt-11'>
        <FilterPage
          currentCategoryName=''
          isProductListLoading={isProductsPending}
          productType={PRODUCTTYPE.FLASH_PROMOTION}
        />
      </div>
    </>
  );
}
