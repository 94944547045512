import React from 'react';
import { IconProps } from '../iconProps';

export const IconArrowsDown: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.2807 14.0306L12.5307 20.7806C12.461 20.8504 12.3783 20.9057 12.2873 20.9434C12.1962 20.9812 12.0986 21.0006 12.0001 21.0006C11.9015 21.0006 11.8039 20.9812 11.7128 20.9434C11.6218 20.9057 11.5391 20.8504 11.4694 20.7806L4.71943 14.0306C4.5787 13.8899 4.49963 13.699 4.49963 13.5C4.49963 13.301 4.5787 13.1101 4.71943 12.9694C4.86016 12.8286 5.05103 12.7496 5.25005 12.7496C5.44907 12.7496 5.63995 12.8286 5.78068 12.9694L11.2501 18.4397V3.75C11.2501 3.55109 11.3291 3.36032 11.4697 3.21967C11.6104 3.07902 11.8011 3 12.0001 3C12.199 3 12.3897 3.07902 12.5304 3.21967C12.671 3.36032 12.7501 3.55109 12.7501 3.75V18.4397L18.2194 12.9694C18.3602 12.8286 18.551 12.7496 18.7501 12.7496C18.9491 12.7496 19.1399 12.8286 19.2807 12.9694C19.4214 13.1101 19.5005 13.301 19.5005 13.5C19.5005 13.699 19.4214 13.8899 19.2807 14.0306Z'
        fill={fill ? fill : '#000000'}
      />
    </svg>
  );
};
